// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.checklist-section-items {
    margin: 0;
    padding: 0;
    font-size: 11pt;
    display: block;
}

.checklist-section-items-key {
    color: var(--color-grey900);
    white-space: pre;
    display: inline-block;
}

.checklist-section-items-value {
    color: var(--color-grey1100);
    display: inline-block;
}`, "",{"version":3,"sources":["webpack://./src/components/tables/sections/ChecklistSection.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,UAAU;IACV,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,2BAA2B;IAC3B,gBAAgB;IAChB,qBAAqB;AACzB;;AAEA;IACI,4BAA4B;IAC5B,qBAAqB;AACzB","sourcesContent":[".checklist-section-items {\n    margin: 0;\n    padding: 0;\n    font-size: 11pt;\n    display: block;\n}\n\n.checklist-section-items-key {\n    color: var(--color-grey900);\n    white-space: pre;\n    display: inline-block;\n}\n\n.checklist-section-items-value {\n    color: var(--color-grey1100);\n    display: inline-block;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
