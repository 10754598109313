import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import './PasswordForgot.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import Typography from '../components/Typography';
import { authForgotPassword } from '../actions';
import { apiRequestState } from '../utility/utility';
import InputField from '../components/core/input/InputField';
import Button from '../components/core/input/Button';

function PasswordForgot({
  dispatchForgotPassword,
  forgotPasswordRequestState,
}) {
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email address').required('Email is required'),
    }),
    initialErrors: {
      email: 'Email is required',
    },
    onSubmit: (values) => {
      const submitValues = { ...values };
      // Make sure the email is submitted in lowercase
      submitValues.email = values.email.toLocaleLowerCase();
      dispatchForgotPassword(submitValues.email);
    },
  });

  useEffect(() => {
    if (forgotPasswordRequestState === apiRequestState.SUCCESS) {
      window.location.href = '/';
    }
  }, [forgotPasswordRequestState]);

  return (
    <div className="password-forgot-container">
      <div className="password-forgot-title">
        <Typography text="Reset Password" />
      </div>

      <form className="password-forgot-form" onSubmit={formik.handleSubmit}>
        <div className="password-forgot-email">
          <InputField
            id="email"
            label="Email"
            type="email"
            placeholder="Email"
            // isRequired={true}
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <div className="password-forgot-validation-error-text">
            {formik.touched.email && formik.errors.email ? (
              <div>{formik.errors.email}</div>
            ) : null}
          </div>
        </div>

        <div className="password-forgot-buttons-container">
          <div className="password-forgot-button-submit">
            <Button
              id="buttonSubmit"
              type="submit"
              isLoading={forgotPasswordRequestState === apiRequestState.LOADING}
              primary
              disabled={!formik.isValid}
            >
              Reset Password
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
}

const mapStateToProps = (state) => ({
  forgotPasswordRequestState: state.auth.forgotPasswordRequestState,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchForgotPassword: (email) => dispatch(authForgotPassword(email)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PasswordForgot);
