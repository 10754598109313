import React, {
  useState, useEffect, forwardRef, useImperativeHandle,
} from 'react';
import _ from 'lodash';
import { apiRequestState, get, set } from '../../../utility/utility';
import CoreFormDualAction from '../../core/form/CoreFormDualAction';
import InputField from '../../core/input/InputField';

const OrganizationFormUser = forwardRef(({
  userPutRequestState,
  dispatchUserPut,
}, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [inputState, setInputState] = useState({});
  const [error, setError] = useState({});
  useImperativeHandle(ref, () => ({
    open: (user) => {
      setInputState(_.cloneDeep(user));
      setIsOpen(true);
      setError({});
    },
    close: () => setIsOpen(false),
  }));

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (userPutRequestState === apiRequestState.SUCCESS) {
      closeModal();
    }
  }, [userPutRequestState]);

  if (!isOpen) return null;

  const validateForm = (state) => {
    // Define the fields to validate
    const fieldsToValidate = ['firstName', 'lastName'];

    // Initialize an object to hold error messages for each field
    const errors = {};

    // Iterate over the fields and validate
    fieldsToValidate.forEach((fieldId) => {
      if (!get(state, fieldId)) {
        errors[fieldId] = `${_.startCase(fieldId)} is required`;
      }
    });

    // Set the error state based on the validation results
    setError(errors);
    return Object.keys(errors).length === 0;
  };

  const handleInputChange = (event, path) => {
    const newState = { ...inputState };
    set(newState, path, event.target.value);
    setInputState(newState);
    validateForm(newState);
  };

  const handleSave = () => {
    if (validateForm(inputState)) {
      // Dispatch the new, updated contacts array
      dispatchUserPut({ ...inputState });
    }
  };

  const renderInputField = (id, state, path = id) => (
    <InputField
      id={id}
      label={_.startCase(id)}
      placeholder={_.startCase(id)}
      value={get(state, path)}
      onChange={(event) => handleInputChange(event, path)}
    />
  );

  return (
    <CoreFormDualAction
      title="User"
      primaryButtonCaption="Save"
      primaryButtonCallback={() => handleSave()}
      primaryButtonCallbackStatus={userPutRequestState === apiRequestState.LOADING}
      primaryButtonDisabled={Object.keys(error).length > 0 || userPutRequestState === apiRequestState.LOADING}
      secondaryButtonCaption="Cancel"
      secondaryButtonCallback={closeModal}
    >
      <div>
        {renderInputField('firstName', inputState, 'firstName')}
        {get(error, 'firstName', false) && <div style={{ color: 'var(--color-warning)' }}>{error.firstName}</div>}
        {renderInputField('lastName', inputState, 'lastName')}
        {get(error, 'lastName', false) && <div style={{ color: 'var(--color-warning)' }}>{error.lastName}</div>}
      </div>
    </CoreFormDualAction>
  );
});

export default OrganizationFormUser;
