import React from 'react';
import _ from 'lodash';
import './ChecklistSection.css';
import { capitalizeFirstLetter } from '../../../utility/utility';
import { overlayFieldsOntoPresets } from '../../../utility/utlityFields';
import Section from './Section';

const severityLevels = [
  { severity: 'advisory', color: 'none' },
  { severity: 'warning', color: 'var(--color-warning)' },
  { severity: 'critical', color: 'var(--color-critical)' },
];

const getSeverityIndex = (severity) => severityLevels.findIndex((level) => level.severity === severity);

const processSingleCheckAlarms = (check, eta) => {
  const currentDateTime = new Date();
  const etaDate = new Date(eta);
  const rawHoursLeft = Math.ceil((etaDate - currentDateTime) / (1000 * 60 * 60));

  const isCheckboxUnchecked = check.type === 'checkbox' && !check.value;
  const isSelectEmpty = check.type === 'select' && !check.value;

  const ret = { severity: null, color: 'none' };

  check?.alarms?.forEach((alarm) => {
    const isTimeElapsed = rawHoursLeft <= alarm.hoursToEta;

    if ((check.type === 'checkbox' && isCheckboxUnchecked && isTimeElapsed)
      || (check.type === 'select' && isSelectEmpty && isTimeElapsed)) {
      if (ret.severity === null || getSeverityIndex(alarm.severity) > getSeverityIndex(ret.severity)) {
        ret.severity = alarm.severity;
        const severityObj = severityLevels.find((level) => level.severity === alarm.severity);
        ret.color = severityObj ? severityObj.color : 'none';
      }
    }
  });

  return ret;
};

export const processChecklistAlarms = (booking, presetChecklistFields = []) => {
  const mergedFields = overlayFieldsOntoPresets(booking.checklist, presetChecklistFields);
  let highestSeverityIndex = -1;
  let highestSeverity = null;
  let associatedColor = 'none';

  // Loop through each check in the mergedFields object
  Object.keys(mergedFields).forEach((fieldName) => {
    const field = mergedFields[fieldName];
    const { severity, color } = processSingleCheckAlarms(field, booking?.tracking?.container?.status?.eta);
    const severityIndex = getSeverityIndex(severity);

    // Compare the severity index to the current highest severity index
    if (severityIndex > highestSeverityIndex) {
      highestSeverityIndex = severityIndex;
      highestSeverity = severity;
      associatedColor = color;
    }
  });

  return { highestSeverity, associatedColor };
};

const renderCheckItems = (mergedChecks, eta) => Object.keys(mergedChecks).map((fieldName) => {
  const field = mergedChecks[fieldName]; // Get the actual field object using the key
  const { severity, color } = processSingleCheckAlarms(field, eta);

  let valueDisplay = '';
  if (field.type === 'checkbox') {
    valueDisplay = field?.value ? 'yes' : 'no';
  } else {
    valueDisplay = field?.value || '';
  }

  return (
    <div key={fieldName} className="checklist-section-items">
      <div className="checklist-section-items-key">
        {`${capitalizeFirstLetter(_.startCase(fieldName))}:`}
        <span> </span>
      </div>
      <div className="checklist-section-items-value">
        {valueDisplay}
      </div>
      <span style={{ color, fontStyle: 'italic', paddingLeft: '10px' }}>
        {severity}
      </span>
    </div>
  );
});

export function ChecklistSection({ booking, presetChecklistFields = [] }) {
  const mergedFields = overlayFieldsOntoPresets(booking.checklist, presetChecklistFields);

  return (
    <Section title="Checklist">
      {renderCheckItems(mergedFields, booking?.tracking?.container?.status?.eta)}
    </Section>
  );
}
