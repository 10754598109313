import React from 'react';
import './BookingsNone.css';
import { useMediaQuery } from 'react-responsive';
import { MEDIA_MOBILE_MAX_WIDTH } from '../utility/AppParameters';
import TitleCard from './cardLists/TitleCard';

function BookingsNone({ title, subtitle }) {
  const isDesktopOrLaptop = useMediaQuery({ minDeviceWidth: MEDIA_MOBILE_MAX_WIDTH });

  const renderDesktop = () => (
    <div className="bookings-none-container">
      <div className="bookings-none-title">
        {title}
      </div>
      <div className="bookings-none-subtitle">
        {subtitle}
      </div>
    </div>
  );

  const renderMobile = () => <TitleCard title={title} subtitle={subtitle} />;

  return isDesktopOrLaptop ? renderDesktop() : renderMobile();
}

export default BookingsNone;
