import React, { useEffect, useState } from 'react';
import { DndContext, closestCenter } from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import FileUploader from './FileUploader';
import ImageTile from './ImageTile';
import ImageViewer from './ImageViewer';
import './ImageGallery.css';

export const IMAGE_GALLERY_MODES = {
  display: 'display',
  select: 'select',
  rearrange: 'rearrange',
};

function ImageGallery({
  images,
  handleNewImage,
  selectedImagesIdsState,
  isSelectable,
  toggleSelectImage,
  uploaderIsEnabled,
  setImages,
  actionMode = IMAGE_GALLERY_MODES.display,
}) {
  const [fullScreenImageIndex, setFullScreenImageIndex] = useState(null);
  const [mode, setMode] = useState(actionMode);

  useEffect(() => {
    setMode(actionMode);
  }, [actionMode]);

  const handleKeyDown = (event) => {
    if (fullScreenImageIndex !== null) {
      switch (event.key) {
        case 'ArrowLeft':
          if (fullScreenImageIndex > 0) {
            setFullScreenImageIndex(fullScreenImageIndex - 1);
          }
          break;
        case 'ArrowRight':
          if (fullScreenImageIndex < images.length - 1) {
            setFullScreenImageIndex(fullScreenImageIndex + 1);
          }
          break;
        default:
          break;
      }
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [fullScreenImageIndex, images.length]);

  const handleCheckboxChange = (event, imageId) => {
    event.stopPropagation();
    toggleSelectImage(imageId);
  };

  const handleImageClick = (index) => {
    setFullScreenImageIndex(index);
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      const oldIndex = images.findIndex((image) => image._id === active.id);
      const newIndex = images.findIndex((image) => image._id === over.id);

      const updatedImages = arrayMove(images, oldIndex, newIndex);
      setImages(updatedImages);
    }
  };

  return (
    <div style={{ touchAction: 'auto' }}>
      <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <SortableContext items={images.map((image) => image._id)} strategy={verticalListSortingStrategy}>
          <div className="image-gallery-image-container">
            {images.map((image, index) => (
              <ImageTile
                key={image._id}
                id={image._id}
                index={index}
                image={image}
                handleImageClick={handleImageClick}
                handleCheckboxChange={handleCheckboxChange}
                isSelected={selectedImagesIdsState.includes(image._id)}
                isSelectable={isSelectable}
                mode={mode}
              />
            ))}
            {uploaderIsEnabled && (
              <div className="image-gallery-image-add-tile">
                <FileUploader
                  onFileDrop={handleNewImage}
                  type="file"
                  name="filePicker"
                  variant="simpleTile"
                  multiple
                />
              </div>
            )}
          </div>
        </SortableContext>
      </DndContext>

      {fullScreenImageIndex !== null && (mode === IMAGE_GALLERY_MODES.display || mode === IMAGE_GALLERY_MODES.select) && (
        <ImageViewer
          image={{
            ...images[fullScreenImageIndex],
            index: fullScreenImageIndex,
            total: images.length,
          }}
          onClose={() => setFullScreenImageIndex(null)}
          onNavigateLeft={() => setFullScreenImageIndex((prev) => Math.max(prev - 1, 0))}
          onNavigateRight={() => setFullScreenImageIndex((prev) => Math.min(prev + 1, images.length - 1))}
          isSelectable={isSelectable}
          isSelected={selectedImagesIdsState.includes(images[fullScreenImageIndex]._id)}
          handleCheckboxChange={handleCheckboxChange}
        />
      )}
    </div>
  );
}

export default ImageGallery;
