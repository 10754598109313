import React from 'react';
import { FaCopy, FaEdit, FaTrash } from 'react-icons/fa';
import { uuid } from '../../../utility/utility';
import BubbleCard from '../../BubbleCard';
import Button from '../../core/input/Button';
import InspectionProductCard from '../cards/InspectionProductCard';
import { INSPECTION_PRODUCT_FORM_MODES } from '../../../utility/InspectionsUtils';

function InspectionBubbleProducts({
  organization,
  inspectionState,
  products,
  onFormOpen,
  onDelete,
}) {
  const renderProduct = (product) => (
    <div key={product._id}>
      <InspectionProductCard
        product={product}
        actions={
          [
            {
              _id: uuid(),
              text: 'Delete',
              icon: <FaTrash title="Delete" />,
              onClick: () => onDelete(product),
            },
            {
              _id: uuid(),
              text: 'Edit',
              icon: <FaEdit title="Edit" />,
              onClick: () => onFormOpen(inspectionState, INSPECTION_PRODUCT_FORM_MODES.edit, product, organization),
            },
            {
              _id: uuid(),
              text: 'Duplicate',
              icon: <FaCopy title="Duplicate" />,
              onClick: () => onFormOpen(inspectionState, INSPECTION_PRODUCT_FORM_MODES.duplicate, product, organization),
            },
          ]
        }
      />
    </div>
  );
  return (
    <BubbleCard
      title="Products Lines"
      style={{ marginBottom: '10px' }}
    >
      {products.map((product) => (
        renderProduct(product)
      ))}
      <div style={{ display: 'flex', justifyContent: 'flex-start', paddingLeft: '10px' }}>
        <Button
          style={{ width: 'auto', paddingLeft: '10px', paddingRight: '10px' }}
          onClick={() => onFormOpen(inspectionState, INSPECTION_PRODUCT_FORM_MODES.new, null, organization)}
          primary
          simple
        >
          Add Product Line
        </Button>
      </div>
    </BubbleCard>
  );
}

export default InspectionBubbleProducts;
