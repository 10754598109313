import _ from 'lodash';
import { mergeFields } from './utlityFields';

export const createShareableFieldsObject = (obj) => {
  const shareableObj = {};

  for (const [key, value] of Object.entries(obj)) {
    if (key === 'tracking') {
      // Special handling for tracking object
      shareableObj[key] = {
        container: true,
        loggers: true,
      };
    } else if (value && typeof value === 'object') {
      shareableObj[key] = createShareableFieldsObject(value);
    } else {
      shareableObj[key] = true;
    }
  }

  return shareableObj;
};

export const extractCustomFields = (settingsObj) => {
  const customFieldsObj = {};

  for (const [category, entries] of Object.entries(settingsObj)) {
    customFieldsObj[category] = {};
    if (Array.isArray(entries)) {
      entries.forEach((entry) => {
        if (entry.id) {
          customFieldsObj[category][entry.id] = {
            type: entry.type,
            defaultValue: entry.defaultValue,
          };
        }
      });
    } else {
      // Assuming the entries are objects if they're not arrays
      // eslint-disable-next-line no-unused-vars
      for (const [notused, entry] of Object.entries(entries)) {
        if (entry.id) {
          customFieldsObj[category][entry.id] = {
            type: entry.type,
            defaultValue: entry.defaultValue,
          };
        }
      }
    }
  }

  return customFieldsObj;
};

export const createBookingShareableFields = (booking, bookingSettings) => {
  // Create the main shareable object
  const mainShareable = createShareableFieldsObject(_.pick(booking, ['booking', 'tracking']));
  // Extract custom fields from the bookingSettings
  const mergedDetails = { details: createShareableFieldsObject(mergeFields({}, bookingSettings?.details)) };
  const mergedDocuments = { documents: createShareableFieldsObject(mergeFields({}, bookingSettings?.documents)) };

  // Merge custom fields into the main shareable object
  return { ...mainShareable, ...mergedDetails, ...mergedDocuments };
};
