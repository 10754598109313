// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inspection-form-document-adding-documents {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    margin: 0;
}

.inspection-form-document-existing-documents {
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 1;
    padding: 0;
    margin: 0;
}

.document-name-group { /* New style for the container div of document name and type */
    display: flex;
    align-items: center;
    gap: 20px; /* Adjust as per your requirement for padding between file type and file name */
}

.inspection-form-document-icon-confirm-group {
    display: flex;
    align-items: center;
    gap: 25px;
}

.inspection-form-document-add-button {
    background-color: var(--color-primaryLight);
    color: var(--color-grey000);
    width: 50px;
    height: 50px;
    border-radius: 50%;
    font-size: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.2)
}

.inspection-form-document-add-button:hover {
    background-color: var(--color-grey000);
    color: var(--color-primaryLight);
}`, "",{"version":3,"sources":["webpack://./src/components/booking/forms/BookingFormAttachDocument.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,UAAU;IACV,SAAS;AACb;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,cAAc;IACd,UAAU;IACV,SAAS;AACb;;AAEA,uBAAuB,8DAA8D;IACjF,aAAa;IACb,mBAAmB;IACnB,SAAS,EAAE,+EAA+E;AAC9F;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;AACb;;AAEA;IACI,2CAA2C;IAC3C,2BAA2B;IAC3B,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,iBAAiB;IACjB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB;AACJ;;AAEA;IACI,sCAAsC;IACtC,gCAAgC;AACpC","sourcesContent":[".inspection-form-document-adding-documents {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding: 0;\n    margin: 0;\n}\n\n.inspection-form-document-existing-documents {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    line-height: 1;\n    padding: 0;\n    margin: 0;\n}\n\n.document-name-group { /* New style for the container div of document name and type */\n    display: flex;\n    align-items: center;\n    gap: 20px; /* Adjust as per your requirement for padding between file type and file name */\n}\n\n.inspection-form-document-icon-confirm-group {\n    display: flex;\n    align-items: center;\n    gap: 25px;\n}\n\n.inspection-form-document-add-button {\n    background-color: var(--color-primaryLight);\n    color: var(--color-grey000);\n    width: 50px;\n    height: 50px;\n    border-radius: 50%;\n    font-size: 1.5rem;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    box-shadow: 0px 0px 10px rgba(0,0,0,0.2)\n}\n\n.inspection-form-document-add-button:hover {\n    background-color: var(--color-grey000);\n    color: var(--color-primaryLight);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
