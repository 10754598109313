import React, { useEffect, useRef } from 'react';
import './InspectionDocumentCard.css';
import _ from 'lodash';
import { get, getTimestampString } from '../../../utility/utility';
import CoreCard from '../../core/content/CoreCard';
import { INSPECTIONS_PENDING_REPORTS_FETCH_INTERVAL_MS } from '../../../config';

function InspectionDocumentCard({
  document: doc, primaryAction, secondaryActions, dispatchInspectionsGetSingle, isLoading, inspectionId,
}) {
  const intervalRef = useRef(null);

  useEffect(() => {
    if (isLoading) {
      intervalRef.current = setInterval(() => {
        dispatchInspectionsGetSingle({ inspectionId });
      }, INSPECTIONS_PENDING_REPORTS_FETCH_INTERVAL_MS);
    } else {
      clearInterval(intervalRef.current);
    }

    return () => {
      clearInterval(intervalRef.current);
    };
  }, [doc.status, dispatchInspectionsGetSingle]);

  return (
    <CoreCard
      content={(
        <div className="inspection-document-card-info">
          <div style={{ paddingLeft: '10px', color: 'var(--color-grey900)', fontWeight: '500' }}>
            <span
              style={{
                cursor: (isLoading ? null : 'pointer'),
                textDecoration: 'underline',
              }}
              onClick={isLoading ? null : primaryAction}
            >
              {_.startCase(get(doc, 'id'))}
            </span>
            {' '}
          </div>
          <div style={{ paddingLeft: '10px', color: 'var(--color-grey700)' }}>
            {(get(doc, 'description'))}
          </div>
          {isLoading ? null : (
            <div style={{ paddingLeft: '10px', color: 'var(--color-grey700)' }}>
              {get(getTimestampString(doc.storedAt), 'all', '')}
              {' '}
              {`(${get(doc, 'size')})`}
              {' '}
              {get(doc, 'isSubmitted', false) && 'Submitted'}
            </div>
          )}
        </div>
      )}
      actions={secondaryActions}
      isLoading={isLoading}
    />
  );
}

export default InspectionDocumentCard;
