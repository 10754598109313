import { React } from 'react';
import './BackgroundVideo.css';

function BackgroundVideo({ videoSrc }) {
  return (
    <video className="video-container" autoPlay loop muted>
      <source src={videoSrc} type="video/mp4" />
      {/* Add more <source> elements for different video formats if needed */}
    </video>
  );
}

export default BackgroundVideo;
