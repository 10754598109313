// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.date-picker-container {
    background-color: var(--color-grey000);
    border-radius: 5px;
    height: 40px;
    width: 100%;
    box-sizing: border-box;
    border: none;
}

.date-picker {
    background-color: var(--color-grey000);
    border-radius: 5px;
    height: 100%;  /* take up full height of its container */
    width: 90%;
    text-indent: 15px;
    box-sizing: border-box;
    border: none; /* Use 'none' instead of 0 for clarity */
    padding-right: 20px;
    margin: 0;
    color: var(--color-grey1100);
    appearance: none; /* can help in removing default browser styles */
}

.date-picker-container:hover,
.date-picker-container:focus {
    outline: 3px solid var(--color-primaryLight);
    outline-offset: -3px; 
}

.date-picker-label {
    color: var(--color-grey000);
    margin-bottom: 0px;
    text-indent: 0px;
}
`, "",{"version":3,"sources":["webpack://./src/components/core/input/DatePicker.css"],"names":[],"mappings":"AAAA;IACI,sCAAsC;IACtC,kBAAkB;IAClB,YAAY;IACZ,WAAW;IACX,sBAAsB;IACtB,YAAY;AAChB;;AAEA;IACI,sCAAsC;IACtC,kBAAkB;IAClB,YAAY,GAAG,yCAAyC;IACxD,UAAU;IACV,iBAAiB;IACjB,sBAAsB;IACtB,YAAY,EAAE,wCAAwC;IACtD,mBAAmB;IACnB,SAAS;IACT,4BAA4B;IAC5B,gBAAgB,EAAE,gDAAgD;AACtE;;AAEA;;IAEI,4CAA4C;IAC5C,oBAAoB;AACxB;;AAEA;IACI,2BAA2B;IAC3B,kBAAkB;IAClB,gBAAgB;AACpB","sourcesContent":[".date-picker-container {\n    background-color: var(--color-grey000);\n    border-radius: 5px;\n    height: 40px;\n    width: 100%;\n    box-sizing: border-box;\n    border: none;\n}\n\n.date-picker {\n    background-color: var(--color-grey000);\n    border-radius: 5px;\n    height: 100%;  /* take up full height of its container */\n    width: 90%;\n    text-indent: 15px;\n    box-sizing: border-box;\n    border: none; /* Use 'none' instead of 0 for clarity */\n    padding-right: 20px;\n    margin: 0;\n    color: var(--color-grey1100);\n    appearance: none; /* can help in removing default browser styles */\n}\n\n.date-picker-container:hover,\n.date-picker-container:focus {\n    outline: 3px solid var(--color-primaryLight);\n    outline-offset: -3px; \n}\n\n.date-picker-label {\n    color: var(--color-grey000);\n    margin-bottom: 0px;\n    text-indent: 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
