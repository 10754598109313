import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import './FileUploader.css';
import { FaPlus } from 'react-icons/fa';

function FileUploader({ onFileDrop, variant, multiple }) {
  const onDrop = useCallback((acceptedFiles) => {
    onFileDrop(acceptedFiles);
  }, [onFileDrop]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, multiple });

  if (variant === 'simpleTile') {
    return (
      <div
        {...getRootProps()}
        className="file-uploader-simple-tile" // apply the class here
      >
        <input {...getInputProps()} />
        <FaPlus
          className="plus-icon"
          style={{ fontSize: '2em' }} // Makes the icon larger; adjust as needed
        />
      </div>
    );
  }

  // Disable ESLint for the next two lines as prop spreading is common with this library
  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <div
      className={`file-uploader-frame ${isDragActive ? 'drag-active' : ''}`}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      {isDragActive ? (
        <p>Drop the file here ...</p>
      ) : (
        // Use HTML entities for the apostrophes
        <p>Drag & drop some file here, or click to select file</p>
      )}
    </div>
  );
  /* eslint-enable react/jsx-props-no-spreading */
}

export default FileUploader;
