import {
  EMAILS_POST_REQUEST_START,
  EMAILS_POST_REQUEST_FAIL,
  EMAILS_POST_REQUEST_SUCCESS,
  EMAILS_POST_REQUEST_IDLE,
} from '../actions';
import { apiRequestState, updateObject } from '../utility/utility';

const initialState = {
  emailsPostRequestState: apiRequestState.IDLE,
};

const emailsPostStart = (state, action) => updateObject(state, {
  emailsPostRequestState: action.requestState,
});

const emailsPostFail = (state, action) => updateObject(state, {
  emailsPostRequestState: action.requestState,
});

const emailsPostSuccess = (state, action) => (
  updateObject(state, {
    emailsPostRequestState: action.requestState,
  }));

const emailsPostIdle = (state, action) => (
  updateObject(state, {
    emailsPostRequestState: action.requestState,
  }));

// eslint-disable-next-line default-param-last
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case EMAILS_POST_REQUEST_START:
      return emailsPostStart(state, action);
    case EMAILS_POST_REQUEST_FAIL:
      return emailsPostFail(state, action);
    case EMAILS_POST_REQUEST_SUCCESS:
      return emailsPostSuccess(state, action);
    case EMAILS_POST_REQUEST_IDLE:
      return emailsPostIdle(state, action);
    default:
      return state;
  }
};

export default reducer;
