import React from 'react';
import './VerticalStepProgress.css'; // Import CSS styles
import { getDateString, getTimeString } from '../utility/utility';

function VerticalStepProgress({ steps }) {
  return (
    <div className="vertical-step-progress">
      <div className="progress-steps">
        {steps.map((step) => (
          <div className={`progress-step ${step.planned ? 'in-progress' : 'done'}`} key={`${step.timestamp}-${step.description}`}>
            <div className={`step-number ${step.planned ? 'in-progress' : 'done'}`}>
              <div className="progress-line" />
            </div>
            <div>
              <div>
                <div className="milestone-line1" id="milestone-description">{`${step.description}`}</div>
                <div className="milestone-line1" id="milestone-location">{`${step.location.city}, ${step.location.country}`}</div>
              </div>
              <div>
                <div className="milestone-line2" id="milestone-timestamp">
                  {getDateString(step.timestamp)}
                  {' '}
                  {getTimeString(step.timestamp)}
                </div>
                <div className="milestone-line2" id="milestone-source">
                  (
                  {step.source}
                  {' '}
                  data)
                </div>
              </div>
            </div>

          </div>
        ))}
      </div>
    </div>
  );
}

export default VerticalStepProgress;
