import React, {
  useState, useEffect, forwardRef, useImperativeHandle,
} from 'react';
import { apiRequestState, get } from '../../../utility/utility';
import CoreFormDualAction from '../../core/form/CoreFormDualAction';
import Select from '../../Select';
import { renderProductDescription } from '../../../utility/InspectionsUtils';

const InspectionFormImagesAssociate = forwardRef(({
  dispatchInspectionImagesAssociate,
  inspectionsImagesAssociateRequestState,
}, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentInspection, setCurrentInspection] = useState({});
  const [imagesTypesState, setImagesTypesState] = useState([]);
  const [imagesIdsState, setImagesIdsState] = useState([]);
  const [inputState, setInputState] = useState({ productId: { value: null, label: '' } });

  useImperativeHandle(ref, () => ({
    open: (inspection, imagesTypes, imagesIds) => {
      setCurrentInspection(inspection);
      setImagesTypesState(imagesTypes);
      setImagesIdsState(imagesIds);
      setIsOpen(true);
    },
    close: () => setIsOpen(false),
  }));

  useEffect(() => {
    if (inspectionsImagesAssociateRequestState === apiRequestState.SUCCESS) {
      setIsOpen(false);
    }
  }, [inspectionsImagesAssociateRequestState]);

  if (!isOpen) return null;

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleInputChange = (event) => {
    const newState = { ...inputState };

    // Check if the 'Unassign' option was selected
    if (event.target.value === 'null') { // 'null' as a string here
      newState.productId.value = null; // Set to actual null
      newState.productId.label = 'Unassign';
    } else {
      // Find the selected product and set its description
      const selectedProduct = currentInspection.shipment.products.find(
        (product) => product._id === event.target.value,
      );
      newState.productId.value = event.target.value;
      newState.productId.label = selectedProduct ? selectedProduct.description : '';
    }

    setInputState(newState);
  };

  const getImageById = (images, id) => {
    for (const image of images) {
      if (image._id === id) {
        return image;
      }
    }
    return null;
  };

  return (
    <CoreFormDualAction
      title={`Associate ${imagesIdsState.length} Image${imagesIdsState.length !== 1 ? 's' : ''}`}
      primaryButtonCaption="Ok"
      primaryButtonCallback={() => {
        const request = {
          productImagesToAssociate: {
            imagesIds: imagesIdsState,
            productId: get(inputState, 'productId.value', null),
          },
        };
        dispatchInspectionImagesAssociate(currentInspection, request);
      }}
      primaryButtonCallbackStatus={inspectionsImagesAssociateRequestState === apiRequestState.LOADING}
      secondaryButtonCaption="Cancel"
      secondaryButtonCallback={closeModal}
    >
      <div>
        <p>
          Pick an association option:
        </p>
        <Select
          id="productId"
          options={[{ value: 'null', label: 'Unassign' }].concat( // 'null' as a string here
            get(currentInspection, 'shipment.products', []).map((product) => ({
              value: product._id,
              label: renderProductDescription(product).all,
            })),
          )}
          onChange={handleInputChange}
          value={inputState.productId.value === null ? 'null' : inputState.productId.value} // Convert actual null to 'null' string for the select
        />
        {imagesIdsState.map((imageId) => (
          <div key={imageId} style={{ display: 'flex', justifyContent: 'center', paddingTop: '20px' }}>
            <img src={getImageById(get(currentInspection, `onsite.${imagesTypesState}`, []), imageId)?.thumbnailKey} alt={imageId} />
          </div>
        ))}
      </div>
    </CoreFormDualAction>
  );
});

export default InspectionFormImagesAssociate;
