// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table {
    border-collapse: collapse;
    border-color: var(--color-primaryDark);
    width: 100%;
    border-radius: 8px 8px 0 0;    
}

.th, td {
    /* border: 1px solid black; */
    padding: 4px;
    text-align: left;
}`, "",{"version":3,"sources":["webpack://./src/components/Table.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,sCAAsC;IACtC,WAAW;IACX,0BAA0B;AAC9B;;AAEA;IACI,6BAA6B;IAC7B,YAAY;IACZ,gBAAgB;AACpB","sourcesContent":[".table {\n    border-collapse: collapse;\n    border-color: var(--color-primaryDark);\n    width: 100%;\n    border-radius: 8px 8px 0 0;    \n}\n\n.th, td {\n    /* border: 1px solid black; */\n    padding: 4px;\n    text-align: left;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
