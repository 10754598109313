import React from 'react';
import { FaEdit } from 'react-icons/fa';
import BubbleCard from '../../BubbleCard';
import { get, uuid } from '../../../utility/utility';
import CoreCard from '../../core/content/CoreCard';

function OrganizationBubbleUser({
  user,
  handleButtonOrganizationFormUserOpen,
}) {
  const renderName = (currentUser) => {
    const result = [];

    const firstName = get(currentUser, 'firstName', false);
    const lastName = get(currentUser, 'lastName', false);

    if (firstName) result.push(firstName);
    if (lastName) result.push(lastName);
    return result.join(' ');
  };

  return (
    <BubbleCard title="User">
      <CoreCard
        key={get(user, '_id')}
        content={(
          <div>
            <div style={{ paddingLeft: '10px', paddingRight: '10px' }}>
              {renderName(user)}
            </div>
            <div style={{
              paddingLeft: '10px', paddingRight: '10px', fontSize: '12px', fontWeight: '300',
            }}
            >
              {(get(user, 'email'))}
            </div>
          </div>
        )}
        actions={[
          {
            _id: uuid(),
            text: 'Edit',
            icon: <FaEdit title="Edit" />,
            onClick: () => handleButtonOrganizationFormUserOpen(user),
          },
        ]}
      />
    </BubbleCard>
  );
}

export default OrganizationBubbleUser;
