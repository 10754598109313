import {
  CARRIERS_GET_REQUEST_START,
  CARRIERS_GET_REQUEST_FAIL,
  CARRIERS_GET_REQUEST_SUCCESS,
} from '../actions';
import { apiRequestState, updateObject } from '../utility/utility';

const initialState = {
  carriers: [],
  carriersGetRequestState: apiRequestState.IDLE,
};

const carriersGetStart = (state, action) => updateObject(state, {
  carriersGetRequestState: action.requestState,
});

const carriersGetFail = (state, action) => updateObject(state, {
  carriersGetRequestState: action.requestState,
});

const carriersGetSuccess = (state, action) => updateObject(state, {
  carriers: action.carriers,
  carriersGetRequestState: action.requestState,
});

// eslint-disable-next-line default-param-last
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CARRIERS_GET_REQUEST_START:
      return carriersGetStart(state, action);
    case CARRIERS_GET_REQUEST_FAIL:
      return carriersGetFail(state, action);
    case CARRIERS_GET_REQUEST_SUCCESS:
      return carriersGetSuccess(state, action);
    default:
      return state;
  }
};

export default reducer;
