import React, {
  useState, useEffect, forwardRef, useImperativeHandle,
} from 'react';
import _ from 'lodash';
import { apiRequestState, get } from '../../../utility/utility';
import CoreFormDualAction from '../../core/form/CoreFormDualAction';
import InputField from '../../core/input/InputField';
import { validateForm } from '../../core/input/CoreInputUtils';

const fieldsToValidate = ['marking'];
const OrganizationFormMarking = forwardRef(({
  dispatchOrganizationsPut,
  organizationPutRequestState,
}, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentMarkings, setCurrentMarkings] = useState([]);
  const [inputState, setInputState] = useState({});
  const [error, setError] = useState({});
  useImperativeHandle(ref, () => ({
    open: (organization, marking) => {
      const markings = get(organization, 'inspectionSettings.markings');
      setCurrentMarkings(_.cloneDeep(markings));
      if (marking) {
        setInputState(_.cloneDeep(marking));
      } else {
        setInputState({});
      }
      setIsOpen(true);
      setError({});
    },
    close: () => setIsOpen(false),
  }));

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (organizationPutRequestState === apiRequestState.SUCCESS) {
      closeModal();
    }
  }, [organizationPutRequestState]);

  if (!isOpen) return null;

  const onInputChange = (event) => {
    const newState = { ...inputState };
    newState[event.target.id] = event.target.value;
    setInputState(newState);
    validateForm({
      state: newState,
      fieldsToValidate,
      setError,
    });
  };

  const handleSave = () => {
    const validation = validateForm({
      state: inputState,
      fieldsToValidate,
      setError,
    });

    if (validation) {
      const markingIndex = currentMarkings.findIndex(
        (element) => get(element, '_id').toString() === inputState._id,
      );

      if (markingIndex !== -1) {
        // Update existing marking
        dispatchOrganizationsPut({
          markingsUpdates: { markingsToUpdate: [inputState] },
        });
      } else {
        // Add new defect
        dispatchOrganizationsPut({
          markingsUpdates: { markingsToAdd: [inputState] },
        });
      }
    }
  };

  const renderInputField = (id, state, path = id) => (
    <InputField
      id={id}
      label={_.startCase(id)}
      placeholder={_.startCase(id)}
      value={get(state, path)}
      onChange={(event) => onInputChange(event, path)}
    />
  );

  return (
    <CoreFormDualAction
      title="Marking"
      primaryButtonCaption="Save"
      primaryButtonCallback={() => handleSave()}
      primaryButtonCallbackStatus={organizationPutRequestState === apiRequestState.LOADING}
      primaryButtonDisabled={Object.keys(error).length > 0 || organizationPutRequestState === apiRequestState.LOADING}
      secondaryButtonCaption="Cancel"
      secondaryButtonCallback={closeModal}
    >
      {renderInputField('marking', inputState)}
    </CoreFormDualAction>
  );
});

export default OrganizationFormMarking;
