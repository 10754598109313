import React from 'react';
import _ from 'lodash';
import { FaEdit, FaTrash } from 'react-icons/fa';
import BubbleCard from '../../BubbleCard';
import { getAddressString, get, uuid } from '../../../utility/utility';
import Button from '../../core/input/Button';
import CoreCard from '../../core/content/CoreCard';

function OrganizationBubbleContacts({
  organization,
  handleButtonOrganizationFormContactOpen,
  dispatchOrganizationsPut,
}) {
  const handleContactDelete = (org, contact) => {
    const contacts = get(org, 'contacts', []);
    const contactIndex = contacts.findIndex((element) => element._id === contact._id);
    if (contactIndex >= 0) {
      dispatchOrganizationsPut({ contactsUpdates: { contactsToDelete: [contacts[contactIndex]] } });
    }
  };

  return (
    <BubbleCard title="Contacts">
      <div style={{ display: 'flex', justifyContent: 'flex-start', paddingLeft: '10px' }}>
        <Button
          style={{ width: 'auto', paddingLeft: '10px', paddingRight: '10px' }}
          primary
          simple
          onClick={() => handleButtonOrganizationFormContactOpen(organization, null)}
        >
          Add Contact
        </Button>
      </div>
      {get(organization, 'contacts', []).map((contact) => (
        <CoreCard
          key={get(contact, '_id')}
          style={{ paddingTop: '5px', paddingBottom: '5px' }}
          isHoverable
          content={(
            <div>
              <div style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                {get(contact, 'company.name')}
              </div>
              <div style={{
                paddingLeft: '10px', paddingRight: '10px', fontSize: '12px', fontWeight: '300',
              }}
              >
                {_.startCase(get(contact, 'type'))}
              </div>
              <div style={{
                paddingLeft: '10px', paddingRight: '10px', fontSize: '12px', fontWeight: '200',
              }}
              >
                {getAddressString(get(contact, 'company.address', {})).lines}
              </div>
              <div style={{
                paddingLeft: '10px', paddingRight: '10px', fontSize: '12px', fontWeight: '200',
              }}
              >
                {getAddressString(get(contact, 'company.address', {})).cityState}
              </div>
              <div style={{
                paddingLeft: '10px', paddingRight: '10px', fontSize: '12px', fontWeight: '200',
              }}
              >
                {getAddressString(get(contact, 'company.address', {})).countryPostalCode}
              </div>
            </div>
          )}
          actions={[
            {
              _id: uuid(),
              text: 'Edit',
              icon: <FaEdit title="Edit" />,
              onClick: () => handleButtonOrganizationFormContactOpen(organization, contact),
            },
            {
              _id: uuid(),
              text: 'Delete',
              icon: <FaTrash title="Delete" />,
              onClick: () => handleContactDelete(organization, contact),
            },
          ]}
        />
      ))}
    </BubbleCard>
  );
}

export default OrganizationBubbleContacts;
