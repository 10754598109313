import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';

import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import rootReducer from './reducers';
// import reportWebVitals from './reportWebVitals';
import App from './App';
import { BUGSNAG_KEY, REDUX_DEV_TOOLS_ARE_ENABLED } from './config';

if (BUGSNAG_KEY) {
  Bugsnag.start({
    apiKey: BUGSNAG_KEY,
    plugins: [new BugsnagPluginReact()],
  });
}

const ErrorBoundary = Bugsnag.getPlugin('react')
  .createErrorBoundary(React);

const root = ReactDOM.createRoot(document.getElementById('root'));
const store = configureStore({
  reducer: rootReducer,
  devTools: !!REDUX_DEV_TOOLS_ARE_ENABLED,
});

root.render(
  <ErrorBoundary>
    <Provider store={store}>
      <App />
    </Provider>
  </ErrorBoundary>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
