import React from 'react';
import { FaEdit } from 'react-icons/fa';
import { get, set, uuid } from '../../../utility/utility';
import './InspectionTabAnalysis.css';
import InspectionBubbleSamples from '../bubbles/InspectionBubbleSamples';
import InspectionBubbleQualityCheck from '../bubbles/InspectionBubbleQualityCheck';
import InspectionBubbleReportImages from '../bubbles/InspectionBubbleReportImages';

function InspectionTabAnalysis({
  organization,
  inspectionState,
  dispatchInspectionsPut,
  handleButtonInspectionSampleFormOpen,
  handleButtonInspectionDefectFormOpen,
  handleButtonInspectionSampleGeneralImagesSelectorModalOpen,
  handleButtonInspectionImagesSelectorModalOpen,
  handleButtonInspectionQualityCheckFormOpen,
  handleButtonInspectionReportImagesSelectorModalOpen,
}) {
  const handleSampleDelete = (sample) => {
    const samplesToDelete = [sample];
    const update = {};
    set(update, 'analysis.samplesToDelete', samplesToDelete);
    dispatchInspectionsPut(inspectionState, update);
  };

  return (
    <div>
      <InspectionBubbleSamples
        organization={organization}
        inspectionState={inspectionState}
        dispatchInspectionsPut={dispatchInspectionsPut}
        samples={get(inspectionState, 'analysis.samples', [])}
        onFormOpen={handleButtonInspectionSampleFormOpen}
        onDelete={handleSampleDelete}
        handleButtonInspectionDefectFormOpen={handleButtonInspectionDefectFormOpen}
        handleButtonInspectionSampleGeneralImagesSelectorModalOpen={handleButtonInspectionSampleGeneralImagesSelectorModalOpen}
        handleButtonInspectionImagesSelectorModalOpen={handleButtonInspectionImagesSelectorModalOpen}
      />
      <InspectionBubbleReportImages
        organization={organization}
        inspectionState={inspectionState}
        dispatchInspectionsPut={dispatchInspectionsPut}
        samples={get(inspectionState, 'analysis.samples', [])}
        onFormOpen={handleButtonInspectionSampleFormOpen}
        onDelete={handleSampleDelete}
        handleButtonInspectionDefectFormOpen={handleButtonInspectionDefectFormOpen}
        handleButtonInspectionSampleGeneralImagesSelectorModalOpen={handleButtonInspectionSampleGeneralImagesSelectorModalOpen}
        handleButtonInspectionReportImagesSelectorModalOpen={handleButtonInspectionReportImagesSelectorModalOpen}
      />
      <div style={{ paddingBottom: '200px' }}>
        <InspectionBubbleQualityCheck
          qualityCheck={get(inspectionState, 'analysis.qualityCheck', {})}
          actions={[
            {
              _id: uuid(),
              text: 'Edit',
              icon: <FaEdit title="Edit" />,
              onClick: () => handleButtonInspectionQualityCheckFormOpen(inspectionState),
            },
          ]}
        />
      </div>
    </div>
  );
}

export default InspectionTabAnalysis;
