import React from 'react';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { get, set, uuid } from '../../../utility/utility';
import { INSPECTION_IMAGE_TYPES, getInsectionProductFromSample, renderProductDescription } from '../../../utility/InspectionsUtils';
import InspectionDefectCard from './InspectionDefectCard';
import CoreCard from '../../core/content/CoreCard';
import ImageGallery from '../../ImageGallery';

function InspectionSampleCard({
  organization,
  inspection,
  sample,
  actions,
  dispatchInspectionsPut,
  handleButtonInspectionDefectFormOpen,
}) {
  const product = getInsectionProductFromSample(inspection, sample);
  const handleDefectDelete = (defect) => {
    const defectsToDelete = [defect];
    const update = {};
    set(update, 'analysis.defectsToDelete', defectsToDelete);
    dispatchInspectionsPut(inspection, update);
  };

  const renderSampleImages = (inspectionLocal, localSample) => {
    const sampleImages = [];
    const images = inspectionLocal.onsite[INSPECTION_IMAGE_TYPES.productImages] || [];

    images.forEach((image) => {
      // Directly access properties assuming they exist. Add checks if they might not exist.
      const imageSampleId = image.sampleId?.toString();
      const imageProductId = image.productId?.toString();
      const imageDefectId = image.defectId; // Keep as is for null/undefined check

      if (imageSampleId === localSample._id.toString()
        && imageProductId === product._id.toString()
        && (imageDefectId === null || imageDefectId === undefined)) {
        sampleImages.push(image);
      }
    });

    return sampleImages;
  };

  const renderDefectImages = (inspectionLocal, defect) => {
    const defectImages = [];
    const images = get(inspectionLocal, `onsite.${INSPECTION_IMAGE_TYPES.productImages}`, []);
    images.forEach((image) => {
      const imageDefectId = get(image, 'defectId').toString();
      const imageProductId = get(image, 'productId').toString();
      const imageSampleId = get(image, 'sampleId').toString();

      // Assuming defect and sample have been passed correctly and have _id properties.
      if (imageDefectId === get(defect, '_id').toString() && imageProductId === product._id.toString() && imageSampleId === get(sample, '_id').toString()) {
        defectImages.push(image);
      }
    });
    return defectImages; // The filter by productId after collecting images is not necessary if checked within the loop.
  };

  const renderDefect = (organizationLocal, inspectionLocal, currentSample, defect) => (
    <div key={defect._id}>
      <InspectionDefectCard
        organization={organization}
        inspection={inspectionLocal}
        defect={defect}
        actions={[
          {
            _id: uuid(),
            text: 'Delete',
            icon: <FaTrash title="Delete" />,
            onClick: () => handleDefectDelete(defect),
          },
          {
            _id: uuid(),
            text: 'Edit',
            icon: <FaEdit title="Edit" />,
            onClick: () => handleButtonInspectionDefectFormOpen(organizationLocal, inspection, sample, defect),
          },
        ]}
        images={renderDefectImages(inspectionLocal, defect)}
      />
    </div>
  );

  const renderDefects = (
    currentOrganization,
    currentInspectoin,
    currentSample,
    defects,
  ) => {
    let output = null;
    if (defects.length) {
      output = (
        <>
          {defects.map((defect) => (
            <div key={defect._id}>
              {renderDefect(
                currentOrganization,
                currentInspectoin,
                currentSample,
                defect,
              )}
            </div>
          ))}
        </>
      );
    }
    return output;
  };

  return (
    <CoreCard
      mainActionLabel="Add Defect"
      mainActionOnClick={() => handleButtonInspectionDefectFormOpen(organization, inspection, sample)}
      content={(
        <div key={get(sample, '_id')} style={{ paddingBottom: '5px' }}>
          <div style={{ color: 'var(--color-grey900)', fontWeight: '700' }}>
            <div style={{ paddingLeft: '10px', color: 'var(--color-grey900)', fontWeight: '700' }}>
              <div>{renderProductDescription(product).title}</div>
            </div>
            <div style={{ paddingLeft: '10px', color: 'var(--color-grey700)' }}>
              {renderProductDescription(product).subtitle}
            </div>
            {get(sample, 'defectChecksumIsShown', false)
              && (
                <div style={{ paddingLeft: '10px', color: 'var(--color-grey700)' }}>
                  {`Checksum ${get(sample, 'defectChecksum', 0)}%`}
                </div>
              )}
          </div>
          <div style={{ width: '350px' }}>
            <ImageGallery
              images={renderSampleImages(inspection, sample)}
            />
          </div>
        </div>
      )}
      isExpandable
      isAlwaysExpanded
      expandedContent={(
        <div>
          {
            renderDefects(
              organization,
              inspection,
              sample,
              get(sample, 'defects', []),
            )
          }
        </div>
      )}
      actions={actions}
    />
  );
}

export default InspectionSampleCard;
