import React, { useState } from 'react';
import { get } from '../../../utility/utility';
import BubbleCard from '../../BubbleCard';
import Button from '../../core/input/Button';
import ImageGallery from '../../ImageGallery';

function InspectionBubbleReportImages({
  inspectionState,
  handleButtonInspectionReportImagesSelectorModalOpen,
}) {
  const [selectedImagesIdsState, setSelectedImagesIdsState] = useState([]);

  const productImages = get(inspectionState, 'onsite.productImages', []);
  const filteredImages = productImages.filter((image) => (image.isShownOnReport === true));
  const getSelectedImagesRatio = (selectedImages, totalImages) => {
    if (!totalImages.length) {
      return '';
    }
    return `(${selectedImages.length}/${totalImages.length})`;
  };

  return (
    <BubbleCard
      title={`Image Selection for Report ${getSelectedImagesRatio(filteredImages, productImages)}`}
      style={{ marginBottom: '10px' }}
    >
      <div style={{
        paddingTop: '5px', paddingLeft: '20px', paddingRight: '20px', paddingBottom: '20px',
      }}
      >
        <ImageGallery
          images={filteredImages}
          selectedImagesIdsState={selectedImagesIdsState}
          setSelectedImagesIdsState={setSelectedImagesIdsState}
          isSelectable={false}
          uploaderIsEnabled={false}
        />
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-start', paddingLeft: '10px' }}>
        <Button
          style={{ width: 'auto', paddingLeft: '10px', paddingRight: '10px' }}
          onClick={() => handleButtonInspectionReportImagesSelectorModalOpen(inspectionState)}
          primary
          simple
        >
          Edit Images Selection for Report
        </Button>
      </div>
    </BubbleCard>
  );
}

export default InspectionBubbleReportImages;
