// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.core-card-actions-menu-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: var(--color-grey900);
}

.core-card-actions-menu {
  position: fixed;
  border-radius: 8px;
  background-color: var(--color-grey000);
  min-width: 160px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  z-index: 2;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
  overflow: hidden;
}

.core-card-actions-menu.visible {
  opacity: 1;
  visibility: visible;
}

.core-card-actions-menu-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  cursor: pointer;
}

.core-card-actions-menu-item:hover {
  background-color: var(--color-primaryLight);
}

.core-card-actions-menu-item-text {
  margin-left: 10px;
}

.core-card-actions-menu-item-icon {
  margin-left: auto;
}`, "",{"version":3,"sources":["webpack://./src/components/core/content/CoreCardActionMenu.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,2BAA2B;AAC7B;;AAEA;EACE,eAAe;EACf,kBAAkB;EAClB,sCAAsC;EACtC,gBAAgB;EAChB,2CAA2C;EAC3C,UAAU;EACV,UAAU;EACV,kBAAkB;EAClB,yCAAyC;EACzC,gBAAgB;AAClB;;AAEA;EACE,UAAU;EACV,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,2CAA2C;AAC7C;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".core-card-actions-menu-container {\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n  color: var(--color-grey900);\n}\n\n.core-card-actions-menu {\n  position: fixed;\n  border-radius: 8px;\n  background-color: var(--color-grey000);\n  min-width: 160px;\n  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);\n  z-index: 2;\n  opacity: 0;\n  visibility: hidden;\n  transition: opacity 0.3s, visibility 0.3s;\n  overflow: hidden;\n}\n\n.core-card-actions-menu.visible {\n  opacity: 1;\n  visibility: visible;\n}\n\n.core-card-actions-menu-item {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 12px 16px;\n  cursor: pointer;\n}\n\n.core-card-actions-menu-item:hover {\n  background-color: var(--color-primaryLight);\n}\n\n.core-card-actions-menu-item-text {\n  margin-left: 10px;\n}\n\n.core-card-actions-menu-item-icon {\n  margin-left: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
