import React from 'react';
import ImageGallery from './ImageGallery';
import { set } from '../utility/utility';

function InspectionImageGallery({
  title,
  images,
  handleNewImage,
  productId,
  selectedImagesIdsState,
  setSelectedImagesIdsState,
  toggleSelectImage,
  toggleSelectAll,
  isSelectable,
  uploaderIsEnabled,
  inspection,
  dispatchInspectionsPut,
  actionMode,
}) {
  const filteredImages = images.filter((image) => (productId ? image.productId === productId : image.productId == null));

  const setImages = (newImages) => {
    const update = {};
    set(update, 'onsite.productImagesToUpdate', newImages.map((image) => image._id));
    dispatchInspectionsPut(inspection, update);
  };

  return (
    <ImageGallery
      title={title}
      images={filteredImages}
      setImages={setImages}
      handleNewImage={handleNewImage}
      selectedImagesIdsState={selectedImagesIdsState}
      setSelectedImagesIdsState={setSelectedImagesIdsState}
      toggleSelectImage={toggleSelectImage}
      toggleSelectAll={toggleSelectAll}
      isSelectable={isSelectable}
      uploaderIsEnabled={uploaderIsEnabled}
      actionMode={actionMode}
    />
  );
}

export default InspectionImageGallery;
