import React from 'react';
import { FaEdit, FaTrash } from 'react-icons/fa';
import BubbleCard from '../../BubbleCard';
import { get, uuid } from '../../../utility/utility';
import CoreCard from '../../core/content/CoreCard';
import Button from '../../core/input/Button';

function OrganizationBubbleMarkings({
  organization,
  handleButtonOrganizationFormDefectOpen,
  dispatchOrganizationsPut,
}) {
  const renderMarking = (element) => {
    const result = [];

    const marking = get(element, 'marking', false);
    const description = get(element, 'description', false);

    if (marking) result.push(marking);
    if (description) result.push(description);

    return result.join(' - ');
  };

  const handleMarkingDelete = (org, defect) => {
    const markings = get(org, 'inspectionSettings.markings', []);
    const markingIndex = markings.findIndex((element) => element._id === defect._id);

    if (markingIndex >= 0) {
      // Pass only the defect _id for deletion
      dispatchOrganizationsPut({ markingsUpdates: { markingsToDelete: [markings[markingIndex]._id] } });
    }
  };

  return (
    <BubbleCard title="Markings">
      <div style={{ display: 'flex', justifyContent: 'flex-start', paddingLeft: '10px' }}>
        <Button
          style={{ width: 'auto', paddingLeft: '10px', paddingRight: '10px' }}
          primary
          simple
          onClick={() => handleButtonOrganizationFormDefectOpen(organization, null)}
        >
          Add Marking
        </Button>
      </div>
      {get(organization, 'inspectionSettings.markings', []).map((marking) => (
        <CoreCard
          key={get(marking, '_id')}
          style={{ paddingTop: '5px', paddingBottom: '5px' }}
          isHoverable
          content={(
            <div style={{ paddingLeft: '10px', paddingRight: '10px' }}>
              {renderMarking(marking)}
            </div>
          )}
          actions={[
            {
              _id: uuid(),
              text: 'Edit',
              icon: <FaEdit title="Edit" />,
              onClick: () => handleButtonOrganizationFormDefectOpen(organization, marking),
            },
            {
              _id: uuid(),
              text: 'Delete',
              icon: <FaTrash title="Delete" />,
              onClick: () => handleMarkingDelete(organization, marking),
            },
          ]}
        />
      ))}
    </BubbleCard>
  );
}

export default OrganizationBubbleMarkings;
