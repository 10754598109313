// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navigation {
    flex-direction: column;
    position: fixed;
    top: 0;
    padding-top: 50px;
    padding-bottom: 0;
    margin-bottom: 0;
    margin-top: 0;
    right: 0;
    padding-right: 0;
    height: 100%;
    width: 300px;
    background-color: var(--color-grey000);
    opacity: 100%;
    z-index: 150;
    display: flex;
}

.section {
    font-size: 16pt;
    color: var(--color-grey700);
    margin-top: 50px;
    margin-left: 50px;
    font-weight: 700;
    vertical-align: middle;
    text-align: left;
}

.section:hover {
    color: var(--color-grey1100);
}

.sectionLine {
    margin-left: 50px;
    margin-right: 40px;
    border-top: 3px solid var(--color-grey700);
}`, "",{"version":3,"sources":["webpack://./src/components/Navigation.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,eAAe;IACf,MAAM;IACN,iBAAiB;IACjB,iBAAiB;IACjB,gBAAgB;IAChB,aAAa;IACb,QAAQ;IACR,gBAAgB;IAChB,YAAY;IACZ,YAAY;IACZ,sCAAsC;IACtC,aAAa;IACb,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,eAAe;IACf,2BAA2B;IAC3B,gBAAgB;IAChB,iBAAiB;IACjB,gBAAgB;IAChB,sBAAsB;IACtB,gBAAgB;AACpB;;AAEA;IACI,4BAA4B;AAChC;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,0CAA0C;AAC9C","sourcesContent":[".navigation {\n    flex-direction: column;\n    position: fixed;\n    top: 0;\n    padding-top: 50px;\n    padding-bottom: 0;\n    margin-bottom: 0;\n    margin-top: 0;\n    right: 0;\n    padding-right: 0;\n    height: 100%;\n    width: 300px;\n    background-color: var(--color-grey000);\n    opacity: 100%;\n    z-index: 150;\n    display: flex;\n}\n\n.section {\n    font-size: 16pt;\n    color: var(--color-grey700);\n    margin-top: 50px;\n    margin-left: 50px;\n    font-weight: 700;\n    vertical-align: middle;\n    text-align: left;\n}\n\n.section:hover {\n    color: var(--color-grey1100);\n}\n\n.sectionLine {\n    margin-left: 50px;\n    margin-right: 40px;\n    border-top: 3px solid var(--color-grey700);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
