import React from 'react'; // You should import React directly, not as a named import
import './SectionDivider.css';

function SectionDivider({
  label,
}) {
  return (
    <>
      <p className="section-divider-label">{label}</p>
      <div className="section-divider-line" />
    </>
  );
}

export default SectionDivider;
