// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bubble-card-container {
  padding: 10px 0px 20px 0px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.7);
  background-color: var(--color-grey000);
  border-radius: 10px;
}

.bubble-card-title-block {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bubble-card-title {
  padding-left: 10px;
  color: var(--color-primaryMedium);
  font-weight: bold;
  font-size: 16pt;
  flex: 1 1;
}`, "",{"version":3,"sources":["webpack://./src/components/BubbleCard.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,yCAAyC;EACzC,sCAAsC;EACtC,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE,kBAAkB;EAClB,iCAAiC;EACjC,iBAAiB;EACjB,eAAe;EACf,SAAO;AACT","sourcesContent":[".bubble-card-container {\n  padding: 10px 0px 20px 0px;\n  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.7);\n  background-color: var(--color-grey000);\n  border-radius: 10px;\n}\n\n.bubble-card-title-block {\n  padding: 10px;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.bubble-card-title {\n  padding-left: 10px;\n  color: var(--color-primaryMedium);\n  font-weight: bold;\n  font-size: 16pt;\n  flex: 1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
