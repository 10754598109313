import React from 'react';
import { FaArrowRight, FaDownload, FaTrash } from 'react-icons/fa';
import BubbleCard from '../../BubbleCard';
import Button from '../../core/input/Button';
import InspectionDocumentCard from '../cards/InspectionDocumentCard';
import { get, uuid } from '../../../utility/utility';

function InspectionBubbleReport({
  handleButtonInspectionGenerateReportModalOpen,
  inspection,
  organization,
  dispatchInspectionsGetSingle,
  dispatchInspectionsReportsDelete,
  dispatchInspectionsGetDocument,
  handleButtonInspectionSubmitReportModalOpen,
}) {
  const renderSecondaryActions = (generatedDocument) => {
    const secondaryActions = [];

    secondaryActions.push({
      _id: uuid(),
      text: 'Delete',
      icon: <FaTrash title="Delete" />,
      onClick: () => dispatchInspectionsReportsDelete(inspection, [generatedDocument._id]),
    });

    if (get(generatedDocument, 'status', false) !== 'pending') {
      secondaryActions.push({
        _id: uuid(),
        text: 'Download',
        icon: <FaDownload title="Download" />,
        onClick: () => dispatchInspectionsGetDocument(inspection, generatedDocument, false),
      });
      if (get(inspection, 'request.bookingId', false)) {
        secondaryActions.push({
          _id: uuid(),
          text: 'Submit',
          icon: <FaArrowRight title="Submit" />,
          onClick: () => handleButtonInspectionSubmitReportModalOpen(inspection, generatedDocument),
        });
      }
    }

    return secondaryActions;
  };

  const renderGeneratedDocument = (generatedDocument) => (
    <InspectionDocumentCard
      inspectionId={get(inspection, '_id')}
      dispatchInspectionsGetSingle={dispatchInspectionsGetSingle}
      key={generatedDocument._id}
      document={generatedDocument}
      primaryAction={() => dispatchInspectionsGetDocument(inspection, generatedDocument)}
      secondaryActions={renderSecondaryActions(generatedDocument)}
      isLoading={get(generatedDocument, 'status', false) === 'pending'}
    />
  );

  return (
    <BubbleCard
      title="Generated Documents"
      style={{ marginBottom: '10px' }}
    >
      {get(inspection, 'report.generatedDocuments', []).map((generatedDocument) => (
        renderGeneratedDocument(generatedDocument)
      ))}
      <div style={{ display: 'flex', justifyContent: 'flex-start', paddingLeft: '10px' }}>
        <Button
          style={{ width: 'auto', paddingLeft: '10px', paddingRight: '10px' }}
          onClick={() => handleButtonInspectionGenerateReportModalOpen(organization, inspection)}
          primary
          simple
        >
          Generate Report
        </Button>
      </div>
    </BubbleCard>
  );
}

export default InspectionBubbleReport;
