import {
  SUBSCRIPTIONS_GET_REQUEST_START,
  SUBSCRIPTIONS_GET_REQUEST_FAIL,
  SUBSCRIPTIONS_GET_REQUEST_SUCCESS,
  SUBSCRIPTIONS_GET_REQUEST_IDLE,
} from '../actions';
import { apiRequestState, updateObject } from '../utility/utility';

const initialState = {
  subscription: [],
  subscriptionsGetRequestState: apiRequestState.IDLE,
};

const subscriptionsGetStart = (state, action) => updateObject(state, {
  subscriptionsGetRequestState: action.requestState,
});

const subscriptionsGetFail = (state, action) => updateObject(state, {
  subscriptionsGetRequestState: action.requestState,
});

const subscriptionsGetSuccess = (state, action) => updateObject(state, {
  subscriptionsGetRequestState: action.requestState,
  subscription: action.subscription,
});

const subscriptionsGetIdle = (state, action) => updateObject(state, {
  subscriptionsGetRequestState: action.requestState,
});

// eslint-disable-next-line default-param-last
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SUBSCRIPTIONS_GET_REQUEST_START:
      return subscriptionsGetStart(state, action);
    case SUBSCRIPTIONS_GET_REQUEST_FAIL:
      return subscriptionsGetFail(state, action);
    case SUBSCRIPTIONS_GET_REQUEST_SUCCESS:
      return subscriptionsGetSuccess(state, action);
    case SUBSCRIPTIONS_GET_REQUEST_IDLE:
      return subscriptionsGetIdle(state, action);
    default:
      return state;
  }
};

export default reducer;
