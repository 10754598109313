import React from 'react';
import { FaEdit, FaTrash } from 'react-icons/fa';
import BubbleCard from '../../BubbleCard';
import { get, uuid } from '../../../utility/utility';
import CoreCard from '../../core/content/CoreCard';
import Button from '../../core/input/Button';

function OrganizationBubbleCommodities({
  organization,
  handleButtonOrganizationFormCommodityOpen,
  dispatchOrganizationsPut,
}) {
  const renderCommodity = (defect) => {
    const result = [];

    const commodity = get(defect, 'commodity', false);
    const description = get(defect, 'description', false);

    if (commodity) result.push(commodity);
    if (description) result.push(description);

    return result.join(' - ');
  };

  const handleCommodityDelete = (org, commodity) => {
    const commodities = get(org, 'inspectionSettings.commodities', []);
    const commodityIndex = commodities.findIndex((element) => element._id === commodity._id);

    if (commodityIndex >= 0) {
      // Pass only the commodity _id for deletion
      dispatchOrganizationsPut({ commoditiesUpdates: { commoditiesToDelete: [commodities[commodityIndex]._id] } });
    }
  };

  return (
    <BubbleCard title="Commodities">
      <div style={{ display: 'flex', justifyContent: 'flex-start', paddingLeft: '10px' }}>
        <Button
          style={{ width: 'auto', paddingLeft: '10px', paddingRight: '10px' }}
          primary
          simple
          onClick={() => handleButtonOrganizationFormCommodityOpen(organization, null)}
        >
          Add Commodity
        </Button>
      </div>
      {get(organization, 'inspectionSettings.commodities', []).map((commodity) => (
        <CoreCard
          key={get(commodity, '_id')}
          style={{ paddingTop: '5px', paddingBottom: '5px' }}
          isHoverable
          content={(
            <div style={{ paddingLeft: '10px', paddingRight: '10px' }}>
              {renderCommodity(commodity)}
            </div>
          )}
          actions={[
            {
              _id: uuid(),
              text: 'Edit',
              icon: <FaEdit title="Edit" />,
              onClick: () => handleButtonOrganizationFormCommodityOpen(organization, commodity),
            },
            {
              _id: uuid(),
              text: 'Delete',
              icon: <FaTrash title="Delete" />,
              onClick: () => handleCommodityDelete(organization, commodity),
            },
          ]}
        />
      ))}
    </BubbleCard>
  );
}

export default OrganizationBubbleCommodities;
