import React, {
  useState, useEffect, forwardRef, useImperativeHandle,
} from 'react';
import _ from 'lodash';
import { apiRequestState, get, set } from '../../../utility/utility';
import CoreFormDualAction from '../../core/form/CoreFormDualAction';
import InputField from '../../core/input/InputField';

const OrganizationFormOrganization = forwardRef(({
  dispatchOrganizationsPut,
  organizationPutRequestState,
}, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [inputState, setInputState] = useState({});
  const [error, setError] = useState({});
  useImperativeHandle(ref, () => ({
    open: (organization) => {
      setInputState(_.cloneDeep(get(organization, 'company', {})));
      setIsOpen(true);
      setError({});
    },
    close: () => setIsOpen(false),
  }));

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (organizationPutRequestState === apiRequestState.SUCCESS) {
      closeModal();
    }
  }, [organizationPutRequestState]);

  if (!isOpen) return null;

  const validateForm = (state) => {
    // Define the fields to validate
    const fieldsToValidate = ['name'];

    // Initialize an object to hold error messages for each field
    const errors = {};

    // Iterate over the fields and validate
    fieldsToValidate.forEach((fieldId) => {
      if (!get(state, fieldId)) {
        errors[fieldId] = `${_.startCase(fieldId)} is required`;
      }
    });

    // Set the error state based on the validation results
    setError(errors);
    return Object.keys(errors).length === 0;
  };

  const handleInputChange = (event, path) => {
    const newState = { ...inputState };
    set(newState, path, event.target.value);
    setInputState(newState);
    validateForm(newState);
  };

  const handleSave = () => {
    if (validateForm(inputState)) {
      // Dispatch the new, updated contacts array
      dispatchOrganizationsPut({ company: inputState });
    }
  };

  const renderInputField = (id, state, path = id) => (
    <InputField
      id={id}
      label={_.startCase(id)}
      placeholder={_.startCase(id)}
      value={get(state, path)}
      onChange={(event) => handleInputChange(event, path)}
    />
  );

  return (
    <CoreFormDualAction
      title="Organization"
      primaryButtonCaption="Save"
      primaryButtonCallback={() => handleSave()}
      primaryButtonCallbackStatus={organizationPutRequestState === apiRequestState.LOADING}
      primaryButtonDisabled={Object.keys(error).length > 0 || organizationPutRequestState === apiRequestState.LOADING}
      secondaryButtonCaption="Cancel"
      secondaryButtonCallback={closeModal}
    >
      <div>
        {renderInputField('name', inputState, 'name')}
        {get(error, 'name', false) && <div style={{ color: 'var(--color-warning)' }}>{error.name}</div>}
        {renderInputField('website', inputState, 'website')}
        {renderInputField('phone', inputState, 'phone')}
        {renderInputField('line1', inputState, 'address.line1')}
        {renderInputField('line2', inputState, 'address.line2')}
        {renderInputField('city', inputState, 'address.city')}
        {renderInputField('state', inputState, 'address.state')}
        {renderInputField('country', inputState, 'address.country')}
        {renderInputField('postalCode', inputState, 'address.postalCode')}
      </div>
    </CoreFormDualAction>
  );
});

export default OrganizationFormOrganization;
