import React, { useState } from 'react';
import './BookingsCardList.css';
import { FaTrash, FaEdit, FaPaperclip } from 'react-icons/fa';
import BookingCard from './BookingCard';
import BookingDetailView from './BookingDetailView';
import BookingSection from '../tables/sections/BookingSection';
import DetailsSection from '../tables/sections/DetailsSection';
import DocumentsSection from '../tables/sections/DocumentSection';
import { ChecklistSection } from '../tables/sections/ChecklistSection';
import TitleCard from './TitleCard';

function BookingsCardList({
  title,
  organization,
  handleTableHeaderClick,
  sortOptions,
  bookings,
  handleButtonBookingDeleteModalOpen,
  handleButtonBookingSaveModalOpen,
  handleButtonBookingAttachDocumentModalOpen,
  dispatchBookingsGetDocument,
}) {
  const [selectedBooking, setSelectedBooking] = useState(null);

  return (
    <div>
      {selectedBooking
        ? (
          <BookingDetailView
            onBack={() => setSelectedBooking(null)}
            actions={[
              {
                id: 'edit',
                icon: <FaEdit />,
                callback: () => handleButtonBookingSaveModalOpen(selectedBooking),
              },
              {
                id: 'attach',
                icon: <FaPaperclip />,
                callback: () => handleButtonBookingAttachDocumentModalOpen(selectedBooking, organization.bookingSettings.documents),
              },
              {
                id: 'delete',
                icon: <FaTrash />,
                callback: () => handleButtonBookingDeleteModalOpen([selectedBooking]),
              },
            ]}
          >
            <BookingSection booking={selectedBooking.booking} />
            <DetailsSection presetDetailsFields={organization.bookingSettings.details} details={selectedBooking.details} />
            <DocumentsSection booking={selectedBooking} dispatchBookingsGetDocument={dispatchBookingsGetDocument} />
            <ChecklistSection booking={selectedBooking} presetChecklistFields={organization.bookingSettings.checklist} />
          </BookingDetailView>
        )
        : null}
      <div className="bookings-cardlist-frame">
        <TitleCard
          title={title}
          sortOptions={sortOptions}
          handleTableHeaderClick={handleTableHeaderClick}
        />
        {bookings.map((booking) => (
          <BookingCard organization={organization} booking={booking} onSelect={setSelectedBooking} />
        ))}
      </div>
    </div>
  );
}

export default BookingsCardList;
