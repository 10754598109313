import React from 'react';
import { FaEdit, FaTrash } from 'react-icons/fa';
import BubbleCard from '../../BubbleCard';
import { get, uuid } from '../../../utility/utility';
import CoreCard from '../../core/content/CoreCard';
import Button from '../../core/input/Button';

function OrganizationBubbleDefects({
  organization,
  handleButtonOrganizationFormDefectOpen,
  dispatchOrganizationsPut,
}) {
  const renderDefectDescription = (defect) => {
    const result = [];

    const commodity = get(defect, 'commodity', false);
    const description = get(defect, 'description', false);

    if (commodity) result.push(commodity);
    if (description) result.push(description);

    return result.join(' - ');
  };

  const handleDefectDelete = (org, defect) => {
    const defects = get(org, 'inspectionSettings.defectDefinitions', []);
    const defectIndex = defects.findIndex((element) => element._id === defect._id);

    if (defectIndex >= 0) {
      // Pass only the defect _id for deletion
      dispatchOrganizationsPut({ defectsUpdates: { defectsToDelete: [defects[defectIndex]._id] } });
    }
  };

  return (
    <BubbleCard title="Defects">
      <div style={{ display: 'flex', justifyContent: 'flex-start', paddingLeft: '10px' }}>
        <Button
          style={{ width: 'auto', paddingLeft: '10px', paddingRight: '10px' }}
          primary
          simple
          onClick={() => handleButtonOrganizationFormDefectOpen(organization, null)}
        >
          Add Defect
        </Button>
      </div>
      {get(organization, 'inspectionSettings.defectDefinitions', []).map((defect) => (
        <CoreCard
          key={get(defect, '_id')}
          style={{ paddingTop: '5px', paddingBottom: '5px' }}
          isHoverable
          content={(
            <div>
              <div style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                {renderDefectDescription(defect)}
              </div>
              <div style={{
                paddingLeft: '10px', paddingRight: '10px', fontSize: '12px', fontWeight: '300',
              }}
              >
                {(get(defect, 'definition'))}
              </div>
            </div>
          )}
          actions={[
            {
              _id: uuid(),
              text: 'Edit',
              icon: <FaEdit title="Edit" />,
              onClick: () => handleButtonOrganizationFormDefectOpen(organization, defect),
            },
            {
              _id: uuid(),
              text: 'Delete',
              icon: <FaTrash title="Delete" />,
              onClick: () => handleDefectDelete(organization, defect),
            },
          ]}
        />
      ))}
    </BubbleCard>
  );
}

export default OrganizationBubbleDefects;
