// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.defect-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }
  
  .defect-row {
    display: flex;
    flex-direction: column;
    background-color: #f9f9f9; /* Background color for defect row */
    padding: 10px;
    border-radius: 4px;
  }
  
  .defect-description-action {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px; /* Space between description and data */
  }
  
  .defect-data-group {
    display: flex;
    justify-content: flex-start;
  }
  
  .defect-description, .defect-data, .defect-action {
    margin-right: 10px;
  }
  
  .defect-data {
    width: 75px;
  }
 `, "",{"version":3,"sources":["webpack://./src/components/inspection/tabs/InspectionTabAnalysis.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,yBAAyB,EAAE,oCAAoC;IAC/D,aAAa;IACb,kBAAkB;EACpB;;EAEA;IACE,aAAa;IACb,8BAA8B;IAC9B,mBAAmB,EAAE,uCAAuC;EAC9D;;EAEA;IACE,aAAa;IACb,2BAA2B;EAC7B;;EAEA;IACE,kBAAkB;EACpB;;EAEA;IACE,WAAW;EACb","sourcesContent":[".defect-group {\n    display: flex;\n    flex-direction: column;\n    margin-bottom: 10px;\n  }\n  \n  .defect-row {\n    display: flex;\n    flex-direction: column;\n    background-color: #f9f9f9; /* Background color for defect row */\n    padding: 10px;\n    border-radius: 4px;\n  }\n  \n  .defect-description-action {\n    display: flex;\n    justify-content: space-between;\n    margin-bottom: 10px; /* Space between description and data */\n  }\n  \n  .defect-data-group {\n    display: flex;\n    justify-content: flex-start;\n  }\n  \n  .defect-description, .defect-data, .defect-action {\n    margin-right: 10px;\n  }\n  \n  .defect-data {\n    width: 75px;\n  }\n "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
