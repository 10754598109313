import axios from 'axios';
import _ from 'lodash';
import { API_BASE_URL } from '../config';
import { notificationsShow } from './notifications';
import localStorageIsAvailable, { apiRequestState } from '../utility/utility';
import { storeConfig } from '../utility/db';

const AUTH_PATH = 'auth';
export const AUTH_SIGN_IN_REQUEST_START = 'AUTH_SIGN_IN_REQUEST_START';
export const AUTH_SIGN_IN_REQUEST_SUCCESS = 'AUTH_SIGN_IN_REQUEST_SUCCESS';
export const AUTH_SIGN_IN_REQUEST_FAIL = 'AUTH_SIGN_IN_REQUEST_FAIL';
export const AUTH_SIGN_IN_REQUEST_IDLE = 'AUTH_SIGN_IN_REQUEST_IDLE';
export const AUTH_FORGOT_PASSWORD_REQUEST_START = 'AUTH_FORGOT_PASSWORD_REQUEST_START';
export const AUTH_FORGOT_PASSWORD_REQUEST_SUCCESS = 'AUTH_FORGOT_PASSWORD_REQUEST_SUCCESS';
export const AUTH_FORGOT_PASSWORD_REQUEST_FAIL = 'AUTH_FORGOT_PASSWORD_REQUEST_FAIL';
export const AUTH_FORGOT_PASSWORD_REQUEST_IDLE = 'AUTH_FORGOT_PASSWORD_REQUEST_IDLE';
export const AUTH_RESET_PASSWORD_REQUEST_START = 'AUTH_RESET_PASSWORD_REQUEST_START';
export const AUTH_RESET_PASSWORD_REQUEST_SUCCESS = 'AUTH_RESET_PASSWORD_REQUEST_SUCCESS';
export const AUTH_RESET_PASSWORD_REQUEST_FAIL = 'AUTH_RESET_PASSWORD_REQUEST_FAIL';
export const AUTH_RESET_PASSWORD_REQUEST_IDLE = 'AUTH_RESET_PASSWORD_REQUEST_IDLE';
export const AUTH_SIGNUP_INVITE_REQUEST_START = 'AUTH_SIGNUP_INVITE_REQUEST_START';
export const AUTH_SIGNUP_INVITE_REQUEST_SUCCESS = 'AUTH_SIGNUP_INVITE_REQUEST_SUCCESS';
export const AUTH_SIGNUP_INVITE_REQUEST_FAIL = 'AUTH_SIGNUP_INVITE_REQUEST_FAIL';
export const AUTH_SIGNUP_INVITE_REQUEST_IDLE = 'AUTH_SIGNUP_INVITE_REQUEST_IDLE';
export const LOGOUT = 'LOGOUT';

const signInStart = () => ({
  type: AUTH_SIGN_IN_REQUEST_START,
  requestState: apiRequestState.LOADING,
});

const signInSuccess = (token) => ({
  type: AUTH_SIGN_IN_REQUEST_SUCCESS,
  token,
  requestState: apiRequestState.SUCCESS,
});

const signInFail = () => ({
  type: AUTH_SIGN_IN_REQUEST_FAIL,
  requestState: apiRequestState.FAIL,
});

const signInIdle = () => ({
  type: AUTH_SIGN_IN_REQUEST_IDLE,
  requestState: apiRequestState.IDLE,
});

export const signIn = (credentionals) => (dispatch) => {
  dispatch(signInStart(credentionals));

  const signInRequest = async () => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/${AUTH_PATH}/signin`,
        _.pick(credentionals, ['email', 'password']),
      );
      if (localStorageIsAvailable() && credentionals.rememberMe) {
        localStorage.setItem('token', response.data.jwt);
      }
      dispatch(signInSuccess(response.data.jwt));
    } catch (err) {
      dispatch(signInFail());
      dispatch(notificationsShow('error', 'Invalid email or password'));
    }
  };

  signInRequest();
};

export const authForgotPasswordStart = () => ({
  type: AUTH_FORGOT_PASSWORD_REQUEST_START,
  requestState: apiRequestState.LOADING,
});

export const authForgotPasswordSuccess = () => ({
  type: AUTH_FORGOT_PASSWORD_REQUEST_SUCCESS,
  requestState: apiRequestState.SUCCESS,
});

export const authForgotPasswordFail = () => ({
  type: AUTH_FORGOT_PASSWORD_REQUEST_FAIL,
  requestState: apiRequestState.FAIL,
});

export const authForgotPasswordIdle = () => ({
  type: AUTH_FORGOT_PASSWORD_REQUEST_IDLE,
  requestState: apiRequestState.IDLE,
});

export const authForgotPassword = (email) => (dispatch) => {
  dispatch(authForgotPasswordStart());

  const authForgotPasswordRequest = async () => {
    try {
      await axios.post(`${API_BASE_URL}/${AUTH_PATH}/forgot-password`, { email });

      await dispatch(authForgotPasswordSuccess());
      await dispatch(authForgotPasswordIdle());
      await dispatch(notificationsShow('success', `Password reset link sent to ${email}`));
      window.location.href = '/';
    } catch (err) {
      dispatch(authForgotPasswordFail());
      dispatch(notificationsShow('error', 'Invalid email'));
    }
  };

  authForgotPasswordRequest();
};

export const authResetPasswordStart = () => ({
  type: AUTH_RESET_PASSWORD_REQUEST_START,
  requestState: apiRequestState.LOADING,
});

export const authResetPasswordSuccess = (token) => ({
  type: AUTH_RESET_PASSWORD_REQUEST_SUCCESS,
  token,
  requestState: apiRequestState.SUCCESS,
});

export const authResetPasswordFail = () => ({
  type: AUTH_RESET_PASSWORD_REQUEST_FAIL,
  requestState: apiRequestState.FAIL,
});

export const authResetPasswordIdle = () => ({
  type: AUTH_RESET_PASSWORD_REQUEST_IDLE,
  requestState: apiRequestState.IDLE,
});

export const authResetPassword = (credentials) => (dispatch) => {
  dispatch(authResetPasswordStart());

  const authResetPasswordRequest = async () => {
    try {
      const response = await axios.patch(`${API_BASE_URL}/${AUTH_PATH}/reset-password/${credentials.resetToken}`, _.pick(credentials, ['password']));

      if (localStorageIsAvailable() && credentials.rememberMe) {
        localStorage.setItem('token', response.data.jwt);
      }
      await dispatch(authResetPasswordSuccess(response.data.jwt));
      await dispatch(authResetPasswordIdle);
      window.location.href = '/';
    } catch (err) {
      dispatch(authResetPasswordFail());
      dispatch(notificationsShow('error', err.response.data));
    }
  };

  authResetPasswordRequest();
};

export const authSignupInviteStart = () => ({
  type: AUTH_SIGNUP_INVITE_REQUEST_START,
  requestState: apiRequestState.LOADING,
});

export const authSignupInviteSuccess = (token) => ({
  type: AUTH_SIGNUP_INVITE_REQUEST_SUCCESS,
  token,
  requestState: apiRequestState.SUCCESS,
});

export const authSignupInviteFail = () => ({
  type: AUTH_SIGNUP_INVITE_REQUEST_FAIL,
  requestState: apiRequestState.FAIL,
});

export const authSignupInviteIdle = () => ({
  type: AUTH_SIGNUP_INVITE_REQUEST_IDLE,
  requestState: apiRequestState.IDLE,
});

export const authSignupInvite = (credentials) => (dispatch) => {
  dispatch(authSignupInviteStart());

  const authSignupInviteRequest = async () => {
    try {
      const response = await axios.patch(`${API_BASE_URL}/${AUTH_PATH}/signup-invite/${credentials.token}`, _.pick(credentials, ['email', 'firstName', 'lastName', 'password']));

      if (localStorageIsAvailable() && credentials.rememberMe) {
        localStorage.setItem('token', response.data.jwt);
      }
      await dispatch(authSignupInviteSuccess(response.data.jwt));
      await dispatch(authSignupInviteIdle);
      window.location.href = '/';
    } catch (err) {
      dispatch(authSignupInviteFail());
      dispatch(notificationsShow('error', err.response.data));
    }
  };

  authSignupInviteRequest();
};

export const logout = () => ({
  type: LOGOUT,
  token: null,
});

export const signOut = () => async (dispatch) => {
  if (localStorageIsAvailable()) {
    localStorage.removeItem('token');
  }

  try {
    await storeConfig(null);
  } catch (error) {
    // console.error('Failed to store configuration:', error);
  }

  dispatch(logout());
};

export const storeToken = (token) => (dispatch) => {
  dispatch(signInStart());
  dispatch(signInSuccess(token));
  dispatch(signInIdle());
};
