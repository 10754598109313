// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.section-box {
    display: block;
    position: relative;
    z-index: 2;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 5px;
    padding: 10px;
    -webkit-backdrop-filter: blur(1px);
            backdrop-filter: blur(1px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    max-height: 300px;
    margin: 10px;
}

.section-title {
    position: absolute;
    top: 10px;
    left: 10px;
    color: var(--color-grey1100);
    margin: 0;
    font-size: 12pt;
    font-weight: bold;
    z-index: 3;
}

.section-items-container {
    padding-top: 0px;
    margin-top: 28px;
    max-height: 260px;
    /* adjusted considering the title height and top padding */
    overflow-y: auto;
    z-index: 1;
    position: relative;
    /* to contain the pseudo-element */
}

.section-items {
    color: var(--color-grey900);
    margin: 0;
    padding: 0;
    font-size: 11pt;
}

@media (max-width: 1200px) {
    .section-box {
        display: block;
        position: relative;
        z-index: 2;
        border-radius: 5px;
        padding: 10px;
        -webkit-backdrop-filter: blur(1px);
                backdrop-filter: blur(1px);
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        max-height: 300px;
        margin: 10px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/tables/sections/Section.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,kBAAkB;IAClB,UAAU;IACV,0CAA0C;IAC1C,kBAAkB;IAClB,aAAa;IACb,kCAA0B;YAA1B,0BAA0B;IAC1B,wCAAwC;IACxC,gBAAgB;IAChB,iBAAiB;IACjB,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,UAAU;IACV,4BAA4B;IAC5B,SAAS;IACT,eAAe;IACf,iBAAiB;IACjB,UAAU;AACd;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,iBAAiB;IACjB,0DAA0D;IAC1D,gBAAgB;IAChB,UAAU;IACV,kBAAkB;IAClB,kCAAkC;AACtC;;AAEA;IACI,2BAA2B;IAC3B,SAAS;IACT,UAAU;IACV,eAAe;AACnB;;AAEA;IACI;QACI,cAAc;QACd,kBAAkB;QAClB,UAAU;QACV,kBAAkB;QAClB,aAAa;QACb,kCAA0B;gBAA1B,0BAA0B;QAC1B,wCAAwC;QACxC,iBAAiB;QACjB,YAAY;IAChB;AACJ","sourcesContent":[".section-box {\n    display: block;\n    position: relative;\n    z-index: 2;\n    background-color: rgba(255, 255, 255, 0.8);\n    border-radius: 5px;\n    padding: 10px;\n    backdrop-filter: blur(1px);\n    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n    max-width: 400px;\n    max-height: 300px;\n    margin: 10px;\n}\n\n.section-title {\n    position: absolute;\n    top: 10px;\n    left: 10px;\n    color: var(--color-grey1100);\n    margin: 0;\n    font-size: 12pt;\n    font-weight: bold;\n    z-index: 3;\n}\n\n.section-items-container {\n    padding-top: 0px;\n    margin-top: 28px;\n    max-height: 260px;\n    /* adjusted considering the title height and top padding */\n    overflow-y: auto;\n    z-index: 1;\n    position: relative;\n    /* to contain the pseudo-element */\n}\n\n.section-items {\n    color: var(--color-grey900);\n    margin: 0;\n    padding: 0;\n    font-size: 11pt;\n}\n\n@media (max-width: 1200px) {\n    .section-box {\n        display: block;\n        position: relative;\n        z-index: 2;\n        border-radius: 5px;\n        padding: 10px;\n        backdrop-filter: blur(1px);\n        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n        max-height: 300px;\n        margin: 10px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
