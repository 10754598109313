// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-field {
    background-color: var(--color-grey000);
    border-radius: 5px;
    height: 40px;
    width: 100%;
    opacity: 100%;
    text-indent: 15px;
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    border: none;
    flex: 1 1;
    padding-right: 30px;
    /* Make space for the icon */
}

.input-field:hover,
.input-field:focus {
    outline: 3px solid var(--color-primaryLight);
    outline-offset: -3px;
}

.input-field-label {
    color: var(--color-grey000);
    margin-bottom: 2px;
    text-indent: 0px;
}

.input-icon-container {
    position: relative;
    display: flex;
}

.input-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: var(--color-grey500);;
    pointer-events: none;
}`, "",{"version":3,"sources":["webpack://./src/components/core/input/InputField.css"],"names":[],"mappings":"AAAA;IACI,sCAAsC;IACtC,kBAAkB;IAClB,YAAY;IACZ,WAAW;IACX,aAAa;IACb,iBAAiB;IACjB,WAAW;IACX,YAAY;IACZ,sBAAsB;IACtB,YAAY;IACZ,SAAO;IACP,mBAAmB;IACnB,4BAA4B;AAChC;;AAEA;;IAEI,4CAA4C;IAC5C,oBAAoB;AACxB;;AAEA;IACI,2BAA2B;IAC3B,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,aAAa;AACjB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,QAAQ;IACR,2BAA2B;IAC3B,2BAA2B;IAC3B,oBAAoB;AACxB","sourcesContent":[".input-field {\n    background-color: var(--color-grey000);\n    border-radius: 5px;\n    height: 40px;\n    width: 100%;\n    opacity: 100%;\n    text-indent: 15px;\n    margin: 0px;\n    padding: 0px;\n    box-sizing: border-box;\n    border: none;\n    flex: 1;\n    padding-right: 30px;\n    /* Make space for the icon */\n}\n\n.input-field:hover,\n.input-field:focus {\n    outline: 3px solid var(--color-primaryLight);\n    outline-offset: -3px;\n}\n\n.input-field-label {\n    color: var(--color-grey000);\n    margin-bottom: 2px;\n    text-indent: 0px;\n}\n\n.input-icon-container {\n    position: relative;\n    display: flex;\n}\n\n.input-icon {\n    position: absolute;\n    right: 10px;\n    top: 50%;\n    transform: translateY(-50%);\n    color: var(--color-grey500);;\n    pointer-events: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
