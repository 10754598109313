import React from 'react';
import _ from 'lodash';
import CoreCard from '../../core/content/CoreCard';
import BubbleCard from '../../BubbleCard';

function InspectionBubbleDocuments({
  documents,
  dispatchBookingsGetDocument,
  bookingId,
}) {
  return (
    <BubbleCard
      title="Documents"
      style={{ marginBottom: '10px' }}
      retrieve
    >
      <CoreCard
        content={(
          <div className="inspection-bubble-documents-container">
            <div className="inspection-bubble-documents-info">
              {documents.map((doc) => (
                <div
                  key={doc.document.id}
                  style={{
                    paddingLeft: '10px',
                    paddingBottom: '10px',
                    color: 'var(--color-grey900)',
                    cursor: 'pointer',
                    textDecoration: 'underline',
                  }}
                  onClick={() => dispatchBookingsGetDocument({ _id: bookingId }, _.camelCase(doc.document.id))}
                >
                  {`${_.startCase(doc.document.id)}`}
                </div>
              ))}
            </div>
          </div>
        )}
      />
    </BubbleCard>
  );
}

export default InspectionBubbleDocuments;
