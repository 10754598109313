import React from 'react'; // Remember to import React directly
import { Link } from 'react-router-dom';
import './Navigation.css';
import { connect } from 'react-redux';

function Navigation({
  isShown, options, onClick, signOut,
}) {
  const navigation = document.querySelector('.navigation');

  if (navigation !== null) {
    if (isShown) {
      navigation.style.display = 'flex';
    } else {
      navigation.style.display = 'none';
    }
  }

  const showSections = () => {
    if (isShown) {
      return (
        <div className="navigation">
          {options.map((option) => (
            <div key={option}>
              <div className="section">
                <Link
                  style={{ color: 'inherit', textDecoration: 'none' }}
                  to={`/${option.toLowerCase()}`}
                  onClick={onClick}
                >
                  {option}
                </Link>
              </div>
              <div className="sectionLine" />
            </div>
          ))}
          <div className="section" onClick={signOut}>
            <Link
              style={{ color: 'inherit', textDecoration: 'none' }}
              to="/"
              onClick={onClick}
            >
              Sign Out
            </Link>
          </div>
        </div>
      );
    }
    return null; // Add this line
  };

  return (
    <>
      {showSections()}
    </>
  );
}

const mapStateToProps = (state) => ({
  me: state.users.me,
});

const mapDispatchToProps = () => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
