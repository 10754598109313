import React, {
  useState, useEffect, forwardRef, useImperativeHandle,
} from 'react';
import { apiRequestState, get } from '../../../utility/utility';
import CoreFormDualAction from '../../core/form/CoreFormDualAction';
import ImageGallery, { IMAGE_GALLERY_MODES } from '../../ImageGallery';

const InspectionFormReportImagesSelector = forwardRef(({
  dispatchInspectionReportImagesSelect,
  inspectionsReportImagesSelectRequestState,
}, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentInspection, setCurrentInspection] = useState({});
  const [selectedImagesIdsState, setSelectedImagesIdsState] = useState([]);
  const [initiallySelectedImagesIdsState, setInitiallySelectedImagesIdsState] = useState([]);

  // Toggle select/deselect all
  const toggleSelectAll = (images) => {
    const allSelected = images.every((image) => selectedImagesIdsState.includes(image._id));
    let newSelectedProductImagesIdsState;

    if (allSelected) {
      // Remove the IDs of the images that are all currently selected
      newSelectedProductImagesIdsState = selectedImagesIdsState.filter((id) => !images.some((image) => image._id === id));
    } else {
      // Add the IDs of the images that are not currently selected
      newSelectedProductImagesIdsState = [
        ...new Set([...selectedImagesIdsState, ...images.map((image) => image._id)]),
      ];
    }

    setSelectedImagesIdsState(newSelectedProductImagesIdsState);
  };

  const toggleSelectImage = (imageId) => {
    const isSelected = selectedImagesIdsState.includes(imageId);
    setSelectedImagesIdsState(
      isSelected
        ? selectedImagesIdsState.filter((id) => id !== imageId)
        : [...selectedImagesIdsState, imageId],
    );
  };

  useImperativeHandle(ref, () => ({
    open: (inspection) => {
      setCurrentInspection(inspection);
      setIsOpen(true);
      const images = get(inspection, 'onsite.productImages', []);
      setSelectedImagesIdsState(images.map((image) => (image.isShownOnReport ? image._id : null)));
      setInitiallySelectedImagesIdsState(images.map((image) => (image.isShownOnReport ? image._id : null)));
    },
    close: () => setIsOpen(false),
  }));

  useEffect(() => {
    if (inspectionsReportImagesSelectRequestState === apiRequestState.SUCCESS) {
      setIsOpen(false);
    }
  }, [inspectionsReportImagesSelectRequestState]);

  if (!isOpen) return null;

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleActionButton = () => {
    const imagesIdsToDeselect = initiallySelectedImagesIdsState.filter((id) => !selectedImagesIdsState.includes(id));
    const imagesIdsToSelect = selectedImagesIdsState.filter((id) => !initiallySelectedImagesIdsState.includes(id));

    const request = {
      imagesToSelect: {
        imagesIds: imagesIdsToSelect,
      },
      imagesToDeselect: {
        imagesIds: imagesIdsToDeselect,
      },
    };
    dispatchInspectionReportImagesSelect(currentInspection, request);
  };

  const productImages = get(currentInspection, 'onsite.productImages', []);

  return (
    <CoreFormDualAction
      title="Report Images Selector"
      primaryButtonCaption="Select"
      primaryButtonCallback={handleActionButton}
      primaryButtonCallbackStatus={inspectionsReportImagesSelectRequestState === apiRequestState.LOADING}
      secondaryButtonCaption="Cancel"
      secondaryButtonCallback={closeModal}
    >
      <div>
        <p>
          Pick images to show to report:
        </p>
        <ImageGallery
          images={productImages}
          selectedImagesIdsState={selectedImagesIdsState}
          setSelectedImagesIdsState={setSelectedImagesIdsState}
          toggleSelectImage={toggleSelectImage}
          toggleSelectAll={toggleSelectAll}
          isSelectable
          actionMode={IMAGE_GALLERY_MODES.select}
        />
      </div>
    </CoreFormDualAction>
  );
});

export default InspectionFormReportImagesSelector;
