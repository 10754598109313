import React from 'react';
import _ from 'lodash';
import { get } from '../../../utility/utility';
import CoreCard from '../../core/content/CoreCard';
import BubbleCard from '../../BubbleCard';

function InspectionBubbleQualityCheck({
  qualityCheck,
  actions,
}) {
  const renderField = ({
    qualityCheck: currentQualityCheck,
    id,
    label = _.startCase(id),
    path = id,
  }) => (
    <div>
      {`${label}: ${get(currentQualityCheck, path)}`}
    </div>
  );

  return (
    <BubbleCard
      title="Quality Check"
      style={{ marginBottom: '10px' }}
    >
      <CoreCard
        content={(
          <div style={{ paddingLeft: '10px', color: 'var(--color-grey700)' }}>
            {renderField({ qualityCheck, id: 'comments' })}
            {renderField({ qualityCheck, id: 'standards' })}
          </div>
        )}
        actions={actions}
      />
    </BubbleCard>
  );
}

export default InspectionBubbleQualityCheck;
