// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.status-banner-container {
  position: fixed;
  background-color: var(--color-grey1100);
  min-height: 10px;
  width: 75%; 
  left: 50%; 
  transform: translateX(-50%);
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.25);
  z-index: 140;
  padding-top: 40px;
  border-radius: 10px; 
}

.status-banner {
  background-color: #ff4136; 
  color: #fff; 
  padding: 10px;
  text-align: center;
  transition: opacity 0.3s;
  border-radius: 10px; 
  position: relative; 
}

.status-banner .close-button {
  position: absolute;
  top: 60%;
  right: 10px;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
}

.status-banner .close-button i {
  pointer-events: none;
}

.status-banner.warning {
  background-color: #ff851b;
}

.status-banner.error {
  background-color: #ff4136;
}

.status-banner.advisory {
  background-color: #0074d9;
}

.status-banner.success {
  background-color: #509e4b;
}
`, "",{"version":3,"sources":["webpack://./src/components/StatusBanner.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,uCAAuC;EACvC,gBAAgB;EAChB,UAAU;EACV,SAAS;EACT,2BAA2B;EAC3B,yCAAyC;EACzC,YAAY;EACZ,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,WAAW;EACX,aAAa;EACb,kBAAkB;EAClB,wBAAwB;EACxB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,WAAW;EACX,2BAA2B;EAC3B,gBAAgB;EAChB,YAAY;EACZ,WAAW;EACX,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".status-banner-container {\n  position: fixed;\n  background-color: var(--color-grey1100);\n  min-height: 10px;\n  width: 75%; \n  left: 50%; \n  transform: translateX(-50%);\n  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.25);\n  z-index: 140;\n  padding-top: 40px;\n  border-radius: 10px; \n}\n\n.status-banner {\n  background-color: #ff4136; \n  color: #fff; \n  padding: 10px;\n  text-align: center;\n  transition: opacity 0.3s;\n  border-radius: 10px; \n  position: relative; \n}\n\n.status-banner .close-button {\n  position: absolute;\n  top: 60%;\n  right: 10px;\n  transform: translateY(-50%);\n  background: none;\n  border: none;\n  color: #fff;\n  font-size: 16px;\n  cursor: pointer;\n}\n\n.status-banner .close-button i {\n  pointer-events: none;\n}\n\n.status-banner.warning {\n  background-color: #ff851b;\n}\n\n.status-banner.error {\n  background-color: #ff4136;\n}\n\n.status-banner.advisory {\n  background-color: #0074d9;\n}\n\n.status-banner.success {\n  background-color: #509e4b;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
