import React from 'react';
import './BookingCard.css';
import {
  get,
} from '../../utility/utility';
import { getBookingIdentifier } from '../../utility/BookingsUtility';
import { processChecklistAlarms } from '../tables/sections/ChecklistSection';
import { getStatusColor } from '../../utility/vesselParams';

function BookingCard({ organization, booking, onSelect }) {
  const alarm = processChecklistAlarms(booking, organization?.bookingSettings?.checklist);
  return (
    <div className="booking-card" onClick={() => onSelect(booking)}>
      <div className="booking-card-status" style={{ backgroundColor: getStatusColor(booking).color }} />
      <div className="booking-card-body">
        <div className="booking-card-line1">
          <div className="booking-card-line1-left">
            <span>
              {get(booking, 'details.loadingWeek')}
              {' '}
            </span>
            {get(booking, 'details.arrivalWeek') ? <span> &rarr; </span> : null}
            {' '}
            {/* HTML entity for right arrow */}
            <span>
              {get(booking, 'details.arrivalWeek')}
            </span>
          </div>
          <div className="booking-card-line1-right">
            {get(booking, 'booking.shipper')}
            {' '}
            #
            {getBookingIdentifier(booking).value}
          </div>
        </div>
        <div className="booking-card-product">
          {get(booking, 'booking.productDescription')}
        </div>
      </div>
      <div className="booking-card-alarm" style={{ backgroundColor: alarm.associatedColor }} />
    </div>
  );
}

export default BookingCard;
