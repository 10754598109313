import axios from 'axios';
import { apiRequestState } from '../utility/utility';
import { API_BASE_URL } from '../config';
import { notificationsShow } from './notifications';

export const EMAILS_POST_REQUEST_START = 'EMAILS_POST_REQUEST_START';
export const EMAILS_POST_REQUEST_SUCCESS = 'EMAILS_POST_REQUEST_SUCCESS';
export const EMAILS_POST_REQUEST_FAIL = 'EMAILS_POST_REQUEST_FAIL';
export const EMAILS_POST_REQUEST_IDLE = 'EMAILS_POST_REQUEST_IDLE';

export const emailsPostStart = () => ({
  type: EMAILS_POST_REQUEST_START,
  requestState: apiRequestState.LOADING,
});

export const emailsPostSuccess = () => ({
  type: EMAILS_POST_REQUEST_SUCCESS,
  requestState: apiRequestState.SUCCESS,
});

export const emailsPostFail = () => ({
  type: EMAILS_POST_REQUEST_FAIL,
  requestState: apiRequestState.FAIL,
});

export const emailsPostIdle = () => ({
  type: EMAILS_POST_REQUEST_IDLE,
  requestState: apiRequestState.IDLE,
});

export const emailsPost = (email) => (dispatch) => {
  dispatch(emailsPostStart());
  const emailsPostRequest = async () => {
    try {
      await axios.post(`${API_BASE_URL}/emails/template`, email);
      await dispatch(emailsPostSuccess());
      await dispatch(emailsPostIdle());
      await dispatch(notificationsShow('success', 'Successfully sent email'));
    } catch (err) {
      dispatch(emailsPostFail());
      dispatch(notificationsShow('error', 'Failed to send email'));
    }
  };

  emailsPostRequest();
};
