import React from 'react';
import './InspectionDefectCard.css';
import { get } from '../../../utility/utility';
import { renderDefectRanges } from '../../../utility/InspectionsUtils';
import ImageGallery from '../../ImageGallery';
import CoreCardActionMenu from '../../core/content/CoreCardActionMenu';

function InspectionDefectCard({ defect, actions, images }) {
  return (
    <div key={defect._id} className="inspection-defect-card-container">
      <div className="inspection-defect-card-info">
        <div style={{ paddingLeft: '10px', color: 'var(--color-grey900)', fontWeight: '500' }}>
          {`${get(defect, 'commodity')} - ${get(defect, 'description')}`}
        </div>
        <div style={{ paddingLeft: '10px', color: 'var(--color-grey700)' }}>
          {get(defect, 'definition')}
        </div>
        <div style={{ paddingLeft: '10px', color: 'var(--color-grey700)' }}>
          {renderDefectRanges(defect)}
        </div>
        <div style={{ paddingTop: '5px', paddingLeft: '10px', paddingRight: '10px' }}>
          <ImageGallery
            images={images}
          />
        </div>
      </div>
      <CoreCardActionMenu actions={actions} />
    </div>
  );
}

export default InspectionDefectCard;
