// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.section-divider-label {
    margin-top: 20px;
    margin-bottom: 4px;
    color: var(--color-grey000);
    font-weight: 600;
}

.section-divider-line {
    border-top: 1px solid var(--color-grey500);
    margin-bottom: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/SectionDivider.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,2BAA2B;IAC3B,gBAAgB;AACpB;;AAEA;IACI,0CAA0C;IAC1C,mBAAmB;AACvB","sourcesContent":[".section-divider-label {\n    margin-top: 20px;\n    margin-bottom: 4px;\n    color: var(--color-grey000);\n    font-weight: 600;\n}\n\n.section-divider-line {\n    border-top: 1px solid var(--color-grey500);\n    margin-bottom: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
