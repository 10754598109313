export const getBookingIdentifier = (booking) => {
  const ret = { identifier: '', value: '' };
  const { purchaseOrder, invoiceNumber } = booking.booking;

  if (purchaseOrder && purchaseOrder !== '') {
    ret.identifier = 'Purchase Order';
    ret.value = purchaseOrder;
  } else if (invoiceNumber && invoiceNumber !== '') {
    ret.identifier = 'Invoice Number';
    ret.value = invoiceNumber;
  }
  return ret;
};

export const getBookingsCount = (bookingsTotal, bookingsFound, searchInput) => {
  let countString = `(${bookingsTotal})`;
  if (searchInput.length) {
    countString = `(${bookingsFound}/${bookingsTotal})`;
  }
  return { found: bookingsFound, total: bookingsTotal, text: countString };
};
