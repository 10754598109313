import { React } from 'react';
import './Typography.css';

function Typography(props) {
  return (
    <div>
      <h1 className="h1">{props.text}</h1>
    </div>
  );
}

export default Typography;
