import _, { cloneDeep } from 'lodash';
import {
  BOOKINGS_GET_DOCUMENT_REQUEST_START,
  BOOKINGS_GET_DOCUMENT_REQUEST_FAIL,
  BOOKINGS_GET_DOCUMENT_REQUEST_SUCCESS,
  BOOKINGS_GET_REQUEST_START,
  BOOKINGS_GET_REQUEST_FAIL,
  BOOKINGS_GET_REQUEST_SUCCESS,
  BOOKINGS_GET_REQUEST_IDLE,
  BOOKINGS_GET_SHARED_REQUEST_START,
  BOOKINGS_GET_SHARED_REQUEST_FAIL,
  BOOKINGS_GET_SHARED_REQUEST_SUCCESS,
  BOOKINGS_GET_SHARED_REQUEST_IDLE,
  BOOKINGS_POST_REQUEST_START,
  BOOKINGS_POST_REQUEST_FAIL,
  BOOKINGS_POST_REQUEST_SUCCESS,
  BOOKINGS_POST_INSPECTION_REQUEST_START,
  BOOKINGS_POST_INSPECTION_REQUEST_FAIL,
  BOOKINGS_POST_INSPECTION_REQUEST_SUCCESS,
  BOOKINGS_POST_INSPECTION_REQUEST_IDLE,
  BOOKINGS_PUT_REQUEST_START,
  BOOKINGS_PUT_REQUEST_SUCCESS,
  BOOKINGS_PUT_REQUEST_FAIL,
  BOOKINGS_PUT_REQUEST_IDLE,
  BOOKINGS_PUT_DEACTIVATE_REQUEST_START,
  BOOKINGS_PUT_DEACTIVATE_REQUEST_SUCCESS,
  BOOKINGS_PUT_DEACTIVATE_REQUEST_FAIL,
  BOOKINGS_PUT_DEACTIVATE_REQUEST_IDLE,
  BOOKINGS_PUT_SHARE_REQUEST_START,
  BOOKINGS_PUT_SHARE_REQUEST_SUCCESS,
  BOOKINGS_PUT_SHARE_REQUEST_FAIL,
  BOOKINGS_PUT_SHARE_REQUEST_IDLE,
  BOOKINGS_DELETE_REQUEST_START,
  BOOKINGS_DELETE_REQUEST_FAIL,
  BOOKINGS_DELETE_REQUEST_SUCCESS,
  BOOKINGS_DELETE_REQUEST_IDLE,
  BOOKINGS_DOCUMENT_PATCH_REQUEST_START,
  BOOKINGS_DOCUMENT_PATCH_REQUEST_FAIL,
  BOOKINGS_DOCUMENT_PATCH_REQUEST_SUCCESS,
  BOOKINGS_DOCUMENT_PATCH_REQUEST_IDLE,
  BOOKINGS_DOCUMENT_DELETE_REQUEST_START,
  BOOKINGS_DOCUMENT_DELETE_REQUEST_FAIL,
  BOOKINGS_DOCUMENT_DELETE_REQUEST_SUCCESS,
  BOOKINGS_DOCUMENT_DELETE_REQUEST_IDLE,
  BOOKINGS_REFRESH_PATCH_REQUEST_START,
  BOOKINGS_REFRESH_PATCH_REQUEST_SUCCESS,
  BOOKINGS_REFRESH_PATCH_REQUEST_FAIL,
  BOOKINGS_REFRESH_PATCH_REQUEST_IDLE,
  BOOKINGS_POST_REQUEST_IDLE,
} from '../actions';
import { apiRequestState, updateObject } from '../utility/utility';

const initialState = {
  bookings: [],
  bookingsDeleteRequestState: apiRequestState.IDLE,
  bookingsDocumentDeleteRequestState: apiRequestState.IDLE,
  bookingsDocumentPatchRequestState: apiRequestState.IDLE,
  bookingsGetDocumentRequestState: apiRequestState.IDLE,
  bookingsGetRequestState: apiRequestState.IDLE,
  bookingsGetSharedRequestState: apiRequestState.IDLE,
  bookingsPostInspectionRequestState: apiRequestState.IDLE,
  bookingsPostRequestState: apiRequestState.IDLE,
  bookingsPutDeactivateRequestState: apiRequestState.IDLE,
  bookingsRefreshPatchRequestState: apiRequestState.IDLE,
  sharedBookings: [],
};

const bookingsGetDocumentStart = (state, action) => updateObject(state, {
  bookingsGetDocumentRequestState: action.requestState,
});

const bookingsGetDocumentFail = (state, action) => updateObject(state, {
  bookingsGetDocumentRequestState: action.requestState,
});

const bookingsGetDocumentSuccess = (state, action) => updateObject(state, {
  bookingsGetDocumentRequestState: action.requestState,
});

const bookingsGetStart = (state, action) => updateObject(state, {
  bookingsGetRequestState: action.requestState,
});

const bookingsGetFail = (state, action) => updateObject(state, {
  bookingsGetRequestState: action.requestState,
});

const bookingsGetSuccess = (state, action) => (
  updateObject(state, {
    bookings: action.bookings,
    bookingsGetRequestState: action.requestState,
  }));

const bookingsGetIdle = (state, action) => (
  updateObject(state, {
    bookingsGetRequestState: action.requestState,
  }));

const bookingsGetSharedStart = (state, action) => updateObject(state, {
  bookingsGetSharedRequestState: action.requestState,
});

const bookingsGetSharedFail = (state, action) => updateObject(state, {
  bookingsGetSharedRequestState: action.requestState,
});

const bookingsGetSharedSuccess = (state, action) => (
  updateObject(state, {
    sharedBookings: action.sharedBookings,
    bookingsGetSharedRequestState: action.requestState,
  }));

const bookingsGetSharedIdle = (state, action) => (
  updateObject(state, {
    bookingsGetSharedRequestState: action.requestState,
  }));

const bookingsPostStart = (state, action) => updateObject(state, {
  bookingsPostRequestState: action.requestState,
});

const bookingsPostFail = (state, action) => updateObject(state, {
  bookingsPostRequestState: action.requestState,
});

const bookingsPostIdle = (state, action) => updateObject(state, {
  bookingsPostRequestState: action.requestState,
});

const bookingsPostSuccess = (state, action) => {
  const newBookings = [...state.bookings];
  newBookings.unshift(action.data);

  return updateObject(state, {
    bookingsPostRequestState: action.requestState,
    bookings: newBookings,
  });
};

const bookingsPostInspectionStart = (state, action) => updateObject(state, {
  bookingsPostInspectionRequestState: action.requestState,
});

const bookingsPostInspectionFail = (state, action) => updateObject(state, {
  bookingsPostInspectionRequestState: action.requestState,
});

const bookingsPostInspectionIdle = (state, action) => updateObject(state, {
  bookingsPostInspectionRequestState: action.requestState,
});

const bookingsPostInspectionSuccess = (state, action) => {
  const newBookings = [...state.bookings];

  // Find the returned booking in the reducer state and update it
  action.data.forEach((booking) => {
    const index = newBookings.findIndex(
      (element) => element._id === booking._id,
    );
    newBookings[index] = _.cloneDeep(booking);
  });

  return updateObject(state, {
    bookingsPostInspectionRequestState: action.requestState,
    bookings: newBookings,
  });
};

const bookingsPutStart = (state, action) => updateObject(state, {
  bookingsPutRequestState: action.requestState,
});

const bookingsPutFail = (state, action) => updateObject(state, {
  bookingsPutRequestState: action.requestState,
});

const bookingsPutIdle = (state, action) => updateObject(state, {
  bookingsPutRequestState: action.requestState,
});

const bookingsPutSuccess = (state, action) => {
  const newBookings = [...state.bookings];

  // Find the returned booking in the reducer state and update it
  const index = newBookings.findIndex(
    (booking) => booking._id === action.booking._id,
  );
  newBookings[index] = _.cloneDeep(action.booking);

  return updateObject(state, {
    bookingsPutRequestState: action.requestState,
    bookings: newBookings,
  });
};

const bookingsPutDeactivateStart = (state, action) => updateObject(state, {
  bookingsPutDeactivateRequestState: action.requestState,
});

const bookingsPutDeactivateFail = (state, action) => updateObject(state, {
  bookingsPutDeactivateRequestState: action.requestState,
});

const bookingsPutDeactivateIdle = (state, action) => updateObject(state, {
  bookingsPutDeactivateRequestState: action.requestState,
});

const bookingsPutDeactivateSuccess = (state, action) => {
  const filteredBookings = state.bookings.filter((booking) => !action.bookings.includes(booking._id));

  return updateObject(state, {
    bookingsPutDeactivateRequestState: action.requestState,
    bookings: filteredBookings,
  });
};

const bookingsPutShareStart = (state, action) => updateObject(state, {
  bookingsPutShareRequestState: action.requestState,
});

const bookingsPutShareFail = (state, action) => updateObject(state, {
  bookingsPutShareRequestState: action.requestState,
});

const bookingsPutShareIdle = (state, action) => updateObject(state, {
  bookingsPutShareRequestState: action.requestState,
});

const bookingsPutShareSuccess = (state, action) => {
  // Deep clone the current bookings in the state.
  const newBookings = cloneDeep(state.bookings);

  // For each booking to be updated
  action.bookings.forEach((bookingToUpdate) => {
    // Find the index of the shared booking.
    const index = newBookings.findIndex((p) => p._id === bookingToUpdate._id);
    if (index !== -1) {
      // Update the shared booking.
      newBookings[index] = bookingToUpdate;
    }
  });

  return updateObject(state, {
    bookingsPutShareRequestState: action.requestState,
    bookings: newBookings,
  });
};

const bookingsDocumentPatchStart = (state, action) => updateObject(state, {
  bookingsDocumentPatchRequestState: action.requestState,
});

const bookingsDocumentPatchFail = (state, action) => updateObject(state, {
  bookingsDocumentPatchRequestState: action.requestState,
});

const bookingsDocumentPatchSuccess = (state, action) => {
  const newBookings = [...state.bookings];

  // Find index of shared booking.
  const index = newBookings.findIndex((p) => p._id === action.booking._id);

  // update the shared booking.
  newBookings[index] = cloneDeep(action.booking);

  return updateObject(state, {
    bookingsDocumentPatchRequestState: action.requestState,
    bookings: newBookings,
  });
};

const bookingsDocumentPatchIdle = (state, action) => updateObject(state, {
  bookingsDocumentPatchRequestState: action.requestState,
});

const bookingsDocumentDeleteStart = (state, action) => updateObject(state, {
  bookingsDocumentDeleteRequestState: action.requestState,
});

const bookingsDocumentDeleteFail = (state, action) => updateObject(state, {
  bookingsDocumentDeleteRequestState: action.requestState,
});

const bookingsDocumentDeleteSuccess = (state, action) => {
  const newBookings = [...state.bookings];

  // Find index of shared booking.
  const index = newBookings.findIndex((p) => p._id === action.booking._id);

  // update the shared booking.
  newBookings[index] = cloneDeep(action.booking);

  return updateObject(state, {
    bookingsDocumentDeleteRequestState: action.requestState,
    bookings: newBookings,
  });
};

const bookingsDocumentDeleteIdle = (state, action) => updateObject(state, {
  bookingsDocumentDeleteRequestState: action.requestState,
});

const bookingsRefreshPatchStart = (state, action) => updateObject(state, {
  bookingsRefreshPatchRequestState: action.requestState,
});

const bookingsRefreshPatchFail = (state, action) => updateObject(state, {
  bookingsRefreshPatchRequestState: action.requestState,
});

const bookingsRefreshPatchSuccess = (state, action) => {
  const newBookings = [...state.bookings];

  // Find the returned booking in the reducer state and update it
  const index = newBookings.findIndex(
    (booking) => booking._id === action.booking._id,
  );
  newBookings[index] = _.cloneDeep(action.booking);

  return updateObject(state, {
    bookingsRefreshPatchRequestState: action.requestState,
    bookings: newBookings,

  });
};

const bookingsRefreshPatchIdle = (state, action) => updateObject(state, {
  bookingsRefreshPatchRequestState: action.requestState,
});

const bookingsDeleteStart = (state, action) => updateObject(state, {
  bookingsDeleteRequestState: action.requestState,
});

const bookingsDeleteFail = (state, action) => updateObject(state, {
  bookingsDeleteRequestState: action.requestState,
});

const bookingsDeleteSuccess = (state, action) => {
  const filteredBookings = state.bookings.filter((booking) => !action.bookings.includes(booking._id));

  return updateObject(state, {
    bookingsDeleteRequestState: action.requestState,
    bookings: filteredBookings,
  });
};

const bookingsDeleteIdle = (state, action) => updateObject(state, {
  bookingsDeleteRequestState: action.requestState,
});

// eslint-disable-next-line default-param-last
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case BOOKINGS_GET_DOCUMENT_REQUEST_START:
      return bookingsGetDocumentStart(state, action);
    case BOOKINGS_GET_DOCUMENT_REQUEST_FAIL:
      return bookingsGetDocumentFail(state, action);
    case BOOKINGS_GET_DOCUMENT_REQUEST_SUCCESS:
      return bookingsGetDocumentSuccess(state, action);
    case BOOKINGS_GET_REQUEST_START:
      return bookingsGetStart(state, action);
    case BOOKINGS_GET_REQUEST_FAIL:
      return bookingsGetFail(state, action);
    case BOOKINGS_GET_REQUEST_SUCCESS:
      return bookingsGetSuccess(state, action);
    case BOOKINGS_GET_REQUEST_IDLE:
      return bookingsGetIdle(state, action);
    case BOOKINGS_GET_SHARED_REQUEST_START:
      return bookingsGetSharedStart(state, action);
    case BOOKINGS_GET_SHARED_REQUEST_FAIL:
      return bookingsGetSharedFail(state, action);
    case BOOKINGS_GET_SHARED_REQUEST_SUCCESS:
      return bookingsGetSharedSuccess(state, action);
    case BOOKINGS_GET_SHARED_REQUEST_IDLE:
      return bookingsGetSharedIdle(state, action);
    case BOOKINGS_POST_REQUEST_START:
      return bookingsPostStart(state, action);
    case BOOKINGS_POST_REQUEST_FAIL:
      return bookingsPostFail(state, action);
    case BOOKINGS_POST_REQUEST_IDLE:
      return bookingsPostIdle(state, action);
    case BOOKINGS_POST_REQUEST_SUCCESS:
      return bookingsPostSuccess(state, action);
    case BOOKINGS_POST_INSPECTION_REQUEST_START:
      return bookingsPostInspectionStart(state, action);
    case BOOKINGS_POST_INSPECTION_REQUEST_FAIL:
      return bookingsPostInspectionFail(state, action);
    case BOOKINGS_POST_INSPECTION_REQUEST_IDLE:
      return bookingsPostInspectionIdle(state, action);
    case BOOKINGS_POST_INSPECTION_REQUEST_SUCCESS:
      return bookingsPostInspectionSuccess(state, action);
    case BOOKINGS_PUT_REQUEST_START:
      return bookingsPutStart(state, action);
    case BOOKINGS_PUT_REQUEST_FAIL:
      return bookingsPutFail(state, action);
    case BOOKINGS_PUT_REQUEST_SUCCESS:
      return bookingsPutSuccess(state, action);
    case BOOKINGS_PUT_REQUEST_IDLE:
      return bookingsPutIdle(state, action);
    case BOOKINGS_PUT_DEACTIVATE_REQUEST_START:
      return bookingsPutDeactivateStart(state, action);
    case BOOKINGS_PUT_DEACTIVATE_REQUEST_FAIL:
      return bookingsPutDeactivateFail(state, action);
    case BOOKINGS_PUT_DEACTIVATE_REQUEST_SUCCESS:
      return bookingsPutDeactivateSuccess(state, action);
    case BOOKINGS_PUT_DEACTIVATE_REQUEST_IDLE:
      return bookingsPutDeactivateIdle(state, action);
    case BOOKINGS_PUT_SHARE_REQUEST_START:
      return bookingsPutShareStart(state, action);
    case BOOKINGS_PUT_SHARE_REQUEST_FAIL:
      return bookingsPutShareFail(state, action);
    case BOOKINGS_PUT_SHARE_REQUEST_SUCCESS:
      return bookingsPutShareSuccess(state, action);
    case BOOKINGS_PUT_SHARE_REQUEST_IDLE:
      return bookingsPutShareIdle(state, action);
    case BOOKINGS_DOCUMENT_PATCH_REQUEST_START:
      return bookingsDocumentPatchStart(state, action);
    case BOOKINGS_DOCUMENT_PATCH_REQUEST_FAIL:
      return bookingsDocumentPatchFail(state, action);
    case BOOKINGS_DOCUMENT_PATCH_REQUEST_SUCCESS:
      return bookingsDocumentPatchSuccess(state, action);
    case BOOKINGS_DOCUMENT_PATCH_REQUEST_IDLE:
      return bookingsDocumentPatchIdle(state, action);
    case BOOKINGS_DOCUMENT_DELETE_REQUEST_START:
      return bookingsDocumentDeleteStart(state, action);
    case BOOKINGS_DOCUMENT_DELETE_REQUEST_FAIL:
      return bookingsDocumentDeleteFail(state, action);
    case BOOKINGS_DOCUMENT_DELETE_REQUEST_SUCCESS:
      return bookingsDocumentDeleteSuccess(state, action);
    case BOOKINGS_DOCUMENT_DELETE_REQUEST_IDLE:
      return bookingsDocumentDeleteIdle(state, action);
    case BOOKINGS_REFRESH_PATCH_REQUEST_START:
      return bookingsRefreshPatchStart(state, action);
    case BOOKINGS_REFRESH_PATCH_REQUEST_FAIL:
      return bookingsRefreshPatchFail(state, action);
    case BOOKINGS_REFRESH_PATCH_REQUEST_SUCCESS:
      return bookingsRefreshPatchSuccess(state, action);
    case BOOKINGS_REFRESH_PATCH_REQUEST_IDLE:
      return bookingsRefreshPatchIdle(state, action);
    case BOOKINGS_DELETE_REQUEST_START:
      return bookingsDeleteStart(state, action);
    case BOOKINGS_DELETE_REQUEST_FAIL:
      return bookingsDeleteFail(state, action);
    case BOOKINGS_DELETE_REQUEST_SUCCESS:
      return bookingsDeleteSuccess(state, action);
    case BOOKINGS_DELETE_REQUEST_IDLE:
      return bookingsDeleteIdle(state, action);
    default:
      return state;
  }
};

export default reducer;
