import {
  React, useState, useEffect, forwardRef, useImperativeHandle,
} from 'react';
import { apiRequestState } from '../../../utility/utility';
import { getBookingIdentifier } from '../../../utility/BookingsUtility';
import CoreFormDualAction from '../../core/form/CoreFormDualAction';

const BookingFormArchive = forwardRef(({
  dispatchBookingsDeactivate,
  bookingsPutDeactivateRequestState,
}, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentBookings, setCurrentBookings] = useState([]);

  useImperativeHandle(ref, () => ({
    open: (bookings) => {
      setCurrentBookings([...bookings]);
      setIsOpen(true);
    },
    close: () => setIsOpen(false),
  }));

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (bookingsPutDeactivateRequestState === apiRequestState.SUCCESS) {
      closeModal();
    }
  }, [bookingsPutDeactivateRequestState]);

  if (!isOpen) return null;
  return (
    <CoreFormDualAction
      title="Archive Booking"
      primaryButtonCaption="Archive"
      primaryButtonCallback={() => dispatchBookingsDeactivate({ bookings: currentBookings.map((booking) => booking._id) })}
      primaryButtonCallbackStatus={bookingsPutDeactivateRequestState === apiRequestState.LOADING}
      secondaryButtonCaption="Cancel"
      secondaryButtonCallback={closeModal}
    >
      <p>
        Confirm stop tracking and archiving
      </p>
      {currentBookings.map((bookingElement) => (
        <p style={{ paddingLeft: '25px' }}>
          <span>- </span>
          {getBookingIdentifier(bookingElement).identifier}
          <span>: </span>
          {getBookingIdentifier(bookingElement).value}
        </p>
      ))}
    </CoreFormDualAction>
  );
});

export default BookingFormArchive;
