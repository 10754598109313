// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Select.css */
.select {
    background-color: var(--color-grey000);
    border-radius: 5px;
    height: 40px;
    width: 100%;
    opacity: 100%;
    text-indent: 15px;
    color: var(--color-grey1100);
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    border: none;
}

.select:hover,
.select:focus {
    outline: 3px solid var(--color-primaryLight);
    outline-offset: -3px;
}

.label {
    color: var(--color-grey000);
    margin-bottom: 2px;
    text-indent: 0px;
}

/* Style for the select element when it has the default value */
.select-default {
    color: var(--color-grey700);
}
`, "",{"version":3,"sources":["webpack://./src/components/Select.css"],"names":[],"mappings":"AAAA,eAAe;AACf;IACI,sCAAsC;IACtC,kBAAkB;IAClB,YAAY;IACZ,WAAW;IACX,aAAa;IACb,iBAAiB;IACjB,4BAA4B;IAC5B,WAAW;IACX,YAAY;IACZ,sBAAsB;IACtB,YAAY;AAChB;;AAEA;;IAEI,4CAA4C;IAC5C,oBAAoB;AACxB;;AAEA;IACI,2BAA2B;IAC3B,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA,+DAA+D;AAC/D;IACI,2BAA2B;AAC/B","sourcesContent":["/* Select.css */\n.select {\n    background-color: var(--color-grey000);\n    border-radius: 5px;\n    height: 40px;\n    width: 100%;\n    opacity: 100%;\n    text-indent: 15px;\n    color: var(--color-grey1100);\n    margin: 0px;\n    padding: 0px;\n    box-sizing: border-box;\n    border: none;\n}\n\n.select:hover,\n.select:focus {\n    outline: 3px solid var(--color-primaryLight);\n    outline-offset: -3px;\n}\n\n.label {\n    color: var(--color-grey000);\n    margin-bottom: 2px;\n    text-indent: 0px;\n}\n\n/* Style for the select element when it has the default value */\n.select-default {\n    color: var(--color-grey700);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
