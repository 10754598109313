import axios from 'axios';
import { StatusCodes } from 'http-status-codes';
import { API_BASE_URL } from '../config';
import { apiRequestState, get } from '../utility/utility';
import { notificationsShow } from './notifications';
import { signOut } from './auth';

export const USERS_PATH = 'users';
export const USER_GET_ME_API_CALL_START = 'USER_GET_ME_API_CALL_START';
export const USER_GET_ME_API_CALL_SUCCESS = 'USER_GET_ME_API_CALL_SUCCESS';
export const USER_GET_ME_API_CALL_FAIL = 'USER_GET_ME_API_CALL_FAIL';
export const USER_POST_API_CALL_START = 'USER_POST_API_CALL_START';
export const USER_POST_API_CALL_SUCCESS = 'USER_POST_API_CALL_SUCCESS';
export const USER_POST_API_CALL_FAIL = 'USER_POST_API_CALL_FAIL';
export const USER_POST_API_CALL_IDLE = 'USER_POST_API_CALL_IDLE';
export const USER_PUT_API_CALL_START = 'USER_PUT_API_CALL_START';
export const USER_PUT_API_CALL_SUCCESS = 'USER_PUT_API_CALL_SUCCESS';
export const USER_PUT_API_CALL_FAIL = 'USER_PUT_API_CALL_FAIL';
export const USER_PUT_API_CALL_IDLE = 'USER_PUT_API_CALL_IDLE';
export const USER_DELETE_API_CALL_START = 'USER_DELETE_API_CALL_START';
export const USER_DELETE_API_CALL_SUCCESS = 'USER_DELETE_API_CALL_SUCCESS';
export const USER_DELETE_API_CALL_FAIL = 'USER_DELETE_API_CALL_FAIL';
export const USER_DELETE_API_CALL_IDLE = 'USER_DELETE_API_CALL_IDLE';

export const userGetMeStart = () => ({
  type: USER_GET_ME_API_CALL_START,
  requestState: apiRequestState.LOADING,
});

export const userGetMeSuccess = (me) => ({
  type: USER_GET_ME_API_CALL_SUCCESS,
  me,
  requestState: apiRequestState.SUCCESS,
});

export const userGetMeFail = () => ({
  type: USER_GET_ME_API_CALL_FAIL,
  requestState: apiRequestState.FAIL,
});

export const userGetMe = () => (dispatch) => {
  dispatch(userGetMeStart());

  const userGetMeRequest = async () => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/${USERS_PATH}/me`,
      );
      dispatch(userGetMeSuccess(response.data));
    } catch (err) {
      if ((get(err, 'response.status') === StatusCodes.UNAUTHORIZED)) {
        dispatch(signOut());
      }
      dispatch(userGetMeFail());
    }
  };

  userGetMeRequest();
};

export const userPostStart = () => ({
  type: USER_POST_API_CALL_START,
  requestState: apiRequestState.LOADING,
});

export const userPostSuccess = (user) => ({
  type: USER_POST_API_CALL_SUCCESS,
  user,
  requestState: apiRequestState.SUCCESS,
});

export const userPostIdle = () => ({
  type: USER_POST_API_CALL_IDLE,
  requestState: apiRequestState.IDLE,
});

export const userPostFail = () => ({
  type: USER_POST_API_CALL_FAIL,
  requestState: apiRequestState.FAIL,
});

export const userPost = (user) => (dispatch) => {
  dispatch(userPostStart());

  const userPostRequest = async () => {
    try {
      const response = await axios.post(`${API_BASE_URL}/${USERS_PATH}/invite-internal`, user);
      await dispatch(userPostSuccess(response.data));
      await dispatch(userPostIdle());
      dispatch(notificationsShow('success', `Successfully created user with email: ${user.email}`));
    } catch (err) {
      dispatch(userPostFail());
      if (err.response.status === StatusCodes.BAD_REQUEST) {
        dispatch(notificationsShow('warning', `${err.response.data}`));
      } else {
        dispatch(notificationsShow('success', `Successfully created user with email: ${user.email}`));
      }
    }
  };

  userPostRequest();
};

export const userPutStart = () => ({
  type: USER_PUT_API_CALL_START,
  requestState: apiRequestState.LOADING,
});

export const userPutSuccess = (user) => ({
  type: USER_PUT_API_CALL_SUCCESS,
  user,
  requestState: apiRequestState.SUCCESS,
});

export const userPutIdle = () => ({
  type: USER_PUT_API_CALL_IDLE,
  requestState: apiRequestState.IDLE,
});

export const userPutFail = () => ({
  type: USER_PUT_API_CALL_FAIL,
  requestState: apiRequestState.FAIL,
});

export const userPut = (user) => (dispatch) => {
  dispatch(userPutStart());

  const userPutRequest = async () => {
    try {
      const response = await axios.put(`${API_BASE_URL}/${USERS_PATH}/${user._id}`, user);
      await dispatch(userPutSuccess(response.data));
      await dispatch(userPutIdle());
      dispatch(notificationsShow('success', `Successfully updated user: ${user.email}`));
    } catch (err) {
      dispatch(userPutFail());
      dispatch(notificationsShow('warning', `${err.response.data}`));
    }
  };

  userPutRequest();
};

export const userDeleteStart = () => ({
  type: USER_DELETE_API_CALL_START,
  requestState: apiRequestState.LOADING,
});

export const userDeleteSuccess = (user) => ({
  type: USER_DELETE_API_CALL_SUCCESS,
  user,
  requestState: apiRequestState.SUCCESS,
});

export const userDeleteIdle = () => ({
  type: USER_DELETE_API_CALL_IDLE,
  requestState: apiRequestState.IDLE,
});

export const userDeleteFail = () => ({
  type: USER_DELETE_API_CALL_FAIL,
  requestState: apiRequestState.FAIL,
});

export const userDelete = (user) => (dispatch) => {
  dispatch(userDeleteStart());

  const userDeleteRequest = async () => {
    try {
      const response = await axios.delete(
        `${API_BASE_URL}/${USERS_PATH}/${user._id}`,
      );
      await dispatch(userDeleteSuccess(response.data));
      await dispatch(userDeleteIdle());
    } catch (err) {
      dispatch(userDeleteFail());
    }
  };

  userDeleteRequest();
};
