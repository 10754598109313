// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.th th {
    position: relative;
    cursor: pointer;
    height: 40px;
    background-color: var(--color-grey000);
    color: var(--color-grey1100);
    padding-right: 10px;
  }
  
  .th .arrow-icon {
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .th th:hover {
    color: var(--color-grey700);
  }
  
  .th th:hover .arrow-icon {
    opacity: 1; 
    color: inherit; 
  }
  
  .th th > div {
    display: flex;
    justify-content: space-between; 
    align-items: center;
    width: 100%; 
  }
  `, "",{"version":3,"sources":["webpack://./src/components/TableHeader.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,eAAe;IACf,YAAY;IACZ,sCAAsC;IACtC,4BAA4B;IAC5B,mBAAmB;EACrB;;EAEA;IACE,UAAU;IACV,6BAA6B;EAC/B;;EAEA;IACE,2BAA2B;EAC7B;;EAEA;IACE,UAAU;IACV,cAAc;EAChB;;EAEA;IACE,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,WAAW;EACb","sourcesContent":[".th th {\n    position: relative;\n    cursor: pointer;\n    height: 40px;\n    background-color: var(--color-grey000);\n    color: var(--color-grey1100);\n    padding-right: 10px;\n  }\n  \n  .th .arrow-icon {\n    opacity: 0;\n    transition: opacity 0.3s ease;\n  }\n  \n  .th th:hover {\n    color: var(--color-grey700);\n  }\n  \n  .th th:hover .arrow-icon {\n    opacity: 1; \n    color: inherit; \n  }\n  \n  .th th > div {\n    display: flex;\n    justify-content: space-between; \n    align-items: center;\n    width: 100%; \n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
