import React from 'react';
import './Button.css';
import Spinner from '../../Spinner';

function Button(props) {
  return (
    <button
      className={`button ${props.primary ? 'primary' : ''} ${props.secondary ? 'secondary' : ''} ${props.outlined ? 'outlined' : ''} ${props.simple ? 'simple' : ''} ${props.rounded ? 'rounded' : ''} ${props.disabled ? 'disabled' : ''}`}
      id={props.id}
      onClick={props.onClick}
      disabled={props.disabled}
      style={props.style}
      // eslint-disable-next-line react/button-has-type
      type={props.type}
    >
      {props.isLoading ? <Spinner /> : props.children}
    </button>
  );
}

export default Button;
