import {
  React, useState, useEffect, useRef,
} from 'react';

import './BookingView.css';
import { FaSearch } from 'react-icons/fa';
import { useMediaQuery } from 'react-responsive';
import { apiRequestState, searchByNestedFields } from '../utility/utility';
import BookingsPendingShipment from './tables/BookingsPendingShipments';
import BookingsShippedOnContainers from './tables/BookingsShippedOnContainers';
import {
  searchableFieldsBookings,
} from '../utility/vesselParams';
import BookingsShared from './tables/BookingsShared';
import BookingsNone from './BookingsNone';
import BookingsLoading from './tables/BookingsLoading';
import BackgroundVideo from './BackgroudVideo';
import { MEDIA_MOBILE_MAX_WIDTH } from '../utility/AppParameters';
import BookingSaveModal from './booking/forms/BookingFormSave';
import BookingDeleteModal from './booking/forms/BookingFormDelete';
import BookingAttachDocumentModal from './booking/forms/BookingFormAttachDocument';
import BookingArchiveModal from './booking/forms/BookingFormArchive';
import BookingShareModal from './booking/forms/BookingFormShare';
import BookingSingleEmailModal from './booking/forms/BookingFormSingleEmail';
import BookingInspectionRequestModal from './booking/forms/BookingFormInspectionRequest';
import BookingCopyModal from './booking/forms/BookingFormCopy';
import Button from './core/input/Button';
import InputField from './core/input/InputField';
// import ToggleSwitch from './ToggleSwitch';

function BookingView({
  bookings,
  bookingsDeleteRequestState,
  bookingsDocumentDeleteRequestState,
  bookingsDocumentPatchRequestState,
  bookingsGetRequestState,
  bookingsGetSharedRequestState,
  bookingsPostRequestState,
  bookingsPostInspectionRequestState,
  bookingsPutDeactivateRequestState,
  bookingsPutRequestState,
  bookingsPutShareRequestState,
  carriers,
  dispatchBookingDocumentDelete,
  dispatchBookingDocumentPatch,
  dispatchBookingRefreshPatch,
  dispatchBookingsDeactivate,
  dispatchBookingsDelete,
  dispatchBookingsGetDocument,
  dispatchInspectionsGetDocument,
  dispatchBookingsPost,
  dispatchBookingsPostInspection,
  dispatchBookingsPut,
  dispatchBookingsShare,
  dispatchEmailsPost,
  dispatchNotification,
  emailsPostRequestState,
  inspectors,
  me,
  organization,
  sharedBookings,
}) {
  const isDesktopOrLaptop = useMediaQuery({ minDeviceWidth: MEDIA_MOBILE_MAX_WIDTH });

  // Modals
  const confirmationModalRef = useRef();
  const bookingModalRef = useRef();
  const deactivateModalRef = useRef();
  const shareModalRef = useRef();
  const documentModalRef = useRef();
  const emailModalRef = useRef();
  const inspectionRequestModalRef = useRef();
  const copyModalRef = useRef();

  const [searchInputState, setSearchInputState] = useState('');
  const [searchedBookingsState, setSearchedBookingsState] = useState(bookings);
  const [searchedSharedBookingsState, setSearchedSharedBookingsState] = useState(sharedBookings);
  // const [toggleActiveBookingsState, setToggleActiveBookingsState] = useState(true);
  const handleSearch = (event) => {
    setSearchInputState(event.target.value);
    const newBookingsState = searchByNestedFields(
      bookings,
      searchableFieldsBookings,
      event.target.value,
    );
    setSearchedBookingsState(newBookingsState);
    const newSharedBookingsState = searchByNestedFields(
      sharedBookings,
      searchableFieldsBookings,
      event.target.value,
    );
    setSearchedSharedBookingsState(newSharedBookingsState);
  };

  // const handleBookingsToggle = (event) => {
  //   setToggleActiveBookingsState(event.target.value);
  // };

  useEffect(() => {
    handleSearch({
      target: {
        value: '',
      },
    });
  }, [
    bookingsPostRequestState,
    bookingsGetRequestState,
    bookingsGetSharedRequestState,
  ]);

  useEffect(() => {
    handleSearch({
      target: {
        value: searchInputState,
      },
    });
  }, [
    bookings,
    bookingsPutRequestState,
    bookingsPostInspectionRequestState,
    bookingsPutShareRequestState,
    bookingsDeleteRequestState,
  ]);

  const handleButtonBookingSaveModalOpen = (booking) => { bookingModalRef.current.open(booking); };
  const handleButtonBookingDeleteModalOpen = (booking) => { confirmationModalRef.current.open(booking); };
  const handleButtonBookingArchiveModalOpen = (booking) => { deactivateModalRef.current.open(booking); };
  const handleButtonBookingShareModalOpen = (booking, bookingSettings) => { shareModalRef.current.open(booking, bookingSettings); };
  const handleButtonBookingAttachDocumentModalOpen = (booking, documentSettings) => { documentModalRef.current.open(booking, documentSettings); };
  const handleButtonBookingSingleEmailModalOpen = (booking) => { emailModalRef.current.open(booking); };
  const handleButtonBookingInspectionRequestModalOpen = (booking) => { inspectionRequestModalRef.current.open(booking); };
  const handleButtonBookingCopyModalOpen = (booking, bookingSettings) => { copyModalRef.current.open(booking, bookingSettings); };

  const renderDashboard = () => {
    if (!bookings.length && !sharedBookings.length) {
      if (bookingsGetRequestState === apiRequestState.IDLE && bookingsGetSharedRequestState === apiRequestState.IDLE) {
        return (
          <BookingsNone
            title="No bookings or containers to show"
            subtitle="Add some bookings and container to start tracking"
          />
        );
      }
      return <BookingsLoading />;
    }
    return (
      <div>
        {me?.permissions?.bookingsView ? (
          <BookingsPendingShipment
            title="Bookings"
            me={me}
            rowSelectionIsEnabled
            searchInput={searchInputState}
            organization={organization}
            headerTitles={['', 'Loading Week', 'Arrival Week', 'Purchase Order', 'Invoice Number', 'Product', 'Shipper', '', '', '']}
            bookings={bookings.filter((item) => item.tracking.container === null)}
            searchedBookings={searchedBookingsState.filter((item) => item.tracking.container === null)}
            handleButtonBookingDeleteModalOpen={handleButtonBookingDeleteModalOpen}
            handleButtonBookingSaveModalOpen={handleButtonBookingSaveModalOpen}
            handleButtonBookingArchiveModalOpen={handleButtonBookingArchiveModalOpen}
            handleButtonBookingShareModalOpen={handleButtonBookingShareModalOpen}
            handleButtonBookingSingleEmailModalOpen={handleButtonBookingSingleEmailModalOpen}
            handleButtonBookingAttachDocumentModalOpen={handleButtonBookingAttachDocumentModalOpen}
            dispatchBookingsGetDocument={dispatchBookingsGetDocument}
            bookingsDocumentPatchRequestState={bookingsDocumentPatchRequestState}
            bookingsPostRequestState={bookingsPostRequestState}
            bookingsGetRequestState={bookingsGetRequestState}
          />
        ) : null}
        {me?.permissions?.containersView && (
          <BookingsShippedOnContainers
            title="Containers"
            me={me}
            searchInput={searchInputState}
            bookings={bookings.filter((item) => item.tracking.container !== null)}
            statusLightsEnabled
            actionButtonsEnabled
            sharingSectionEnabled
            alarmsAreEnabled
            organization={organization}
            detailsSectionIsEnabled
            presetDetailsFields={organization?.bookingSettings?.details}
            presetChecklistFields={organization?.bookingSettings?.checklist}
            rowSelectionIsEnabled
            headerTitles={['', 'ETA', 'Pickup Date', 'Carrier', 'Vessel', 'Container', 'PO', 'Shipper', 'Product', 'Origin', 'Destination', '', '', '', '', '', '']}
            searchedBookings={searchedBookingsState.filter((item) => item.tracking.container !== null)}
            handleButtonBookingSaveModalOpen={handleButtonBookingSaveModalOpen}
            handleButtonBookingDeleteModalOpen={handleButtonBookingDeleteModalOpen}
            handleButtonBookingArchiveModalOpen={handleButtonBookingArchiveModalOpen}
            handleButtonBookingShareModalOpen={handleButtonBookingShareModalOpen}
            handleButtonBookingAttachDocumentModalOpen={handleButtonBookingAttachDocumentModalOpen}
            handleButtonBookingSingleEmailModalOpen={handleButtonBookingSingleEmailModalOpen}
            handleButtonBookingInspectionRequestModalOpen={handleButtonBookingInspectionRequestModalOpen}
            handleButtonBookingCopyModalOpen={handleButtonBookingCopyModalOpen}
            user={me}
            bookingsPostRequestState={bookingsPostRequestState}
            bookingsGetRequestState={bookingsGetRequestState}
            bookingsDocumentPatchRequestState={bookingsDocumentPatchRequestState}
            dispatchBookingsGetDocument={dispatchBookingsGetDocument}
            dispatchInspectionsGetDocument={dispatchInspectionsGetDocument}
            dispatchEmailsPost={dispatchEmailsPost}
            emailsPostRequestState={emailsPostRequestState}
            dispatchBookingRefreshPatch={dispatchBookingRefreshPatch}
          />
        )}
        {sharedBookings.length ? (
          <BookingsShared
            bookingsGetSharedRequestState={bookingsGetSharedRequestState}
            dispatchBookingsGetDocument={dispatchBookingsGetDocument}
            headerTitles={['', 'ETA', 'Pickup Date', 'Carrier', 'Vessel', 'Container Id', 'Purchase Order', 'Owner', 'Shipper', 'Product', 'Origin', 'Destination', '', '', '']}
            me={me}
            organization={organization}
            searchedBookings={searchedSharedBookingsState}
            searchInput={searchInputState}
            sharedBookings={sharedBookings}
            title="Shared Containers"
          />
        ) : null}
      </div>
    );
  };

  const renderModals = () => (
    <div>
      <BookingSaveModal
        ref={bookingModalRef}
        onCreate={dispatchBookingsPost}
        onEdit={dispatchBookingsPut}
        carriers={carriers}
        organization={organization}
        bookingsPostRequestState={bookingsPostRequestState}
        bookingsPutRequestState={bookingsPutRequestState}
      />
      <BookingDeleteModal
        ref={confirmationModalRef}
        dispatchBookingsDelete={dispatchBookingsDelete}
        bookingsDeleteRequestState={bookingsDeleteRequestState}
      />
      <BookingAttachDocumentModal
        ref={documentModalRef}
        bookings={bookings}
        dispatchBookingDocumentPatch={dispatchBookingDocumentPatch}
        bookingsDocumentPatchRequestState={bookingsDocumentPatchRequestState}
        dispatchBookingDocumentDelete={dispatchBookingDocumentDelete}
        bookingsDocumentDeleteRequestState={bookingsDocumentDeleteRequestState}
        dispatchBookingsGetDocument={dispatchBookingsGetDocument}
      />
      <BookingArchiveModal
        ref={deactivateModalRef}
        dispatchBookingsDeactivate={dispatchBookingsDeactivate}
        bookingsPutDeactivateRequestState={bookingsPutDeactivateRequestState}
      />
      <BookingShareModal
        ref={shareModalRef}
        dispatchBookingsShare={dispatchBookingsShare}
        bookingsPutShareRequestState={bookingsPutShareRequestState}
      />
      <BookingSingleEmailModal
        ref={emailModalRef}
        user={me}
        organization={organization}
        emailsPostRequestState={emailsPostRequestState}
        dispatchEmailsPost={dispatchEmailsPost}
      />
      <BookingInspectionRequestModal
        ref={inspectionRequestModalRef}
        user={me}
        organization={organization}
        inspectors={inspectors}
        bookingsPostInspectionRequestState={bookingsPostInspectionRequestState}
        dispatchBookingsPostInspection={dispatchBookingsPostInspection}
      />
      <BookingCopyModal
        ref={copyModalRef}
        user={me}
        organization={organization}
        emailsPostRequestState={emailsPostRequestState}
        dispatchNotification={dispatchNotification}
      />
    </div>
  );

  const renderControls = () => (
    <>
      {me?.permissions?.bookingsEdit
        ? (
          <div className="bookings-add-button">
            <Button primary onClick={() => handleButtonBookingSaveModalOpen(null)}>Add Booking</Button>
          </div>
        )
        : <div />}
      <div className="bookings-controls-container">
        <div className="bookings-search-bar">
          <InputField
            placeholder="Search by PO, booking ID, Product, Carrier, Vessel, etc..."
            onChange={(event) => handleSearch(event)}
            icon={<FaSearch />}
          >
            Search By
          </InputField>
        </div>
        {/* <div className="bookings-toggle-switch">
          <ToggleSwitch
            id="toggle-bookings"
            label={toggleActiveBookingsState ? 'Showing active only' : 'Showing everything'}
            onToggle={handleBookingsToggle}
            value={toggleActiveBookingsState}
          />
        </div> */}
      </div>
    </>
  );

  return (
    <div>
      <div className="booking-container">
        <div style={{ position: 'relative', zIndex: 10 }}>
          {renderModals()}
          {renderControls()}
          {renderDashboard()}
          <div className="booking-view-page-last-element" />
        </div>
      </div>
      {isDesktopOrLaptop && <BackgroundVideo videoSrc="wallpaper.mov" />}
    </div>
  );
}

export default BookingView;
