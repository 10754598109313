// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.document-section-items {
  color: var(--color-grey900);
  margin: 0;
  padding-bottom: 2px;
  font-size: 11pt;
  text-decoration: underline;
}

.document-section-no-items {
  color: var(--color-grey900);
  margin: 0;
  padding-bottom: 2px;
  font-size: 11pt;
}`, "",{"version":3,"sources":["webpack://./src/components/tables/sections/DocumentSection.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B,SAAS;EACT,mBAAmB;EACnB,eAAe;EACf,0BAA0B;AAC5B;;AAEA;EACE,2BAA2B;EAC3B,SAAS;EACT,mBAAmB;EACnB,eAAe;AACjB","sourcesContent":[".document-section-items {\n  color: var(--color-grey900);\n  margin: 0;\n  padding-bottom: 2px;\n  font-size: 11pt;\n  text-decoration: underline;\n}\n\n.document-section-no-items {\n  color: var(--color-grey900);\n  margin: 0;\n  padding-bottom: 2px;\n  font-size: 11pt;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
