import {
  REPORTS_CONTAINERS_GET_REQUEST_START,
  REPORTS_CONTAINERS_GET_REQUEST_FAIL,
  REPORTS_CONTAINERS_GET_REQUEST_SUCCESS,
  REPORTS_CONTAINERS_GET_REQUEST_IDLE,
} from '../actions';
import { apiRequestState, updateObject } from '../utility/utility';

const initialState = {
  bookings: [],
  reportsContainersGetRequestState: apiRequestState.IDLE,
};

const reportsContainersGetStart = (state, action) => updateObject(state, {
  reportsContainersGetRequestState: action.requestState,
});

const reportsContainersGetFail = (state, action) => updateObject(state, {
  reportsContainersGetRequestState: action.requestState,
});

const reportsContainersGetSuccess = (state, action) => updateObject(state, {
  reportsContainersGetRequestState: action.requestState,
  bookings: action.bookings,
});

const reportsContainersGetIdle = (state, action) => updateObject(state, {
  reportsContainersGetRequestState: action.requestState,
});

// eslint-disable-next-line default-param-last
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case REPORTS_CONTAINERS_GET_REQUEST_START:
      return reportsContainersGetStart(state, action);
    case REPORTS_CONTAINERS_GET_REQUEST_FAIL:
      return reportsContainersGetFail(state, action);
    case REPORTS_CONTAINERS_GET_REQUEST_SUCCESS:
      return reportsContainersGetSuccess(state, action);
    case REPORTS_CONTAINERS_GET_REQUEST_IDLE:
      return reportsContainersGetIdle(state, action);
    default:
      return state;
  }
};

export default reducer;
