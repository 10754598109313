// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bookings-none-container {
    margin: 50px 50px 50px 50px;
    padding: 10px 0px 20px 0px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.7);
    background-color: var(--color-grey000);
    border-radius: 10px;
}

.bookings-none-title {
    color: var(--color-primaryMedium);
    font-weight: bold;
    font-size: 16pt;
    padding-left: 20px;
}

.bookings-none-subtitle {
    padding-top: 15px;
    padding-left: 20px;
    color: var(--color-grey900);
    font-weight: 500;
}`, "",{"version":3,"sources":["webpack://./src/components/BookingsNone.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,0BAA0B;IAC1B,yCAAyC;IACzC,sCAAsC;IACtC,mBAAmB;AACvB;;AAEA;IACI,iCAAiC;IACjC,iBAAiB;IACjB,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,2BAA2B;IAC3B,gBAAgB;AACpB","sourcesContent":[".bookings-none-container {\n    margin: 50px 50px 50px 50px;\n    padding: 10px 0px 20px 0px;\n    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.7);\n    background-color: var(--color-grey000);\n    border-radius: 10px;\n}\n\n.bookings-none-title {\n    color: var(--color-primaryMedium);\n    font-weight: bold;\n    font-size: 16pt;\n    padding-left: 20px;\n}\n\n.bookings-none-subtitle {\n    padding-top: 15px;\n    padding-left: 20px;\n    color: var(--color-grey900);\n    font-weight: 500;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
