import React from 'react'; import _ from 'lodash';
import TextArea from '../components/core/input/TextArea';
import Checkbox from '../components/core/input/CoreInputCheckbox';
import Select from '../components/Select';
import { capitalizeFirstLetter } from './utility';
import DatePicker from '../components/core/input/DatePicker';
import InputField from '../components/core/input/InputField';

export const mergeFields = (fields, presetFields, options = {}) => {
  const mergedFields = {};

  Object.keys(presetFields).forEach((key) => {
    mergedFields[key] = key in fields ? fields[key] : presetFields[key].defaultValue;
  });

  if (options.keepUnmatchedFields) {
    Object.keys(fields).forEach((key) => {
      if (!(key in mergedFields)) {
        mergedFields[key] = fields[key];
      }
    });
  }

  return mergedFields;
};

export const overlayFieldsOntoPresets = (fields, presetFields) => {
  // Create a deep copy of presetFields to avoid mutating the original object.
  const result = _.cloneDeep(presetFields);

  // Iterate over each key in the fields object
  _.forEach(fields, (value, key) => {
    if (result[key]) { // If the key exists in presetFields
      result[key].value = value; // Assign the value from fields to the result object
    }
  });

  return result;
};

export const displayInputFields = (fields, state, path, handleChange) => {
  if (!fields) return null;

  return Object.keys(fields).map((fieldKey) => {
    const field = fields[fieldKey];

    const handleFieldChange = (event) => {
      handleChange(event, `${path}.${fieldKey}`);
    };

    const commonProps = {
      key: fieldKey,
      id: fieldKey,
      value: state?.[path]?.[fieldKey] ?? field.defaultValue,
      label: capitalizeFirstLetter(_.startCase(fieldKey)),
      onChange: handleFieldChange,
    };

    switch (field.type) {
      case 'textInput':
        return <InputField {...commonProps} />;
      case 'datePicker':
        return <DatePicker {...commonProps}>{commonProps.label}</DatePicker>;
      case 'textArea':
        return <TextArea {...commonProps} />;
      case 'checkbox':
        return (
          <Checkbox
            {...commonProps}
            checked={state?.[path]?.[fieldKey] ?? field.defaultValue}
          >
            {commonProps.label}
          </Checkbox>
        );
      case 'select':
        return (
          <Select
            {...commonProps}
            options={[''].concat(field.options)}
          >
            {commonProps.label}
          </Select>
        );
      default:
        return null;
    }
  });
};
