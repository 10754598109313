import React from 'react';
import _ from 'lodash';
import { FaEdit } from 'react-icons/fa';
import BubbleCard from '../../BubbleCard';
import { getAddressString, get, uuid } from '../../../utility/utility';
import CoreCard from '../../core/content/CoreCard';

function OrganizationBubbleOrganization({
  organization,
  handleButtonOrganizationFormOrganizationOpen,
  style,
}) {
  const renderFeatures = (currentOrganization) => {
    const features = Object.keys(get(currentOrganization, 'features', []));
    if (!features.length) return null;
    return (
      <div>
        <div style={{
          paddingLeft: '10px', paddingRight: '10px', paddingTop: '10px', fontSize: '12px', fontWeight: '500',
        }}
        >
          Features
        </div>
        {
          features.map((feature) => (
            <div
              style={{
                paddingLeft: '10px', paddingRight: '10px', fontSize: '12px', fontWeight: '200',
              }}
              key={feature}
            >
              {_.startCase(feature)}
            </div>
          ))
        }
      </div>
    );
  };

  return (
    <BubbleCard title="Organization" style={style}>
      <CoreCard
        key={get(organization, '_id')}
        content={(
          <div>
            <div style={{ paddingLeft: '10px', paddingRight: '10px' }}>
              {get(organization, 'company.name')}
            </div>
            <div style={{
              paddingLeft: '10px', paddingRight: '10px', fontSize: '12px', fontWeight: '300',
            }}
            >
              {_.startCase(get(organization, 'type'))}
            </div>
            <div style={{
              paddingLeft: '10px', paddingRight: '10px', fontSize: '12px', fontWeight: '200',
            }}
            >
              {getAddressString(get(organization, 'company.address', {})).lines}
            </div>
            <div style={{
              paddingLeft: '10px', paddingRight: '10px', fontSize: '12px', fontWeight: '200',
            }}
            >
              {getAddressString(get(organization, 'company.address', {})).cityState}
            </div>
            <div style={{
              paddingLeft: '10px', paddingRight: '10px', fontSize: '12px', fontWeight: '200',
            }}
            >
              {getAddressString(get(organization, 'company.address', {})).countryPostalCode}
            </div>
            <div style={{
              paddingTop: '10px', paddingLeft: '10px', paddingRight: '10px', fontSize: '12px', fontWeight: '200',
            }}
            >
              {get(organization, 'company.phone')}
            </div>
            <div style={{
              paddingLeft: '10px', paddingRight: '10px', fontSize: '12px', fontWeight: '200',
            }}
            >
              {get(organization, 'company.website')}
            </div>
            {renderFeatures(organization)}
          </div>
        )}
        actions={[
          {
            _id: uuid(),
            text: 'Edit',
            icon: <FaEdit title="Edit" />,
            onClick: () => handleButtonOrganizationFormOrganizationOpen(organization),
          },
        ]}
      />
    </BubbleCard>
  );
}

export default OrganizationBubbleOrganization;
