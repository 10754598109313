// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.file-uploader-frame {
    border: 2px dashed var(--color-grey000);
    padding: 20px;
    text-align: center;
}

.file-uploader-frame.drag-active {
    border-color: var(--color-primaryLight);
}

.file-uploader-simple-tile {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-grey300);
    color: gray;
    transition: background-color 0.3s;
}

.file-uploader-simple-tile:hover {
    background-color: var(--color-primaryLight);
}
`, "",{"version":3,"sources":["webpack://./src/components/FileUploader.css"],"names":[],"mappings":"AAAA;IACI,uCAAuC;IACvC,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,uCAAuC;AAC3C;;AAEA;IACI,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,QAAQ;IACR,SAAS;IACT,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,sCAAsC;IACtC,WAAW;IACX,iCAAiC;AACrC;;AAEA;IACI,2CAA2C;AAC/C","sourcesContent":[".file-uploader-frame {\n    border: 2px dashed var(--color-grey000);\n    padding: 20px;\n    text-align: center;\n}\n\n.file-uploader-frame.drag-active {\n    border-color: var(--color-primaryLight);\n}\n\n.file-uploader-simple-tile {\n    position: absolute;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    background-color: var(--color-grey300);\n    color: gray;\n    transition: background-color 0.3s;\n}\n\n.file-uploader-simple-tile:hover {\n    background-color: var(--color-primaryLight);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
