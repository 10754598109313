import React, { useEffect } from 'react';
import './CoreFormDualAction.css';
import Button from '../input/Button';

function CoreFormDualAction({
  title,
  primaryButtonCaption,
  primaryButtonCallback,
  primaryButtonCallbackStatus,
  primaryButtonDisabled,
  primaryButtonEnterKeyPressIsEnabled = true,
  secondaryButtonCaption,
  secondaryButtonCallback,
  children,
}) {
  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !primaryButtonDisabled && primaryButtonEnterKeyPressIsEnabled) {
      primaryButtonCallback(); // Trigger the primary button action if Enter key is pressed
      event.preventDefault(); // Prevent any default behavior for Enter key press
    } else if (event.key === 'Escape') {
      secondaryButtonCallback(); // Trigger the secondary button action if Escape key is pressed
      event.preventDefault(); // Prevent any default behavior for Escape key press
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [primaryButtonCallback, primaryButtonDisabled, secondaryButtonCallback]);

  return (
    <>
      <div className="core-form-dual-action-backdrop" onClick={secondaryButtonCallback} />
      <div className="core-form-dual-action-frame">
        <h1 className="core-form-dual-action-title">{title}</h1>
        <div className="core-form-dual-action-start-line" />
        <div className="core-form-dual-action-content">
          {children}
        </div>
        <div className="core-form-dual-action-end-line" />
        {secondaryButtonCaption
          ? (
            <div className="core-form-dual-action-buttons-section">
              <div className="core-form-dual-action-secondary-button">
                <Button
                  secondary
                  onClick={secondaryButtonCallback}
                >
                  {secondaryButtonCaption}
                </Button>
              </div>
              <div className="core-form-dual-action-primary-button">
                <Button
                  primary
                  onClick={primaryButtonCallback}
                  isLoading={primaryButtonCallbackStatus}
                  disabled={primaryButtonDisabled}
                >
                  {primaryButtonCaption}
                </Button>
              </div>
            </div>
          )
          : (
            <div className="core-form-dual-action-buttons-section">
              <Button
                primary
                onClick={primaryButtonCallback}
                isLoading={primaryButtonCallbackStatus}
                disabled={primaryButtonDisabled}
              >
                {primaryButtonCaption}
              </Button>
            </div>
          )}

      </div>
    </>
  );
}

export default CoreFormDualAction;
