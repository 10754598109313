import React from 'react';
import { get } from '../../../utility/utility';
import CoreCard from '../../core/content/CoreCard';
import { renderProductDescription } from '../../../utility/InspectionsUtils';

function InspectionProductCard({ product, actions }) {
  return (
    <CoreCard
      isHoverable
      content={(
        <div key={get(product, '_id')} style={{ paddingBottom: '5px' }}>
          <div style={{ paddingLeft: '10px', color: 'var(--color-grey900)', fontWeight: '500' }}>
            <div>{renderProductDescription(product).title}</div>
          </div>
          <div style={{ paddingLeft: '10px', color: 'var(--color-grey700)' }}>
            {renderProductDescription(product).subtitle}
          </div>
        </div>
      )}
      actions={actions}
    />
  );
}

export default InspectionProductCard;
