// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title-card {
    display: flex;
    flex-direction: column; /* Align children vertically */
    width: 100%;
    background-color: var(--color-grey000);
    border: 1px solid var(--color-grey100);
    margin-top: 15px;
    box-sizing: border-box;
    padding: 10px;
}

.title-card-header {
    display: flex; /* Use flex layout */
    justify-content: space-between; /* Place title and select at opposite ends */
    width: 100%; /* Use full width of the parent */
    align-items: center; /* Vertical alignment */
    margin-bottom: 10px; /* Space between header and subtitle */
}

.title-card-title {
    font-size: 16px;
    color: var(--color-primaryMedium);
    font-weight: 700;
    flex: 1 1; /* Allows the title to grow and take available space */
}

.title-card-subtitle {
    color: var(--color-grey900);
    font-weight: 400;

}

.title-card-actions-container {
    display: flex;
    align-items: center;
    padding-right: 0px;
    gap: 20px; 
    color: var(--color-primaryMedium);
  }
`, "",{"version":3,"sources":["webpack://./src/components/cardLists/TitleCard.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB,EAAE,8BAA8B;IACtD,WAAW;IACX,sCAAsC;IACtC,sCAAsC;IACtC,gBAAgB;IAChB,sBAAsB;IACtB,aAAa;AACjB;;AAEA;IACI,aAAa,EAAE,oBAAoB;IACnC,8BAA8B,EAAE,4CAA4C;IAC5E,WAAW,EAAE,iCAAiC;IAC9C,mBAAmB,EAAE,uBAAuB;IAC5C,mBAAmB,EAAE,sCAAsC;AAC/D;;AAEA;IACI,eAAe;IACf,iCAAiC;IACjC,gBAAgB;IAChB,SAAO,EAAE,sDAAsD;AACnE;;AAEA;IACI,2BAA2B;IAC3B,gBAAgB;;AAEpB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,SAAS;IACT,iCAAiC;EACnC","sourcesContent":[".title-card {\n    display: flex;\n    flex-direction: column; /* Align children vertically */\n    width: 100%;\n    background-color: var(--color-grey000);\n    border: 1px solid var(--color-grey100);\n    margin-top: 15px;\n    box-sizing: border-box;\n    padding: 10px;\n}\n\n.title-card-header {\n    display: flex; /* Use flex layout */\n    justify-content: space-between; /* Place title and select at opposite ends */\n    width: 100%; /* Use full width of the parent */\n    align-items: center; /* Vertical alignment */\n    margin-bottom: 10px; /* Space between header and subtitle */\n}\n\n.title-card-title {\n    font-size: 16px;\n    color: var(--color-primaryMedium);\n    font-weight: 700;\n    flex: 1; /* Allows the title to grow and take available space */\n}\n\n.title-card-subtitle {\n    color: var(--color-grey900);\n    font-weight: 400;\n\n}\n\n.title-card-actions-container {\n    display: flex;\n    align-items: center;\n    padding-right: 0px;\n    gap: 20px; \n    color: var(--color-primaryMedium);\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
