import React from 'react';
import './Select.css';

function Select({
  id,
  onChange,
  value = '',
  disabled,
  options = [],
  children,
  placeholder,
}) {
  const isPlaceholderSelected = value === '';

  // Function to render each option
  const renderOption = (option) => {
    if (typeof option === 'string') {
      return (
        <option key={option} value={option}>
          {option}
        </option>
      );
    }
    // Assuming option is an object with id and value
    return (
      <option key={option.value} value={option.value}>
        {option.label || option.value}
      </option>
    );
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
      {children ? <p className="label">{children}</p> : null}
      <select
        id={id}
        onChange={onChange}
        value={value}
        disabled={disabled}
        className={`select ${isPlaceholderSelected ? 'select-default' : ''}`}
      >
        {/* Placeholder option */}
        <option value="" disabled hidden>
          {placeholder}
        </option>

        {options.map(renderOption)}
      </select>
    </div>
  );
}

export default Select;
