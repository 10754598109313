import { React } from 'react';
import _ from 'lodash';
import './DetailsSection.css';
import { mergeFields } from '../../../utility/utlityFields';
import Section from './Section';

function DetailsSection({ details, presetDetailsFields = {} }) {
  const mergedDetails = mergeFields(details, presetDetailsFields, { keepUnmatchedFields: true });
  const detailsKeys = Object.keys(mergedDetails);

  return (
    <Section title="Details">
      {detailsKeys.length
        ? detailsKeys.map((key) => (
          <p key={key} className="details-section-items">
            {_.startCase(key)}
            <span>: </span>
            {mergedDetails[key]}
          </p>
        ))
        : <p className="details-section-items">None</p>}
    </Section>
  );
}

export default DetailsSection;
