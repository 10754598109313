import React from 'react';
import { FaEdit } from 'react-icons/fa';
import _ from 'lodash';
import {
  get, set, uuid,
} from '../../../utility/utility';
import InspectionBubbleDocuments from '../bubbles/InspectionBubbleDocuments';
import InspectionBubbleLogistics from '../bubbles/InspectionBubbleLogistics';
import InspectionBubbleProducts from '../bubbles/InspectionBubbleProducts';

function InspectionTabShipment({
  organization,
  inspectionState,
  dispatchInspectionsPut,
  dispatchBookingsGetDocument,
  handleButtonInspectionShipmentFormOpen,
  handleButtonInspectionProductFormOpen,
}) {
  const onDelete = (product) => {
    const productsToDelete = [product];
    const update = {};
    set(update, 'shipment.productsToDelete', productsToDelete);
    dispatchInspectionsPut(inspectionState, update);
  };

  return (
    <div>
      <InspectionBubbleLogistics
        logistics={get(inspectionState, 'shipment.logistics', {})}
        actions={[
          {
            _id: uuid(),
            text: 'Edit',
            icon: <FaEdit title="Edit" />,
            onClick: () => handleButtonInspectionShipmentFormOpen(inspectionState),
          },
        ]}
      />
      <InspectionBubbleProducts
        organization={organization}
        inspectionState={inspectionState}
        products={get(inspectionState, 'shipment.products', [])}
        onFormOpen={handleButtonInspectionProductFormOpen}
        onDelete={onDelete}
      />
      <InspectionBubbleDocuments
        documents={get(inspectionState, 'shipment.documents', []).map((document) => ({
          document,
          retrieve: () => dispatchBookingsGetDocument({ _id: inspectionState.request.bookingId }, _.camelCase(document.id)),
        }))}
        dispatchBookingsGetDocument={dispatchBookingsGetDocument}
        bookingId={get(inspectionState, 'request.bookingId')}
        actions={[]}
      />
    </div>
  );
}

export default InspectionTabShipment;
