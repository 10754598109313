import React, {
  useState, useEffect, forwardRef, useImperativeHandle,
} from 'react';
import { apiRequestState, get } from '../../../utility/utility';
import CoreFormDualAction from '../../core/form/CoreFormDualAction';
import InspectionImageGallery from '../../InspectionImageGallery';
import { INSPECTION_IMAGE_TYPES, retrieveProductImagesAvailableForAssociation, retrieveProductImagesCurrentlyUsedForAssociation } from '../../../utility/InspectionsUtils';

const InspectionFormSampleGeneralImagesSelector = forwardRef(({
  dispatchInspectionImagesAssociate,
  inspectionsImagesAssociateRequestState,
}, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentInspection, setCurrentInspection] = useState({});
  const [productState, setProductState] = useState(null);
  const [sampleState, setSampleState] = useState(null);
  const [selectedImagesIdsState, setSelectedImagesIdsState] = useState([]);
  const [initiallySelectedImagesIdsState, setInitiallySelectedImagesIdsState] = useState([]);

  useImperativeHandle(ref, () => ({
    open: (inspection, product, sample) => {
      setCurrentInspection(inspection);
      setProductState(product);
      setSampleState(sample);
      setIsOpen(true);
      const images = get(inspection, `onsite.${INSPECTION_IMAGE_TYPES.productImages}`, []);
      const curentlyUsedSampleImages = retrieveProductImagesCurrentlyUsedForAssociation(images, sample._id);
      setSelectedImagesIdsState(curentlyUsedSampleImages.map((image) => image._id));
      setInitiallySelectedImagesIdsState(curentlyUsedSampleImages.map((image) => image._id));
    },
    close: () => setIsOpen(false),
  }));

  useEffect(() => {
    if (inspectionsImagesAssociateRequestState === apiRequestState.SUCCESS) {
      setIsOpen(false);
    }
  }, [inspectionsImagesAssociateRequestState]);

  if (!isOpen) return null;

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleActionButton = () => {
    const sampleImagesToDeassociate = initiallySelectedImagesIdsState.filter((id) => !selectedImagesIdsState.includes(id));
    const sampleImagesToAssociate = selectedImagesIdsState.filter((id) => !initiallySelectedImagesIdsState.includes(id));

    const request = {
      sampleImagesToAssociate: {
        imagesIds: sampleImagesToAssociate,
        sampleId: get(sampleState, '_id', null),
      },
      sampleImagesToDeassociate: {
        imagesIds: sampleImagesToDeassociate,
      },
    };
    dispatchInspectionImagesAssociate(currentInspection, request);
  };

  const productImages = get(currentInspection, `onsite.${INSPECTION_IMAGE_TYPES.productImages}`, []);

  return (
    <CoreFormDualAction
      title="Sample Packing Images Selector"
      primaryButtonCaption="Ok"
      primaryButtonCallback={handleActionButton}
      primaryButtonCallbackStatus={inspectionsImagesAssociateRequestState === apiRequestState.LOADING}
      secondaryButtonCaption="Cancel"
      secondaryButtonCallback={closeModal}
    >
      <div>
        <p>
          Pick general packing images to associate with this sample:
        </p>
        <InspectionImageGallery
          images={[
            ...retrieveProductImagesAvailableForAssociation(productImages),
            ...retrieveProductImagesCurrentlyUsedForAssociation(productImages, sampleState._id),
          ].sort((a, b) => new Date(a.metadata.capturedAt) - new Date(b.metadata.capturedAt))}
          productId={productState._id}
          selectedImagesIdsState={selectedImagesIdsState}
          setSelectedImagesIdsState={setSelectedImagesIdsState}
          isSelectable
        />
      </div>
    </CoreFormDualAction>
  );
});

export default InspectionFormSampleGeneralImagesSelector;
