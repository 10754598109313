import React from 'react';
import { FaEdit } from 'react-icons/fa';
import {
  get, uuid,
} from '../../../utility/utility';
import InspectionBubbleRequest from '../bubbles/InspectionBubbleRequest';
import InspectionBubbleReport from '../bubbles/InspectionBubbleReport';

function InspectionTabReport({
  organization,
  inspectionState,
  dispatchInspectionsGetSingle,
  dispatchInspectionsGetDocument,
  dispatchInspectionImagesDownloadZipGet,
  dispatchInspectionsPatchRepopulate,
  dispatchInspectionsReportsDelete,
  handleButtonInspectionSubmitReportModalOpen,
  handleButtonInspectionGenerateReportModalOpen,
  handleButtonInspectionRequestFormOpen,
}) {
  return (
    <div>
      <InspectionBubbleRequest
        inspection={inspectionState}
        request={get(inspectionState, 'request', {})}
        dispatchInspectionsPatchRepopulate={dispatchInspectionsPatchRepopulate}
        dispatchInspectionImagesDownloadZipGet={dispatchInspectionImagesDownloadZipGet}
        actions={[
          {
            _id: uuid(),
            text: 'Edit',
            icon: <FaEdit title="Edit" />,
            onClick: () => handleButtonInspectionRequestFormOpen(inspectionState),
          },
        ]}
      />
      <InspectionBubbleReport
        organization={organization}
        inspection={inspectionState}
        dispatchInspectionsGetSingle={dispatchInspectionsGetSingle}
        handleButtonInspectionGenerateReportModalOpen={handleButtonInspectionGenerateReportModalOpen}
        dispatchInspectionsReportsDelete={dispatchInspectionsReportsDelete}
        dispatchInspectionsGetDocument={dispatchInspectionsGetDocument}
        handleButtonInspectionSubmitReportModalOpen={handleButtonInspectionSubmitReportModalOpen}
        report={get(inspectionState, 'report', {})}
      />
    </div>
  );
}

export default InspectionTabReport;
