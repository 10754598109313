import { React } from 'react';

function CoreInputCheckbox(props) {
  return (
    <div style={props.style} className="core-input-checkbox">
      <input type="checkbox" id={props.id} onChange={props.onChange} checked={props.checked} />
      {props.children}
    </div>
  );
}

export default CoreInputCheckbox;
