// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inspection-defect-card-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--color-grey050);
  /* Optional border */
  padding: 10px;
  background-color: var(--color-grey050);
  /* White background */
  position: relative;
  /* For absolute positioning of the dropdown menu */
}

.inspection-defect-card-info {
  flex-grow: 1;
  text-align: left;
}

.inspection-defect-card-name {
  color: var(--color-grey900);
  font-weight: bold;
}

.inspection-defect-card-description {
  color: var(--color-grey900);
}

.inspection-defect-card-reference
{
  color: var(--color-grey700);
}
`, "",{"version":3,"sources":["webpack://./src/components/inspection/cards/InspectionDefectCard.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,6CAA6C;EAC7C,oBAAoB;EACpB,aAAa;EACb,sCAAsC;EACtC,qBAAqB;EACrB,kBAAkB;EAClB,kDAAkD;AACpD;;AAEA;EACE,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,2BAA2B;EAC3B,iBAAiB;AACnB;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;;EAEE,2BAA2B;AAC7B","sourcesContent":[".inspection-defect-card-container {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  border-bottom: 1px solid var(--color-grey050);\n  /* Optional border */\n  padding: 10px;\n  background-color: var(--color-grey050);\n  /* White background */\n  position: relative;\n  /* For absolute positioning of the dropdown menu */\n}\n\n.inspection-defect-card-info {\n  flex-grow: 1;\n  text-align: left;\n}\n\n.inspection-defect-card-name {\n  color: var(--color-grey900);\n  font-weight: bold;\n}\n\n.inspection-defect-card-description {\n  color: var(--color-grey900);\n}\n\n.inspection-defect-card-reference\n{\n  color: var(--color-grey700);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
