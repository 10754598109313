import React from 'react';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { get, uuid } from '../../../utility/utility';
import BubbleCard from '../../BubbleCard';
import Button from '../../core/input/Button';
import InspectionMeasurementCard from '../cards/InspectionMeasurementCard';

function InspectionBubbleMeasurements({
  inspection,
  measurements,
  onFormOpen,
  onDelete,
}) {
  const renderMeasurement = (measurement) => (
    <div key={measurement._id}>
      <InspectionMeasurementCard
        measurement={measurement}
        actions={
          [
            {
              _id: uuid(),
              text: 'Delete',
              icon: <FaTrash title="Delete" />,
              onClick: () => onDelete(measurement),
            },
            {
              _id: uuid(),
              text: 'Edit',
              icon: <FaEdit title="Edit" />,
              onClick: () => onFormOpen(inspection, 'temperatures', measurement),
            },
          ]
        }
      />
    </div>
  );

  return (
    <BubbleCard
      title="Measurements"
      style={{ marginBottom: '10px' }}
    >
      {get(measurements, 'temperatures', []).map((measurement) => (
        renderMeasurement(measurement)
      ))}
      <div style={{ display: 'flex', justifyContent: 'flex-start', paddingLeft: '10px' }}>
        <Button
          style={{ width: 'auto', paddingLeft: '10px', paddingRight: '10px' }}
          onClick={() => onFormOpen(inspection, 'temperatures', null)}
          primary
          simple
        >
          Add Measurement
        </Button>
      </div>
    </BubbleCard>
  );
}

export default InspectionBubbleMeasurements;
