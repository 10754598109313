import _ from 'lodash';
import React from 'react';
import './DocumentSection.css';
import { capitalizeFirstLetter } from '../../../utility/utility';
import Section from './Section';

function DocumentsSection({
  booking,
  dispatchBookingsGetDocument,
}) {
  return (
    <Section title="Documents">
      {
        (booking.documents && Object.keys(booking.documents).length > 0)
          ? Object.keys(booking.documents).map((documentType) => (
            <p
              style={{ cursor: 'pointer' }}
              className="document-section-items"
              key={documentType}
              onClick={() => dispatchBookingsGetDocument(booking, _.camelCase(documentType))}
            >
              {capitalizeFirstLetter(_.startCase(documentType))}
            </p>
          ))
          : <p className="document-section-no-items">None</p>
      }
    </Section>
  );
}

export default DocumentsSection;
