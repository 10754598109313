import React from 'react';
import { FaEdit } from 'react-icons/fa';
import { get, uuid } from '../../../utility/utility';
import InspectionBubbleParticipant from '../bubbles/InspectionBubbleParticipant';
import { INSPECTION_PARTICIPANT_TYPES } from '../../../utility/InspectionsUtils';

function InspectionTabParticipants({
  organization,
  inspectionState,
  handleButtonInspectionParticipantFormOpen,
}) {
  const renderParticipant = (partcipantType, participant) => (
    <InspectionBubbleParticipant
      partipantType={partcipantType}
      participant={participant}
      actions={[
        {
          _id: uuid(),
          text: 'Edit',
          icon: <FaEdit title="Edit" />,
          onClick: () => handleButtonInspectionParticipantFormOpen(organization, inspectionState, partcipantType, participant),
        },
      ]}
    />
  );

  return (
    <div>
      {renderParticipant(INSPECTION_PARTICIPANT_TYPES.applicant, get(inspectionState, 'participants.applicant'))}
      {renderParticipant(INSPECTION_PARTICIPANT_TYPES.shipper, get(inspectionState, 'participants.shipper'))}
      {renderParticipant(INSPECTION_PARTICIPANT_TYPES.consignee, get(inspectionState, 'participants.consignee'))}
    </div>
  );
}

export default InspectionTabParticipants;
