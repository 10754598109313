// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bookings-booked-table {
  margin: 50px 50px 50px 50px;
  padding: 10px 0px 20px 0px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.7);
  background-color: var(--color-grey000);
  border-radius: 10px;
}

.bookings-booked-title-block {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bookings-booked-title {
  color: var(--color-primaryMedium);
  font-weight: bold;
  font-size: 16pt;
  flex: 1 1; 
}

.bookings-booked-bulk-action-buttons .action-icon:hover {
  color: black;
}
.bookings-booked-bulk-action-buttons .action-icon {
  cursor: pointer;
  color: var(--color-primaryMedium);
}

.bookings-booked-bulk-action-buttons {
  display: flex;
  align-items: center;
  padding-right: 20px;
  gap: 25px;
}
`, "",{"version":3,"sources":["webpack://./src/components/tables/BookingsPendingShipment.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B,0BAA0B;EAC1B,yCAAyC;EACzC,sCAAsC;EACtC,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE,iCAAiC;EACjC,iBAAiB;EACjB,eAAe;EACf,SAAO;AACT;;AAEA;EACE,YAAY;AACd;AACA;EACE,eAAe;EACf,iCAAiC;AACnC;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,SAAS;AACX","sourcesContent":[".bookings-booked-table {\n  margin: 50px 50px 50px 50px;\n  padding: 10px 0px 20px 0px;\n  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.7);\n  background-color: var(--color-grey000);\n  border-radius: 10px;\n}\n\n.bookings-booked-title-block {\n  padding: 10px;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.bookings-booked-title {\n  color: var(--color-primaryMedium);\n  font-weight: bold;\n  font-size: 16pt;\n  flex: 1; \n}\n\n.bookings-booked-bulk-action-buttons .action-icon:hover {\n  color: black;\n}\n.bookings-booked-bulk-action-buttons .action-icon {\n  cursor: pointer;\n  color: var(--color-primaryMedium);\n}\n\n.bookings-booked-bulk-action-buttons {\n  display: flex;\n  align-items: center;\n  padding-right: 20px;\n  gap: 25px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
