// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reporting-title {
  font-size: 30px;
  font-weight: 700;
  padding-top: 30px;
  padding-left: 50px;
}
.reporting-timeline-select {
  display: flex;
  align-items: center;
  margin: 50px 50px 50px 50px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.7);
  background-color: var(--color-grey000);
  border-radius: 10px;
  max-width: 400px;
}

.reporting-search-bar {
  display: flex;
  align-items: center;
  margin: 50px 50px 50px 50px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.7);
  background-color: var(--color-grey000);
  border-radius: 10px;
  max-width: 400px;
}

.report-view-page-last-element {
  padding-bottom: 500px;
}

/* Media query for mobile devices */
@media (max-width: 1200px) {
  .reporting-title {
    padding-left: 10px;
    padding-right: 20px;
  }

  .reporting-timeline-select {
    margin-top: 10px;
    margin-bottom: 30px;
    margin-right: 10px;
    margin-left: 10px;
  }

  .reporting-search-bar {
    margin-top: 10px;
    margin-bottom: 30px;
    margin-right: 10px;
    margin-left: 10px;
  }
}`, "",{"version":3,"sources":["webpack://./src/containers/ReportingPage.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;AACpB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,2BAA2B;EAC3B,yCAAyC;EACzC,sCAAsC;EACtC,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,2BAA2B;EAC3B,yCAAyC;EACzC,sCAAsC;EACtC,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;AACvB;;AAEA,mCAAmC;AACnC;EACE;IACE,kBAAkB;IAClB,mBAAmB;EACrB;;EAEA;IACE,gBAAgB;IAChB,mBAAmB;IACnB,kBAAkB;IAClB,iBAAiB;EACnB;;EAEA;IACE,gBAAgB;IAChB,mBAAmB;IACnB,kBAAkB;IAClB,iBAAiB;EACnB;AACF","sourcesContent":[".reporting-title {\n  font-size: 30px;\n  font-weight: 700;\n  padding-top: 30px;\n  padding-left: 50px;\n}\n.reporting-timeline-select {\n  display: flex;\n  align-items: center;\n  margin: 50px 50px 50px 50px;\n  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.7);\n  background-color: var(--color-grey000);\n  border-radius: 10px;\n  max-width: 400px;\n}\n\n.reporting-search-bar {\n  display: flex;\n  align-items: center;\n  margin: 50px 50px 50px 50px;\n  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.7);\n  background-color: var(--color-grey000);\n  border-radius: 10px;\n  max-width: 400px;\n}\n\n.report-view-page-last-element {\n  padding-bottom: 500px;\n}\n\n/* Media query for mobile devices */\n@media (max-width: 1200px) {\n  .reporting-title {\n    padding-left: 10px;\n    padding-right: 20px;\n  }\n\n  .reporting-timeline-select {\n    margin-top: 10px;\n    margin-bottom: 30px;\n    margin-right: 10px;\n    margin-left: 10px;\n  }\n\n  .reporting-search-bar {\n    margin-top: 10px;\n    margin-bottom: 30px;\n    margin-right: 10px;\n    margin-left: 10px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
