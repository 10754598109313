// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.booking-card {
    display: flex;
    width: 100%;
    min-height: 60px;
    background-color: var(--color-grey000);
    border: 1px solid var(--color-grey100);
    padding: 0;
    margin: 0;
    box-sizing: border-box; /* Ensure border width is included in the card's total width */
    font-size: small;
}

.booking-card-status {
    background-color: green;
    width: 5px;
    flex-shrink: 0; /* Prevents this column from shrinking */
}

.booking-card-body {
    flex-grow: 1; /* Allows this column to grow and take up the remaining space */
    padding: 10px; /* Optional: Adds some spacing around the content */
}

.booking-card-line1 {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0px;  /* Optional: Adds some space below this line */
    font-weight: 500;
}

.booking-card-line1-left, .booking-card-line1-right {
    flex-shrink: 0;
}

.booking-card-product {
    font-weight: 200;
}

.booking-card-alarm {
    width: 5px;
    flex-shrink: 0; /* Prevents this column from shrinking */
}

.booking-card-text {
    padding: 0;
    margin: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/cardLists/BookingCard.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,WAAW;IACX,gBAAgB;IAChB,sCAAsC;IACtC,sCAAsC;IACtC,UAAU;IACV,SAAS;IACT,sBAAsB,EAAE,8DAA8D;IACtF,gBAAgB;AACpB;;AAEA;IACI,uBAAuB;IACvB,UAAU;IACV,cAAc,EAAE,wCAAwC;AAC5D;;AAEA;IACI,YAAY,EAAE,+DAA+D;IAC7E,aAAa,EAAE,mDAAmD;AACtE;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,kBAAkB,GAAG,8CAA8C;IACnE,gBAAgB;AACpB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,UAAU;IACV,cAAc,EAAE,wCAAwC;AAC5D;;AAEA;IACI,UAAU;IACV,SAAS;AACb","sourcesContent":[".booking-card {\n    display: flex;\n    width: 100%;\n    min-height: 60px;\n    background-color: var(--color-grey000);\n    border: 1px solid var(--color-grey100);\n    padding: 0;\n    margin: 0;\n    box-sizing: border-box; /* Ensure border width is included in the card's total width */\n    font-size: small;\n}\n\n.booking-card-status {\n    background-color: green;\n    width: 5px;\n    flex-shrink: 0; /* Prevents this column from shrinking */\n}\n\n.booking-card-body {\n    flex-grow: 1; /* Allows this column to grow and take up the remaining space */\n    padding: 10px; /* Optional: Adds some spacing around the content */\n}\n\n.booking-card-line1 {\n    display: flex;\n    justify-content: space-between;\n    margin-bottom: 0px;  /* Optional: Adds some space below this line */\n    font-weight: 500;\n}\n\n.booking-card-line1-left, .booking-card-line1-right {\n    flex-shrink: 0;\n}\n\n.booking-card-product {\n    font-weight: 200;\n}\n\n.booking-card-alarm {\n    width: 5px;\n    flex-shrink: 0; /* Prevents this column from shrinking */\n}\n\n.booking-card-text {\n    padding: 0;\n    margin: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
