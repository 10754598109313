import _ from 'lodash';
import React from 'react';
import './InspectionSection.css';
import { capitalizeFirstLetter } from '../../../utility/utility';
import Section from './Section';

function InspectionsSection({
  inspection,
  dispatchInspectionsGetDocument,
}) {
  return (
    <Section title="Inspection">
      <div>
        <p className="inspection-section-no-items">{`Status: ${_.startCase(inspection.status)}`}</p>
        {(inspection.reports && inspection.reports.length > 0)
          ? inspection.reports.map((report) => {
            if (report?.paymentLink) {
              return (
                <a
                  style={{ cursor: 'pointer', textDecoration: 'underline' }} // Added textDecoration for visual indication of a link
                  className="inspection-section-items"
                  key={`${report.id}_link`} // Modified key to ensure uniqueness
                  href={report.paymentLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  Pay
                  {' '}
                  {capitalizeFirstLetter(_.startCase(report.id))}
                </a>
              );
            }
            return (
              <div>
                <p
                  style={{ cursor: 'pointer' }}
                  className="inspection-section-items"
                  key={report.id}
                  onClick={() => dispatchInspectionsGetDocument({ _id: inspection.id }, { id: report.id, value: report.value })}
                >
                  {capitalizeFirstLetter(_.startCase(report.id))}
                </p>
              </div>
            );
          })
          : <p className="inspection-section-no-items">No reports yet</p>}
      </div>
    </Section>
  );
}

export default InspectionsSection;
