// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bookings-shared-title-block {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bookings-shared-title {
  color: var(--color-primaryMedium);
  font-weight: bold;
  font-size: 16pt;
  flex: 1 1; 
}

.bookings-shared-table-collaspsible-row-content {
  position: relative;
  min-height: 70vh;
  margin: 0;
  padding: 0;
}

.bookings-shared-map {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.booking-view-containers-table {
  margin: 50px 50px 50px 50px;
  padding: 10px 0px 20px 0px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.7);
  background-color: var(--color-grey000);
  border-radius: 10px;
}

.booking-view-table {
  margin: 0;
  padding: 5px 0px 0px 0px;
}
`, "",{"version":3,"sources":["webpack://./src/components/tables/BookingsShared.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE,iCAAiC;EACjC,iBAAiB;EACjB,eAAe;EACf,SAAO;AACT;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,QAAQ;EACR,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,2BAA2B;EAC3B,0BAA0B;EAC1B,yCAAyC;EACzC,sCAAsC;EACtC,mBAAmB;AACrB;;AAEA;EACE,SAAS;EACT,wBAAwB;AAC1B","sourcesContent":[".bookings-shared-title-block {\n  padding: 10px;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.bookings-shared-title {\n  color: var(--color-primaryMedium);\n  font-weight: bold;\n  font-size: 16pt;\n  flex: 1; \n}\n\n.bookings-shared-table-collaspsible-row-content {\n  position: relative;\n  min-height: 70vh;\n  margin: 0;\n  padding: 0;\n}\n\n.bookings-shared-map {\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  z-index: 1;\n}\n\n.booking-view-containers-table {\n  margin: 50px 50px 50px 50px;\n  padding: 10px 0px 20px 0px;\n  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.7);\n  background-color: var(--color-grey000);\n  border-radius: 10px;\n}\n\n.booking-view-table {\n  margin: 0;\n  padding: 5px 0px 0px 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
