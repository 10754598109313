// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spinner {
  width: 20px;
  height: 20px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: var(--color-primaryLight);
  border-radius: 50%;
  animation: spin 1s linear infinite;
  /* position: relative; */
  transform: translate(-50%, -50%);
  pointer-events: none;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Spinner.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,oCAAoC;EACpC,4CAA4C;EAC5C,kBAAkB;EAClB,kCAAkC;EAClC,wBAAwB;EACxB,gCAAgC;EAChC,oBAAoB;AACtB;;AAEA;EACE;IACE,uBAAuB;EACzB;;EAEA;IACE,yBAAyB;EAC3B;AACF","sourcesContent":[".spinner {\n  width: 20px;\n  height: 20px;\n  border: 4px solid rgba(0, 0, 0, 0.1);\n  border-left-color: var(--color-primaryLight);\n  border-radius: 50%;\n  animation: spin 1s linear infinite;\n  /* position: relative; */\n  transform: translate(-50%, -50%);\n  pointer-events: none;\n}\n\n@keyframes spin {\n  0% {\n    transform: rotate(0deg);\n  }\n\n  100% {\n    transform: rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
