import axios from 'axios';
import { StatusCodes } from 'http-status-codes';
import {
  apiRequestState, createRequestAction, get, uuid,
} from '../utility/utility';
import { API_BASE_URL, USE_STORAGE_INDEXED_DB, IMAGE_SYNC_BATCH_SIZE } from '../config';
import { notificationsShow } from './notifications';
import { countImages, storeObjects } from '../utility/db';
import { INSPECTION_RETRIEVE_OPTIONS } from '../utility/InspectionsUtils';

export const INSPECTIONS_GET_REQUEST_START = 'INSPECTIONS_GET_REQUEST_START';
export const INSPECTIONS_GET_REQUEST_SUCCESS = 'INSPECTIONS_GET_REQUEST_SUCCESS';
export const INSPECTIONS_GET_REQUEST_FAIL = 'INSPECTIONS_GET_REQUEST_FAIL';
export const INSPECTIONS_GET_REQUEST_IDLE = 'INSPECTIONS_GET_REQUEST_IDLE';
export const INSPECTIONS_GET_SINGLE_REQUEST_START = 'INSPECTIONS_GET_SINGLE_REQUEST_START';
export const INSPECTIONS_GET_SINGLE_REQUEST_SUCCESS = 'INSPECTIONS_GET_SINGLE_REQUEST_SUCCESS';
export const INSPECTIONS_GET_SINGLE_REQUEST_FAIL = 'INSPECTIONS_GET_SINGLE_REQUEST_FAIL';
export const INSPECTIONS_GET_SINGLE_REQUEST_IDLE = 'INSPECTIONS_GET_SINGLE_REQUEST_IDLE';
export const INSPECTIONS_GET_AVAILABLE_STAFF_REQUEST_START = 'INSPECTIONS_GET_AVAILABLE_STAFF_REQUEST_START';
export const INSPECTIONS_GET_AVAILABLE_STAFF_REQUEST_SUCCESS = 'INSPECTIONS_GET_AVAILABLE_STAFF_REQUEST_SUCCESS';
export const INSPECTIONS_GET_AVAILABLE_STAFF_REQUEST_FAIL = 'INSPECTIONS_GET_AVAILABLE_STAFF_REQUEST_FAIL';
export const INSPECTIONS_GET_AVAILABLE_STAFF_REQUEST_IDLE = 'INSPECTIONS_GET_AVAILABLE_STAFF_REQUEST_IDLE';
export const INSPECTIONS_POST_REQUEST_START = 'INSPECTIONS_POST_REQUEST_START';
export const INSPECTIONS_POST_REQUEST_SUCCESS = 'INSPECTIONS_POST_REQUEST_SUCCESS';
export const INSPECTIONS_POST_REQUEST_FAIL = 'INSPECTIONS_POST_REQUEST_FAIL';
export const INSPECTIONS_POST_REQUEST_IDLE = 'INSPECTIONS_POST_REQUEST_IDLE';
export const INSPECTIONS_PUT_REQUEST_START = 'INSPECTIONS_PUT_REQUEST_START';
export const INSPECTIONS_PUT_REQUEST_SUCCESS = 'INSPECTIONS_PUT_REQUEST_SUCCESS';
export const INSPECTIONS_PUT_REQUEST_FAIL = 'INSPECTIONS_PUT_REQUEST_FAIL';
export const INSPECTIONS_PUT_REQUEST_IDLE = 'INSPECTIONS_PUT_REQUEST_IDLE';
export const INSPECTIONS_PUT_ASSIGN_REQUEST_START = 'INSPECTIONS_PUT_ASSIGN_REQUEST_START';
export const INSPECTIONS_PUT_ASSIGN_REQUEST_SUCCESS = 'INSPECTIONS_PUT_ASSIGN_REQUEST_SUCCESS';
export const INSPECTIONS_PUT_ASSIGN_REQUEST_FAIL = 'INSPECTIONS_PUT_ASSIGN_REQUEST_FAIL';
export const INSPECTIONS_PUT_ASSIGN_REQUEST_IDLE = 'INSPECTIONS_PUT_ASSIGN_REQUEST_IDLE';
export const INSPECTIONS_PUT_MARK_REQUEST_START = 'INSPECTIONS_PUT_MARK_REQUEST_START';
export const INSPECTIONS_PUT_MARK_REQUEST_SUCCESS = 'INSPECTIONS_PUT_MARK_REQUEST_SUCCESS';
export const INSPECTIONS_PUT_MARK_REQUEST_FAIL = 'INSPECTIONS_PUT_MARK_REQUEST_FAIL';
export const INSPECTIONS_PUT_MARK_REQUEST_IDLE = 'INSPECTIONS_PUT_MARK_REQUEST_IDLE';
export const INSPECTIONS_PUT_UNASSIGN_SELF_REQUEST_START = 'INSPECTIONS_PUT_UNASSIGN_SELF_REQUEST_START';
export const INSPECTIONS_PUT_UNASSIGN_SELF_REQUEST_SUCCESS = 'INSPECTIONS_PUT_UNASSIGN_SELF_REQUEST_SUCCESS';
export const INSPECTIONS_PUT_UNASSIGN_SELF_REQUEST_FAIL = 'INSPECTIONS_PUT_UNASSIGN_SELF_REQUEST_FAIL';
export const INSPECTIONS_PUT_UNASSIGN_SELF_REQUEST_IDLE = 'INSPECTIONS_PUT_UNASSIGN_SELF_REQUEST_IDLE';
export const INSPECTIONS_PUT_SUBMIT_REPORT_REQUEST_START = 'INSPECTIONS_PUT_SUBMIT_REPORT_REQUEST_START';
export const INSPECTIONS_PUT_SUBMIT_REPORT_REQUEST_SUCCESS = 'INSPECTIONS_PUT_SUBMIT_REPORT_REQUEST_SUCCESS';
export const INSPECTIONS_PUT_SUBMIT_REPORT_REQUEST_FAIL = 'INSPECTIONS_PUT_SUBMIT_REPORT_REQUEST_FAIL';
export const INSPECTIONS_PUT_SUBMIT_REPORT_REQUEST_IDLE = 'INSPECTIONS_PUT_SUBMIT_REPORT_REQUEST_IDLE';
export const INSPECTIONS_GENERERATE_REPORT_POST_REQUEST_START = 'INSPECTIONS_GENERERATE_REPORT_POST_REQUEST_START';
export const INSPECTIONS_GENERERATE_REPORT_POST_REQUEST_SUCCESS = 'INSPECTIONS_GENERERATE_REPORT_POST_REQUEST_SUCCESS';
export const INSPECTIONS_GENERERATE_REPORT_POST_REQUEST_FAIL = 'INSPECTIONS_GENERERATE_REPORT_POST_REQUEST_FAIL';
export const INSPECTIONS_GENERERATE_REPORT_POST_REQUEST_IDLE = 'INSPECTIONS_GENERERATE_REPORT_POST_REQUEST_IDLE';
export const INSPECTIONS_REPORTS_DELETE_POST_REQUEST_START = 'INSPECTIONS_REPORTS_DELETE_POST_REQUEST_START';
export const INSPECTIONS_REPORTS_DELETE_POST_REQUEST_SUCCESS = 'INSPECTIONS_REPORTS_DELETE_POST_REQUEST_SUCCESS';
export const INSPECTIONS_REPORTS_DELETE_POST_REQUEST_FAIL = 'INSPECTIONS_REPORTS_DELETE_POST_REQUEST_FAIL';
export const INSPECTIONS_REPORTS_DELETE_POST_REQUEST_IDLE = 'INSPECTIONS_REPORTS_DELETE_POST_REQUEST_IDLE';
export const INSPECTIONS_IMAGES_DOWNLOAD_ZIP_GET_REQUEST_START = 'INSPECTIONS_IMAGES_DOWNLOAD_ZIP_GET_REQUEST_START';
export const INSPECTIONS_IMAGES_DOWNLOAD_ZIP_GET_REQUEST_SUCCESS = 'INSPECTIONS_IMAGES_DOWNLOAD_ZIP_GET_REQUEST_SUCCESS';
export const INSPECTIONS_IMAGES_DOWNLOAD_ZIP_GET_REQUEST_FAIL = 'INSPECTIONS_IMAGES_DOWNLOAD_ZIP_GET_REQUEST_FAIL';
export const INSPECTIONS_IMAGES_DOWNLOAD_ZIP_GET_REQUEST_IDLE = 'INSPECTIONS_IMAGES_DOWNLOAD_ZIP_GET_REQUEST_IDLE';
export const INSPECTIONS_IMAGES_PATCH_REQUEST_START = 'INSPECTIONS_IMAGES_PATCH_REQUEST_START';
export const INSPECTIONS_IMAGES_PATCH_REQUEST_SUCCESS = 'INSPECTIONS_IMAGES_PATCH_REQUEST_SUCCESS';
export const INSPECTIONS_IMAGES_PATCH_REQUEST_FAIL = 'INSPECTIONS_IMAGES_PATCH_REQUEST_FAIL';
export const INSPECTIONS_IMAGES_PATCH_REQUEST_IDLE = 'INSPECTIONS_IMAGES_PATCH_REQUEST_IDLE';
export const INSPECTIONS_IMAGE_DELETE_REQUEST_START = 'INSPECTIONS_IMAGE_DELETE_REQUEST_START';
export const INSPECTIONS_IMAGE_DELETE_REQUEST_SUCCESS = 'INSPECTIONS_IMAGE_DELETE_REQUEST_SUCCESS';
export const INSPECTIONS_IMAGE_DELETE_REQUEST_FAIL = 'INSPECTIONS_IMAGE_DELETE_REQUEST_FAIL';
export const INSPECTIONS_IMAGE_DELETE_REQUEST_IDLE = 'INSPECTIONS_IMAGE_DELETE_REQUEST_IDLE';
export const INSPECTIONS_IMAGES_ASSOCIATE_PUT_REQUEST_START = 'INSPECTIONS_IMAGES_ASSOCIATE_PUT_REQUEST_START';
export const INSPECTIONS_IMAGES_ASSOCIATE_PUT_REQUEST_SUCCESS = 'INSPECTIONS_IMAGES_ASSOCIATE_PUT_REQUEST_SUCCESS';
export const INSPECTIONS_IMAGES_ASSOCIATE_PUT_REQUEST_FAIL = 'INSPECTIONS_IMAGES_ASSOCIATE_PUT_REQUEST_FAIL';
export const INSPECTIONS_IMAGES_ASSOCIATE_PUT_REQUEST_IDLE = 'INSPECTIONS_IMAGES_ASSOCIATE_PUT_REQUEST_IDLE';
export const INSPECTIONS_IMAGES_SELECT_PUT_REQUEST_START = 'INSPECTIONS_IMAGES_SELECT_PUT_REQUEST_START';
export const INSPECTIONS_IMAGES_SELECT_PUT_REQUEST_SUCCESS = 'INSPECTIONS_IMAGES_SELECT_PUT_REQUEST_SUCCESS';
export const INSPECTIONS_IMAGES_SELECT_PUT_REQUEST_FAIL = 'INSPECTIONS_IMAGES_SELECT_PUT_REQUEST_FAIL';
export const INSPECTIONS_IMAGES_SELECT_PUT_REQUEST_IDLE = 'INSPECTIONS_IMAGES_SELECT_PUT_REQUEST_IDLE';
export const INSPECTIONS_DELETE_REQUEST_START = 'INSPECTIONS_DELETE_REQUEST_START';
export const INSPECTIONS_DELETE_REQUEST_SUCCESS = 'INSPECTIONS_DELETE_REQUEST_SUCCESS';
export const INSPECTIONS_DELETE_REQUEST_FAIL = 'INSPECTIONS_DELETE_REQUEST_FAIL';
export const INSPECTIONS_DELETE_REQUEST_IDLE = 'INSPECTIONS_DELETE_REQUEST_IDLE';
export const INSPECTIONS_PATCH_REPOPULATE_REQUEST_START = 'INSPECTIONS_PATCH_REPOPULATE_REQUEST_START';
export const INSPECTIONS_PATCH_REPOPULATE_REQUEST_SUCCESS = 'INSPECTIONS_PATCH_REPOPULATE_REQUEST_SUCCESS';
export const INSPECTIONS_PATCH_REPOPULATE_REQUEST_FAIL = 'INSPECTIONS_PATCH_REPOPULATE_REQUEST_FAIL';
export const INSPECTIONS_PATCH_REPOPULATE_REQUEST_IDLE = 'INSPECTIONS_PATCH_REPOPULATE_REQUEST_IDLE';
export const INSPECTIONS_GET_DOCUMENT_REQUEST_START = 'INSPECTIONS_GET_DOCUMENT_REQUEST_START';
export const INSPECTIONS_GET_DOCUMENT_REQUEST_SUCCESS = 'INSPECTIONS_GET_DOCUMENT_REQUEST_SUCCESS';
export const INSPECTIONS_GET_DOCUMENT_REQUEST_FAIL = 'INSPECTIONS_GET_DOCUMENT_REQUEST_FAIL';
export const INSPECTIONS_IMAGES_LEFT_TO_SYNC_COUNT_START = 'INSPECTIONS_IMAGES_LEFT_TO_SYNC_COUNT_START';
export const INSPECTIONS_IMAGES_LEFT_TO_SYNC_COUNT_SUCCESS = 'INSPECTIONS_IMAGES_LEFT_TO_SYNC_COUNT_SUCCESS';

export const inspectionsGetDocumentStart = () => ({
  type: INSPECTIONS_GET_DOCUMENT_REQUEST_START,
  requestState: apiRequestState.LOADING,
});

export const inspectionsGetDocumentSuccess = (response, openInPreview = true) => {
  const downloadUrl = window.URL.createObjectURL(response.data);

  if (openInPreview) {
    // Create a new window or tab to display the PDF
    window.open(downloadUrl, '_blank');
    // It's important to revoke the object URL to avoid memory leaks
    window.URL.revokeObjectURL(downloadUrl);
  } else {
    // Create a temporary link element to trigger download
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = get(response, 'headers.x-filename', 'default.pdf'); // You can specify a default filename here
    document.body.appendChild(link);
    link.click();
    // Remove the link element and revoke URL after triggering download
    document.body.removeChild(link);
    window.URL.revokeObjectURL(downloadUrl);
  }

  return {
    type: INSPECTIONS_GET_DOCUMENT_REQUEST_SUCCESS,
    requestState: apiRequestState.SUCCESS,
  };
};

export const inspectionsGetDocumentFail = () => ({
  type: INSPECTIONS_GET_DOCUMENT_REQUEST_FAIL,
  requestState: apiRequestState.FAIL,
});

export const inspectionsGetDocument = (inspection, documentData, openInPreview = true) => async (dispatch) => {
  dispatch(inspectionsGetDocumentStart());

  try {
    const response = await axios.post(`${API_BASE_URL}/inspections/${inspection._id}/report/fetch`, documentData, {
      headers: {
        // Specify any necessary headers, e.g., Content-Type
      },
      responseType: 'blob',
    });

    await dispatch(inspectionsGetDocumentSuccess(response, openInPreview));
  } catch (err) {
    dispatch(inspectionsGetDocumentFail());
    dispatch(notificationsShow('error', 'Failed to retrieve Document'));
  }
};

export const inspectionsGetSingleStart = () => ({
  type: INSPECTIONS_GET_SINGLE_REQUEST_START,
  inspectionsGetSingleRequestState: apiRequestState.LOADING,
});

export const inspectionsGetSingleSuccess = (inspection) => ({
  inspection,
  type: INSPECTIONS_GET_SINGLE_REQUEST_SUCCESS,
  inspectionsGetSingleRequestState: apiRequestState.SUCCESS,
});

export const inspectionsGetSingleFail = () => ({
  type: INSPECTIONS_GET_SINGLE_REQUEST_FAIL,
  inspectionsGetSingleRequestState: apiRequestState.FAIL,
});

export const inspectionsGetSingle = (body) => async (dispatch) => {
  try {
    const inspectionId = get(body, 'inspectionId', null);
    if (!inspectionId) throw new Error('Invalid inspection id');

    dispatch(inspectionsGetSingleStart());

    const response = await axios.get(`${API_BASE_URL}/inspections/${inspectionId}`);

    await dispatch(inspectionsGetSingleSuccess(response.data));
  } catch (err) {
    dispatch(inspectionsGetSingleFail());
  }
};

export const inspectionsGetStart = () => ({
  type: INSPECTIONS_GET_REQUEST_START,
  requestState: apiRequestState.LOADING,
});

export const inspectionsGetSuccess = (inspections) => ({
  inspections,
  type: INSPECTIONS_GET_REQUEST_SUCCESS,
  requestState: apiRequestState.SUCCESS,
});

export const inspectionsGetFail = () => ({
  type: INSPECTIONS_GET_REQUEST_FAIL,
  requestState: apiRequestState.FAIL,
});

export const inspectionsGetIdle = () => ({
  type: INSPECTIONS_GET_REQUEST_IDLE,
  requestState: apiRequestState.IDLE,
});

export const inspectionsGet = (body) => async (dispatch) => {
  const status = get(body, 'status', INSPECTION_RETRIEVE_OPTIONS.active);
  dispatch(inspectionsGetStart());

  try {
    const response = await axios.get(`${API_BASE_URL}/inspections/?options=${status}`);

    await dispatch(inspectionsGetSuccess(response.data));
    await dispatch(inspectionsGetIdle());
  } catch (err) {
    dispatch(inspectionsGetFail());
  }
};

export const inspectionsGetAvailableStaffStart = () => createRequestAction(INSPECTIONS_GET_AVAILABLE_STAFF_REQUEST_START, apiRequestState.LOADING);
export const inspectionsGetAvailableStaffSuccess = (availableStaff) => createRequestAction(INSPECTIONS_GET_AVAILABLE_STAFF_REQUEST_SUCCESS, apiRequestState.SUCCESS, availableStaff);
export const inspectionsGetAvailableStaffFail = () => createRequestAction(INSPECTIONS_GET_AVAILABLE_STAFF_REQUEST_FAIL, apiRequestState.FAIL);
export const inspectionsGetAvailableStaffIdle = () => createRequestAction(INSPECTIONS_GET_AVAILABLE_STAFF_REQUEST_IDLE, apiRequestState.IDLE);

export const inspectionsGetAvailableStaff = () => async (dispatch) => {
  dispatch(inspectionsGetAvailableStaffStart());

  try {
    const response = await axios.get(`${API_BASE_URL}/inspections/availableStaff`);
    await dispatch(inspectionsGetAvailableStaffSuccess(response.data));
    await dispatch(inspectionsGetAvailableStaffIdle(response.data));
  } catch (err) {
    dispatch(inspectionsGetAvailableStaffFail());
  }
};

export const inspectionsPostStart = () => createRequestAction(INSPECTIONS_POST_REQUEST_START, apiRequestState.LOADING);
export const inspectionsPostSuccess = (inspection) => createRequestAction(INSPECTIONS_POST_REQUEST_SUCCESS, apiRequestState.SUCCESS, inspection);
export const inspectionsPostFail = () => createRequestAction(INSPECTIONS_POST_REQUEST_FAIL, apiRequestState.FAIL);
export const inspectionsPostIdle = () => createRequestAction(INSPECTIONS_POST_REQUEST_IDLE, apiRequestState.IDLE);

export const inspectionsPost = (inspection) => (dispatch) => {
  dispatch(inspectionsPostStart());
  const inspectionsPostRequest = async () => {
    try {
      const response = await axios.post(`${API_BASE_URL}/inspections`, inspection);

      await dispatch(inspectionsPostSuccess(response.data));
      await dispatch(inspectionsPostIdle());
      dispatch(notificationsShow('success', 'Successfully created inspection'));
    } catch (err) {
      dispatch(inspectionsPostFail());
      if (err.response.status === StatusCodes.BAD_REQUEST) {
        dispatch(notificationsShow('warning', err.response.data));
      } else {
        dispatch(notificationsShow('error', 'Failed to create inspection'));
      }
    }
  };

  inspectionsPostRequest();
};

export const inspectionsPutStart = () => ({
  type: INSPECTIONS_PUT_REQUEST_START,
  requestState: apiRequestState.LOADING,
});

export const inspectionsPutSuccess = (inspection) => ({
  type: INSPECTIONS_PUT_REQUEST_SUCCESS,
  requestState: apiRequestState.SUCCESS,
  inspection,
});

export const inspectionsPutFail = () => ({
  type: INSPECTIONS_PUT_REQUEST_FAIL,
  requestState: apiRequestState.FAIL,
});

export const inspectionsPutIdle = () => ({
  type: INSPECTIONS_PUT_REQUEST_IDLE,
  requestState: apiRequestState.IDLE,
});

export const inspectionsPut = (inspection, body) => (dispatch) => {
  dispatch(inspectionsPutStart());
  const inspectionsPutRequest = async () => {
    try {
      const response = await axios.put(`${API_BASE_URL}/inspections/${inspection._id}`, body);

      await dispatch(inspectionsPutSuccess(response.data));
      await dispatch(inspectionsPutIdle());
      dispatch(notificationsShow('success', 'Successfully updated inspection'));
    } catch (err) {
      dispatch(inspectionsPutFail());
      if (err.response.status === StatusCodes.BAD_REQUEST) {
        dispatch(notificationsShow('warning', `${err.response.data}`));
      } else {
        dispatch(notificationsShow('error', 'Failed to update inspection'));
      }
    }
  };

  inspectionsPutRequest();
};

export const inspectionsPutAssignStart = () => ({
  type: INSPECTIONS_PUT_ASSIGN_REQUEST_START,
  requestState: apiRequestState.LOADING,
});

export const inspectionsPutAssignSuccess = (inspections) => ({
  type: INSPECTIONS_PUT_ASSIGN_REQUEST_SUCCESS,
  requestState: apiRequestState.SUCCESS,
  inspections,
});

export const inspectionsPutAssignFail = () => ({
  type: INSPECTIONS_PUT_ASSIGN_REQUEST_FAIL,
  requestState: apiRequestState.FAIL,
});

export const inspectionsPutAssignIdle = () => ({
  type: INSPECTIONS_PUT_ASSIGN_REQUEST_IDLE,
  requestState: apiRequestState.IDLE,
});

export const inspectionsPutAssign = (assignment) => (dispatch) => {
  dispatch(inspectionsPutAssignStart());
  const inspectionsPutAssignRequest = async () => {
    try {
      const response = await axios.put(`${API_BASE_URL}/inspections/assign`, assignment);

      await dispatch(inspectionsPutAssignSuccess(response.data));
      await dispatch(inspectionsPutAssignIdle());
      dispatch(notificationsShow('success', 'Successfully assigned inspection'));
    } catch (err) {
      dispatch(inspectionsPutAssignFail());
      if (err.response.status === StatusCodes.BAD_REQUEST) {
        dispatch(notificationsShow('warning', `${err.response.data}`));
      } else {
        dispatch(notificationsShow('error', 'Failed to assign inspection'));
      }
    }
  };

  inspectionsPutAssignRequest();
};

export const inspectionsPutMarkStart = () => ({
  type: INSPECTIONS_PUT_MARK_REQUEST_START,
  requestState: apiRequestState.LOADING,
});

export const inspectionsPutMarkSuccess = (inspections) => ({
  type: INSPECTIONS_PUT_MARK_REQUEST_SUCCESS,
  requestState: apiRequestState.SUCCESS,
  inspections,
});

export const inspectionsPutMarkFail = () => ({
  type: INSPECTIONS_PUT_MARK_REQUEST_FAIL,
  requestState: apiRequestState.FAIL,
});

export const inspectionsPutMarkIdle = () => ({
  type: INSPECTIONS_PUT_MARK_REQUEST_IDLE,
  requestState: apiRequestState.IDLE,
});

export const inspectionsPutMark = (assignment) => (dispatch) => {
  dispatch(inspectionsPutMarkStart());
  const inspectionsPutMarkRequest = async () => {
    try {
      const response = await axios.put(`${API_BASE_URL}/inspections/mark`, assignment);

      await dispatch(inspectionsPutMarkSuccess(response.data));
      await dispatch(inspectionsPutMarkIdle());
      dispatch(notificationsShow('success', 'Successfully marked inspection'));
    } catch (err) {
      dispatch(inspectionsPutMarkFail());
      if (err.response.status === StatusCodes.BAD_REQUEST) {
        dispatch(notificationsShow('warning', `${err.response.data}`));
      } else {
        dispatch(notificationsShow('error', 'Failed to mark inspection'));
      }
    }
  };

  inspectionsPutMarkRequest();
};

export const inspectionsPutUnassignSelfStart = () => ({
  type: INSPECTIONS_PUT_UNASSIGN_SELF_REQUEST_START,
  requestState: apiRequestState.LOADING,
});

export const inspectionsPutUnassignSelfSuccess = (inspection) => ({
  type: INSPECTIONS_PUT_UNASSIGN_SELF_REQUEST_SUCCESS,
  requestState: apiRequestState.SUCCESS,
  inspection,
});

export const inspectionsPutUnassignSelfFail = () => ({
  type: INSPECTIONS_PUT_UNASSIGN_SELF_REQUEST_FAIL,
  requestState: apiRequestState.FAIL,
});

export const inspectionsPutUnassignSelfIdle = () => ({
  type: INSPECTIONS_PUT_UNASSIGN_SELF_REQUEST_IDLE,
  requestState: apiRequestState.IDLE,
});

export const inspectionsPutUnassignSelf = (unassignment) => (dispatch) => {
  dispatch(inspectionsPutUnassignSelfStart());
  const inspectionsPutUnassignSelfRequest = async () => {
    try {
      const response = await axios.put(`${API_BASE_URL}/inspections/unassign`, unassignment);

      await dispatch(inspectionsPutUnassignSelfSuccess(response.data));
      await dispatch(inspectionsPutUnassignSelfIdle());
      dispatch(notificationsShow('success', 'Successfully unassigned inspection'));
    } catch (err) {
      dispatch(inspectionsPutUnassignSelfFail());
      if (err.response.status === StatusCodes.BAD_REQUEST) {
        dispatch(notificationsShow('warning', `${err.response.data}`));
      } else {
        dispatch(notificationsShow('error', 'Failed to unassign inspection'));
      }
    }
  };

  inspectionsPutUnassignSelfRequest();
};

export const inspectionsPutSubmitReportStart = () => ({
  type: INSPECTIONS_PUT_SUBMIT_REPORT_REQUEST_START,
  requestState: apiRequestState.LOADING,
});

export const inspectionsPutSubmitReportSuccess = (inspection) => ({
  type: INSPECTIONS_PUT_SUBMIT_REPORT_REQUEST_SUCCESS,
  requestState: apiRequestState.SUCCESS,
  inspection,
});

export const inspectionsPutSubmitReportFail = () => ({
  type: INSPECTIONS_PUT_SUBMIT_REPORT_REQUEST_FAIL,
  requestState: apiRequestState.FAIL,
});

export const inspectionsPutSubmitReportIdle = () => ({
  type: INSPECTIONS_PUT_SUBMIT_REPORT_REQUEST_IDLE,
  requestState: apiRequestState.IDLE,
});

export const inspectionsPutSubmitReport = (inspection, report) => (dispatch) => {
  dispatch(inspectionsPutSubmitReportStart());
  const inspectionsPutSubmitReportRequest = async () => {
    try {
      const response = await axios.put(`${API_BASE_URL}/inspections/${inspection._id}/report/submit`, { inspection, report });

      await dispatch(inspectionsPutSubmitReportSuccess(response.data));
      await dispatch(inspectionsPutSubmitReportIdle());
      dispatch(notificationsShow('success', 'Successfully submitted report'));
    } catch (err) {
      dispatch(inspectionsPutSubmitReportFail());
      if (err.response.status === StatusCodes.BAD_REQUEST) {
        dispatch(notificationsShow('warning', `${err.response.data}`));
      } else {
        dispatch(notificationsShow('error', 'Failed to submit report'));
      }
    }
  };

  inspectionsPutSubmitReportRequest();
};

export const inspectionsDeleteStart = () => ({
  type: INSPECTIONS_DELETE_REQUEST_START,
  requestState: apiRequestState.LOADING,
});

export const inspectionsDeleteSuccess = (inspections) => ({
  type: INSPECTIONS_DELETE_REQUEST_SUCCESS,
  requestState: apiRequestState.SUCCESS,
  inspections,
});

export const inspectionsDeleteFail = () => ({
  type: INSPECTIONS_DELETE_REQUEST_FAIL,
  requestState: apiRequestState.FAIL,
});

export const inspectionsDeleteIdle = () => ({
  type: INSPECTIONS_DELETE_REQUEST_IDLE,
  requestState: apiRequestState.IDLE,
});

export const inspectionsDelete = (inspections) => (dispatch) => {
  dispatch(inspectionsDeleteStart());

  const inspectionsDeleteRequest = async () => {
    try {
      const response = await axios.delete(`${API_BASE_URL}/inspections`, { data: inspections });

      await dispatch(inspectionsDeleteSuccess(response.data));
      await dispatch(inspectionsDeleteIdle());
      dispatch(notificationsShow('success', 'Successfully deleted inspection'));
    } catch (err) {
      dispatch(inspectionsDeleteFail());
      dispatch(notificationsShow('error', 'Failed to delete inspection'));
    }
  };

  inspectionsDeleteRequest();
};

export const inspectionsPatchRepopulateStart = () => createRequestAction(INSPECTIONS_PATCH_REPOPULATE_REQUEST_START, apiRequestState.LOADING);
export const inspectionsPatchRepopulateSuccess = (inspections) => createRequestAction(INSPECTIONS_PATCH_REPOPULATE_REQUEST_SUCCESS, apiRequestState.SUCCESS, inspections);
export const inspectionsPatchRepopulateFail = () => createRequestAction(INSPECTIONS_PATCH_REPOPULATE_REQUEST_FAIL, apiRequestState.FAIL);
export const inspectionsPatchRepopulateIdle = () => createRequestAction(INSPECTIONS_PATCH_REPOPULATE_REQUEST_IDLE, apiRequestState.IDLE);

export const inspectionsPatchRepopulate = (inspections) => (dispatch) => {
  dispatch(inspectionsPatchRepopulateStart());
  const inspectionsPatchRepopulateRequest = async () => {
    try {
      const response = await axios.patch(`${API_BASE_URL}/inspections/fetchFromBooking`, inspections);
      await dispatch(inspectionsPatchRepopulateSuccess(response.data));
      await dispatch(inspectionsPatchRepopulateIdle());
      dispatch(notificationsShow('success', 'Successfully repopulated inspection'));
    } catch (err) {
      dispatch(inspectionsPatchRepopulateFail());
      dispatch(notificationsShow('error', 'Failed to repopulate inspection from booking'));
    }
  };

  inspectionsPatchRepopulateRequest();
};

export const inspectionsImagesPatchStart = () => ({
  type: INSPECTIONS_IMAGES_PATCH_REQUEST_START,
  requestState: apiRequestState.LOADING,
});

export const inspectionsImagesPatchSuccess = ({ inspection, uploadProgress, leftToSync }) => ({
  type: INSPECTIONS_IMAGES_PATCH_REQUEST_SUCCESS,
  requestState: leftToSync === 0 ? apiRequestState.SUCCESS : apiRequestState.LOADING,
  inspection,
  uploadProgress,
  leftToSync,
});

export const inspectionsImagesPatchFail = () => ({
  type: INSPECTIONS_IMAGES_PATCH_REQUEST_FAIL,
  requestState: apiRequestState.FAIL,
});

export const inspectionsImagesPatchIdle = () => ({
  type: INSPECTIONS_IMAGES_PATCH_REQUEST_IDLE,
  requestState: apiRequestState.IDLE,
});

const cloneFile = (originalFile) => new File(
  [originalFile],
  originalFile.name,
  {
    type: originalFile.type,
    lastModified: originalFile.lastModified,
  },
);

const storeImagesLocally = async (images, dispatch, inspection, productId) => {
  try {
    const objectsToStore = images.map((image) => {
      const imageFile = cloneFile(image);

      return ({
        imageFile,
        inspectionId: inspection._id,
        productId,
      });
    });

    dispatch(inspectionsImagesPatchStart());

    // Store the images in IndexedDB and get their data
    await storeObjects(objectsToStore);
    const totalImages = await countImages();

    dispatch(inspectionsImagesPatchSuccess({ inspection, leftToSync: totalImages }));
    dispatch(inspectionsImagesPatchIdle());
  } catch (error) {
    dispatch(inspectionsImagesPatchFail());
  }
};

// Helper function to send images in batches
const sendImagesInBatches = async (images, batchSize, dispatch, inspection, imageType, assignmentId) => {
  const totalBatches = Math.ceil(images.length / batchSize);
  let currentBatch = 0;

  const uploadBatch = async (imageBatch, batchId) => {
    const formData = new FormData();
    imageBatch.forEach((image) => {
      formData.append('images', image);
    });

    // Include batchId in the URL or as a part of the form data
    const url = `${API_BASE_URL}/inspections/${inspection._id}/images/${imageType}/${assignmentId}/${batchId}`;
    try {
      const response = await axios.patch(url, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        timeout: 15 * 60 * 1000, // 15 min
      });

      // Calculate progress and dispatch success
      const uploadProgress = Math.round(((currentBatch + 1) / totalBatches) * 100);
      const temp = {
        leftToSync: totalBatches - currentBatch,
        inspection: response.data.inspection,
        uploadProgress,
      };
      dispatch(inspectionsImagesPatchSuccess(temp));
      return true; // Batch was successfully uploaded
    } catch (error) {
      dispatch(inspectionsImagesPatchFail());
      dispatch(notificationsShow('error', 'Failed to upload images'));
      return false; // Batch failed to upload
    }
  };

  // Sequential batch upload with progress updates
  dispatch(inspectionsImagesPatchStart());
  while (currentBatch < totalBatches) {
    const batch = images.slice(currentBatch * batchSize, (currentBatch + 1) * batchSize);
    const batchId = uuid(); // Generate a unique ID for each batch

    // eslint-disable-next-line
    const success = await uploadBatch(batch, batchId);
    if (!success) break; // Stop if uploading fails
    // eslint-disable-next-line
    currentBatch++; // Proceed to the next batch
  }

  if (currentBatch === totalBatches) {
    dispatch(notificationsShow('success', 'All images successfully uploaded'));
  }

  dispatch(inspectionsImagesPatchIdle());
};

export const inspectionsImagesPatch = (inspection, images, imageType, assignmentId) => (dispatch) => {
  const batchSize = IMAGE_SYNC_BATCH_SIZE; // Adjust this number based on your server's capacity

  if (USE_STORAGE_INDEXED_DB) {
    storeImagesLocally(images, dispatch, inspection, assignmentId);
  } else {
    sendImagesInBatches(images, batchSize, dispatch, inspection, imageType, assignmentId);
  }
};

export const inspectionsImageDeleteStart = () => ({
  type: INSPECTIONS_IMAGE_DELETE_REQUEST_START,
  requestState: apiRequestState.LOADING,
});

export const inspectionsImageDeleteSuccess = (inspection) => ({
  type: INSPECTIONS_IMAGE_DELETE_REQUEST_SUCCESS,
  requestState: apiRequestState.SUCCESS,
  inspection,
});

export const inspectionsImageDeleteFail = () => ({
  type: INSPECTIONS_IMAGE_DELETE_REQUEST_FAIL,
  requestState: apiRequestState.FAIL,
});

export const inspectionsImageDeleteIdle = () => ({
  type: INSPECTIONS_IMAGE_DELETE_REQUEST_IDLE,
  requestState: apiRequestState.IDLE,
});

export const inspectionsImageDelete = (inspection, request) => (dispatch) => {
  dispatch(inspectionsImageDeleteStart());
  const inspectionsImageDeleteRequest = async () => {
    try {
      const response = await axios.delete(`${API_BASE_URL}/inspections/${inspection._id}/images`, { data: request });

      await dispatch(inspectionsImageDeleteSuccess(response.data));
      await dispatch(inspectionsImageDeleteIdle());
      dispatch(notificationsShow('success', 'Successfully deleted images'));
    } catch (err) {
      dispatch(inspectionsImageDeleteFail());
      dispatch(notificationsShow('error', 'Failed to delete images'));
    }
  };

  inspectionsImageDeleteRequest();
};

export const inspectionsImagesAssociateStart = () => ({
  type: INSPECTIONS_IMAGES_ASSOCIATE_PUT_REQUEST_START,
  requestState: apiRequestState.LOADING,
});

export const inspectionsImagesAssociateSuccess = (inspection) => ({
  type: INSPECTIONS_IMAGES_ASSOCIATE_PUT_REQUEST_SUCCESS,
  requestState: apiRequestState.SUCCESS,
  inspection,
});

export const inspectionsImagesAssociateFail = () => ({
  type: INSPECTIONS_IMAGES_ASSOCIATE_PUT_REQUEST_FAIL,
  requestState: apiRequestState.FAIL,
});

export const inspectionsImagesAssociateIdle = () => ({
  type: INSPECTIONS_IMAGES_ASSOCIATE_PUT_REQUEST_IDLE,
  requestState: apiRequestState.IDLE,
});

export const inspectionsImagesAssociate = (inspection, association) => (dispatch) => {
  dispatch(inspectionsImagesAssociateStart());
  const inspectionsImagesAssociateRequest = async () => {
    try {
      const response = await axios.put(`${API_BASE_URL}/inspections/${inspection._id}/images/associate`, association);

      await dispatch(inspectionsImagesAssociateSuccess(response.data));
      await dispatch(inspectionsImagesAssociateIdle());
      dispatch(notificationsShow('success', 'Successfully associated images'));
    } catch (err) {
      dispatch(inspectionsImagesAssociateFail());
      dispatch(notificationsShow('error', 'Failed to associate images'));
    }
  };

  inspectionsImagesAssociateRequest();
};

export const inspectionsImagesSelectStart = () => ({
  type: INSPECTIONS_IMAGES_SELECT_PUT_REQUEST_START,
  requestState: apiRequestState.LOADING,
});

export const inspectionsImagesSelectSuccess = (inspection) => ({
  type: INSPECTIONS_IMAGES_SELECT_PUT_REQUEST_SUCCESS,
  requestState: apiRequestState.SUCCESS,
  inspection,
});

export const inspectionsImagesSelectFail = () => ({
  type: INSPECTIONS_IMAGES_SELECT_PUT_REQUEST_FAIL,
  requestState: apiRequestState.FAIL,
});

export const inspectionsImagesSelectIdle = () => ({
  type: INSPECTIONS_IMAGES_SELECT_PUT_REQUEST_IDLE,
  requestState: apiRequestState.IDLE,
});

export const inspectionsReportImagesSelect = (inspection, select) => (dispatch) => {
  dispatch(inspectionsImagesSelectStart());
  const inspectionsImagesSelectRequest = async () => {
    try {
      const response = await axios.put(`${API_BASE_URL}/inspections/${inspection._id}/images/select`, select);

      await dispatch(inspectionsImagesSelectSuccess(response.data));
      await dispatch(inspectionsImagesSelectIdle());
      dispatch(notificationsShow('success', 'Successfully selected images'));
    } catch (err) {
      dispatch(inspectionsImagesSelectFail());
      dispatch(notificationsShow('error', 'Failed to select images'));
    }
  };

  inspectionsImagesSelectRequest();
};

export const inspectionsGenerateReportPostStart = () => ({
  type: INSPECTIONS_GENERERATE_REPORT_POST_REQUEST_START,
  requestState: apiRequestState.LOADING,
});

export const inspectionsGenerateReportPostSuccess = (inspection) => ({
  type: INSPECTIONS_GENERERATE_REPORT_POST_REQUEST_SUCCESS,
  requestState: apiRequestState.SUCCESS,
  inspection,
});

export const inspectionsGenerateReportPostFail = () => ({
  type: INSPECTIONS_GENERERATE_REPORT_POST_REQUEST_FAIL,
  requestState: apiRequestState.FAIL,
});

export const inspectionsGenerateReportPostIdle = () => ({
  type: INSPECTIONS_GENERERATE_REPORT_POST_REQUEST_IDLE,
  requestState: apiRequestState.IDLE,
});

export const inspectionsGenerateReportPost = (inspection, service) => async (dispatch) => {
  dispatch(inspectionsGenerateReportPostStart());

  try {
    const response = await axios.post(`${API_BASE_URL}/inspections/${inspection._id}/report/generate`, { service });

    await dispatch(inspectionsGenerateReportPostSuccess(response.data, response.headers));
    await dispatch(inspectionsGenerateReportPostIdle());
    dispatch(notificationsShow('success', 'Successfully generated report'));
  } catch (err) {
    if (err.response.status === StatusCodes.BAD_REQUEST) {
      dispatch(notificationsShow('warning', `${err.response.status}`));
    } else {
      dispatch(notificationsShow('error', 'Failed to generate report'));
    }
    dispatch(inspectionsGenerateReportPostFail());
  }
};

export const inspectionsReportsDeleteStart = () => ({
  type: INSPECTIONS_REPORTS_DELETE_POST_REQUEST_START,
  requestState: apiRequestState.LOADING,
});

export const inspectionsReportsDeleteSuccess = (inspection) => ({
  type: INSPECTIONS_REPORTS_DELETE_POST_REQUEST_SUCCESS,
  requestState: apiRequestState.SUCCESS,
  inspection,
});

export const inspectionsReportsDeleteFail = () => ({
  type: INSPECTIONS_REPORTS_DELETE_POST_REQUEST_FAIL,
  requestState: apiRequestState.FAIL,
});

export const inspectionsReportsDeleteIdle = () => ({
  type: INSPECTIONS_REPORTS_DELETE_POST_REQUEST_IDLE,
  requestState: apiRequestState.IDLE,
});

export const inspectionsReportsDelete = (inspection, reportIds) => (dispatch) => {
  dispatch(inspectionsReportsDeleteStart());
  const inspectionsReportsDeleteRequest = async () => {
    try {
      const response = await axios.post(`${API_BASE_URL}/inspections/${inspection._id}/reports/delete`, { reportIds });

      await dispatch(inspectionsReportsDeleteSuccess(response.data));
      await dispatch(inspectionsReportsDeleteIdle());
      dispatch(notificationsShow('success', 'Successfully deleted reports'));
    } catch (err) {
      dispatch(inspectionsReportsDeleteFail());
      dispatch(notificationsShow('error', 'Failed to delete reports'));
    }
  };

  inspectionsReportsDeleteRequest();
};

export const inspectionsImagesDownloadZipGetStart = () => ({
  type: INSPECTIONS_IMAGES_DOWNLOAD_ZIP_GET_REQUEST_START,
  requestState: apiRequestState.LOADING,
});

export const inspectionsImagesDownloadZipGetSuccess = (data, headers) => {
  const fileName = headers['x-filename'];
  const downloadUrl = window.URL.createObjectURL(data);

  const link = document.createElement('a');
  link.href = downloadUrl;
  link.download = fileName;
  link.target = '_blank';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  return {
    type: INSPECTIONS_IMAGES_DOWNLOAD_ZIP_GET_REQUEST_SUCCESS,
    requestState: apiRequestState.SUCCESS,
  };
};

export const inspectionsImagesDownloadZipGetFail = () => ({
  type: INSPECTIONS_IMAGES_DOWNLOAD_ZIP_GET_REQUEST_FAIL,
  requestState: apiRequestState.FAIL,
});

export const inspectionsImagesDownloadZipGet = (inspection, type) => async (dispatch) => {
  dispatch(inspectionsImagesDownloadZipGetStart());

  try {
    const response = await axios.get(`${API_BASE_URL}/inspections/${inspection._id}/downloadImages/${type}`, {
      responseType: 'blob',
    });
    await dispatch(inspectionsImagesDownloadZipGetSuccess(response.data, response.headers));
  } catch (err) {
    if (err?.response?.status === StatusCodes.BAD_REQUEST) {
      dispatch(notificationsShow('warning', `${err.response.status}`));
    } else {
      dispatch(notificationsShow('error', 'Failed to update booking'));
    }
    dispatch(inspectionsImagesDownloadZipGetFail());
  }
};

export const getImagesLeftToSyncCountStart = () => ({
  type: INSPECTIONS_IMAGES_LEFT_TO_SYNC_COUNT_START,
  requestState: apiRequestState.LOADING,
});

export const getImagesLeftToSyncCountSuccess = ({ imagesLeftToSync, uploadedImages }) => ({
  type: INSPECTIONS_IMAGES_LEFT_TO_SYNC_COUNT_SUCCESS,
  requestState: apiRequestState.SUCCESS,
  imagesLeftToSync,
  uploadedImages,
});

export const getImagesLeftToSyncCount = (uploadedImages) => async (dispatch) => {
  const imagesLeftToSync = await countImages();

  await dispatch(getImagesLeftToSyncCountStart());
  await dispatch(getImagesLeftToSyncCountSuccess({ imagesLeftToSync, uploadedImages }));
};
