import { React } from 'react';
import './TableBody.css';

function TableBody({ children }) {
  return (
    <tbody>
      {children}
    </tbody>
  );
}

export default TableBody;
