import axios from 'axios';
import { StatusCodes } from 'http-status-codes';
import { apiRequestState, createRequestAction } from '../utility/utility';
import { API_BASE_URL } from '../config';
import { notificationsShow } from './notifications';

export const ORGANIZATION_GET_REQUEST_START = 'ORGANIZATION_GET_REQUEST_START';
export const ORGANIZATION_GET_REQUEST_SUCCESS = 'ORGANIZATION_GET_REQUEST_SUCCESS';
export const ORGANIZATION_GET_REQUEST_FAIL = 'ORGANIZATION_GET_REQUEST_FAIL';
export const ORGANIZATION_GET_REQUEST_IDLE = 'ORGANIZATION_GET_REQUEST_IDLE';
export const ORGANIZATION_GET_INSPECTORS_REQUEST_START = 'ORGANIZATION_GET_INSPECTORS_REQUEST_START';
export const ORGANIZATION_GET_INSPECTORS_REQUEST_SUCCESS = 'ORGANIZATION_GET_INSPECTORS_REQUEST_SUCCESS';
export const ORGANIZATION_GET_INSPECTORS_REQUEST_FAIL = 'ORGANIZATION_GET_INSPECTORS_REQUEST_FAIL';
export const ORGANIZATION_PUT_REQUEST_START = 'ORGANIZATION_PUT_REQUEST_START';
export const ORGANIZATION_PUT_REQUEST_SUCCESS = 'ORGANIZATION_PUT_REQUEST_SUCCESS';
export const ORGANIZATION_PUT_REQUEST_FAIL = 'ORGANIZATION_PUT_REQUEST_FAIL';
export const ORGANIZATION_PUT_REQUEST_IDLE = 'ORGANIZATION_PUT_REQUEST_IDLE';

export const organizationGetStart = () => createRequestAction(ORGANIZATION_GET_REQUEST_START, apiRequestState.LOADING);
export const organizationGetSuccess = (organization) => createRequestAction(ORGANIZATION_GET_REQUEST_SUCCESS, apiRequestState.SUCCESS, organization);
export const organizationGetFail = () => createRequestAction(ORGANIZATION_GET_REQUEST_FAIL, apiRequestState.FAIL);
export const organizationGetIdle = () => createRequestAction(ORGANIZATION_GET_REQUEST_IDLE, apiRequestState.IDLE);
export const organizationGet = () => (dispatch) => {
  dispatch(organizationGetStart());
  const organizationGetRequest = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/organizations/`);
      dispatch(organizationGetSuccess(response.data));
      await dispatch(organizationGetIdle());
    } catch (err) {
      dispatch(organizationGetFail());
    }
  };

  organizationGetRequest();
};

export const organizationGetInspectorsStart = () => createRequestAction(ORGANIZATION_GET_INSPECTORS_REQUEST_START, apiRequestState.LOADING);
export const organizationGetInspectorsSuccess = (organizations) => createRequestAction(ORGANIZATION_GET_INSPECTORS_REQUEST_SUCCESS, apiRequestState.SUCCESS, organizations);
export const organizationGetInspectorsFail = () => createRequestAction(ORGANIZATION_GET_INSPECTORS_REQUEST_FAIL, apiRequestState.FAIL);
export const organizationGetInspectors = () => (dispatch) => {
  dispatch(organizationGetInspectorsStart());
  const organizationGetInspectorsRequest = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/organizations/inspectors`);
      dispatch(organizationGetInspectorsSuccess(response.data));
    } catch (err) {
      dispatch(organizationGetInspectorsFail());
    }
  };

  organizationGetInspectorsRequest();
};

export const organizationPutStart = () => createRequestAction(ORGANIZATION_PUT_REQUEST_START, apiRequestState.LOADING);
export const organizationPutSuccess = (organization) => createRequestAction(ORGANIZATION_PUT_REQUEST_SUCCESS, apiRequestState.SUCCESS, organization);
export const organizationPutFail = () => createRequestAction(ORGANIZATION_PUT_REQUEST_FAIL, apiRequestState.FAIL);
export const organizationPutIdle = () => createRequestAction(ORGANIZATION_PUT_REQUEST_IDLE, apiRequestState.IDLE);
export const organizationPut = (body) => (dispatch) => {
  dispatch(organizationPutStart());
  const organizationPutRequest = async () => {
    try {
      const response = await axios.put(`${API_BASE_URL}/organizations`, body);
      await dispatch(organizationPutSuccess(response.data));
      await dispatch(organizationPutIdle());
      dispatch(notificationsShow('success', 'Successfully updated organization'));
    } catch (err) {
      dispatch(organizationPutFail());
      if (err.response.status === StatusCodes.BAD_REQUEST) {
        dispatch(notificationsShow('warning', `${err.response.data}`));
      } else {
        dispatch(notificationsShow('error', 'Failed to update organization'));
      }
    }
  };

  organizationPutRequest();
};
