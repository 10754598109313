// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.date-time-picker-container {
    display: flex;
    align-items: center;
    border: none;
    border-radius: 4px;
    overflow: hidden;
}

.date-time-picker-label {
    padding-bottom: 0px;
    margin-bottom: 3px;
    color: var(--color-grey000);
    font-weight: 400;}

.date-picker,
.time-picker {
    font-size: 0.9em;
    outline: none;
    min-width: 40%
}

.date-time-picker-container:hover,
.date-time-picker-container:focus-within {
    box-shadow: 0 0 0 3px var(--color-primaryLight);
}

.date-time-picker-icon-clock {
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 0;
    flex: 1 1 auto;
    padding: 0 10px;
    cursor: pointer;
    background-color: var(--color-primaryLight);
}`, "",{"version":3,"sources":["webpack://./src/components/core/input/DateTimePicker.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,YAAY;IACZ,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;IACnB,kBAAkB;IAClB,2BAA2B;IAC3B,gBAAgB,CAAC;;AAErB;;IAEI,gBAAgB;IAChB,aAAa;IACb;AACJ;;AAEA;;IAEI,+CAA+C;AACnD;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,YAAY;IACZ,gBAAgB;IAChB,cAAc;IACd,eAAe;IACf,eAAe;IACf,2CAA2C;AAC/C","sourcesContent":[".date-time-picker-container {\n    display: flex;\n    align-items: center;\n    border: none;\n    border-radius: 4px;\n    overflow: hidden;\n}\n\n.date-time-picker-label {\n    padding-bottom: 0px;\n    margin-bottom: 3px;\n    color: var(--color-grey000);\n    font-weight: 400;}\n\n.date-picker,\n.time-picker {\n    font-size: 0.9em;\n    outline: none;\n    min-width: 40%\n}\n\n.date-time-picker-container:hover,\n.date-time-picker-container:focus-within {\n    box-shadow: 0 0 0 3px var(--color-primaryLight);\n}\n\n.date-time-picker-icon-clock {\n    height: 35px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    border: none;\n    border-radius: 0;\n    flex: 1 1 auto;\n    padding: 0 10px;\n    cursor: pointer;\n    background-color: var(--color-primaryLight);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
