import React from 'react';
import Spinner from '../Spinner';
import './BookingsLoading.css';

function BookingsLoading() {
  return (
    <div className="bookings-none-container">
      <div style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      >
        <Spinner />
      </div>
    </div>
  );
}

export default BookingsLoading;
