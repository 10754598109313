import React, {
  useState, useEffect, forwardRef, useImperativeHandle,
} from 'react';
import _ from 'lodash';
import { apiRequestState, get } from '../../../utility/utility';
import CoreFormDualAction from '../../core/form/CoreFormDualAction';
import Checkbox from '../../core/input/CoreInputCheckbox';
import {
  renderCheckbox, renderInputField, renderSelectField, validateForm,
} from '../../core/input/CoreInputUtils';
import { INSPECTION_IMAGE_QUALITY_LEVELS } from '../../../utility/InspectionsUtils';

const fieldsToValidate = ['title', 'cost', 'imageQuality'];
const InspectionFormGenerateReport = forwardRef(({
  inspectionsGenerateReportPostRequestState,
  dispatchInspectionsGenerateReportPost,
}, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [organizationState, setOrganizationState] = useState({});
  const [currentInspection, setCurrentInspection] = useState([]);
  const [error, setError] = useState({});
  const [inputState, setInputState] = useState({});

  useImperativeHandle(ref, () => ({
    open: (organization, inspection) => {
      setOrganizationState(organization);
      setCurrentInspection(inspection);
      setInputState({
        title: get(inspection, 'request.service.title'),
        cost: get(inspection, 'request.service.cost'),
        options: [].concat(get(inspection, 'request.service.options', [])),
        imageQuality: INSPECTION_IMAGE_QUALITY_LEVELS.max,
        startDateIsDisplayedOnImages: true,
      });
      setIsOpen(true);
      setError({});
    },
    close: () => setIsOpen(false),
  }));

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (inspectionsGenerateReportPostRequestState === apiRequestState.SUCCESS) {
      closeModal();
    }
  }, [inspectionsGenerateReportPostRequestState]);

  if (!isOpen) return null;

  const onInputChange = (event) => {
    const newState = { ...inputState };
    newState[event.target.id] = event.target.value;
    setInputState(newState);
    validateForm({
      state: newState,
      fieldsToValidate,
      setError,
    });
  };

  const handleCheckboxChange = (event, index) => {
    const newState = { ...inputState };
    _.set(newState, `options[${index}].value`, event.target.checked);
    setInputState(newState);
  };

  const options = get(organizationState, 'inspectionSettings.services', []).map((service) => (service.title));

  return (
    <CoreFormDualAction
      title="Generate Report"
      primaryButtonCaption="Generate"
      primaryButtonCallback={() => dispatchInspectionsGenerateReportPost(currentInspection, inputState)}
      primaryButtonCallbackStatus={inspectionsGenerateReportPostRequestState === apiRequestState.LOADING}
      primaryButtonDisabled={Object.keys(error).length > 0 || inspectionsGenerateReportPostRequestState === apiRequestState.LOADING}
      secondaryButtonCaption="Cancel"
      secondaryButtonCallback={closeModal}
    >
      {renderSelectField({
        id: 'title', label: 'Report Type', inputState, onInputChange, options, error,
      })}
      {!!inputState.title && (
        renderInputField({
          id: 'cost', label: 'Service cost', inputState, onInputChange, error,
        })
      )}
      {renderSelectField({
        id: 'imageQuality', label: 'Image Quality', inputState, onInputChange, options: Object.keys(INSPECTION_IMAGE_QUALITY_LEVELS), error,
      })}
      {!!inputState.title && (
        <>
          <p style={{ paddingTop: '5px', paddingBottom: '2px', marginBottom: '0px' }}>Include in report</p>
          <div style={{ paddingLeft: '10px' }}>
            {get(inputState, 'options', []).map((option, index) => (
              <Checkbox
                key={`option-${option.title}`} // Added a key for list items
                id={`options.${index}.checked`} // Assuming you want to use this as the ID
                onChange={(event) => handleCheckboxChange(event, index)} // Change the function name to match the actual function
                checked={get(inputState, `options.${index}.value`, false)} // This should be a boolean
              >
                {_.startCase(option.title)}
              </Checkbox>
            ))}

          </div>
        </>
      )}
      {
        get(inputState, 'options', []).some((option) => option.title === 'images' && option.value) && (
          <div style={{ paddingTop: '5px' }}>
            {renderCheckbox({
              id: 'startDateIsDisplayedOnImages', label: 'Show start date on images', inputState, onInputChange,
            })}
          </div>
        )
      }
    </CoreFormDualAction>
  );
});

export default InspectionFormGenerateReport;
