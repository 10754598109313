import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import {
  get as lodashGet,
  set as lodashSet,
  cloneDeep,
  isNaN,
} from 'lodash';
import { getWeek } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { v4 as uuidv4 } from 'uuid';

TimeAgo.addDefaultLocale(en);

// Create formatter (English).
const timeAgo = new TimeAgo('en-US');

export default function localStorageIsAvailable() {
  try {
    const mod = '';
    localStorage.setItem(mod, mod);
    localStorage.removeItem(mod);
    return true;
  } catch (exception) {
    return false;
  }
}

export const apiRequestState = {
  IDLE: 'idle',
  LOADING: 'loading',
  SUCCESS: 'success',
  FAIL: 'fail',
};

// Action Creators
export const createRequestAction = (type, requestState, data = null) => ({
  type,
  requestState,
  data,
});

export const updateObject = (oldObject, updatedProperties) => ({
  ...oldObject,
  ...updatedProperties,
});

export const getTimeSince = (timestamp) => {
  if (timestamp == null || Number.isNaN(new Date(timestamp).getTime())) {
    return 'Unavailable';
  }
  return timeAgo.format(new Date(timestamp));
};

export const getTimeString = (isoTimestamp) => {
  const timestamp = new Date(isoTimestamp);
  const timeString = timestamp.toLocaleTimeString();
  return timeString;
};

export const DATE_OPTIONS = {
  NONE: 'NONE',
  INCLUDE_WEEK: 'INCLUDE_WEEK',
};

export const getTimestampString = (timestamp) => {
  const date = new Date(timestamp);
  const timezone = 'America/New_York';

  const dateString = date.toLocaleDateString('en-US', {
    timeZone: timezone,
  });

  const timeString = date.toLocaleTimeString();

  return { time: timeString, date: dateString, all: `${dateString} ${timeString}` };
};

export const getDateString = (timestamp, options = DATE_OPTIONS.NONE) => {
  if (!timestamp) {
    return 'unavailable';
  }

  const date = new Date(timestamp);
  const timezone = 'America/New_York';

  const dateString = date.toLocaleDateString('en-US', {
    timeZone: timezone,
  });

  if (options === DATE_OPTIONS.NONE) {
    return dateString;
  }

  if (options === DATE_OPTIONS.INCLUDE_WEEK) {
    const zonedDate = utcToZonedTime(date, timezone);
    const weekNumber = getWeek(zonedDate);
    return `${dateString} (W${weekNumber})`;
  }

  return 'unavailable';
};

export const epochTimestampToDateString = (epochTimestamp) => {
  // Check if epochTimestamp is a number and positive.
  if (!epochTimestamp || typeof epochTimestamp !== 'number' || epochTimestamp < 0) {
    return '';
  }

  const date = new Date(epochTimestamp * 1000); // convert to milliseconds
  return date.toISOString().split('T')[0]; // convert to ISO string and split on 'T' character
};

export const getFileExtension = (filename) => {
  // Split the filename by dot separator
  const parts = filename.split('.');

  // Retrieve the last element of the resulting array (i.e., the extension)
  const extension = parts[parts.length - 1];

  return extension;
};

export const getFileExtensionFromContentType = (contentType) => {
  const extensionMap = {
    'application/pdf': 'pdf',
    'application/msword': 'doc',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
    'application/vnd.ms-excel': 'xls',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
    'text/csv': 'csv',
    'image/jpeg': 'jpg',
    'image/png': 'png',
  };
  return extensionMap[contentType] || '';
};

// Helpeexport r function to retrieve the nested field value
export function getFieldNestedValue(object, field) {
  const fieldKeys = field.split('.');
  let value = object;
  for (const key of fieldKeys) {
    if (!value || typeof value !== 'object') {
      return undefined;
    }
    value = value[key];
  }
  return value;
}

// Function to search for objects based on multiple nested fields matching the string
export function searchByNestedFields(array, fields, searchString) {
  const results = [];

  for (const item of array) {
    let isMatch = false;

    for (const field of fields) {
      const nestedValue = getFieldNestedValue(item, field);

      if (nestedValue && nestedValue.toLowerCase().includes(searchString.toLowerCase())) {
        isMatch = true;
        break;
      }
    }

    if (isMatch) {
      results.push(item);
    }
  }

  return results;
}

export const setNestedKey = (obj, keyPath, value) => {
  const newObj = { ...obj }; // Creates a shallow copy of the original object to ensure immutability

  const keys = keyPath.split('.');
  let current = newObj;

  keys.forEach((key, index) => {
    // If it's the last key in the path, set the value
    if (index === keys.length - 1) {
      current[key] = value;
    } else {
      // Ensure the current key leads to an object.
      // If not, initialize it as an empty object.
      if (typeof current[key] !== 'object' || current[key] === null) {
        current[key] = {};
      }
      current = current[key]; // Dive deeper into the nested structure
    }
  });

  return newObj; // Return the new object with the nested key set
};

export const get = (obj, keyPath, defaultValue = '') => (
  lodashGet(obj, keyPath, defaultValue) || defaultValue
);

export const set = (obj, keyPath, value) => (
  lodashSet(obj, keyPath, value)
);

export const compareByKey = (key) => (a, b) => {
  const valA = String(get(a, key));
  const valB = String(get(b, key));

  if (!valA && !valB) return 0; // both are null/undefined
  if (!valA) return 1; // only valA is null/undefined
  if (!valB) return -1; // only valB is null/undefined

  return valA.localeCompare(valB);
};

export const getLocationString = (country, city) => {
  if (country && city) {
    return `${country}, ${city}`;
  }

  if (country) {
    return country;
  }

  return '';
};

export const handleSort = (event, elements, sortKeyMap, stateSetter) => {
  const newState = cloneDeep(elements);
  const sortKey = sortKeyMap[event.target.value];

  if (sortKey) {
    newState.sort(compareByKey(sortKey));
  }

  stateSetter(newState);
};

export const capitalizeFirstLetter = (str) => {
  if (!str || typeof str !== 'string') return '';
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const uuid = () => (
  uuidv4()
);

export const getAddressString = (address) => {
  const lines = [];
  const cityState = [];
  const countryPostalCode = [];

  const line1 = get(address, 'line1', false);
  const line2 = get(address, 'line2', false);
  const city = get(address, 'city', false);
  const state = get(address, 'state', false);
  const country = get(address, 'country', false);
  const postalCode = get(address, 'postalCode', false);

  if (line1) lines.push(line1);
  if (line2) lines.push(line2);
  if (city) cityState.push(city);
  if (state) cityState.push(state);
  if (country) countryPostalCode.push(country);
  if (postalCode) countryPostalCode.push(postalCode);

  return {
    lines: lines.join(', '),
    cityState: cityState.join(', '),
    countryPostalCode: countryPostalCode.join(', '),
    all: [...lines, cityState, countryPostalCode].join(', '),
  };
};

export const permissionCheck = (source, checks) => {
  for (const check of checks) {
    const isPermitted = get(source, check, false);
    if (!isPermitted) return false;
  }
  return true;
};

export const featureCheck = (source, features) => {
  for (const feature of features) {
    const isEnabled = get(source, feature, false);
    if (!isEnabled) return false;
  }
  return true;
};

export const convertTemperature = (value, currentUnit, targetUnit, decimals = 1) => {
  if (!value) return '';

  let convertedValue = parseFloat(value);
  if (isNaN(convertedValue)) return '';

  if (currentUnit === 'C' && targetUnit === 'F') {
    convertedValue = convertedValue * (9 / 5) + 32;
  } else if (currentUnit === 'F' && targetUnit === 'C') {
    convertedValue = (convertedValue - 32) * (5 / 9);
  }

  return convertedValue.toFixed(decimals);
};

export const convertWeight = (value, currentUnit, targetUnit, decimals = 1) => {
  if (!value) return '';

  let convertedValue = parseFloat(value);
  if (isNaN(convertedValue)) return '';

  if (currentUnit === 'kg' && targetUnit === 'lbs') {
    convertedValue *= 2.20462; // Correct conversion factor for kg to lbs
  } else if (currentUnit === 'lbs' && targetUnit === 'kg') {
    convertedValue /= 2.20462; // Correct conversion factor for lbs to kg
  }

  return convertedValue.toFixed(decimals); // Return the converted value rounded to 1 decimal place
};
