import { React, useEffect } from 'react';
import { connect } from 'react-redux';

import './BookingPage.css';
import BookingView from '../components/BookingView';
import {
  carriersGet, bookingsGetDocument, bookingsGet, bookingsPost, bookingsPut, bookingsDocumentPatch, userGetMe, emailsPost, bookingsPutDeactivate, bookingsPutShare, bookingsRefreshPatch, bookingsDelete, notificationsShow, bookingsDocumentDelete, bookingsGetShared, organizationGetInspectors, inspectionsPost, bookingsPostInspection, inspectionsGetDocument,
} from '../actions';

function BookingPage({
  bookings,
  bookingsDeleteRequestState,
  bookingsDocumentDeleteRequestState,
  bookingsDocumentPatchRequestState,
  bookingsGetRequestState,
  bookingsGetSharedRequestState,
  bookingsPostInspectionRequestState,
  bookingsPostRequestState,
  bookingsPutDeactivateRequestState,
  bookingsPutRequestState,
  bookingsPutShareRequestState,
  carriers,
  dispatchBookingDocumentDelete,
  dispatchBookingDocumentPatch,
  dispatchBookingRefreshPatch,
  dispatchBookingsDeactivate,
  dispatchBookingsDelete,
  dispatchBookingsGet,
  dispatchBookingsGetDocument,
  dispatchInspectionsGetDocument,
  dispatchBookingsGetShared,
  dispatchBookingsPost,
  dispatchBookingsPostInspection,
  dispatchBookingsPut,
  dispatchBookingsShare,
  dispatchCarriersGet,
  dispatchEmailsPost,
  dispatchInspectionsPost,
  dispatchNotification,
  dispatchOrganizationGetInspectors,
  dispatchUsersGetMe,
  emailsPostRequestState,
  inspectionsPostRequestState,
  inspectors,
  me,
  organization,
  sharedBookings,
}) {
  useEffect(() => {
    dispatchUsersGetMe();
    dispatchBookingsGet();
    dispatchCarriersGet();
    dispatchBookingsGetShared();
    dispatchOrganizationGetInspectors();
  }, []);

  return bookings ? (
    <BookingView
      bookings={bookings}
      bookingsDeleteRequestState={bookingsDeleteRequestState}
      bookingsDocumentDeleteRequestState={bookingsDocumentDeleteRequestState}
      bookingsDocumentPatchRequestState={bookingsDocumentPatchRequestState}
      bookingsGetRequestState={bookingsGetRequestState}
      bookingsGetSharedRequestState={bookingsGetSharedRequestState}
      bookingsPostRequestState={bookingsPostRequestState}
      bookingsPostInspectionRequestState={bookingsPostInspectionRequestState}
      bookingsPutDeactivateRequestState={bookingsPutDeactivateRequestState}
      bookingsPutRequestState={bookingsPutRequestState}
      bookingsPutShareRequestState={bookingsPutShareRequestState}
      carriers={carriers}
      dispatchBookingDocumentDelete={dispatchBookingDocumentDelete}
      dispatchBookingDocumentPatch={dispatchBookingDocumentPatch}
      dispatchBookingRefreshPatch={dispatchBookingRefreshPatch}
      dispatchBookingsDeactivate={dispatchBookingsDeactivate}
      dispatchBookingsDelete={dispatchBookingsDelete}
      dispatchBookingsGetDocument={dispatchBookingsGetDocument}
      dispatchInspectionsGetDocument={dispatchInspectionsGetDocument}
      dispatchBookingsPost={dispatchBookingsPost}
      dispatchBookingsPostInspection={dispatchBookingsPostInspection}
      dispatchBookingsPut={dispatchBookingsPut}
      dispatchBookingsShare={dispatchBookingsShare}
      dispatchEmailsPost={dispatchEmailsPost}
      dispatchInspectionsPost={dispatchInspectionsPost}
      dispatchNotification={dispatchNotification}
      emailsPostRequestState={emailsPostRequestState}
      inspectionsPostRequestState={inspectionsPostRequestState}
      inspectors={inspectors}
      me={me}
      organization={organization}
      sharedBookings={sharedBookings}
    />
  ) : null;
}

const mapStateToProps = (state) => ({
  bookings: state.bookings.bookings,
  bookingsDeleteRequestState: state.bookings.bookingsDeleteRequestState,
  bookingsDocumentDeleteRequestState: state.bookings.bookingsDocumentDeleteRequestState,
  bookingsDocumentPatchRequestState: state.bookings.bookingsDocumentPatchRequestState,
  bookingsGetRequestState: state.bookings.bookingsGetRequestState,
  bookingsGetSharedRequestState: state.bookings.bookingsGetSharedRequestState,
  bookingsPostRequestState: state.bookings.bookingsPostRequestState,
  bookingsPostInspectionRequestState: state.bookings.bookingsPostInspectionRequestState,
  bookingsPutDeactivateRequestState: state.bookings.bookingsPutDeactivateRequestState,
  bookingsPutRequestState: state.bookings.bookingsPutRequestState,
  bookingsPutShareRequestState: state.bookings.bookingsPutShareRequestState,
  carriers: state.carriers.carriers,
  emailsPostRequestState: state.emails.emailsPostRequestState,
  inspectionsPostRequestState: state.inspections.inspectionsPostRequestState,
  inspectors: state.organizations.inspectors,
  me: state.users.me,
  organization: state.organizations.organization,
  sharedBookings: state.bookings.sharedBookings,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchBookingDocumentDelete: (booking, documentType) => dispatch(bookingsDocumentDelete(booking, documentType)),
  dispatchBookingDocumentPatch: (booking, documentType, document) => dispatch(bookingsDocumentPatch(booking, documentType, document)),
  dispatchBookingRefreshPatch: (booking) => dispatch(bookingsRefreshPatch(booking)),
  dispatchBookingsDeactivate: (booking) => dispatch(bookingsPutDeactivate(booking)),
  dispatchBookingsDelete: (booking) => dispatch(bookingsDelete(booking)),
  dispatchBookingsGet: () => dispatch(bookingsGet()),
  dispatchBookingsGetDocument: (booking, documentType) => dispatch(bookingsGetDocument(booking, documentType)),
  dispatchBookingsGetShared: () => dispatch(bookingsGetShared()),
  dispatchBookingsPost: (body) => dispatch(bookingsPost(body)),
  dispatchBookingsPostInspection: (body) => dispatch(bookingsPostInspection(body)),
  dispatchBookingsPut: (body) => dispatch(bookingsPut(body)),
  dispatchBookingsShare: (booking, shareObj) => dispatch(bookingsPutShare(booking, shareObj)),
  dispatchCarriersGet: () => dispatch(carriersGet()),
  dispatchEmailsPost: (email) => dispatch(emailsPost(email)),
  dispatchInspectionsPost: (inspection) => dispatch(inspectionsPost(inspection)),
  dispatchNotification: (severity, message) => dispatch(notificationsShow(severity, message)),
  dispatchOrganizationGetInspectors: () => dispatch(organizationGetInspectors()),
  dispatchUsersGetMe: () => dispatch(userGetMe()),
  dispatchInspectionsGetDocument: (inspection, document) => dispatch(inspectionsGetDocument(inspection, document)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BookingPage);
