import React from 'react';
import {
  FaCopy,
  FaEdit, FaTrash,
} from 'react-icons/fa';
import { uuid } from '../../../utility/utility';
import BubbleCard from '../../BubbleCard';
import Button from '../../core/input/Button';
import InspectionSampleCard from '../cards/InspectionSampleCard';
import { INSPECTION_PRODUCT_FORM_MODES } from '../../../utility/InspectionsUtils';

function InspectionBubbleSamples({
  organization,
  inspectionState,
  samples,
  onFormOpen,
  onDelete,
  handleButtonInspectionDefectFormOpen,
  handleButtonInspectionImagesSelectorModalOpen,
  dispatchInspectionsPut,
}) {
  const renderSample = (sample) => (
    <div key={sample._id}>
      <InspectionSampleCard
        organization={organization}
        dispatchInspectionsPut={dispatchInspectionsPut}
        inspection={inspectionState}
        sample={sample}
        handleButtonInspectionDefectFormOpen={handleButtonInspectionDefectFormOpen}
        handleButtonInspectionImagesSelectorModalOpen={handleButtonInspectionImagesSelectorModalOpen}
        actions={[
          {
            _id: uuid(),
            text: 'Delete',
            icon: <FaTrash title="Delete" />,
            onClick: () => onDelete(sample),
          },
          {
            _id: uuid(),
            text: 'Edit',
            icon: <FaEdit title="Edit" />,
            onClick: () => onFormOpen(inspectionState, INSPECTION_PRODUCT_FORM_MODES.edit, sample),
          },
          {
            _id: uuid(),
            text: 'Duplicate',
            icon: <FaCopy title="Duplicate" />,
            onClick: () => onFormOpen(inspectionState, INSPECTION_PRODUCT_FORM_MODES.duplicate, sample),
          },
        ]}
      />
    </div>
  );

  return (
    <BubbleCard
      title="Samples & Defects"
      style={{ marginBottom: '10px' }}
    >
      {samples.map((sample) => (
        renderSample(sample)
      ))}
      <div style={{ display: 'flex', justifyContent: 'flex-start', paddingLeft: '10px' }}>
        <Button
          style={{ width: 'auto', paddingLeft: '10px', paddingRight: '10px' }}
          onClick={() => onFormOpen(inspectionState, INSPECTION_PRODUCT_FORM_MODES.new, null)}
          primary
          simple
        >
          Add a Product Sample
        </Button>
      </div>
    </BubbleCard>
  );
}

export default InspectionBubbleSamples;
