import React from 'react';
import _ from 'lodash';
import './BookingSection.css';
import Section from './Section';

function BookingSection({ booking }) {
  const localBooking = _.cloneDeep(booking);
  delete localBooking.organizationId;
  return (
    <Section title="Booking">
      {Object.keys(booking).map((key) => (
        <p className="booking-section-items" key={key}>
          {_.startCase(key)}
          :
          {' '}
          {localBooking[key]}
        </p>
      ))}
    </Section>
  );
}

export default BookingSection;
