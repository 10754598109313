import axios from 'axios';
import { apiRequestState } from '../utility/utility';
import { API_BASE_URL } from '../config';

export const CARRIERS_GET_REQUEST_START = 'CARRIERS_GET_REQUEST_START';
export const CARRIERS_GET_REQUEST_SUCCESS = 'CARRIERS_GET_REQUEST_SUCCESS';
export const CARRIERS_GET_REQUEST_FAIL = 'CARRIERS_GET_REQUEST_FAIL';

export const carriersGetStart = () => ({
  type: CARRIERS_GET_REQUEST_START,
  carriersRequestState: apiRequestState.LOADING,
});

export const carriersGetSuccess = (carriers) => {
  // 1. Create a new array where each object has had 'scac' removed
  const carriersWithoutScac = carriers.map((object) => {
    const { scac, ...rest } = object;
    return rest;
  });

  // 2. Remove duplicate objects with key-value "name"
  const carrierNames = new Set();
  const uniqueCarriers = carriersWithoutScac.filter((element) => {
    if (!carrierNames.has(element.name)) {
      carrierNames.add(element.name);
      return true;
    }
    return false;
  });

  return {
    type: CARRIERS_GET_REQUEST_SUCCESS,
    carriers: uniqueCarriers,
    carriersRequestState: apiRequestState.SUCCESS,
  };
};

export const carriersGetFail = () => ({
  type: CARRIERS_GET_REQUEST_FAIL,
  carriersRequestState: apiRequestState.FAIL,
});

export const carriersGet = () => (dispatch) => {
  dispatch(carriersGetStart());
  const carriersGetRequest = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/carriers`);
      dispatch(carriersGetSuccess(response.data));
    } catch (err) {
      dispatch(carriersGetFail());
    }
  };

  carriersGetRequest();
};
