import React from 'react';
import './BookingSharingSection.css';
import Section from './Section';

function BookingSharingSection({ booking }) {
  return (
    <Section title="Sharing">
      {booking.sharing.length ? booking.sharing.map((share) => (
        <p className="booking-section-items" key={share.email}>{share.email}</p>
      )) : <p className="booking-section-items">None</p>}
    </Section>
  );
}

export default BookingSharingSection;
