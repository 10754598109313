import React from 'react';
import './Section.css';

function Section({ title, children }) {
  return (
    <div className="section-box">
      <div className="section-title">{title}</div>
      <div className="section-items-container">
        {children}
      </div>
    </div>
  );
}

export default Section;
