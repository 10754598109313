import _, { cloneDeep } from 'lodash';
import {
  INSPECTIONS_GET_DOCUMENT_REQUEST_START,
  INSPECTIONS_GET_DOCUMENT_REQUEST_FAIL,
  INSPECTIONS_GET_DOCUMENT_REQUEST_SUCCESS,
  INSPECTIONS_GET_SINGLE_REQUEST_START,
  INSPECTIONS_GET_SINGLE_REQUEST_FAIL,
  INSPECTIONS_GET_SINGLE_REQUEST_SUCCESS,
  INSPECTIONS_GET_REQUEST_START,
  INSPECTIONS_GET_REQUEST_FAIL,
  INSPECTIONS_GET_REQUEST_SUCCESS,
  INSPECTIONS_GET_REQUEST_IDLE,
  INSPECTIONS_GET_AVAILABLE_STAFF_REQUEST_START,
  INSPECTIONS_GET_AVAILABLE_STAFF_REQUEST_FAIL,
  INSPECTIONS_GET_AVAILABLE_STAFF_REQUEST_SUCCESS,
  INSPECTIONS_GET_AVAILABLE_STAFF_REQUEST_IDLE,
  INSPECTIONS_POST_REQUEST_START,
  INSPECTIONS_POST_REQUEST_FAIL,
  INSPECTIONS_POST_REQUEST_SUCCESS,
  INSPECTIONS_POST_REQUEST_IDLE,
  INSPECTIONS_PUT_REQUEST_START,
  INSPECTIONS_PUT_REQUEST_SUCCESS,
  INSPECTIONS_PUT_REQUEST_FAIL,
  INSPECTIONS_PUT_REQUEST_IDLE,
  INSPECTIONS_PUT_ASSIGN_REQUEST_START,
  INSPECTIONS_PUT_ASSIGN_REQUEST_SUCCESS,
  INSPECTIONS_PUT_ASSIGN_REQUEST_FAIL,
  INSPECTIONS_PUT_ASSIGN_REQUEST_IDLE,
  INSPECTIONS_PUT_MARK_REQUEST_START,
  INSPECTIONS_PUT_MARK_REQUEST_SUCCESS,
  INSPECTIONS_PUT_MARK_REQUEST_FAIL,
  INSPECTIONS_PUT_MARK_REQUEST_IDLE,
  INSPECTIONS_PUT_UNASSIGN_SELF_REQUEST_START,
  INSPECTIONS_PUT_UNASSIGN_SELF_REQUEST_SUCCESS,
  INSPECTIONS_PUT_UNASSIGN_SELF_REQUEST_FAIL,
  INSPECTIONS_PUT_UNASSIGN_SELF_REQUEST_IDLE,
  INSPECTIONS_PUT_SUBMIT_REPORT_REQUEST_START,
  INSPECTIONS_PUT_SUBMIT_REPORT_REQUEST_SUCCESS,
  INSPECTIONS_PUT_SUBMIT_REPORT_REQUEST_FAIL,
  INSPECTIONS_PUT_SUBMIT_REPORT_REQUEST_IDLE,
  INSPECTIONS_DELETE_REQUEST_START,
  INSPECTIONS_DELETE_REQUEST_FAIL,
  INSPECTIONS_DELETE_REQUEST_SUCCESS,
  INSPECTIONS_DELETE_REQUEST_IDLE,
  INSPECTIONS_GENERERATE_REPORT_POST_REQUEST_START,
  INSPECTIONS_GENERERATE_REPORT_POST_REQUEST_FAIL,
  INSPECTIONS_GENERERATE_REPORT_POST_REQUEST_SUCCESS,
  INSPECTIONS_GENERERATE_REPORT_POST_REQUEST_IDLE,
  INSPECTIONS_IMAGES_DOWNLOAD_ZIP_GET_REQUEST_START,
  INSPECTIONS_IMAGES_DOWNLOAD_ZIP_GET_REQUEST_FAIL,
  INSPECTIONS_IMAGES_DOWNLOAD_ZIP_GET_REQUEST_SUCCESS,
  INSPECTIONS_IMAGES_DOWNLOAD_ZIP_GET_REQUEST_IDLE,
  INSPECTIONS_REPORTS_DELETE_POST_REQUEST_START,
  INSPECTIONS_REPORTS_DELETE_POST_REQUEST_FAIL,
  INSPECTIONS_REPORTS_DELETE_POST_REQUEST_SUCCESS,
  INSPECTIONS_REPORTS_DELETE_POST_REQUEST_IDLE,
  INSPECTIONS_IMAGES_PATCH_REQUEST_START,
  INSPECTIONS_IMAGES_PATCH_REQUEST_FAIL,
  INSPECTIONS_IMAGES_PATCH_REQUEST_SUCCESS,
  INSPECTIONS_IMAGES_PATCH_REQUEST_IDLE,
  INSPECTIONS_IMAGE_DELETE_REQUEST_START,
  INSPECTIONS_IMAGE_DELETE_REQUEST_FAIL,
  INSPECTIONS_IMAGE_DELETE_REQUEST_SUCCESS,
  INSPECTIONS_IMAGE_DELETE_REQUEST_IDLE,
  INSPECTIONS_IMAGES_ASSOCIATE_PUT_REQUEST_START,
  INSPECTIONS_IMAGES_ASSOCIATE_PUT_REQUEST_FAIL,
  INSPECTIONS_IMAGES_ASSOCIATE_PUT_REQUEST_SUCCESS,
  INSPECTIONS_IMAGES_ASSOCIATE_PUT_REQUEST_IDLE,
  INSPECTIONS_IMAGES_SELECT_PUT_REQUEST_START,
  INSPECTIONS_IMAGES_SELECT_PUT_REQUEST_FAIL,
  INSPECTIONS_IMAGES_SELECT_PUT_REQUEST_SUCCESS,
  INSPECTIONS_IMAGES_SELECT_PUT_REQUEST_IDLE,
  INSPECTIONS_PATCH_REPOPULATE_REQUEST_START,
  INSPECTIONS_PATCH_REPOPULATE_REQUEST_FAIL,
  INSPECTIONS_PATCH_REPOPULATE_REQUEST_SUCCESS,
  INSPECTIONS_PATCH_REPOPULATE_REQUEST_IDLE,
  INSPECTIONS_IMAGES_LEFT_TO_SYNC_COUNT_START,
  INSPECTIONS_IMAGES_LEFT_TO_SYNC_COUNT_SUCCESS,
} from '../actions';
import { apiRequestState, get, updateObject } from '../utility/utility';

const initialState = {
  inspectionsImagesLeftToSync: null,
  inspections: [],
  availableStaff: [],
  inspectionsGetRequestState: apiRequestState.IDLE,
  inspectionsGetSingleRequestState: apiRequestState.IDLE,
  inspectionsGetAvailableStaffRequestState: apiRequestState.IDLE,
  inspectionsGetDocumentRequestState: apiRequestState.IDLE,
  inspectionsPostRequestState: apiRequestState.IDLE,
  inspectionsPutRequestState: apiRequestState.IDLE,
  inspectionsPutAssignRequestState: apiRequestState.IDLE,
  inspectionsPutMarkRequestState: apiRequestState.IDLE,
  inspectionsPutUnassignSelfRequestState: apiRequestState.IDLE,
  inspectionsDeleteRequestState: apiRequestState.IDLE,
  inspectionsGenerateReportPostRequestState: apiRequestState.IDLE,
  inspectionsImagesPatchRequestState: apiRequestState.IDLE,
  inspectionsImagesPatchProgress: null,
  inspectionsImageDeleteRequestState: apiRequestState.IDLE,
  inspectionsPatchRepopulateRequestState: apiRequestState.IDLE,
  inspectionsImagesDownloadZipGetRequestState: apiRequestState.IDLE,
  inspectionsImagesAssociateRequestState: apiRequestState.IDLE,
  inspectionsReportsDeleteRequestState: apiRequestState.IDLE,
  inspectionsPutSubmitReportRequestState: apiRequestState.IDLE,
  inspectionsImagesLeftToSyncCountState: apiRequestState.IDLE,
  inspectionsReportImagesSelectRequestState: apiRequestState.IDLE,
};

const updateSpecificItemsArrayById = (stateArray, itemsToUpdate, key = '_id') => {
  const newStateArray = [...stateArray];
  itemsToUpdate.forEach((item) => {
    const index = newStateArray.findIndex((p) => p[key] === item[key]);
    if (index < 0) throw new Error(`Could not find the item with key: ${item[key]}`);
    newStateArray[index] = _.cloneDeep(item);
  });

  return newStateArray;
};

const inspectionsGetDocumentStart = (state, action) => updateObject(state, {
  inspectionsGetDocumentRequestState: action.requestState,
});

const inspectionsGetDocumentFail = (state, action) => updateObject(state, {
  inspectionsGetDocumentRequestState: action.requestState,
});

const inspectionsGetDocumentSuccess = (state, action) => updateObject(state, {
  inspectionsGetDocumentRequestState: action.requestState,
});

const inspectionsGetSingleStart = (state, action) => updateObject(state, {
  inspectionsGetSingleRequestState: action.requestState,
});

const inspectionsGetSingleFail = (state, action) => updateObject(state, {
  inspectionsGetSingleRequestState: action.requestState,
});

const inspectionsGetSingleSuccess = (state, action) => {
  const newInspections = [...state.inspections];
  // Find index of inspection and update it.
  const index = newInspections.findIndex((p) => p._id === action.inspection._id);
  newInspections[index] = _.cloneDeep(action.inspection);
  return updateObject(state, {
    inspectionsGetRequestState: action.requestState,
    inspections: newInspections,
  });
};

const inspectionsGetStart = (state, action) => updateObject(state, {
  inspections: action.inspections,
  inspectionsGetRequestState: action.requestState,
});

const inspectionsGetFail = (state, action) => updateObject(state, {
  inspectionsGetRequestState: action.requestState,
});

const inspectionsGetIdle = (state, action) => updateObject(state, {
  inspectionsGetRequestState: action.requestState,
});

const inspectionsGetSuccess = (state, action) => updateObject(state, {
  inspections: action.inspections,
  inspectionsGetRequestState: action.requestState,
});

const inspectionsGetAvailableStaffStart = (state, action) => updateObject(state, {
  inspectionsGetAvailableStaffRequestState: action.requestState,
});

const inspectionsGetAvailableStaffFail = (state, action) => updateObject(state, {
  inspectionsGetAvailableStaffRequestState: action.requestState,
});

const inspectionsGetAvailableStaffSuccess = (state, action) => updateObject(state, {
  availableStaff: action.data,
  inspectionsGetAvailableStaffRequestState: action.requestState,
});

const inspectionsGetAvailableStaffIdle = (state, action) => updateObject(state, {
  inspectionsGetAvailableStaffRequestState: action.requestState,
});

const inspectionsPostStart = (state, action) => updateObject(state, {
  inspectionsPostRequestState: action.requestState,
});

const inspectionsPostFail = (state, action) => updateObject(state, {
  inspectionsPostRequestState: action.requestState,
});

const inspectionsPostIdle = (state, action) => updateObject(state, {
  inspectionsPostRequestState: action.requestState,
});

const inspectionsPostSuccess = (state, action) => {
  const newInspections = [...state.inspections];
  newInspections.unshift(action.data);

  return updateObject(state, {
    inspectionsPostRequestState: action.requestState,
    inspections: newInspections,
  });
};

const inspectionsPutStart = (state, action) => updateObject(state, {
  inspectionsPutRequestState: action.requestState,
});

const inspectionsPutFail = (state, action) => updateObject(state, {
  inspectionsPutRequestState: action.requestState,
});

const inspectionsPutIdle = (state, action) => updateObject(state, {
  inspectionsPutRequestState: action.requestState,
});

const inspectionsPutSuccess = (state, action) => {
  const newInspections = [...state.inspections];

  // Find index of inspection and update it.
  const index = newInspections.findIndex((p) => p._id === action.inspection._id);
  newInspections[index] = _.cloneDeep(action.inspection);

  return updateObject(state, {
    inspectionsPutRequestState: action.requestState,
    inspections: newInspections,
  });
};

const inspectionsPutAssignStart = (state, action) => updateObject(state, {
  inspectionsPutAssignRequestState: action.requestState,
});

const inspectionsPutAssignFail = (state, action) => updateObject(state, {
  inspectionsPutAssignRequestState: action.requestState,
});

const inspectionsPutAssignIdle = (state, action) => updateObject(state, {
  inspectionsPutAssignRequestState: action.requestState,
});

const inspectionsPutAssignSuccess = (state, action) => {
  const newInspections = updateSpecificItemsArrayById(
    get(state, 'inspections', []),
    get(action, 'inspections', []),
  );

  return updateObject(state, {
    inspectionsPutAssignRequestState: action.requestState,
    inspections: newInspections,
  });
};

const inspectionsPutMarkStart = (state, action) => updateObject(state, {
  inspectionsPutMarkRequestState: action.requestState,
});

const inspectionsPutMarkFail = (state, action) => updateObject(state, {
  inspectionsPutMarkRequestState: action.requestState,
});

const inspectionsPutMarkIdle = (state, action) => updateObject(state, {
  inspectionsPutMarkRequestState: action.requestState,
});

const inspectionsPutMarkSuccess = (state, action) => {
  const newInspections = updateSpecificItemsArrayById(
    get(state, 'inspections', []),
    get(action, 'inspections', []),
  );

  return updateObject(state, {
    inspectionsPutMarkRequestState: action.requestState,
    inspections: newInspections,
  });
};

const inspectionsPutUnassignSelfStart = (state, action) => updateObject(state, {
  inspectionsPutUnassignSelfRequestState: action.requestState,
});

const inspectionsPutUnassignSelfFail = (state, action) => updateObject(state, {
  inspectionsPutUnassignSelfRequestState: action.requestState,
});

const inspectionsPutUnassignSelfIdle = (state, action) => updateObject(state, {
  inspectionsPutUnassignSelfRequestState: action.requestState,
});

const inspectionsPutUnassignSelfSuccess = (state, action) => {
  const newInspections = [...state.inspections];

  // Find index of inspection and update it.
  const index = newInspections.findIndex((p) => p._id === action.inspection._id);
  newInspections[index] = _.cloneDeep(action.inspection);

  return updateObject(state, {
    inspectionsPutUnassignSelfRequestState: action.requestState,
    inspections: newInspections,
  });
};

const inspectionsPutSubmitReportStart = (state, action) => updateObject(state, {
  inspectionsPutSubmitReportRequestState: action.requestState,
});

const inspectionsPutSubmitReportFail = (state, action) => updateObject(state, {
  inspectionsPutSubmitReportRequestState: action.requestState,
});

const inspectionsPutSubmitReportIdle = (state, action) => updateObject(state, {
  inspectionsPutSubmitReportRequestState: action.requestState,
});

const inspectionsPutSubmitReportSuccess = (state, action) => {
  const newInspections = [...state.inspections];

  // Find index of inspection and update it.
  const index = newInspections.findIndex((p) => p._id === action.inspection._id);
  newInspections[index] = _.cloneDeep(action.inspection);

  return updateObject(state, {
    inspectionsPutSubmitReportRequestState: action.requestState,
    inspections: newInspections,
  });
};

const inspectionsDeleteStart = (state, action) => updateObject(state, {
  inspectionsDeleteRequestState: action.requestState,
});

const inspectionsDeleteFail = (state, action) => updateObject(state, {
  inspectionsDeleteRequestState: action.requestState,
});

const inspectionsDeleteSuccess = (state, action) => {
  const filteredInspections = state.inspections.filter((inspection) => !action.inspections.includes(inspection._id));

  return updateObject(state, {
    inspectionsDeleteRequestState: action.requestState,
    inspections: filteredInspections,
  });
};

const inspectionsDeleteIdle = (state, action) => updateObject(state, {
  inspectionsDeleteRequestState: action.requestState,
});

const inspectionsPatchRepopulateStart = (state, action) => updateObject(state, {
  inspectionsPatchRepopulateRequestState: action.requestState,
});

const inspectionsPatchRepopulateFail = (state, action) => updateObject(state, {
  inspectionsPatchRepopulateRequestState: action.requestState,
});

const inspectionsPatchRepopulateSuccess = (state, action) => {
  // Create a map of updated inspections for quick access
  const updatedInspectionsMap = action.data.reduce((acc, inspection) => {
    acc[inspection._id] = inspection;
    return acc;
  }, {});

  // Merge the existing inspections with the updated ones
  const mergedInspections = state.inspections.map((inspection) => (updatedInspectionsMap[inspection._id] ? updatedInspectionsMap[inspection._id] : inspection));

  return updateObject(state, {
    inspectionsPatchRepopulateRequestState: action.requestState,
    inspections: mergedInspections,
  });
};

const inspectionsPatchRepopulateIdle = (state, action) => updateObject(state, {
  inspectionsDeleteRequestState: action.requestState,
});

const inspectionsGenerateReportPostStart = (state, action) => updateObject(state, {
  inspectionsGenerateReportPostRequestState: action.requestState,
});

const inspectionsGenerateReportPostFail = (state, action) => updateObject(state, {
  inspectionsGenerateReportPostRequestState: action.requestState,
});

const inspectionsGenerateReportPostIdle = (state, action) => updateObject(state, {
  inspectionsGenerateReportPostRequestState: action.requestState,
});

const inspectionsGenerateReportPostSuccess = (state, action) => {
  const newInspections = [...state.inspections];
  // Find index of inspection and update it.
  const index = newInspections.findIndex((p) => p._id === action.inspection._id);
  newInspections[index] = _.cloneDeep(action.inspection);
  return updateObject(state, {
    inspectionsGenerateReportPostRequestState: action.requestState,
    inspections: newInspections,
  });
};
const inspectionsReportsDeleteStart = (state, action) => updateObject(state, {
  inspectionsReportsDeleteRequestState: action.requestState,
});

const inspectionsReportsDeleteFail = (state, action) => updateObject(state, {
  inspectionsReportsDeleteRequestState: action.requestState,
});

const inspectionsReportsDeleteIdle = (state, action) => updateObject(state, {
  inspectionsReportsDeleteRequestState: action.requestState,
});

const inspectionsReportsDeleteSuccess = (state, action) => {
  const newInspections = [...state.inspections];
  // Find index of inspection and update it.
  const index = newInspections.findIndex((p) => p._id === action.inspection._id);
  newInspections[index] = _.cloneDeep(action.inspection);
  return updateObject(state, {
    inspectionsReportsDeleteRequestState: action.requestState,
    inspections: newInspections,
  });
};

const inspectionsImagesPatchStart = (state, action) => updateObject(state, {
  inspectionsImagesPatchRequestState: action.requestState,
  inspectionsImagesPatchProgress: 0,
});

const inspectionsImagesPatchFail = (state, action) => updateObject(state, {
  inspectionsImagesPatchRequestState: action.requestState,
  inspectionsImagesPatchProgress: null,
});

const inspectionsImagesPatchIdle = (state, action) => updateObject(state, {
  inspectionsImagesPatchRequestState: action.requestState,
  inspectionsImagesPatchProgress: null,
});

const inspectionsImagesPatchSuccess = (state, action) => {
  const newInspections = [...state.inspections];
  // Find index of inspection and update it.
  const index = newInspections.findIndex((p) => p._id === action.inspection._id);
  newInspections[index] = _.cloneDeep(action.inspection);
  return updateObject(state, {
    inspectionsImagesLeftToSync: action.leftToSync,
  });
};

const inspectionsImageDeleteStart = (state, action) => updateObject(state, {
  inspectionsImageDeleteRequestState: action.requestState,
});

const inspectionsImageDeleteFail = (state, action) => updateObject(state, {
  inspectionsImageDeleteRequestState: action.requestState,
});

const inspectionsImageDeleteIdle = (state, action) => updateObject(state, {
  inspectionsImageDeleteRequestState: action.requestState,
});

const inspectionsImageDeleteSuccess = (state, action) => {
  const newInspections = [...state.inspections];
  // Find index of inspection and update it.
  const index = newInspections.findIndex((p) => p._id === action.inspection._id);
  newInspections[index] = _.cloneDeep(action.inspection);
  return updateObject(state, {
    inspectionsImageDeleteRequestState: action.requestState,
    inspections: newInspections,
  });
};

const inspectionsImagesAssociateStart = (state, action) => updateObject(state, {
  inspectionsImagesAssociateRequestState: action.requestState,
});

const inspectionsImagesAssociateFail = (state, action) => updateObject(state, {
  inspectionsImagesAssociateRequestState: action.requestState,
});

const inspectionsImagesAssociateIdle = (state, action) => updateObject(state, {
  inspectionsImagesAssociateRequestState: action.requestState,
});

const inspectionsImagesAssociateSuccess = (state, action) => {
  const newInspections = [...state.inspections];
  // Find index of inspection and update it.
  const index = newInspections.findIndex((p) => p._id === action.inspection._id);
  newInspections[index] = _.cloneDeep(action.inspection);
  return updateObject(state, {
    inspectionsImagesAssociateRequestState: action.requestState,
    inspections: newInspections,
  });
};

const inspectionsImagesSelectStart = (state, action) => updateObject(state, {
  inspectionsReportImagesSelectRequestState: action.requestState,
});

const inspectionsImagesSelectFail = (state, action) => updateObject(state, {
  inspectionsReportImagesSelectRequestState: action.requestState,
});

const inspectionsImagesSelectIdle = (state, action) => updateObject(state, {
  inspectionsReportImagesSelectRequestState: action.requestState,
});

const inspectionsImagesSelectSuccess = (state, action) => {
  const newInspections = [...state.inspections];
  // Find index of inspection and update it.
  const index = newInspections.findIndex((p) => p._id === action.inspection._id);
  newInspections[index] = _.cloneDeep(action.inspection);
  return updateObject(state, {
    inspectionsReportImagesSelectRequestState: action.requestState,
    inspections: newInspections,
  });
};

const inspectionsImagesDownloadZipGetStart = (state, action) => updateObject(state, {
  inspectionsImagesDownloadZipGetRequestState: action.requestState,
});

const inspectionsImagesDownloadZipGetFail = (state, action) => updateObject(state, {
  inspectionsImagesDownloadZipGetRequestState: action.requestState,
});

const inspectionsImagesDownloadZipGetSuccess = (state, action) => updateObject(state, {
  inspectionsImagesDownloadZipGetRequestState: action.requestState,
});

const inspectionsImagesDownloadZipGetIdle = (state, action) => updateObject(state, {
  inspectionsImagesDownloadZipGetRequestState: action.requestState,
});

const inspectionsImagesLeftToSyncCountStart = (state, action) => updateObject(state, {
  inspectionsImagesLeftToSyncCountState: action.requestState,
});

const inspectionsImagesLeftToSyncCountSuccess = (state, action) => {
  const { uploadedImages = [], imagesLeftToSync, requestState } = action;

  // Clone inspections state to ensure immutability
  const newInspections = cloneDeep(state.inspections);

  uploadedImages.forEach((image) => {
    const inspectionIndex = newInspections.findIndex((p) => p._id === image.inspectionId);

    if (inspectionIndex !== -1) {
      const inspection = newInspections[inspectionIndex];
      inspection.onsite = inspection.onsite || {};
      inspection.onsite.productImages = inspection.onsite.productImages || [];
      inspection.onsite.productImages.push(image);

      // Sort images based on the captured date after adding new images
      inspection.onsite.productImages.sort(
        (a, b) => new Date(a.metadata.capturedAt) - new Date(b.metadata.capturedAt),
      );
    } else {
      // eslint-disable-next-line
      console.warn(`Inspection ID ${image.inspectionId} not found in state.`);
    }
  });

  // Update state object with new inspections, remaining images to sync, and request state
  return updateObject(state, {
    inspectionsImagesLeftToSyncCountState: requestState,
    inspectionsImagesLeftToSync: imagesLeftToSync,
    inspections: newInspections,
  });
};

// eslint-disable-next-line default-param-last
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case INSPECTIONS_GET_DOCUMENT_REQUEST_START:
      return inspectionsGetDocumentStart(state, action);
    case INSPECTIONS_GET_DOCUMENT_REQUEST_FAIL:
      return inspectionsGetDocumentFail(state, action);
    case INSPECTIONS_GET_DOCUMENT_REQUEST_SUCCESS:
      return inspectionsGetDocumentSuccess(state, action);
    case INSPECTIONS_GET_SINGLE_REQUEST_START:
      return inspectionsGetSingleStart(state, action);
    case INSPECTIONS_GET_SINGLE_REQUEST_FAIL:
      return inspectionsGetSingleFail(state, action);
    case INSPECTIONS_GET_SINGLE_REQUEST_SUCCESS:
      return inspectionsGetSingleSuccess(state, action);
    case INSPECTIONS_GET_REQUEST_START:
      return inspectionsGetStart(state, action);
    case INSPECTIONS_GET_REQUEST_FAIL:
      return inspectionsGetFail(state, action);
    case INSPECTIONS_GET_REQUEST_SUCCESS:
      return inspectionsGetSuccess(state, action);
    case INSPECTIONS_GET_REQUEST_IDLE:
      return inspectionsGetIdle(state, action);
    case INSPECTIONS_GET_AVAILABLE_STAFF_REQUEST_START:
      return inspectionsGetAvailableStaffStart(state, action);
    case INSPECTIONS_GET_AVAILABLE_STAFF_REQUEST_FAIL:
      return inspectionsGetAvailableStaffFail(state, action);
    case INSPECTIONS_GET_AVAILABLE_STAFF_REQUEST_SUCCESS:
      return inspectionsGetAvailableStaffSuccess(state, action);
    case INSPECTIONS_GET_AVAILABLE_STAFF_REQUEST_IDLE:
      return inspectionsGetAvailableStaffIdle(state, action);
    case INSPECTIONS_POST_REQUEST_START:
      return inspectionsPostStart(state, action);
    case INSPECTIONS_POST_REQUEST_FAIL:
      return inspectionsPostFail(state, action);
    case INSPECTIONS_POST_REQUEST_IDLE:
      return inspectionsPostIdle(state, action);
    case INSPECTIONS_POST_REQUEST_SUCCESS:
      return inspectionsPostSuccess(state, action);
    case INSPECTIONS_PUT_REQUEST_START:
      return inspectionsPutStart(state, action);
    case INSPECTIONS_PUT_REQUEST_FAIL:
      return inspectionsPutFail(state, action);
    case INSPECTIONS_PUT_REQUEST_SUCCESS:
      return inspectionsPutSuccess(state, action);
    case INSPECTIONS_PUT_REQUEST_IDLE:
      return inspectionsPutIdle(state, action);
    case INSPECTIONS_PUT_ASSIGN_REQUEST_START:
      return inspectionsPutAssignStart(state, action);
    case INSPECTIONS_PUT_ASSIGN_REQUEST_FAIL:
      return inspectionsPutAssignFail(state, action);
    case INSPECTIONS_PUT_ASSIGN_REQUEST_SUCCESS:
      return inspectionsPutAssignSuccess(state, action);
    case INSPECTIONS_PUT_ASSIGN_REQUEST_IDLE:
      return inspectionsPutAssignIdle(state, action);
    case INSPECTIONS_PUT_MARK_REQUEST_START:
      return inspectionsPutMarkStart(state, action);
    case INSPECTIONS_PUT_MARK_REQUEST_FAIL:
      return inspectionsPutMarkFail(state, action);
    case INSPECTIONS_PUT_MARK_REQUEST_SUCCESS:
      return inspectionsPutMarkSuccess(state, action);
    case INSPECTIONS_PUT_MARK_REQUEST_IDLE:
      return inspectionsPutMarkIdle(state, action);
    case INSPECTIONS_PUT_UNASSIGN_SELF_REQUEST_START:
      return inspectionsPutUnassignSelfStart(state, action);
    case INSPECTIONS_PUT_UNASSIGN_SELF_REQUEST_FAIL:
      return inspectionsPutUnassignSelfFail(state, action);
    case INSPECTIONS_PUT_UNASSIGN_SELF_REQUEST_SUCCESS:
      return inspectionsPutUnassignSelfSuccess(state, action);
    case INSPECTIONS_PUT_UNASSIGN_SELF_REQUEST_IDLE:
      return inspectionsPutUnassignSelfIdle(state, action);
    case INSPECTIONS_PUT_SUBMIT_REPORT_REQUEST_START:
      return inspectionsPutSubmitReportStart(state, action);
    case INSPECTIONS_PUT_SUBMIT_REPORT_REQUEST_FAIL:
      return inspectionsPutSubmitReportFail(state, action);
    case INSPECTIONS_PUT_SUBMIT_REPORT_REQUEST_SUCCESS:
      return inspectionsPutSubmitReportSuccess(state, action);
    case INSPECTIONS_PUT_SUBMIT_REPORT_REQUEST_IDLE:
      return inspectionsPutSubmitReportIdle(state, action);
    case INSPECTIONS_DELETE_REQUEST_START:
      return inspectionsDeleteStart(state, action);
    case INSPECTIONS_DELETE_REQUEST_FAIL:
      return inspectionsDeleteFail(state, action);
    case INSPECTIONS_DELETE_REQUEST_SUCCESS:
      return inspectionsDeleteSuccess(state, action);
    case INSPECTIONS_DELETE_REQUEST_IDLE:
      return inspectionsDeleteIdle(state, action);
    case INSPECTIONS_PATCH_REPOPULATE_REQUEST_START:
      return inspectionsPatchRepopulateStart(state, action);
    case INSPECTIONS_PATCH_REPOPULATE_REQUEST_FAIL:
      return inspectionsPatchRepopulateFail(state, action);
    case INSPECTIONS_PATCH_REPOPULATE_REQUEST_SUCCESS:
      return inspectionsPatchRepopulateSuccess(state, action);
    case INSPECTIONS_PATCH_REPOPULATE_REQUEST_IDLE:
      return inspectionsPatchRepopulateIdle(state, action);
    case INSPECTIONS_GENERERATE_REPORT_POST_REQUEST_START:
      return inspectionsGenerateReportPostStart(state, action);
    case INSPECTIONS_GENERERATE_REPORT_POST_REQUEST_FAIL:
      return inspectionsGenerateReportPostFail(state, action);
    case INSPECTIONS_GENERERATE_REPORT_POST_REQUEST_SUCCESS:
      return inspectionsGenerateReportPostSuccess(state, action);
    case INSPECTIONS_GENERERATE_REPORT_POST_REQUEST_IDLE:
      return inspectionsGenerateReportPostIdle(state, action);
    case INSPECTIONS_REPORTS_DELETE_POST_REQUEST_START:
      return inspectionsReportsDeleteStart(state, action);
    case INSPECTIONS_REPORTS_DELETE_POST_REQUEST_FAIL:
      return inspectionsReportsDeleteFail(state, action);
    case INSPECTIONS_REPORTS_DELETE_POST_REQUEST_SUCCESS:
      return inspectionsReportsDeleteSuccess(state, action);
    case INSPECTIONS_REPORTS_DELETE_POST_REQUEST_IDLE:
      return inspectionsReportsDeleteIdle(state, action);
    case INSPECTIONS_IMAGES_DOWNLOAD_ZIP_GET_REQUEST_START:
      return inspectionsImagesDownloadZipGetStart(state, action);
    case INSPECTIONS_IMAGES_DOWNLOAD_ZIP_GET_REQUEST_FAIL:
      return inspectionsImagesDownloadZipGetFail(state, action);
    case INSPECTIONS_IMAGES_DOWNLOAD_ZIP_GET_REQUEST_SUCCESS:
      return inspectionsImagesDownloadZipGetSuccess(state, action);
    case INSPECTIONS_IMAGES_DOWNLOAD_ZIP_GET_REQUEST_IDLE:
      return inspectionsImagesDownloadZipGetIdle(state, action);
    case INSPECTIONS_IMAGES_PATCH_REQUEST_START:
      return inspectionsImagesPatchStart(state, action);
    case INSPECTIONS_IMAGES_PATCH_REQUEST_FAIL:
      return inspectionsImagesPatchFail(state, action);
    case INSPECTIONS_IMAGES_PATCH_REQUEST_SUCCESS:
      return inspectionsImagesPatchSuccess(state, action);
    case INSPECTIONS_IMAGES_PATCH_REQUEST_IDLE:
      return inspectionsImagesPatchIdle(state, action);
    case INSPECTIONS_IMAGE_DELETE_REQUEST_START:
      return inspectionsImageDeleteStart(state, action);
    case INSPECTIONS_IMAGE_DELETE_REQUEST_FAIL:
      return inspectionsImageDeleteFail(state, action);
    case INSPECTIONS_IMAGE_DELETE_REQUEST_SUCCESS:
      return inspectionsImageDeleteSuccess(state, action);
    case INSPECTIONS_IMAGE_DELETE_REQUEST_IDLE:
      return inspectionsImageDeleteIdle(state, action);
    case INSPECTIONS_IMAGES_ASSOCIATE_PUT_REQUEST_START:
      return inspectionsImagesAssociateStart(state, action);
    case INSPECTIONS_IMAGES_ASSOCIATE_PUT_REQUEST_FAIL:
      return inspectionsImagesAssociateFail(state, action);
    case INSPECTIONS_IMAGES_ASSOCIATE_PUT_REQUEST_SUCCESS:
      return inspectionsImagesAssociateSuccess(state, action);
    case INSPECTIONS_IMAGES_ASSOCIATE_PUT_REQUEST_IDLE:
      return inspectionsImagesAssociateIdle(state, action);
    case INSPECTIONS_IMAGES_SELECT_PUT_REQUEST_START:
      return inspectionsImagesSelectStart(state, action);
    case INSPECTIONS_IMAGES_SELECT_PUT_REQUEST_FAIL:
      return inspectionsImagesSelectFail(state, action);
    case INSPECTIONS_IMAGES_SELECT_PUT_REQUEST_SUCCESS:
      return inspectionsImagesSelectSuccess(state, action);
    case INSPECTIONS_IMAGES_SELECT_PUT_REQUEST_IDLE:
      return inspectionsImagesSelectIdle(state, action);
    case INSPECTIONS_IMAGES_LEFT_TO_SYNC_COUNT_START:
      return inspectionsImagesLeftToSyncCountStart(state, action);
    case INSPECTIONS_IMAGES_LEFT_TO_SYNC_COUNT_SUCCESS:
      return inspectionsImagesLeftToSyncCountSuccess(state, action);
    default:
      return state;
  }
};

export default reducer;
