import React, {
  useState, useEffect, forwardRef, useImperativeHandle,
} from 'react';
import { apiRequestState, get } from '../../../utility/utility';
import CoreFormDualAction from '../../core/form/CoreFormDualAction';

const InspectionFormImagesDelete = forwardRef(({
  dispatchInspectionImageDelete,
  inspectionsImageDeleteRequestState,

}, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentInspection, setCurrentInspection] = useState([]);
  const [imageIdsState, setImageIdsState] = useState([]);
  const [imagesTypeState, setImagesTypeState] = useState('');

  useImperativeHandle(ref, () => ({
    open: (inspection, imagesType, imageIds) => {
      setCurrentInspection(inspection);
      setImagesTypeState(imagesType);
      setImageIdsState(imageIds);
      setIsOpen(true);
    },
    close: () => setIsOpen(false),
  }));

  useEffect(() => {
    if (inspectionsImageDeleteRequestState === apiRequestState.SUCCESS) {
      setIsOpen(false);
    }
  }, [inspectionsImageDeleteRequestState]);

  if (!isOpen) return null;

  const closeModal = () => {
    setIsOpen(false);
  };

  const getImageById = (images, id) => {
    for (const image of images) {
      if (image._id === id) {
        return image;
      }
    }
    return null;
  };

  return (
    <CoreFormDualAction
      title="Delete Images"
      primaryButtonCaption="Delete"
      primaryButtonCallback={() => dispatchInspectionImageDelete(currentInspection, { imagesType: imagesTypeState, imagesIds: imageIdsState })}
      primaryButtonCallbackStatus={inspectionsImageDeleteRequestState === apiRequestState.LOADING}
      secondaryButtonCaption="Cancel"
      secondaryButtonCallback={closeModal}
    >
      <div>
        <p>
          {`Confirm deleting ${imageIdsState.length} images:`}
        </p>
        {imageIdsState.map((imageId) => (
          <div key={imageId} style={{ display: 'flex', justifyContent: 'center' }}>
            <img src={getImageById(get(currentInspection, `onsite.${imagesTypeState}`, []), imageId)?.thumbnailKey} alt={imageId} />
          </div>
        ))}
      </div>
    </CoreFormDualAction>
  );
});

export default InspectionFormImagesDelete;
