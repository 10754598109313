import React, { useState } from 'react';
import './SortSelect.css';
import { FaSort } from 'react-icons/fa';

function SortSelect({
  id, onChange, value, disabled, options = [],
}) {
  const [isOpen, setIsOpen] = useState(false);

  const handleIconClick = () => {
    setIsOpen(!isOpen);
  };

  const handleSelectChange = (event) => {
    onChange(event);
    setIsOpen(false);
  };

  return (
    <div className="sort-select-container">
      <FaSort className="sort-icon" onClick={handleIconClick} />
      <select
        id={id}
        onChange={handleSelectChange}
        value={value}
        disabled={disabled}
        className={`sort-select-hidden ${isOpen ? 'visible' : ''}`}
        size={isOpen ? '5' : '1'} // Adjust size based on isOpen state
      >
        {options.map((option) => (
          <option key={option} value={option}>{option}</option>
        ))}
      </select>
    </div>
  );
}

export default SortSelect;
