import React from 'react';
import _ from 'lodash';
import { get } from '../../../utility/utility';
import CoreCard from '../../core/content/CoreCard';
import BubbleCard from '../../BubbleCard';

function InspectionBubbleAssessment({
  assessment,
  actions,
}) {
  const renderField = ({
    assessment: currentAssessment,
    id,
    label = _.startCase(id),
    path = id,
  }) => (
    <div>
      {`${label}: ${get(currentAssessment, path)}`}
    </div>
  );

  return (
    <BubbleCard
      title="Assessment"
      style={{ marginBottom: '10px' }}
    >
      <CoreCard
        content={(
          <div>
            <div style={{ paddingLeft: '10px', paddingBottom: '10px', color: 'var(--color-grey900)' }}>
              {renderField({ assessment, id: 'startTimestamp.date', label: 'Start date' })}
              {renderField({ assessment, id: 'startTimestamp.time', label: 'Start time' })}
            </div>
            <div style={{ paddingLeft: '10px', paddingBottom: '10px', color: 'var(--color-grey900)' }}>
              {renderField({ assessment, id: 'endTimestamp.date', label: 'End date' })}
              {renderField({ assessment, id: 'endTimestamp.time', label: 'End time' })}
            </div>
            <div style={{ paddingLeft: '10px', paddingBottom: '10px', color: 'var(--color-grey900)' }}>
              {renderField({ assessment, id: 'internalMemo' })}
            </div>
          </div>
        )}
        actions={actions}
      />
    </BubbleCard>
  );
}

export default InspectionBubbleAssessment;
