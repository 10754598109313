import { React } from 'react';
import './Backdrop.css';

function Backdrop(props) {
  const backdrop = document.querySelector('.backdrop');
  if (backdrop !== null) {
    if (props.isShown) {
      backdrop.style.display = 'block';
    } else {
      backdrop.style.display = 'none';
    }
  }

  return (
    <div className="backdrop" onClick={props.onClick} />
  );
}

export default Backdrop;
