import React, {
  useState, useEffect, forwardRef, useImperativeHandle,
} from 'react';
import { apiRequestState, get } from '../../../utility/utility';
import CoreFormDualAction from '../../core/form/CoreFormDualAction';
import { renderDateTimePicker, renderTextArea } from '../../core/input/CoreInputUtils';

const InspectionFormAssessment = forwardRef(({
  dispatchInspectionsPut,
  inspectionsPutRequestState,
}, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentInspection, setCurrentInspection] = useState(null);
  const [inputState, setInputState] = useState({});
  useImperativeHandle(ref, () => ({
    open: (inspection) => {
      setCurrentInspection(inspection);
      setInputState(get(inspection, 'onsite.assessment', {}));
      setIsOpen(true);
    },
    close: () => setIsOpen(false),
  }));

  const closeModal = () => {
    setIsOpen(false);
  };
  useEffect(() => {
    if (inspectionsPutRequestState === apiRequestState.SUCCESS) {
      closeModal();
    }
  }, [inspectionsPutRequestState]);

  if (!isOpen) return null;

  const onInputChange = (event, path) => {
    const newState = { ...inputState };
    newState[path] = event.target.value;
    setInputState(newState);
  };

  const handleSave = (inpsection, state) => {
    dispatchInspectionsPut(inpsection, { onsite: { assessment: { ...state } } });
  };

  return (
    <CoreFormDualAction
      title="Assessment"
      primaryButtonCaption="Save"
      primaryButtonCallback={() => handleSave(currentInspection, inputState)}
      primaryButtonCallbackStatus={inspectionsPutRequestState === apiRequestState.LOADING}
      primaryButtonDisabled={inspectionsPutRequestState === apiRequestState.LOADING}
      secondaryButtonCaption="Cancel"
      secondaryButtonCallback={closeModal}
    >
      {renderDateTimePicker({
        id: 'startTimestamp',
        label: 'Inspection Start Timestamp',
        inputState,
        onInputChange,
      })}
      {renderDateTimePicker({
        id: 'endTimestamp',
        label: 'Inspection End Timestamp',
        inputState,
        onInputChange,
      })}
      {renderTextArea({ id: 'internalMemo', inputState, onInputChange })}
    </CoreFormDualAction>
  );
});

export default InspectionFormAssessment;
