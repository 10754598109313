import React, {
  useState, useEffect, forwardRef, useImperativeHandle,
} from 'react';
import _ from 'lodash';
import { apiRequestState, get, set } from '../../../utility/utility';
import CoreFormDualAction from '../../core/form/CoreFormDualAction';
import InputFieldSelect from '../../core/input/InputFieldSelect';
import {
  renderCheckbox, renderInputField, renderTextArea, validateForm,
} from '../../core/input/CoreInputUtils';

const fieldsToValidate = ['commodity', 'description'];
const InspectionFormDefect = forwardRef(({
  dispatchInspectionsPut,
  inspectionsPutRequestState,
}, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [organizationState, setOrganizationState] = useState({});
  const [currentInspection, setCurrentInspection] = useState(null);
  const [currentDefect, setCurrentDefect] = useState(null);
  const [presetDefectState, setPresetDefectState] = useState(null);
  const [inputState, setInputState] = useState({});
  const [AverageIsOccasionalState, setAverageIsOccasionalState] = useState({});
  const [error, setError] = useState({});

  useImperativeHandle(ref, () => ({
    open: (organization, inspection, sample, defect) => {
      setOrganizationState(organization);
      setCurrentInspection(inspection);
      setCurrentDefect(defect);
      setPresetDefectState(null);
      setIsOpen(true);
      setError({});
      if (!defect) {
        setInputState({ sampleId: sample._id });
      } else {
        setInputState({ ...defect });
      }
    },
    close: () => setIsOpen(false),
  }));

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (inspectionsPutRequestState === apiRequestState.SUCCESS) {
      closeModal();
    }
  }, [inspectionsPutRequestState]);

  if (!isOpen) return null;

  const onInputChange = (event) => {
    const newState = { ...inputState };
    newState[event.target.id] = event.target.value;
    setInputState(newState);
    validateForm({
      state: newState,
      fieldsToValidate,
      setError,
    });
    setPresetDefectState(null);
  };

  const onIsOccasionalChange = (event) => {
    const newAverageIsOccasionalState = { ...AverageIsOccasionalState };
    newAverageIsOccasionalState[event.target.id] = event.target.value;
    setAverageIsOccasionalState(newAverageIsOccasionalState);
    const newState = { ...inputState };
    if (event.target.value) {
      newState.average = 'Occasional';
    } else {
      newState.average = '';
    }
    setInputState(newState);
  };

  const handleSave = (inspection, defect) => {
    if (validateForm({
      state: inputState,
      fieldsToValidate,
      setError,
    })) {
      if (defect) {
        // Editin an existing defect
        const defectsToUpdate = [inputState];
        const update = {};
        set(update, 'analysis.defectsToUpdate', defectsToUpdate);
        dispatchInspectionsPut(inspection, update);
      } else {
        // Creating a new defect
        const defectsToAdd = [inputState];
        const update = {};
        set(update, 'analysis.defectsToAdd', defectsToAdd);
        dispatchInspectionsPut(inspection, update);
      }
    }
  };
  const defectPresets = get(organizationState, 'inspectionSettings.defectDefinitions', []);

  const handlePresetSelectChange = (event) => {
    const defectIndex = defectPresets.findIndex((element) => element._id.toString() === event.target.value);

    if (defectIndex >= 0) {
      const presetDefect = defectPresets[defectIndex];
      setInputState({ ...inputState, ...(_.pick(presetDefect, ['commodity', 'description', 'definition'])) });
      setPresetDefectState({ label: `${presetDefect.commodity} - ${presetDefect.description}`, value: event.target.value });
    }
  };

  return (
    <CoreFormDualAction
      title="Defect"
      primaryButtonCaption="Save"
      primaryButtonCallback={() => handleSave(currentInspection, currentDefect)}
      primaryButtonCallbackStatus={inspectionsPutRequestState === apiRequestState.LOADING}
      primaryButtonDisabled={Object.keys(error).length > 0 || inspectionsPutRequestState === apiRequestState.LOADING}
      secondaryButtonCaption="Cancel"
      secondaryButtonCallback={closeModal}
    >
      <div>
        <InputFieldSelect
          style={{ color: 'black' }}
          id="defectPreset"
          label="Defect Preset Selector"
          placeholder="Load a preset defect"
          options={[].concat(defectPresets.map((setting) => ({ value: setting._id, label: `${setting.commodity} - ${setting.description}` })))}
          onChange={(event) => handlePresetSelectChange({ target: { id: 'newDefect', value: get(event, 'value') } })}
          value={presetDefectState}
          createIsDisabled
        />
        {renderInputField({
          id: 'commodity', inputState, onInputChange, error,
        })}
        {renderInputField({
          id: 'description', inputState, onInputChange, error,
        })}
        {renderTextArea({ id: 'definition', inputState, onInputChange })}
        {renderInputField({ id: 'average', inputState, onInputChange })}
        {renderCheckbox({
          id: 'averageIsOccasional', label: 'Occasional', inputState: AverageIsOccasionalState, onInputChange: onIsOccasionalChange,
        })}
        {renderInputField({ id: 'rangeMin', inputState, onInputChange })}
        {renderInputField({ id: 'rangeMax', inputState, onInputChange })}
      </div>
    </CoreFormDualAction>
  );
});

export default InspectionFormDefect;
