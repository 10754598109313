import React, {
  useState, useEffect, forwardRef, useImperativeHandle,
} from 'react';
import _ from 'lodash';
import { apiRequestState } from '../../../utility/utility';
import Select from '../../Select';

import { getBookingIdentifier } from '../../../utility/BookingsUtility';
import CoreFormDualAction from '../../core/form/CoreFormDualAction';

const BookingFormInspectionRequest = forwardRef(({
  bookingsPostInspectionRequestState,
  dispatchBookingsPostInspection,
  inspectors,
}, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [inputState, setInputState] = useState({
    inspector: '',
    bookings: [],
    service: {},
  });
  const [error, setError] = useState(null);

  useImperativeHandle(ref, () => ({
    open: (bookings) => {
      setIsOpen(true);
      setInputState({
        bookings: _.cloneDeep(bookings),
      });
      setError(null);
    },
    close: () => setIsOpen(false),
  }));

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (bookingsPostInspectionRequestState === apiRequestState.SUCCESS) {
      closeModal();
    }
  }, [bookingsPostInspectionRequestState]);

  if (!isOpen) return null;

  const handleChange = (event) => {
    const newState = { ...inputState };
    newState[event.target.id] = event.target.value;
    setInputState(newState);
  };

  const handleRequestInspection = () => {
    const serviceRequested = inspectors.find((org) => org.company.name === inputState.inspector).inspectionSettings.services.find((service) => (service.title === inputState.service));

    const body = {
      request: {
        inspectorOrganizationId: inspectors.find((inspector) => inspector.company.name === inputState.inspector)._id,
        serviceId: serviceRequested._id,
        bookingIds: inputState.bookings.map((booking) => (booking._id)),
      },
    };
    dispatchBookingsPostInspection(body);
  };

  return (
    <CoreFormDualAction
      title="Inspection Request"
      primaryButtonCaption="Request"
      primaryButtonCallback={() => handleRequestInspection()}
      primaryButtonCallbackStatus={bookingsPostInspectionRequestState === apiRequestState.LOADING}
      primaryButtonDisabled={error || bookingsPostInspectionRequestState === apiRequestState.LOADING}
      secondaryButtonCaption="Cancel"
      secondaryButtonCallback={closeModal}
    >
      <>
        <p>
          Request inspection for:
        </p>
        {inputState.bookings.map((booking) => (
          <p style={{ margin: '0px', paddingLeft: '25px' }}>
            <span>- </span>
            {getBookingIdentifier(booking).identifier}
            <span>: </span>
            {getBookingIdentifier(booking).value}
          </p>
        ))}
      </>
      <Select id="inspector" value={inputState.inspector ?? ''} onChange={handleChange} options={[''].concat(inspectors.map((org) => org.company.name))}>Inspector</Select>
      {inputState.inspector && (
        <Select
          id="service"
          value={inputState.service ?? ''}
          onChange={handleChange}
          options={[''].concat(inspectors.find((org) => org.company.name === inputState.inspector).inspectionSettings.services.map((service) => (service.title)))}
        >
          Service
        </Select>
      )}

      {error?.template && <div style={{ color: 'var(--color-warning)' }}>{error.template}</div>}
    </CoreFormDualAction>
  );
});

export default BookingFormInspectionRequest;
