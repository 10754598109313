export const NOTIFICATIONS_SHOW_STATUS = 'NOTIFICATIONS_SHOW_STATUS';
export const NOTIFICATIONS_CLEAR = 'NOTIFICATIONS_CLEAR';

export const notificationsShow = (severity, message) => ({
  type: NOTIFICATIONS_SHOW_STATUS,
  isShown: true,
  severity,
  message,
});

export const notificationsClear = () => ({
  type: NOTIFICATIONS_CLEAR,
  isShown: false,
});
