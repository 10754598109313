import { React } from 'react';
import './TableData.css';

function TableData(props) {
  if (props?.color) {
    return (
      <td style={{ backgroundColor: props.color }}>{props.children}</td>
    );
  }
  return (
    <td>{props.children}</td>
  );
}

export default TableData;
