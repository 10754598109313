import {
  React, useEffect, useRef, useState,
} from 'react';
import { useMediaQuery } from 'react-responsive';
import './BookingsPendingShipment.css';
import _ from 'lodash';
import { FaEdit, FaPaperclip, FaTrash } from 'react-icons/fa';
import Table from '../Table';
import TableHeader from '../TableHeader';
import TableBody from '../TableBody';
import TableCollapsibleRow from '../TableCollapsibleRow';
import { apiRequestState, get, handleSort } from '../../utility/utility';
import Spinner from '../Spinner';
import BookingSection from './sections/BookingSection';
import DetailsSection from './sections/DetailsSection';
import DocumentsSection from './sections/DocumentSection';
import { ChecklistSection } from './sections/ChecklistSection';
import BookingsCardList from '../cardLists/BookingsCardList';
import { SORT_OPTIONS, sortKeyMap } from '../../utility/vesselParams';

import { getBookingsCount } from '../../utility/BookingsUtility';
import { MEDIA_MOBILE_MAX_WIDTH } from '../../utility/AppParameters';
import Checkbox from '../core/input/CoreInputCheckbox';

function BookingsPendingShipment({
  me,
  title,
  searchInput,
  bookings,
  organization,
  headerTitles,
  searchedBookings,
  rowSelectionIsEnabled,
  handleButtonBookingSaveModalOpen,
  handleButtonBookingDeleteModalOpen,
  handleButtonBookingAttachDocumentModalOpen,
  dispatchBookingsGetDocument,
  bookingsGetRequestState,
  bookingsPostRequestState,
}) {
  const isDesktopOrLaptop = useMediaQuery({ minDeviceWidth: MEDIA_MOBILE_MAX_WIDTH });
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedBookings, setSelectedBookings] = useState([]);
  const [previousBookings, setPreviousBookings] = useState(searchedBookings);
  const [sortedBookingsState, setSortedBookingsState] = useState(previousBookings);
  const prevSearchedBookingsRef = useRef();

  const selectCheckboxHandler = (id) => {
    const newSelectedIds = [...selectedIds];
    const exists = newSelectedIds.includes(id);

    if (!exists) {
      newSelectedIds.push(id);
    } else {
      const index = newSelectedIds.indexOf(id);
      if (index > -1) {
        newSelectedIds.splice(index, 1);
      }
    }
    setSelectedIds(newSelectedIds);
    setSelectedBookings(_.filter(searchedBookings, (object) => _.includes(newSelectedIds, object._id)));
  };

  useEffect(() => {
    prevSearchedBookingsRef.current = searchedBookings;
  }, [searchedBookings]);

  useEffect(() => {
    if (!_.isEqual(previousBookings, searchedBookings)) {
      setSelectedIds([]);
      setPreviousBookings(searchedBookings);
    }
    handleSort({
      target: {
        value: SORT_OPTIONS.ETA,
      },
    }, searchedBookings, sortKeyMap, setSortedBookingsState);
  }, [searchedBookings, previousBookings]);

  const handleTableHeaderClick = (header) => {
    handleSort({
      target: {
        value: header.title,
      },
    }, searchedBookings, sortKeyMap, setSortedBookingsState);
  };

  const handleMasterCheckboxChange = (event) => {
    const { checked } = event.target;

    if (checked) {
      setSelectedIds(searchedBookings.map((booking) => booking._id));
      setSelectedBookings(searchedBookings);
    } else {
      setSelectedIds([]);
      setSelectedBookings([]);
    }
  };

  const displayBookings = (displayedBookings) => {
    if (!organization?.bookingSettings) return null;

    return (
      displayedBookings.map((booking) => (
        <TableCollapsibleRow
          key={booking._id}
          id={booking._id}
          selectCheckboxIsEnabled={me?.permissions?.bookingsEdit && rowSelectionIsEnabled}
          selectCheckboxHandler={selectCheckboxHandler}
          checked={selectedIds.includes(booking._id)}
          expandedContent={(
            <>
              {me?.permissions?.bookingsEdit
                ? (
                  <div className="table-collapsible-row-action-bar">
                    <FaEdit title="Edit" onClick={() => handleButtonBookingSaveModalOpen(booking)}>Edit</FaEdit>
                    <FaPaperclip title="Attach" onClick={() => handleButtonBookingAttachDocumentModalOpen(booking, organization.bookingSettings.documents)}>Attach</FaPaperclip>
                    <FaTrash title="Delete" onClick={() => handleButtonBookingDeleteModalOpen([booking])}>Delete</FaTrash>
                  </div>
                )
                : null}
              <BookingSection booking={booking.booking} />
              <DetailsSection presetDetailsFields={organization.bookingSettings.details} details={booking.details} />
              <DocumentsSection booking={booking} dispatchBookingsGetDocument={dispatchBookingsGetDocument} />
              <ChecklistSection booking={booking} presetChecklistFields={organization.bookingSettings.checklist} />
            </>
          )}
        >
          <td />
          <td>{get(booking, 'details.loadingWeek')}</td>
          <td>{get(booking, 'details.arrivalWeek')}</td>
          <td>{get(booking, 'booking.purchaseOrder')}</td>
          <td>{get(booking, 'booking.invoiceNumber')}</td>
          <td>{get(booking, 'booking.productDescription')}</td>
          <td>{get(booking, 'booking.shipper')}</td>
          <td />
        </TableCollapsibleRow>
      ))
    );
  };

  const newElement = rowSelectionIsEnabled && <Checkbox checked={selectedIds.length === searchedBookings.length} onChange={handleMasterCheckboxChange} />;
  const updatedHeaderTitles = headerTitles.slice(0, -2)
    .concat(newElement)
    .concat(headerTitles.slice(-1));

  const bookingsCount = getBookingsCount(
    bookings.filter((item) => item.tracking.container === null).length,
    searchedBookings.length,
    searchInput,
  );

  function renderBookingsTable() {
    if (bookingsCount.total === 0) return null;

    return (
      <div className="bookings-booked-table">
        <div className="bookings-booked-title-block">
          <div className="bookings-booked-title">
            {`${title} ${bookingsCount.text}`}
          </div>
          {
            selectedIds.length > 0 && (
              <div className="bookings-booked-bulk-action-buttons">
                <FaTrash title="Delete" className="action-icon" onClick={() => handleButtonBookingDeleteModalOpen(selectedBookings)} />
              </div>
            )
          }
        </div>
        {bookingsCount.found > 0
          && (
            <div>
              {(bookingsGetRequestState === apiRequestState.LOADING || bookingsPostRequestState === apiRequestState.LOADING)
                ? (
                  <div style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  >
                    <Spinner />
                  </div>
                )
                : (
                  <Table>
                    <TableHeader onClick={handleTableHeaderClick} titles={updatedHeaderTitles} />
                    <TableBody me={me}>
                      {displayBookings(sortedBookingsState)}
                    </TableBody>
                  </Table>
                )}
            </div>
          )}
        {' '}
      </div>
    );
  }

  function renderBookingsCardList() {
    if (bookingsCount.found === 0 || bookingsCount.total === 0) return null;

    return (
      <BookingsCardList
        title={`${title} ${bookingsCount.text}`}
        sortOptions={headerTitles.filter((option) => typeof option === 'string' && option.length > 0)}
        handleTableHeaderClick={handleTableHeaderClick}
        organization={organization}
        bookings={sortedBookingsState}
        handleButtonBookingDeleteModalOpen={handleButtonBookingDeleteModalOpen}
        handleButtonBookingSaveModalOpen={handleButtonBookingSaveModalOpen}
        handleButtonBookingAttachDocumentModalOpen={handleButtonBookingAttachDocumentModalOpen}
        dispatchBookingsGetDocument={dispatchBookingsGetDocument}
        bookingsPostRequestState={bookingsPostRequestState}
        bookingsGetRequestState={bookingsGetRequestState}
      />
    );
  }

  return (
    <div>
      {isDesktopOrLaptop ? renderBookingsTable() : renderBookingsCardList()}
    </div>
  );
}
export default BookingsPendingShipment;
