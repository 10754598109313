import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import './PasswordReset.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import Typography from '../components/Typography';
import { authResetPassword } from '../actions';
import { apiRequestState } from '../utility/utility';
import Button from '../components/core/input/Button';
import Checkbox from '../components/core/input/CoreInputCheckbox';
import InputField from '../components/core/input/InputField';

function PasswordReset({
  dispatchResetPassword,
  resetPasswordRequestState,
}) {
  const getQueryParam = (name) => {
    const params = new URLSearchParams(window.location.search);
    return params.get(name);
  };

  const resetToken = getQueryParam('resetToken');

  const formik = useFormik({
    initialValues: {
      password: '',
      rememberMe: true,
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required('Password is required')
        .min(8, 'Password must be at least 8 characters')
        .matches(/[a-z]/, 'Password must contain at least one lowercase char')
        .matches(/[A-Z]/, 'Password must contain at least one uppercase char')
        .matches(/[a-zA-Z]+[^a-zA-Z\s]+/, 'Password must contain at least 1 number or special char (@, #, $, %, ^, &, *)'),
      rememberMe: Yup.bool(),
    }),
    initialErrors: {
      password: 'Password is required',
      rememberMe: true,
    },
    onSubmit: (values) => {
      const submitValues = { ...values };
      submitValues.resetToken = resetToken;
      dispatchResetPassword(submitValues);
    },
  });

  useEffect(() => {
    if (resetPasswordRequestState === apiRequestState.SUCCESS) {
      window.location.href = '/';
    }
  }, [resetPasswordRequestState]);

  return (
    <div className="password-reset-container">
      <div className="password-reset-title">
        <Typography text="Password Reset" />
      </div>

      <form className="password-reset-form" onSubmit={formik.handleSubmit}>
        <div className="password-reset-password">
          <InputField
            id="password"
            label="New Password"
            type="password"
            placeholder="Password"
            // isRequired={true}
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <div className="password-reset-validation-error-text">
            {formik.touched.password && formik.errors.password ? (
              <div>{formik.errors.password}</div>
            ) : null}
          </div>
          <div className="password-reset-checkbox-remember-me">
            <Checkbox
              id="rememberMe"
              name="rememberMe"
              onChange={formik.handleChange}
              checked={formik.values.rememberMe}
            >
              Remember Me
            </Checkbox>
          </div>
        </div>

        <div className="password-reset-buttons-container">
          <div className="password-reset-button-submit">
            <Button
              id="buttonSubmit"
              type="submit"
              isLoading={resetPasswordRequestState === apiRequestState.LOADING}
              primary
              disabled={!formik.isValid}
            >
              Set password and sign in
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
}

const mapStateToProps = (state) => ({
  resetPasswordRequestState: state.auth.resetPasswordRequestState,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchResetPassword: (credentials) => dispatch(authResetPassword(credentials)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset);
