import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import './SignupInvite.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import Typography from '../components/Typography';
import { apiRequestState } from '../utility/utility';

import { authSignupInvite } from '../actions';
import Button from '../components/core/input/Button';
import Checkbox from '../components/core/input/CoreInputCheckbox';
import InputField from '../components/core/input/InputField';

function SignupInvite({
  dispatchSignUpInvite,
  signUpInviteRequestState,
}) {
  const getQueryParam = (name) => {
    const params = new URLSearchParams(window.location.search);
    return params.get(name);
  };

  const token = getQueryParam('resetToken');
  const email = getQueryParam('email');

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      password: '',
      rememberMe: true,
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required('First name is required'),
      lastName: Yup.string().required('Last name is required'),
      password: Yup.string()
        .required('Password is required')
        .min(8, 'Password must be at least 8 characters')
        .matches(/[a-z]/, 'Password must contain at least one lowercase char')
        .matches(/[A-Z]/, 'Password must contain at least one uppercase char')
        .matches(/[a-zA-Z]+[^a-zA-Z\s]+/, 'Password must contain at least 1 number or special char (@, #, $, %, ^, &, *)'),
      rememberMe: Yup.bool(),
    }),
    initialErrors: {
      firstName: 'First name is required',
      lastName: 'Last name is required',
      password: 'Password is required',
      rememberMe: true,
    },
    onSubmit: (values) => {
      const submitValues = { ...values };
      submitValues.email = email;
      submitValues.token = token;
      dispatchSignUpInvite(submitValues);
    },
  });

  useEffect(() => {
    if (signUpInviteRequestState === apiRequestState.SUCCESS) {
      window.location.href = '/';
    }
  }, [signUpInviteRequestState]);

  return (
    <div className="signup-invite-container">
      <div className="signup-invite-title">
        <Typography text="Sign Up" />
      </div>
      <div className="signup-invite-subtitle">
        {`Invitation for ${email}`}
      </div>

      <form className="signup-invite-form" onSubmit={formik.handleSubmit}>
        <div className="signup-invite-password">
          <InputField
            id="firstName"
            label="First Name"
            type="text"
            placeholder="First Name"
            value={formik.values.firstName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <div className="signup-invite-validation-error-text">
            {formik.touched.firstName && formik.errors.firstName ? (
              <div>{formik.errors.firstName}</div>
            ) : null}
          </div>
          <InputField
            id="lastName"
            label="Last Name"
            type="text"
            placeholder="Last Name"
            value={formik.values.lastName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <div className="signup-invite-validation-error-text">
            {formik.touched.lastName && formik.errors.lastName ? (
              <div>{formik.errors.lastName}</div>
            ) : null}
          </div>
          <InputField
            id="password"
            label="Password"
            type="password"
            placeholder="Password"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <div className="signup-invite-validation-error-text">
            {formik.touched.password && formik.errors.password ? (
              <div>{formik.errors.password}</div>
            ) : null}
          </div>
          <div className="signup-invite-checkbox-remember-me">
            <Checkbox
              id="rememberMe"
              name="rememberMe"
              onChange={formik.handleChange}
              checked={formik.values.rememberMe}
            >
              Remember Me
            </Checkbox>
          </div>
        </div>

        <div className="signup-invite-buttons-container">
          <div className="signup-invite-button-submit">
            <Button
              id="buttonSubmit"
              type="submit"
              isLoading={signUpInviteRequestState === apiRequestState.LOADING}
              primary
              disabled={!formik.isValid}
            >
              Sign Up
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
}

const mapStateToProps = (state) => ({
  signUpInviteRequestState: state.auth.signUpInviteRequestState,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchSignUpInvite: (credentials) => dispatch(authSignupInvite(credentials)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignupInvite);
