import React, {
  useState, forwardRef, useImperativeHandle, useRef,
} from 'react';
import {
  DATE_OPTIONS, getDateString, getLocationString, get,
} from '../../../utility/utility';

import Select from '../../Select';
import CoreFormDualAction from '../../core/form/CoreFormDualAction';
import Checkbox from '../../core/input/CoreInputCheckbox';

const possibleColumns = [
  {
    id: 'eta',
    label: 'ETA',
    isShown: true,
    path: '',
  },
  {
    id: 'carrierName',
    label: 'Carrier Name',
    isShown: true,
    path: 'tracking.container.carrierName',
  },
  {
    id: 'vessel',
    label: 'Vessel',
    isShown: true,
    path: 'tracking.container.status.vessel',
  },
  {
    id: 'containerId',
    label: 'Container Id',
    isShown: true,
    path: 'tracking.container.containerId',
  },
  {
    id: 'purchaseOrder',
    label: 'PO',
    isShown: true,
    path: 'booking.purchaseOrder',
  },
  {
    id: 'shipper',
    label: 'Shipper',
    isShown: true,
    path: 'booking.shipper',
  },
  {
    id: 'productDescription',
    label: 'Product',
    isShown: true,
    path: 'booking.productDescription',
  },
  {
    id: 'origin',
    label: 'Origin',
    isShown: true,
    path: '',
  },
  {
    id: 'destination',
    label: 'Destination',
    isShown: true,
    path: '',
  },
];

const BookingFormCopy = forwardRef(({
  organization,
  dispatchNotification,
}, ref) => {
  const tableRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [bookings, setBookings] = useState([]);
  const [columns, setColumns] = useState(possibleColumns);

  useImperativeHandle(ref, () => ({
    open: (bookingSelection) => {
      setIsOpen(true);
      setBookings(bookingSelection);

      // Adding the custom detail fields as possible columns
      const detailFields = organization.bookingSettings.details;
      const detailsArray = Object.keys(detailFields).map((key) => ({
        id: detailFields[key].id,
        type: detailFields[key].type,
        label: detailFields[key].label,
        defaultValue: detailFields[key].defaultValue,
        options: detailFields[key].options || undefined,
      }));
      const details = detailsArray.map((detail) => ({
        id: detail.id, label: detail.label, isShown: false, path: `details.${detail.id}`,
      }));
      setColumns(possibleColumns.concat(details));
    },
    close: () => setIsOpen(false),
  }));

  const closeModal = () => {
    setIsOpen(false);
  };

  if (!isOpen) return null;

  const handleInputChange = (event) => {
    const { id, checked } = event.target;
    // map through the columns and update the isShown property for the matching column
    const updatedColumns = columns.map((column) => {
      if (column.id === id) {
        return { ...column, isShown: checked };
      }
      return column;
    });

    setColumns(updatedColumns);
  };

  const copyTableToClipboard = async () => {
    // Get the table's outerHTML
    let tableHtml = tableRef.current.outerHTML;

    // Concatenate the link
    const link = '<a href="https://www.govessel.ca" target="_blank" rel="noopener noreferrer">Generated by Vessel</a>';
    tableHtml += link;

    if (navigator.clipboard && window.isSecureContext) {
      try {
        await navigator.clipboard.write([
          new ClipboardItem({
            'text/html': new Blob([tableHtml], { type: 'text/html' }),
          }),
        ]);
        dispatchNotification('success', 'Table copied to clipboard');
      } catch (err) {
        dispatchNotification('error', 'Failed to copy to clipboard');
      }
    } else {
      // Clipboard API not available
    }
  };

  const table = () => (
    <table ref={tableRef} border="1" cellSpacing="0" cellPadding="0" width="75%" style={{ tableLayout: 'auto' }}>
      <thead>
        <tr style={{ backgroundColor: '#696969' }}>
          {columns.map((column) => (
            column.isShown
              ? (
                <th style={{ fontFamily: 'Arial', color: 'white', fontSize: '12px' }}>
                  {column.label}
                </th>
              )
              : null
          ))}
        </tr>
      </thead>
      <tbody>
        {bookings?.map((booking) => (
          <tr>
            {columns.map((column) => {
              let retValue = null;
              if (column.id === 'eta') {
                retValue = getDateString(get(booking, 'tracking.container.status.eta'), DATE_OPTIONS.INCLUDE_WEEK);
              } else if (column.id === 'origin') {
                retValue = getLocationString(
                  get(booking, 'tracking.container.status.origin.country'),
                  get(booking, 'tracking.container.status.origin.city'),
                );
              } else if (column.id === 'destination') {
                retValue = getLocationString(
                  get(booking, 'tracking.container.status.destination.country'),
                  get(booking, 'tracking.container.status.destination.city'),
                );
              } else {
                retValue = get(booking, column.path);
              }
              return column.isShown
                ? (
                  <td style={{ fontFamily: 'Arial', fontSize: '12px' }}>
                    {retValue}
                  </td>
                )
                : null;
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );

  return (
    <CoreFormDualAction
      title="Copy"
      primaryButtonCaption="Copy"
      primaryButtonCallback={copyTableToClipboard}
      secondaryButtonCaption="Close"
      secondaryButtonCallback={closeModal}
    >
      <div style={{ paddingBottom: '20px' }}>
        <Select id="copyFormat" options={['Table']}>Format</Select>
      </div>
      <label>Columns</label>
      {columns.map((column) => (
        <Checkbox
          style={{ paddingLeft: '15px' }}
          onChange={handleInputChange}
          id={column.id}
          checked={column.isShown}
        >
          {column.label}
        </Checkbox>
      ))}
      <div style={{ paddingTop: '15px' }}><label>Preview</label></div>
      {table()}
    </CoreFormDualAction>
  );
});

export default BookingFormCopy;
