// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bookingViewTitle {
  padding-bottom: 30px;
  padding-left: 50px;
}

.bookings-add-button {
  margin: 50px 50px 50px 50px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.7);
  border-radius: 10px;
  max-width: 400px;
}

.bookings-controls-container {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bookings-search-bar {
  display: flex;
  align-items: center;
  margin: 0px 0px 0px 50px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.7);
  background-color: var(--color-grey000);
  border-radius: 10px;
  max-width: 400px;
  flex-grow: 1;
}

.bookings-toggle-switch {
  margin: 0px 50px 0px 0px;
  padding: 10px;

}

/* Media query for mobile devices */
@media (max-width: 1200px) {
  .bookings-add-button {
    margin-top: 10px;
    margin-bottom: 30px;
    margin-right: 10px;
    margin-left: 10px;
  }

  .bookings-search-bar {
    margin-top: 10px;
    margin-bottom: 30px;
    margin-right: 10px;
    margin-left: 10px;
  }

  .bookingViewTitle {
    padding-left: 20px;
    padding-right: 20px;
  }
}`, "",{"version":3,"sources":["webpack://./src/containers/BookingPage.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,kBAAkB;AACpB;;AAEA;EACE,2BAA2B;EAC3B,yCAAyC;EACzC,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,SAAS;EACT,UAAU;EACV,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,wBAAwB;EACxB,yCAAyC;EACzC,sCAAsC;EACtC,mBAAmB;EACnB,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,wBAAwB;EACxB,aAAa;;AAEf;;AAEA,mCAAmC;AACnC;EACE;IACE,gBAAgB;IAChB,mBAAmB;IACnB,kBAAkB;IAClB,iBAAiB;EACnB;;EAEA;IACE,gBAAgB;IAChB,mBAAmB;IACnB,kBAAkB;IAClB,iBAAiB;EACnB;;EAEA;IACE,kBAAkB;IAClB,mBAAmB;EACrB;AACF","sourcesContent":[".bookingViewTitle {\n  padding-bottom: 30px;\n  padding-left: 50px;\n}\n\n.bookings-add-button {\n  margin: 50px 50px 50px 50px;\n  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.7);\n  border-radius: 10px;\n  max-width: 400px;\n}\n\n.bookings-controls-container {\n  margin: 0;\n  padding: 0;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.bookings-search-bar {\n  display: flex;\n  align-items: center;\n  margin: 0px 0px 0px 50px;\n  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.7);\n  background-color: var(--color-grey000);\n  border-radius: 10px;\n  max-width: 400px;\n  flex-grow: 1;\n}\n\n.bookings-toggle-switch {\n  margin: 0px 50px 0px 0px;\n  padding: 10px;\n\n}\n\n/* Media query for mobile devices */\n@media (max-width: 1200px) {\n  .bookings-add-button {\n    margin-top: 10px;\n    margin-bottom: 30px;\n    margin-right: 10px;\n    margin-left: 10px;\n  }\n\n  .bookings-search-bar {\n    margin-top: 10px;\n    margin-bottom: 30px;\n    margin-right: 10px;\n    margin-left: 10px;\n  }\n\n  .bookingViewTitle {\n    padding-left: 20px;\n    padding-right: 20px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
