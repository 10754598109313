import {
  NOTIFICATIONS_SHOW_STATUS,
  NOTIFICATIONS_CLEAR,
} from '../actions';
import { updateObject } from '../utility/utility';

const initialState = {
  severity: null,
  message: null,
  isShown: false,
};

const notificationsShow = (state, action) => (
  updateObject(state, {
    type: action.type,
    severity: action.severity,
    message: action.message,
    isShown: action.isShown,
  }));

// eslint-disable-next-line default-param-last
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case NOTIFICATIONS_SHOW_STATUS:
      return notificationsShow(state, action);
    case NOTIFICATIONS_CLEAR:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
