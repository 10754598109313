// TabView.js
import React, { useState } from 'react';
import './TabView.css';

function TabView({ tabs }) {
  const [activeTab, setActiveTab] = useState(tabs[0].name);

  return (
    <div className="tab-view-container">
      <div className="tabs-header">
        {tabs.map((tab) => (
          <button
            type="button"
            key={tab.name}
            className={`tabs-header-button ${activeTab === tab.name ? 'active' : ''}`}
            onClick={() => setActiveTab(tab.name)}
          >
            {tab.name}
          </button>
        ))}
      </div>
      <div className="tabs-content">
        {tabs.find((tab) => tab.name === activeTab).content}
      </div>
    </div>
  );
}

export default TabView;
