import React, {
  useState, useEffect, forwardRef, useImperativeHandle,
} from 'react';
import _ from 'lodash';
import { apiRequestState, get } from '../../../utility/utility';
import CoreFormDualAction from '../../core/form/CoreFormDualAction';

const InspectionFormSubmitReport = forwardRef(({
  inspectionsPutSubmitReportRequestState,
  dispatchInspectioSubmitReport,
}, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentInspection, setCurrentInspection] = useState([]);
  const [reportState, setReportState] = useState(null);
  const [error, setError] = useState(null);

  useImperativeHandle(ref, () => ({
    open: (inspection, report) => {
      setCurrentInspection(inspection);
      setReportState(report);
      setIsOpen(true);
      setError(null);
    },
    close: () => setIsOpen(false),
  }));

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (inspectionsPutSubmitReportRequestState === apiRequestState.SUCCESS) {
      closeModal();
    }
  }, [inspectionsPutSubmitReportRequestState]);

  const renderInspectionSummary = (inspection) => {
    const output = [];
    const applicantName = get(inspection, 'participants.applicant.name', false);
    const purchaseOrder = get(inspection, 'shipment.logistics.purchaseOrder', false);
    if (applicantName) {
      output.push(applicantName);
    }
    if (purchaseOrder) {
      output.push(`#${purchaseOrder}`);
    }
    return output.join(' ');
  };

  if (!isOpen) return null;

  return (
    <CoreFormDualAction
      title="Submit Inspection Report"
      primaryButtonCaption="Submit"
      primaryButtonCallback={() => dispatchInspectioSubmitReport(currentInspection, reportState)}
      primaryButtonCallbackStatus={inspectionsPutSubmitReportRequestState === apiRequestState.LOADING}
      primaryButtonDisabled={error || inspectionsPutSubmitReportRequestState === apiRequestState.LOADING}
      secondaryButtonCaption="Cancel"
      secondaryButtonCallback={closeModal}
    >
      <p>Selected inspection: </p>
      <div>
        <p style={{ paddingLeft: '10px', paddingBottom: '0px', marginBottom: '0px' }}>
          <strong>
            {_.startCase(get(currentInspection, 'request.service.title'))}
          </strong>
        </p>
        <p style={{ paddingLeft: '10px', paddingTop: '0px', margin: '0px' }}>
          {renderInspectionSummary(currentInspection)}
        </p>
        <p style={{ paddingLeft: '10px', margin: '0px' }}>
          {get(currentInspection, 'summary.commodity')}
        </p>
      </div>
    </CoreFormDualAction>
  );
});

export default InspectionFormSubmitReport;
