import React from 'react';
import _ from 'lodash';
import BubbleCard from '../../BubbleCard';
import {
  epochTimestampToDateString, get,
} from '../../../utility/utility';
import CoreCard from '../../core/content/CoreCard';
import Checkout from '../../StripeCheckout';

function OrganizationBubbleSubscription({
  organization,
  style,
  subscription,
}) {
  const { plan, paymentMethod, billingHistory } = subscription;

  const displayPlan = (inputPlan) => {
    if (!inputPlan) {
      return (
        <>
          <span style={{ color: 'var(--color-grey900)', fontWeight: '600' }}>Free trial</span>

          <div style={{ display: 'flex', justifyContent: 'flex-start', paddingTop: '10px' }}>
            <Checkout />
          </div>
        </>
      );
    }
    return (
      <>
        <span style={{ color: 'var(--color-grey900)', fontWeight: '600' }}>{get(plan, 'name')}</span>
        <span style={{ color: 'var(--color-grey900)' }}>{' automatically renews on '}</span>
        <span style={{ color: 'var(--color-grey900)', fontWeight: '600' }}>{epochTimestampToDateString(get(plan, 'current_period_end'))}</span>
      </>
    );
  };

  const displayPaymentMethod = (inputPaymentMethod) => {
    if (!inputPaymentMethod) return ('None');

    return (
      <>
        <span style={{ color: 'var(--color-grey900)', fontWeight: '600' }}>{_.startCase(get(inputPaymentMethod, 'brand'))}</span>
        <span style={{ color: 'var(--color-grey900)' }}>{' ending in '}</span>
        <span style={{ color: 'var(--color-grey900)', fontWeight: '600' }}>{get(inputPaymentMethod, 'last4')}</span>
        <span style={{ color: 'var(--color-grey900)' }}>{' expiring '}</span>
        <span style={{ color: 'var(--color-grey900)', fontWeight: '600' }}>{get(inputPaymentMethod, 'exp_month')}</span>
        <span style={{ color: 'var(--color-grey900)' }}>/</span>
        <span style={{ color: 'var(--color-grey900)', fontWeight: '600' }}>{get(inputPaymentMethod, 'exp_year')}</span>
      </>
    );
  };

  const displayBillingHistory = (inputBillingHistory) => {
    if (!inputBillingHistory) return ('None');

    return (
      <div>
        {inputBillingHistory?.map((billing) => (
          <div>
            <span style={{ color: 'var(--color-grey900)', paddingRight: '20px' }}>
              <a style={{ color: 'var(--color-grey900)' }} href={billing.invoice_pdf}>Invoice</a>
            </span>
            <span style={{ color: 'var(--color-grey900)' }}>{epochTimestampToDateString(billing.created)}</span>
          </div>
        ))}
      </div>
    );
  };

  return (
    <BubbleCard title="Subscription" style={style}>
      <CoreCard
        key={get(organization, '_id')}
        content={(
          <div>
            <div style={{ paddingLeft: '10px', paddingRight: '10px' }}>
              Current Plan
            </div>
            <div style={{ paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px' }}>
              {displayPlan(plan)}
            </div>
            {paymentMethod
              && (
                <div>
                  <div style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                    Payment Method
                  </div>
                  <div style={{ paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px' }}>
                    {displayPaymentMethod(paymentMethod)}
                  </div>
                </div>
              )}

            {billingHistory
              && (
                <div>
                  <div style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                    Billing History
                  </div>
                  <div style={{ paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px' }}>
                    {displayBillingHistory(billingHistory)}
                  </div>
                </div>
              )}
          </div>
        )}
      />
    </BubbleCard>
  );
}

export default OrganizationBubbleSubscription;
