import { React } from 'react';
import './ContainerTrackingSections.css';
import VerticalStepProgress from '../../VerticalStepProgress';
import { getTimeSince } from '../../../utility/utility';
import Section from './Section';

function ContainerTrackingSection({ container }) {
  if (container.status?.milestones?.length) {
    return (
      <Section title="Container Tracking">
        <VerticalStepProgress steps={container.status.milestones} />
        <p className="container-tracking-section-last-update">{`Last internal update: ${getTimeSince(container?.status?.lastPartnerUpdateAt)}`}</p>
      </Section>
    );
  }

  return null;
}
export default ContainerTrackingSection;
