import React from 'react';
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';
import Button from './core/input/Button';
import { API_BASE_URL, STRIPE_PUBLIC_KEY } from '../config';

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

function Checkout() {
  const handleClick = async () => {
    // Create a new Checkout Session in the backend
    const response = await axios.post(`${API_BASE_URL}/subscriptions`, {
      priceId: 'price_1NaPnOGirhJimORBDuDeLRxR', // replace with your priceId
    });

    const sessionId = response.data.id;
    const stripe = await stripePromise;

    // When the customer clicks on the button, redirect them to Stripe Checkout
    const result = await stripe.redirectToCheckout({ sessionId });

    if (result.error) {
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // console.error(result.error.message);
    }
  };

  return (
    <Button
      style={{ width: 'auto', paddingLeft: '10px', paddingRight: '10px' }}
      primary
      role="link"
      onClick={handleClick}
    >

      Subscribe to Commercial Monthly Plan
    </Button>
  );
}

export default Checkout;
