// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-gallery-image-container {
  display: flex;
  flex-wrap: wrap;
  margin: -2px;
}

.image-gallery-image-add-tile {
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: calc(25% - 4px);
  max-width: calc(25% - 4px);
  margin: 2px;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  padding-top: calc(25% - 4px);
}

@media (min-width: 1200px) {
  .image-gallery-image-add-tile {
    max-width: 200px;
    padding-top: 200px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/ImageGallery.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;EACf,YAAY;AACd;;AAEA;EACE,YAAyB;EAAzB,cAAyB;EAAzB,2BAAyB;EACzB,0BAA0B;EAC1B,WAAW;EACX,sBAAsB;EACtB,kBAAkB;EAClB,gBAAgB;EAChB,4BAA4B;AAC9B;;AAEA;EACE;IACE,gBAAgB;IAChB,kBAAkB;EACpB;AACF","sourcesContent":[".image-gallery-image-container {\n  display: flex;\n  flex-wrap: wrap;\n  margin: -2px;\n}\n\n.image-gallery-image-add-tile {\n  flex: 1 0 calc(25% - 4px);\n  max-width: calc(25% - 4px);\n  margin: 2px;\n  box-sizing: border-box;\n  position: relative;\n  overflow: hidden;\n  padding-top: calc(25% - 4px);\n}\n\n@media (min-width: 1200px) {\n  .image-gallery-image-add-tile {\n    max-width: 200px;\n    padding-top: 200px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
