import React, { useState } from 'react';
import './TableCollapsibleRow.css';

function TableCollapsibleRow({
  id,
  disabled,
  children,
  expandedContent,
  selectCheckboxIsEnabled,
  checked,
  selectCheckboxHandler,
  alarmColor = 'None',
  title,
}) {
  const [isRowExpanded, setIsRowExpanded] = useState(false);

  const toggleRow = (event) => {
    const clickedCellIndex = Array.from(event.currentTarget.children).indexOf(event.target.parentNode);
    const isCheckboxCell = clickedCellIndex === event.currentTarget.children.length - 2;
    if (!isCheckboxCell) {
      setIsRowExpanded((prevIsRowExpanded) => !prevIsRowExpanded);
    }
  };

  return (
    <>
      <tr title={title} className="table-collapsible-row-collapsed" onClick={disabled ? () => { } : toggleRow}>
        {children}
        {selectCheckboxIsEnabled
          ? (
            <>
              <td>
                <input
                  type="checkbox"
                  onClick={(event) => event.stopPropagation()}
                  onChange={() => selectCheckboxHandler(id)}
                  checked={checked}
                />
              </td>
              <td style={{ backgroundColor: alarmColor }} />
            </>
          )
          : (
            <>
              <td />
              <td />
            </>
          )}
      </tr>
      {isRowExpanded && (
        <tr>
          <td className="table-collapsible-row-expanded" colSpan={children.length + (2)}>
            {expandedContent}
          </td>
        </tr>
      )}
    </>
  );
}

export default TableCollapsibleRow;
