import React from 'react';
import Creatable from 'react-select/creatable';

// Important note about options formatting. They have to follow this format:
// { value, label }
// null insted of  { value, label } will display the placeholder value.

function InputFieldSelect({
  value,
  options,
  onChange,
  label,
  id,
  placeholder,
  createIsDisabled,
  style,
}) {
  const customComponents = {
    ClearIndicator: null,
  };

  // Function to determine if the new option is valid
  const isValidNewOption = (inputValue, selectOptions) => {
    // If creation is disabled or if the input value matches an existing option, do not show the create option
    const isExistingOption = selectOptions.some((option) => option.value === inputValue);
    return !createIsDisabled && !isExistingOption;
  };

  const customStyles = {
    // Style for the option labels
    option: (provided) => ({
      ...provided,
      ...style,
    }),
    // Add more custom styles if needed
  };

  return (
    <>
      {label && (
      <p className="input-field-select-action-label">
        {label}
      </p>
      )}
      <Creatable
        styles={customStyles}
        id={id}
        isClearable
        onChange={onChange}
        options={options}
        value={value}
        placeholder={placeholder}
        components={customComponents}
        isValidNewOption={isValidNewOption}
      />
    </>
  );
}

export default InputFieldSelect;
