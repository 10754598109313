// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* TabView.css */
.tab-view-container {
  padding-top: 75px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: var(--color-grey300)
}

.tabs-header {
  position: fixed;
  top: 100px;
  width: 100%;
  display: flex;
  height: 50px;
  justify-content: space-between;
  z-index: 10;
}

.tabs-header>* {
  flex-grow: 1;
  text-align: center;
}

.tabs-header::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  height: 30px;
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0), var(--color-grey700));
}

.tabs-header-button{
  cursor: pointer;
  padding: 10px;
  border: none;
  background-color: var(--color-grey000);
  color: var(--color-gre1100);
  transition: background-color 0.3s;
}

.tabs-header-button.active {
  background-color: var(--color-grey300)
}

.tabs-content {
  padding-left: 10px;
  padding-right: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/TabView.css"],"names":[],"mappings":"AAAA,gBAAgB;AAChB;EACE,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,WAAW;EACX,YAAY;EACZ;AACF;;AAEA;EACE,eAAe;EACf,UAAU;EACV,WAAW;EACX,aAAa;EACb,YAAY;EACZ,8BAA8B;EAC9B,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,SAAS;EACT,OAAO;EACP,QAAQ;EACR,YAAY;EACZ,iFAAiF;AACnF;;AAEA;EACE,eAAe;EACf,aAAa;EACb,YAAY;EACZ,sCAAsC;EACtC,2BAA2B;EAC3B,iCAAiC;AACnC;;AAEA;EACE;AACF;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;AACrB","sourcesContent":["/* TabView.css */\n.tab-view-container {\n  padding-top: 75px;\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  height: 100%;\n  background-color: var(--color-grey300)\n}\n\n.tabs-header {\n  position: fixed;\n  top: 100px;\n  width: 100%;\n  display: flex;\n  height: 50px;\n  justify-content: space-between;\n  z-index: 10;\n}\n\n.tabs-header>* {\n  flex-grow: 1;\n  text-align: center;\n}\n\n.tabs-header::after {\n  content: \"\";\n  position: absolute;\n  top: 100%;\n  left: 0;\n  right: 0;\n  height: 30px;\n  background-image: linear-gradient(to top, rgba(0, 0, 0, 0), var(--color-grey700));\n}\n\n.tabs-header-button{\n  cursor: pointer;\n  padding: 10px;\n  border: none;\n  background-color: var(--color-grey000);\n  color: var(--color-gre1100);\n  transition: background-color 0.3s;\n}\n\n.tabs-header-button.active {\n  background-color: var(--color-grey300)\n}\n\n.tabs-content {\n  padding-left: 10px;\n  padding-right: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
