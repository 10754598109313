// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboardView {
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    padding-top: 50px;
    background-color: var(--color-grey500);
    z-index: 40;
    height: 100%;
    overflow-y: scroll;
}

@media (max-width: 1200px) {
    .dashboardView {
        padding-top: 75px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,cAAc;IACd,MAAM;IACN,OAAO;IACP,WAAW;IACX,iBAAiB;IACjB,sCAAsC;IACtC,WAAW;IACX,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI;QACI,iBAAiB;IACrB;AACJ","sourcesContent":[".dashboardView {\n    position: fixed;\n    display: block;\n    top: 0;\n    left: 0;\n    width: 100%;\n    padding-top: 50px;\n    background-color: var(--color-grey500);\n    z-index: 40;\n    height: 100%;\n    overflow-y: scroll;\n}\n\n@media (max-width: 1200px) {\n    .dashboardView {\n        padding-top: 75px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
