const dbName = 'ImagesDatabase';
const dbVersion = 10;
const imageStoreName = 'images';
const configStoreName = 'config';
const CONFIG_ID = 'appConfig'; // A constant identifier for the single configuration object

// Function to generate a UUID (Version 4)
function generateUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    // eslint-disable-next-line
    const r = Math.random() * 16 | 0;
    // eslint-disable-next-line
    const v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

const openDatabase = () => new Promise((resolve, reject) => {
  const request = indexedDB.open(dbName, dbVersion);

  request.onupgradeneeded = (event) => {
    const db = event.target.result;
    // Create an object store for images
    if (!db.objectStoreNames.contains(imageStoreName)) {
      db.createObjectStore(imageStoreName, { keyPath: 'id', autoIncrement: false });
    }
    // Create a single-object store for the user configuration
    if (!db.objectStoreNames.contains(configStoreName)) {
      db.createObjectStore(configStoreName, { keyPath: 'configKey' });
    }
  };

  request.onerror = (event) => {
    // console.error(`Error opening database: ${event.target.errorCode}`);
    reject(new Error(`Database error: ${event.target.errorCode}`));
  };

  request.onsuccess = (event) => {
    const db = event.target.result;
    db.onerror = (newError) => {
      // console.error('Database operation error:', newError.target.error);
      reject(new Error(`Database operation error: ${newError.target.errorCode}`));
    };
    resolve(db);
  };

  request.onblocked = () => {
    // console.warn('Database opening blocked. Close other tabs with this site open.');
  };
});

const executeTransaction = async (storeNames, mode, callback) => {
  const db = await openDatabase();
  const transaction = db.transaction(storeNames, mode);
  const stores = storeNames.reduce((acc, name) => {
    acc[name] = transaction.objectStore(name);
    return acc;
  }, {});

  return new Promise((resolve, reject) => {
    transaction.onerror = (event) => {
      // console.error('Transaction error:', event.target.error);
      reject(new Error(`Transaction error: ${event.target.errorCode}`));
    };

    callback(stores, resolve, reject);
  });
};

const findById = async (id) => executeTransaction([imageStoreName], 'readonly', (stores, resolve, reject) => {
  const request = stores[imageStoreName].get(id);
  request.onsuccess = () => resolve(request.result);
  request.onerror = () => reject(new Error(`Error fetching ID ${id}: ${request.error}`));
});

const deleteById = async (id) => executeTransaction([imageStoreName], 'readwrite', (stores, resolve, reject) => {
  const request = stores[imageStoreName].delete(id);
  request.onsuccess = () => resolve(`Record with ID ${id} deleted.`);
  request.onerror = () => reject(new Error(`Error deleting ID ${id}: ${request.error}`));
});

const fetchElements = async (limit) => {
  const images = [];
  return executeTransaction([imageStoreName], 'readonly', (stores, resolve, reject) => {
    const cursorRequest = stores[imageStoreName].openCursor();
    cursorRequest.onsuccess = (event) => {
      const cursor = event.target.result;
      if (cursor && images.length < limit) {
        images.push(cursor.value);
        cursor.continue();
      } else {
        resolve(images);
      }
    };
    cursorRequest.onerror = () => reject(new Error(`Error fetching images: ${cursorRequest.error}`));
  });
};

const countImages = async () => executeTransaction([imageStoreName], 'readonly', (stores, resolve, reject) => {
  const request = stores[imageStoreName].count();
  request.onsuccess = () => resolve(request.result);
  request.onerror = () => reject(new Error(`Error counting images: ${request.error}`));
});

const flushImages = async () => executeTransaction([imageStoreName], 'readwrite', (stores, resolve, reject) => {
  const imageStoreRequest = stores[imageStoreName].clear();

  imageStoreRequest.onsuccess = () => resolve('All image records flushed successfully.');
  imageStoreRequest.onerror = () => reject(new Error(`Error clearing ${imageStoreName}: ${imageStoreRequest.error}`));
});

const storeObjects = async (objects) => {
  const storedObjects = [];

  try {
    for (const object of objects) {
      const id = generateUUID();

      // Prepare the new object for storage
      const newObject = {
        id,
        inspectionId: object.inspectionId,
        productId: object.productId,
        imageFile: object.imageFile, // Store Base64 string
      };

      // Open a new transaction for each record
      // eslint-disable-next-line
      const db = await openDatabase();
      const transaction = db.transaction([imageStoreName], 'readwrite');
      const store = transaction.objectStore(imageStoreName);

      // Add the object to the store
      const request = store.add(newObject);

      // eslint-disable-next-line
      await new Promise((resolve, reject) => {
        request.onsuccess = () => {
          storedObjects.push(newObject);
          resolve();
        };

        request.onerror = (event) => {
          // console.error(`Error sequentially storing object in "${imageStoreName}":`, event.target.error);
          reject(new Error(`Sequential store error: ${event.target.error}`));
        };
      });

      // Await transaction completion
      // eslint-disable-next-line
      await new Promise((resolve, reject) => {
        transaction.oncomplete = () => {
          resolve();
        };

        transaction.onerror = (event) => {
          // console.error(`Sequential transaction error in "${imageStoreName}":`, event.target.error);
          reject(new Error(`Sequential transaction error: ${event.target.error}`));
        };
      });
    }
  } catch (error) {
    // console.error('Error in sequential storeObjects:', error.message);
  }

  return storedObjects;
};

// Configuration-specific functions
const storeConfig = async (configObject) => executeTransaction([configStoreName], 'readwrite', (stores, resolve, reject) => {
  const request = stores[configStoreName].put({ configKey: CONFIG_ID, configValue: configObject });
  request.onsuccess = () => resolve({ configKey: CONFIG_ID, configValue: configObject });
  request.onerror = () => reject(new Error(`Error storing configuration: ${request.error}`));
});

const findConfig = async () => executeTransaction([configStoreName], 'readonly', (stores, resolve, reject) => {
  const request = stores[configStoreName].get(CONFIG_ID);
  request.onsuccess = () => resolve(request.result ? request.result.configValue : null);
  request.onerror = () => reject(new Error(`Error fetching configuration: ${request.error}`));
});

export {
  countImages,
  flushImages,
  storeObjects,
  deleteById,
  openDatabase,
  findById,
  fetchElements,
  storeConfig,
  findConfig,
};
