import React, {
  useState, useEffect, forwardRef, useImperativeHandle,
} from 'react';
import _ from 'lodash';
import { apiRequestState, get } from '../../../utility/utility';
import CoreFormDualAction from '../../core/form/CoreFormDualAction';
import InputField from '../../core/input/InputField';
import { validateForm } from '../../core/input/CoreInputUtils';

const fieldsToValidate = ['commodity', 'description'];
const OrganizationFormDefect = forwardRef(({
  dispatchOrganizationsPut,
  organizationPutRequestState,
}, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentDefects, setCurrentDefects] = useState([]);
  const [inputState, setInputState] = useState({});
  const [error, setError] = useState({});
  useImperativeHandle(ref, () => ({
    open: (organization, defect) => {
      const defects = get(organization, 'inspectionSettings.defectDefinitions');
      setCurrentDefects(_.cloneDeep(defects));
      if (defect) {
        setInputState(_.cloneDeep(defect));
      } else {
        setInputState({});
      }
      setIsOpen(true);
      setError({});
    },
    close: () => setIsOpen(false),
  }));

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (organizationPutRequestState === apiRequestState.SUCCESS) {
      closeModal();
    }
  }, [organizationPutRequestState]);

  if (!isOpen) return null;

  const onInputChange = (event) => {
    const newState = { ...inputState };
    newState[event.target.id] = event.target.value;
    setInputState(newState);
    validateForm({
      state: newState,
      fieldsToValidate,
      setError,
    });
  };

  const handleSave = () => {
    const validation = validateForm({
      state: inputState,
      fieldsToValidate,
      setError,
    });

    if (validation) {
      const defectIndex = currentDefects.findIndex(
        (element) => get(element, '_id').toString() === inputState._id,
      );

      if (defectIndex !== -1) {
        // Update existing defect
        dispatchOrganizationsPut({
          defectsUpdates: { defectsToUpdate: [inputState] },
        });
      } else {
        // Add new defect
        dispatchOrganizationsPut({
          defectsUpdates: { defectsToAdd: [inputState] },
        });
      }
    }
  };

  const renderInputField = (id, state, path = id) => (
    <InputField
      id={id}
      label={_.startCase(id)}
      placeholder={_.startCase(id)}
      value={get(state, path)}
      onChange={(event) => onInputChange(event, path)}
    />
  );

  return (
    <CoreFormDualAction
      title="Defect"
      primaryButtonCaption="Save"
      primaryButtonCallback={() => handleSave()}
      primaryButtonCallbackStatus={organizationPutRequestState === apiRequestState.LOADING}
      primaryButtonDisabled={Object.keys(error).length > 0 || organizationPutRequestState === apiRequestState.LOADING}
      secondaryButtonCaption="Cancel"
      secondaryButtonCallback={closeModal}
    >
      <div>
        {renderInputField('commodity', inputState)}
        {renderInputField('description', inputState)}
        {renderInputField('definition', inputState)}
      </div>
    </CoreFormDualAction>
  );
});

export default OrganizationFormDefect;
