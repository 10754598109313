import React, {
  useState, useEffect, forwardRef, useImperativeHandle,
} from 'react';
import isEmail from 'validator/lib/isEmail';
import { apiRequestState, get } from '../../../utility/utility';
import CoreFormDualAction from '../../core/form/CoreFormDualAction';
import Select from '../../Select';
import { INSPECTION_ROLES } from '../../../utility/InspectionsUtils';

const InspectionFormAssign = forwardRef(({ dispatchInspectionsAssign, inspectionsPutAssignRequestState }, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentInspections, setCurrentInspections] = useState([]);
  const [inputState, setInputState] = useState({});
  const [availableStaffState, setAvailableStaffState] = useState([]);

  const [error, setError] = useState(null);

  useImperativeHandle(ref, () => ({
    open: (inspections, availableStaff) => {
      setCurrentInspections(inspections);
      setAvailableStaffState(availableStaff);
      setIsOpen(true);
      setError(null);
      setInputState({ role: INSPECTION_ROLES.onsite });
    },
    close: () => setIsOpen(false),
  }));

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (inspectionsPutAssignRequestState === apiRequestState.SUCCESS) {
      closeModal();
    }
  }, [inspectionsPutAssignRequestState]);

  const validateForm = (state) => {
    if (!state?.email) {
      setError('Staff Email is required.');
      return false;
    } if (!isEmail(state?.email)) {
      setError('Invalid Email format');
      return false;
    }
    setError(null);
    return true;
  };

  const handleInputChange = (event) => {
    const newState = { ...inputState };
    newState[event.target.id] = event.target.value;
    setInputState(newState);
    validateForm(newState);
  };

  const handleAssign = () => {
    if (validateForm(inputState)) {
      const assignment = { ...inputState, inspectionIds: currentInspections.map((inspection) => inspection._id) };
      dispatchInspectionsAssign(assignment);
    }
  };

  const renderInspectionSummary = (inspection) => {
    const output = [];
    const applicantName = get(inspection, 'summary.applicantName', false);
    const purchaseOrder = get(inspection, 'summary.purchaseOrder', false);
    if (applicantName) {
      output.push(applicantName);
    }
    if (purchaseOrder) {
      output.push(`#${purchaseOrder}`);
    }
    return output.join(' ');
  };

  if (!isOpen) return null;

  return (
    <CoreFormDualAction
      title="Assign Inspection"
      primaryButtonCaption="Assign"
      primaryButtonCallback={handleAssign}
      primaryButtonCallbackStatus={inspectionsPutAssignRequestState === apiRequestState.LOADING}
      primaryButtonDisabled={error || inspectionsPutAssignRequestState === apiRequestState.LOADING}
      secondaryButtonCaption="Cancel"
      secondaryButtonCallback={closeModal}
    >
      <p>Selected inspections: </p>
      {currentInspections.map((inspection) => (
        <div key={get(inspection, '_id')}>
          <strong>
            <p style={{ paddingLeft: '10px', paddingTop: '10px', margin: '0px' }}>
              {get(inspection, 'summary.commodity')}
            </p>
          </strong>
          <p style={{ paddingLeft: '10px', paddingTop: '0px', margin: '0px' }}>
            {renderInspectionSummary(inspection)}
          </p>
        </div>

      ))}
      <Select
        id="email"
        options={[''].concat(availableStaffState.map((staff) => (staff.email)))}
        value={get(inputState, 'email')}
        onChange={handleInputChange}
      >
        Staff Email
      </Select>
      {error && <div style={{ color: 'var(--color-warning)' }}>{error}</div>}
      <Select
        id="role"
        options={[''].concat(Object.keys(INSPECTION_ROLES).map((role) => (role)))}
        value={get(inputState, 'role')}
        onChange={handleInputChange}
      >
        Role
      </Select>
    </CoreFormDualAction>
  );
});

export default InspectionFormAssign;
