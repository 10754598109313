import React from 'react';
import _ from 'lodash';
import { get } from '../../../utility/utility';
import CoreCard from '../../core/content/CoreCard';
import BubbleCard from '../../BubbleCard';

function InspectionBubbleLogistics({
  logistics,
  actions,
}) {
  const renderField = ({
    logistics: currentShipment,
    id,
    label = _.startCase(id),
    path = id,
  }) => (
    <div>
      {`${label}: ${get(currentShipment, path)}`}
    </div>
  );

  return (
    <BubbleCard
      title="Logistics"
      style={{ marginBottom: '10px' }}
    >
      <CoreCard
        content={(
          <div>
            <div style={{ paddingLeft: '10px', color: 'var(--color-grey900)' }}>
              {renderField({ logistics, id: 'commodity', label: 'Commodity Description' })}
              {renderField({ logistics, id: 'purchaseOrder' })}
              {renderField({ logistics, id: 'billOfLading' })}
              {renderField({ logistics, id: 'shipperReference' })}
            </div>
            <div style={{ paddingLeft: '10px', paddingTop: '10px', color: 'var(--color-grey900)' }}>
              {renderField({ logistics, id: 'carrierType' })}
              {renderField({ logistics, id: 'carrierName' })}
              {renderField({ logistics, id: 'carrierVehicle' })}
              {renderField({ logistics, id: 'carrierReference' })}
            </div>
            <div style={{ paddingLeft: '10px', paddingTop: '10px', color: 'var(--color-grey900)' }}>
              {renderField({ logistics, id: 'arrivalTimestamp.date', label: 'Arrival date' })}
              {renderField({ logistics, id: 'arrivalTimestamp.time', label: 'Arrival time' })}
            </div>
          </div>
        )}
        actions={actions}
      />
    </BubbleCard>
  );
}

export default InspectionBubbleLogistics;
