// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.core-card-container {
    background-color: var(--color-grey000);
}

.core-card-hoverable:hover {
    background-color: var(--color-grey050);
}

.core-card-header {
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    align-items: center;
    background-color: None;
}

.core-card-header-content-wrapper {
    display: flex;
    align-items: center;
    flex-grow: 1;
    justify-content: space-between;
    min-width: 100px;
    /* This will separate content and the arrow */
}

.core-card-header-arrow {
    margin-left: auto;
    /* This will push the arrow to the right */
    padding-right: 20px;
    cursor: pointer;
}

.core-card-action-menu {
    flex-shrink: 0;
}

.core-card-expanded-content {
    background-color: var(--color-grey100);
}`, "",{"version":3,"sources":["webpack://./src/components/core/content/CoreCard.css"],"names":[],"mappings":"AAAA;IACI,sCAAsC;AAC1C;;AAEA;IACI,sCAAsC;AAC1C;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,aAAa;IACb,mBAAmB;IACnB,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,YAAY;IACZ,8BAA8B;IAC9B,gBAAgB;IAChB,6CAA6C;AACjD;;AAEA;IACI,iBAAiB;IACjB,0CAA0C;IAC1C,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,sCAAsC;AAC1C","sourcesContent":[".core-card-container {\n    background-color: var(--color-grey000);\n}\n\n.core-card-hoverable:hover {\n    background-color: var(--color-grey050);\n}\n\n.core-card-header {\n    padding-left: 10px;\n    padding-right: 10px;\n    display: flex;\n    align-items: center;\n    background-color: None;\n}\n\n.core-card-header-content-wrapper {\n    display: flex;\n    align-items: center;\n    flex-grow: 1;\n    justify-content: space-between;\n    min-width: 100px;\n    /* This will separate content and the arrow */\n}\n\n.core-card-header-arrow {\n    margin-left: auto;\n    /* This will push the arrow to the right */\n    padding-right: 20px;\n    cursor: pointer;\n}\n\n.core-card-action-menu {\n    flex-shrink: 0;\n}\n\n.core-card-expanded-content {\n    background-color: var(--color-grey100);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
