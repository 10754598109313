import {
  ORGANIZATION_GET_REQUEST_START,
  ORGANIZATION_GET_REQUEST_FAIL,
  ORGANIZATION_GET_REQUEST_SUCCESS,
  ORGANIZATION_GET_REQUEST_IDLE,
  ORGANIZATION_GET_INSPECTORS_REQUEST_START,
  ORGANIZATION_GET_INSPECTORS_REQUEST_FAIL,
  ORGANIZATION_GET_INSPECTORS_REQUEST_SUCCESS,
  ORGANIZATION_PUT_REQUEST_START,
  ORGANIZATION_PUT_REQUEST_SUCCESS,
  ORGANIZATION_PUT_REQUEST_FAIL,
  ORGANIZATION_PUT_REQUEST_IDLE,
} from '../actions';
import { apiRequestState, updateObject } from '../utility/utility';

const initialState = {
  organizationGetRequestState: apiRequestState.IDLE,
  organizationGetInspectorsRequestState: apiRequestState.IDLE,
  organizationPutRequestState: apiRequestState.IDLE,
  inspectors: [],
};

const organizationGetStart = (state, action) => updateObject(state, {
  organizationGetRequestState: action.requestState,
});

const organizationGetFail = (state, action) => updateObject(state, {
  organizationGetRequestState: action.requestState,
});

const organizationGetIdle = (state, action) => updateObject(state, {
  organizationGetRequestState: action.requestState,
});

const organizationGetSuccess = (state, action) => (
  updateObject(state, {
    organization: action.data,
    organizationGetRequestState: action.requestState,
  }));

const organizationGetInspectorsStart = (state, action) => updateObject(state, {
  organizationGetInspectorsRequestState: action.requestState,
});

const organizationGetInspectorsFail = (state, action) => updateObject(state, {
  organizationGetInspectorsRequestState: action.requestState,
});

const organizationGetInspectorsSuccess = (state, action) => (
  updateObject(state, {
    inspectors: action.data,
    organizationGetInspectorsRequestState: action.requestState,
  }));

const organizationsPutStart = (state, action) => updateObject(state, {
  organizationPutRequestState: action.requestState,
});

const organizationsPutFail = (state, action) => updateObject(state, {
  organizationPutRequestState: action.requestState,
});

const organizationsPutIdle = (state, action) => updateObject(state, {
  organizationPutRequestState: action.requestState,
});

const organizationsPutSuccess = (state, action) => (updateObject(state, {
  organizationPutRequestState: action.requestState,
  organization: action.data,
}));

// eslint-disable-next-line default-param-last
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ORGANIZATION_GET_REQUEST_START:
      return organizationGetStart(state, action);
    case ORGANIZATION_GET_REQUEST_FAIL:
      return organizationGetFail(state, action);
    case ORGANIZATION_GET_REQUEST_SUCCESS:
      return organizationGetSuccess(state, action);
    case ORGANIZATION_GET_REQUEST_IDLE:
      return organizationGetIdle(state, action);
    case ORGANIZATION_GET_INSPECTORS_REQUEST_START:
      return organizationGetInspectorsStart(state, action);
    case ORGANIZATION_GET_INSPECTORS_REQUEST_FAIL:
      return organizationGetInspectorsFail(state, action);
    case ORGANIZATION_GET_INSPECTORS_REQUEST_SUCCESS:
      return organizationGetInspectorsSuccess(state, action);
    case ORGANIZATION_PUT_REQUEST_START:
      return organizationsPutStart(state, action);
    case ORGANIZATION_PUT_REQUEST_FAIL:
      return organizationsPutFail(state, action);
    case ORGANIZATION_PUT_REQUEST_SUCCESS:
      return organizationsPutSuccess(state, action);
    case ORGANIZATION_PUT_REQUEST_IDLE:
      return organizationsPutIdle(state, action);
    default:
      return state;
  }
};

export default reducer;
