import { combineReducers } from 'redux';
import { routerReducer as routing } from 'react-router-redux';
import auth from './auth';
import carriers from './carriers';
import bookings from './bookingsReducer';
import emails from './emails';
import notifications from './notifications';
import organizations from './organizationsReducer';
import reports from './reports';
import subscriptions from './subscriptions';
import users from './users';
import inspections from './inspectionsReducer';

const appReducer = combineReducers({
  auth,
  carriers,
  bookings,
  emails,
  notifications,
  organizations,
  reports,
  subscriptions,
  users,
  inspections,
  routing,
});

const initialState = appReducer({}, {});

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    // Reset state to initial state on logout
    return initialState;
  }
  return appReducer(state, action);
};

export default rootReducer;
