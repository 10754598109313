import React, { useState } from 'react';
import { FaLink, FaTrash } from 'react-icons/fa';
import BubbleCard from '../../BubbleCard';
import { INSPECTION_IMAGE_TYPES, renderProductDescription } from '../../../utility/InspectionsUtils';
import { get, uuid } from '../../../utility/utility';
import InspectionImageGallery from '../../InspectionImageGallery';
import Button from '../../core/input/Button';
import { IMAGE_GALLERY_MODES } from '../../ImageGallery';

function InspectionBubbleProductImages({
  inspection,
  handleFileDrop,
  selectedImagesIdsState,
  setSelectedImagesIdsState,
  toggleSelectImage,
  toggleSelectAll,
  handleImagesDelete,
  handleImagesAssociate,
  dispatchInspectionsPut,
}) {
  const [mode, setMode] = useState(IMAGE_GALLERY_MODES.display);

  const imageActions = [
    {
      _id: uuid(),
      icon: <FaTrash className="inspections-onsite-image-gallery-action" />,
      onClick: handleImagesDelete,
    },
    {
      _id: uuid(),
      icon: <FaLink className="inspections-onsite-image-gallery-action" />,
      onClick: handleImagesAssociate,
    },
  ];

  const renderImageActions = (selectedType, selectedIds, actions) => {
    if (selectedIds.length) {
      return actions.map((action) => (
        <div
          key={action._id}
          onClick={() => action.onClick(selectedType, selectedIds)}
        >
          {action.icon}
        </div>
      ));
    }
    return null;
  };

  const renderImageGalleries = (currentInspection) => {
    const products = get(currentInspection, 'shipment.products', []);
    const images = get(currentInspection, `onsite.${INSPECTION_IMAGE_TYPES.productImages}`, []);

    return products.map((product) => (
      <div key={product._id}>
        <div style={{
          display: 'flex', alignItems: 'center', paddingLeft: '20px', paddingRight: '20px', color: 'var(--color-grey900)',
        }}
        >
          <div style={{ flex: 1 }}>{renderProductDescription(product).all}</div>
          {
            mode === IMAGE_GALLERY_MODES.select && (
            <Button
              simple
              style={{ width: 'auto' }} // Override the default width
              onClick={() => toggleSelectAll(images.filter((image) => image.productId === product._id))}
            >
              {images.filter((image) => image.productId === product._id).every((image) => selectedImagesIdsState.includes(image._id)) ? 'Deselect All' : 'Select All'}
            </Button>
            )
          }
        </div>

        <div style={{
          paddingTop: '5px', paddingLeft: '20px', paddingRight: '20px', paddingBottom: '20px',
        }}
        >
          <InspectionImageGallery
            title={renderProductDescription(product).all}
            images={images}
            handleNewImage={(files) => handleFileDrop(files, currentInspection, INSPECTION_IMAGE_TYPES.productImages, product._id)}
            productId={product._id}
            selectedImagesIdsState={selectedImagesIdsState}
            setSelectedImagesIdsState={setSelectedImagesIdsState}
            toggleSelectImage={toggleSelectImage}
            toggleSelectAll={toggleSelectAll}
            isSelectable
            uploaderIsEnabled
            inspection={currentInspection}
            dispatchInspectionsPut={dispatchInspectionsPut}
            actionMode={mode}
          />
        </div>
      </div>
    ));
  };

  return (
    <BubbleCard
      title="Inspection Images"
      style={{ marginBottom: '10px' }}
    >
      <div style={{
        display: 'flex', justifyContent: 'flex-end', paddingRight: '20px', gap: '15px', marginBottom: '10px',
      }}
      >
        {mode === IMAGE_GALLERY_MODES.display ? (
          <>
            <Button simple style={{ width: 'auto' }} onClick={() => setMode(IMAGE_GALLERY_MODES.select)}>Select</Button>
            <Button simple style={{ width: 'auto' }} onClick={() => setMode(IMAGE_GALLERY_MODES.rearrange)}>Rearrange</Button>
          </>
        ) : (
          <Button simple style={{ width: 'auto' }} onClick={() => setMode(IMAGE_GALLERY_MODES.display)}>Done</Button>
        )}
      </div>

      <div className="inspections-onsite-image-gallery-actions-container">
        {renderImageActions(INSPECTION_IMAGE_TYPES.productImages, selectedImagesIdsState, imageActions)}
      </div>
      {renderImageGalleries(inspection, dispatchInspectionsPut)}
      <div style={{
        display: 'flex', alignItems: 'center', paddingLeft: '20px', paddingRight: '20px', color: 'var(--color-grey900)',
      }}
      >

        {!!get(inspection, 'shipment.products', []).length && (
          <div style={{ flex: 1 }}>
            <div style={{ color: 'var(--color-grey900)' }}>
              To be categorized
            </div>
          </div>
        )}
        {mode === IMAGE_GALLERY_MODES.select
          && (
          <Button
            simple
            style={{ width: 'auto' }} // Override the default width
            onClick={() => toggleSelectAll(get(inspection, `onsite.${INSPECTION_IMAGE_TYPES.productImages}`, []).filter((image) => image.productId === null))}
          >
            {get(inspection, `onsite.${INSPECTION_IMAGE_TYPES.productImages}`, []).filter((image) => image.productId === null).every((image) => selectedImagesIdsState.includes(image._id)) ? 'Deselect All' : 'Select All'}
          </Button>
          )}
      </div>
      <div style={{
        paddingTop: '5px', paddingLeft: '20px', paddingRight: '20px', paddingBottom: '20px',
      }}
      >
        <InspectionImageGallery
          images={get(inspection, `onsite.${INSPECTION_IMAGE_TYPES.productImages}`, [])}
          handleNewImage={(files) => handleFileDrop(files, inspection, INSPECTION_IMAGE_TYPES.productImages)}
          selectedImagesIdsState={selectedImagesIdsState}
          toggleSelectImage={toggleSelectImage}
          productId={null}
          setSelectedImagesIdsState={setSelectedImagesIdsState}
          isSelectable
          uploaderIsEnabled
          inspection={inspection}
          dispatchInspectionsPut={dispatchInspectionsPut}
          actionMode={mode}
        />
      </div>
    </BubbleCard>
  );
}

export default InspectionBubbleProductImages;
