// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.textarea {
    background-color: var(--color-grey000);
    border-radius: 5px;
    width: 100%;
    height: auto;
    opacity: 100%;
    padding-top: 15px;
    padding-left: 15px;
    padding-bottom: 15px;
    margin-bottom:15px;
    box-sizing: border-box;
    border: none;
}

.textarea:hover,
.textarea:focus {
    outline: 3px solid var(--color-primaryLight);
    outline-offset: -3px; /* Adjusts the outline to cover the border area */
}

.label {
    color: var(--color-grey000);
    margin-bottom: 2px;
    text-indent: 0px;
}
`, "",{"version":3,"sources":["webpack://./src/components/core/input/TextArea.css"],"names":[],"mappings":"AAAA;IACI,sCAAsC;IACtC,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,aAAa;IACb,iBAAiB;IACjB,kBAAkB;IAClB,oBAAoB;IACpB,kBAAkB;IAClB,sBAAsB;IACtB,YAAY;AAChB;;AAEA;;IAEI,4CAA4C;IAC5C,oBAAoB,EAAE,iDAAiD;AAC3E;;AAEA;IACI,2BAA2B;IAC3B,kBAAkB;IAClB,gBAAgB;AACpB","sourcesContent":[".textarea {\n    background-color: var(--color-grey000);\n    border-radius: 5px;\n    width: 100%;\n    height: auto;\n    opacity: 100%;\n    padding-top: 15px;\n    padding-left: 15px;\n    padding-bottom: 15px;\n    margin-bottom:15px;\n    box-sizing: border-box;\n    border: none;\n}\n\n.textarea:hover,\n.textarea:focus {\n    outline: 3px solid var(--color-primaryLight);\n    outline-offset: -3px; /* Adjusts the outline to cover the border area */\n}\n\n.label {\n    color: var(--color-grey000);\n    margin-bottom: 2px;\n    text-indent: 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
