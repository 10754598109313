import React from 'react';
import { connect } from 'react-redux';
import './SignIn.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';

import Typography from '../components/Typography';
import { signIn } from '../actions';
import { apiRequestState } from '../utility/utility';
import Button from '../components/core/input/Button';
import Checkbox from '../components/core/input/CoreInputCheckbox';
import InputField from '../components/core/input/InputField';

function SignIn({
  dispatchLogin,
  signInRequestState,
}) {
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      rememberMe: true,
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email address').required('Email is required'),
      password: Yup.string().required('Password is required'),
      rememberMe: Yup.bool(),
    }),
    initialErrors: {
      email: 'Email is required',
      password: 'Password is required',
      rememberMe: true,
    },
    onSubmit: (values) => {
      const editedValues = { ...values };
      // Make sure the email is submitted in lowercase
      editedValues.email = values.email.toLocaleLowerCase();
      dispatchLogin(editedValues);
    },
  });

  return (
    <div className="sign-in-container">
      <div className="sign-in-title">
        <Typography text="Sign In" />
      </div>

      <form className="sign-in-form" onSubmit={formik.handleSubmit}>
        <div className="sign-in-email">
          <InputField
            id="email"
            label="Email"
            type="email"
            placeholder="Email"
            // isRequired={true}
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <div className="sign-in-validation-error-text">
            {formik.touched.email && formik.errors.email ? (
              <div>{formik.errors.email}</div>
            ) : null}
          </div>
        </div>
        <div className="sign-in-password">
          <InputField
            id="password"
            label="Password"
            type="password"
            placeholder="Password"
            // isRequired={true}
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <div className="sign-in-validation-error-text">
            {formik.touched.password && formik.errors.password ? (
              <div>{formik.errors.password}</div>
            ) : null}
          </div>
          <div className="sign-in-forgot-password">
            <Link style={{ color: 'inherit', textDecoration: 'none' }} to="/forgot-password">Forgot password?</Link>
          </div>
          <div className="sign-in-checkbox-remember-me">
            <Checkbox
              id="rememberMe"
              name="rememberMe"
              onChange={formik.handleChange}
              checked={formik.values.rememberMe}
            >
              Remember Me
            </Checkbox>
          </div>
        </div>

        <div className="sign-in-buttons-container">
          <div className="sign-in-button-submit">
            <Button
              id="buttonSubmit"
              type="submit"
              isLoading={signInRequestState === apiRequestState.LOADING}
              primary
              disabled={!formik.isValid}
            >
              Sign In
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
}

const mapStateToProps = (state) => ({
  signInRequestState: state.users.signInRequestState,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchLogin: (signInData, rememberMe) => dispatch(signIn(signInData, rememberMe)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
