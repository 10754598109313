import React from 'react'; // You should import React directly, not as a named import
import './Header.css';

function Header(props) {
  const showMenuButton = () => {
    if (props.showMenuButton) {
      return (
        <svg className="menu" onClick={props.onMenuClick} width="40px" height="50px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path d="M5 8H13.75M5 12H19M10.25 16L19 16" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      );
    }
    return null; // Return null when props.showMenuButton is falsy
  };
  return (
    <header className="header">
      <h1 className="logo" translate="no">{props.brandName}</h1>
      {showMenuButton()}
    </header>
  );
}

export default Header;
