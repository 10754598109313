import axios from 'axios';
import { StatusCodes } from 'http-status-codes';
import { apiRequestState, createRequestAction } from '../utility/utility';
import { API_BASE_URL } from '../config';
import { notificationsShow } from './notifications';

export const BOOKINGS_GET_DOCUMENT_REQUEST_START = 'BOOKINGS_GET_DOCUMENT_REQUEST_START';
export const BOOKINGS_GET_DOCUMENT_REQUEST_SUCCESS = 'BOOKINGS_GET_DOCUMENT_REQUEST_SUCCESS';
export const BOOKINGS_GET_DOCUMENT_REQUEST_FAIL = 'BOOKINGS_GET_DOCUMENT_REQUEST_FAIL';
export const BOOKINGS_GET_REQUEST_START = 'BOOKINGS_GET_REQUEST_START';
export const BOOKINGS_GET_REQUEST_SUCCESS = 'BOOKINGS_GET_REQUEST_SUCCESS';
export const BOOKINGS_GET_REQUEST_FAIL = 'BOOKINGS_GET_REQUEST_FAIL';
export const BOOKINGS_GET_REQUEST_IDLE = 'BOOKINGS_GET_REQUEST_IDLE';
export const BOOKINGS_GET_SHARED_REQUEST_START = 'BOOKINGS_GET_SHARED_REQUEST_START';
export const BOOKINGS_GET_SHARED_REQUEST_SUCCESS = 'BOOKINGS_GET_SHARED_REQUEST_SUCCESS';
export const BOOKINGS_GET_SHARED_REQUEST_FAIL = 'BOOKINGS_GET_SHARED_REQUEST_FAIL';
export const BOOKINGS_GET_SHARED_REQUEST_IDLE = 'BOOKINGS_GET_SHARED_REQUEST_IDLE';
export const BOOKINGS_POST_REQUEST_START = 'BOOKINGS_POST_REQUEST_START';
export const BOOKINGS_POST_REQUEST_SUCCESS = 'BOOKINGS_POST_REQUEST_SUCCESS';
export const BOOKINGS_POST_REQUEST_FAIL = 'BOOKINGS_POST_REQUEST_FAIL';
export const BOOKINGS_POST_REQUEST_IDLE = 'BOOKINGS_POST_REQUEST_IDLE';
export const BOOKINGS_POST_INSPECTION_REQUEST_START = 'BOOKINGS_POST_INSPECTION_REQUEST_START';
export const BOOKINGS_POST_INSPECTION_REQUEST_SUCCESS = 'BOOKINGS_POST_INSPECTION_REQUEST_SUCCESS';
export const BOOKINGS_POST_INSPECTION_REQUEST_FAIL = 'BOOKINGS_POST_INSPECTION_REQUEST_FAIL';
export const BOOKINGS_POST_INSPECTION_REQUEST_IDLE = 'BOOKINGS_POST_INSPECTION_REQUEST_IDLE';
export const BOOKINGS_PUT_REQUEST_START = 'BOOKINGS_PUT_REQUEST_START';
export const BOOKINGS_PUT_REQUEST_SUCCESS = 'BOOKINGS_PUT_REQUEST_SUCCESS';
export const BOOKINGS_PUT_REQUEST_FAIL = 'BOOKINGS_PUT_REQUEST_FAIL';
export const BOOKINGS_PUT_REQUEST_IDLE = 'BOOKINGS_PUT_REQUEST_IDLE';
export const BOOKINGS_PUT_DEACTIVATE_REQUEST_START = 'BOOKINGS_PUT_DEACTIVATE_REQUEST_START';
export const BOOKINGS_PUT_DEACTIVATE_REQUEST_SUCCESS = 'BOOKINGS_PUT_DEACTIVATE_REQUEST_SUCCESS';
export const BOOKINGS_PUT_DEACTIVATE_REQUEST_FAIL = 'BOOKINGS_PUT_DEACTIVATE_REQUEST_FAIL';
export const BOOKINGS_PUT_DEACTIVATE_REQUEST_IDLE = 'BOOKINGS_PUT_DEACTIVATE_REQUEST_IDLE';
export const BOOKINGS_PUT_SHARE_REQUEST_START = 'BOOKINGS_PUT_SHARE_REQUEST_START';
export const BOOKINGS_PUT_SHARE_REQUEST_SUCCESS = 'BOOKINGS_PUT_SHARE_REQUEST_SUCCESS';
export const BOOKINGS_PUT_SHARE_REQUEST_FAIL = 'BOOKINGS_PUT_SHARE_REQUEST_FAIL';
export const BOOKINGS_PUT_SHARE_REQUEST_IDLE = 'BOOKINGS_PUT_SHARE_REQUEST_IDLE';
export const BOOKINGS_DOCUMENT_PATCH_REQUEST_START = 'BOOKINGS_DOCUMENT_PATCH_REQUEST_START';
export const BOOKINGS_DOCUMENT_PATCH_REQUEST_SUCCESS = 'BOOKINGS_DOCUMENT_PATCH_REQUEST_SUCCESS';
export const BOOKINGS_DOCUMENT_PATCH_REQUEST_FAIL = 'BOOKINGS_DOCUMENT_PATCH_REQUEST_FAIL';
export const BOOKINGS_DOCUMENT_PATCH_REQUEST_IDLE = 'BOOKINGS_DOCUMENT_PATCH_REQUEST_IDLE';
export const BOOKINGS_DOCUMENT_DELETE_REQUEST_START = 'BOOKINGS_DOCUMENT_DELETE_REQUEST_START';
export const BOOKINGS_DOCUMENT_DELETE_REQUEST_SUCCESS = 'BOOKINGS_DOCUMENT_DELETE_REQUEST_SUCCESS';
export const BOOKINGS_DOCUMENT_DELETE_REQUEST_FAIL = 'BOOKINGS_DOCUMENT_DELETE_REQUEST_FAIL';
export const BOOKINGS_DOCUMENT_DELETE_REQUEST_IDLE = 'BOOKINGS_DOCUMENT_DELETE_REQUEST_IDLE';
export const BOOKINGS_REFRESH_PATCH_REQUEST_START = 'BOOKINGS_REFRESH_PATCH_REQUEST_START';
export const BOOKINGS_REFRESH_PATCH_REQUEST_SUCCESS = 'BOOKINGS_REFRESH_PATCH_REQUEST_SUCCESS';
export const BOOKINGS_REFRESH_PATCH_REQUEST_FAIL = 'BOOKINGS_REFRESH_PATCH_REQUEST_FAIL';
export const BOOKINGS_REFRESH_PATCH_REQUEST_IDLE = 'BOOKINGS_REFRESH_PATCH_REQUEST_IDLE';
export const BOOKINGS_DELETE_REQUEST_START = 'BOOKINGS_DELETE_REQUEST_START';
export const BOOKINGS_DELETE_REQUEST_SUCCESS = 'BOOKINGS_DELETE_REQUEST_SUCCESS';
export const BOOKINGS_DELETE_REQUEST_FAIL = 'BOOKINGS_DELETE_REQUEST_FAIL';
export const BOOKINGS_DELETE_REQUEST_IDLE = 'BOOKINGS_DELETE_REQUEST_IDLE';

export const bookingsGetDocumentStart = () => ({
  type: BOOKINGS_GET_DOCUMENT_REQUEST_START,
  bookingsGetDocumentRequestState: apiRequestState.LOADING,
});

export const bookingsGetDocumentSuccess = (booking, documentType, data) => {
  const downloadUrl = window.URL.createObjectURL(data);

  // Create a new window or tab to display the PDF
  window.open(downloadUrl, '_blank');
  window.URL.revokeObjectURL(downloadUrl);

  return {
    type: BOOKINGS_GET_DOCUMENT_REQUEST_SUCCESS,
    bookingsGetDocumentRequestState: apiRequestState.SUCCESS,
  };
};

export const bookingsGetDocumentFail = () => ({
  type: BOOKINGS_GET_DOCUMENT_REQUEST_FAIL,
  bookingsGetDocumentRequestState: apiRequestState.FAIL,
});

export const bookingsGetDocument = (booking, documentType) => async (dispatch) => {
  dispatch(bookingsGetDocumentStart());

  try {
    const response = await axios.get(`${API_BASE_URL}/bookings/${booking._id}/${documentType}`, {
      responseType: 'blob',
    });

    await dispatch(bookingsGetDocumentSuccess(booking, documentType, response.data));
  } catch (err) {
    dispatch(bookingsGetDocumentFail());
    dispatch(notificationsShow('error', `Failed to retrieve ${documentType}`));
  }
};

export const bookingsGetStart = () => ({
  type: BOOKINGS_GET_REQUEST_START,
  requestState: apiRequestState.LOADING,
});

export const bookingsGetSuccess = (bookings) => ({
  type: BOOKINGS_GET_REQUEST_SUCCESS,
  bookings,
  requestState: apiRequestState.SUCCESS,
});

export const bookingsGetFail = () => ({
  type: BOOKINGS_GET_REQUEST_FAIL,
  requestState: apiRequestState.FAIL,
});

export const bookingsGetIdle = () => ({
  type: BOOKINGS_GET_REQUEST_IDLE,
  requestState: apiRequestState.IDLE,
});

export const bookingsGet = () => (dispatch) => {
  dispatch(bookingsGetStart());

  const bookingsGetRequest = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/bookings`);
      await dispatch(bookingsGetSuccess(response.data));
      await dispatch(bookingsGetIdle());
    } catch (err) {
      dispatch(bookingsGetFail());
      dispatch(notificationsShow('error', 'Failed to retrieve active bookings'));
    }
  };

  bookingsGetRequest();
};

export const bookingsGetSharedStart = () => ({
  type: BOOKINGS_GET_SHARED_REQUEST_START,
  requestState: apiRequestState.LOADING,
});

export const bookingsGetSharedSuccess = (sharedBookings) => ({
  type: BOOKINGS_GET_SHARED_REQUEST_SUCCESS,
  sharedBookings,
  requestState: apiRequestState.SUCCESS,
});

export const bookingsGetSharedFail = () => ({
  type: BOOKINGS_GET_SHARED_REQUEST_FAIL,
  requestState: apiRequestState.FAIL,
});

export const bookingsGetSharedIdle = () => ({
  type: BOOKINGS_GET_SHARED_REQUEST_IDLE,
  requestState: apiRequestState.IDLE,
});

export const bookingsGetShared = () => (dispatch) => {
  dispatch(bookingsGetSharedStart());

  const bookingsGetSharedRequest = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/bookings/shared`);
      await dispatch(bookingsGetSharedSuccess(response.data));
      await dispatch(bookingsGetSharedIdle());
    } catch (err) {
      dispatch(bookingsGetSharedFail());
      dispatch(notificationsShow('error', 'Failed to retrieve shared bookings'));
    }
  };

  bookingsGetSharedRequest();
};

export const bookingsPostStart = () => createRequestAction(BOOKINGS_POST_REQUEST_START, apiRequestState.LOADING);
export const bookingsPostSuccess = (booking) => createRequestAction(BOOKINGS_POST_REQUEST_SUCCESS, apiRequestState.SUCCESS, booking);
export const bookingsPostFail = () => createRequestAction(BOOKINGS_POST_REQUEST_FAIL, apiRequestState.FAIL);
export const bookingsPostIdle = () => createRequestAction(BOOKINGS_POST_REQUEST_IDLE, apiRequestState.IDLE);

export const bookingsPost = (booking) => (dispatch) => {
  dispatch(bookingsPostStart());
  const bookingsPostRequest = async () => {
    try {
      const response = await axios.post(`${API_BASE_URL}/bookings`, booking);

      await dispatch(bookingsPostSuccess(response.data));
      await dispatch(bookingsPostIdle());
      dispatch(notificationsShow('success', 'Successfully created booking'));
    } catch (err) {
      dispatch(bookingsPostFail());
      if (err.response.status === StatusCodes.BAD_REQUEST) {
        dispatch(notificationsShow('warning', err.response.data));
      } else {
        dispatch(notificationsShow('error', 'Failed to create booking'));
      }
    }
  };

  bookingsPostRequest();
};

export const bookingsPostInspectionStart = () => createRequestAction(BOOKINGS_POST_INSPECTION_REQUEST_START, apiRequestState.LOADING);
export const bookingsPostInspectionSuccess = (bookings) => createRequestAction(BOOKINGS_POST_INSPECTION_REQUEST_SUCCESS, apiRequestState.SUCCESS, bookings);
export const bookingsPostInspectionFail = () => createRequestAction(BOOKINGS_POST_INSPECTION_REQUEST_FAIL, apiRequestState.FAIL);
export const bookingsPostInspectionIdle = () => createRequestAction(BOOKINGS_POST_INSPECTION_REQUEST_IDLE, apiRequestState.IDLE);

export const bookingsPostInspection = (bookings) => (dispatch) => {
  dispatch(bookingsPostInspectionStart());
  const bookingsPostInspectionRequest = async () => {
    try {
      const response = await axios.post(`${API_BASE_URL}/bookings/requestInspection`, bookings);

      await dispatch(bookingsPostInspectionSuccess(response.data));
      await dispatch(bookingsPostInspectionIdle());
      dispatch(notificationsShow('success', 'Successfully requested inspection'));
    } catch (err) {
      dispatch(bookingsPostInspectionFail());
      if (err.response.status === StatusCodes.BAD_REQUEST) {
        dispatch(notificationsShow('warning', err.response.data));
      } else {
        dispatch(notificationsShow('error', 'Failed to request inspection'));
      }
    }
  };

  bookingsPostInspectionRequest();
};

export const bookingsPutStart = () => ({
  type: BOOKINGS_PUT_REQUEST_START,
  requestState: apiRequestState.LOADING,
});

export const bookingsPutSuccess = (booking) => ({
  type: BOOKINGS_PUT_REQUEST_SUCCESS,
  requestState: apiRequestState.SUCCESS,
  booking,
});

export const bookingsPutFail = () => ({
  type: BOOKINGS_PUT_REQUEST_FAIL,
  requestState: apiRequestState.FAIL,
});

export const bookingsPutIdle = () => ({
  type: BOOKINGS_PUT_REQUEST_IDLE,
  requestState: apiRequestState.IDLE,
});

export const bookingsPut = (booking) => (dispatch) => {
  dispatch(bookingsPutStart());
  const bookingsPutRequest = async () => {
    try {
      const response = await axios.put(`${API_BASE_URL}/bookings/${booking._id}`, booking);

      await dispatch(bookingsPutSuccess(response.data));
      await dispatch(bookingsPutIdle());
      dispatch(notificationsShow('success', 'Successfully updated booking'));
    } catch (err) {
      dispatch(bookingsPutFail());
      if (err.response.status === StatusCodes.BAD_REQUEST) {
        dispatch(notificationsShow('warning', `${err.response.data}`));
      } else {
        dispatch(notificationsShow('error', 'Failed to update booking'));
      }
    }
  };

  bookingsPutRequest();
};

export const bookingsPutShareStart = () => ({
  type: BOOKINGS_PUT_SHARE_REQUEST_START,
  requestState: apiRequestState.LOADING,
});

export const bookingsPutShareSuccess = (bookings) => ({
  type: BOOKINGS_PUT_SHARE_REQUEST_SUCCESS,
  requestState: apiRequestState.SUCCESS,
  bookings,
});

export const bookingsPutShareFail = () => ({
  type: BOOKINGS_PUT_SHARE_REQUEST_FAIL,
  requestState: apiRequestState.FAIL,
});

export const bookingsPutShareIdle = () => ({
  type: BOOKINGS_PUT_SHARE_REQUEST_IDLE,
  requestState: apiRequestState.IDLE,
});

export const bookingsPutShare = (bookings, share) => (dispatch) => {
  dispatch(bookingsPutShareStart());
  const bookingsPutShareRequest = async () => {
    try {
      const response = await axios.put(`${API_BASE_URL}/bookings/share`, { bookingIds: bookings, share });
      await dispatch(bookingsPutShareSuccess(response.data));
      await dispatch(bookingsPutShareIdle());
      dispatch(notificationsShow('success', 'Successfully shared booking'));
    } catch (err) {
      dispatch(bookingsPutShareFail());
      if (err.response?.status === StatusCodes.BAD_REQUEST) {
        dispatch(notificationsShow('warning', `${err.response.data}`));
      } else {
        dispatch(notificationsShow('error', 'Failed to share booking'));
      }
    }
  };

  bookingsPutShareRequest();
};

export const bookingsDocumentPatchStart = () => ({
  type: BOOKINGS_DOCUMENT_PATCH_REQUEST_START,
  requestState: apiRequestState.LOADING,
});

export const bookingsDocumentPatchSuccess = (booking) => ({
  type: BOOKINGS_DOCUMENT_PATCH_REQUEST_SUCCESS,
  requestState: apiRequestState.SUCCESS,
  booking,
});

export const bookingsDocumentPatchFail = () => ({
  type: BOOKINGS_DOCUMENT_PATCH_REQUEST_FAIL,
  requestState: apiRequestState.FAIL,
});

export const bookingsDocumentPatchIdle = () => ({
  type: BOOKINGS_DOCUMENT_PATCH_REQUEST_IDLE,
  requestState: apiRequestState.IDLE,
});

export const bookingsDocumentPatch = (booking, documentType, document) => (dispatch) => {
  const formData = new FormData();
  formData.append('document', document);

  dispatch(bookingsDocumentPatchStart());
  const bookingsDocumentPatchRequest = async () => {
    try {
      const response = await axios.patch(`${API_BASE_URL}/bookings/${booking._id}/${documentType}`, formData);

      await dispatch(bookingsDocumentPatchSuccess(response.data));
      await dispatch(bookingsDocumentPatchIdle());
      dispatch(notificationsShow('success', `Successfully uploaded ${documentType}`));
    } catch (err) {
      dispatch(bookingsDocumentPatchFail());
      dispatch(notificationsShow('error', `Failed to upload ${documentType}`));
    }
  };

  bookingsDocumentPatchRequest();
};

export const bookingsDocumentDeleteStart = () => ({
  type: BOOKINGS_DOCUMENT_DELETE_REQUEST_START,
  requestState: apiRequestState.LOADING,
});

export const bookingsDocumentDeleteSuccess = (booking) => ({
  type: BOOKINGS_DOCUMENT_DELETE_REQUEST_SUCCESS,
  requestState: apiRequestState.SUCCESS,
  booking,
});

export const bookingsDocumentDeleteFail = () => ({
  type: BOOKINGS_DOCUMENT_DELETE_REQUEST_FAIL,
  requestState: apiRequestState.FAIL,
});

export const bookingsDocumentDeleteIdle = () => ({
  type: BOOKINGS_DOCUMENT_DELETE_REQUEST_IDLE,
  requestState: apiRequestState.IDLE,
});

export const bookingsDocumentDelete = (booking, documentType) => (dispatch) => {
  dispatch(bookingsDocumentDeleteStart());
  const bookingsDocumentDeleteRequest = async () => {
    try {
      const response = await axios.delete(`${API_BASE_URL}/bookings/${booking._id}/${documentType}`);
      await dispatch(bookingsDocumentDeleteSuccess(response.data));
      await dispatch(bookingsDocumentDeleteIdle());
      dispatch(notificationsShow('success', `Successfully deleted ${documentType}`));
    } catch (err) {
      dispatch(bookingsDocumentDeleteFail());
      dispatch(notificationsShow('error', `Failed to delete ${documentType}`));
    }
  };

  bookingsDocumentDeleteRequest();
};

export const bookingsRefreshPatchStart = () => ({
  type: BOOKINGS_REFRESH_PATCH_REQUEST_START,
  requestState: apiRequestState.LOADING,
});

export const bookingsRefreshPatchSuccess = (booking) => ({
  type: BOOKINGS_REFRESH_PATCH_REQUEST_SUCCESS,
  requestState: apiRequestState.SUCCESS,
  booking,
});

export const bookingsRefreshPatchFail = () => ({
  type: BOOKINGS_REFRESH_PATCH_REQUEST_FAIL,
  requestState: apiRequestState.FAIL,
});

export const bookingsRefreshPatchIdle = () => ({
  type: BOOKINGS_REFRESH_PATCH_REQUEST_IDLE,
  requestState: apiRequestState.IDLE,
});

export const bookingsRefreshPatch = (booking) => (dispatch) => {
  dispatch(bookingsRefreshPatchStart());
  const bookingsRefreshPatchRequest = async () => {
    try {
      const response = await axios.patch(`${API_BASE_URL}/bookings/refresh/${booking._id}`);

      await dispatch(bookingsRefreshPatchSuccess(response.data));
      await dispatch(bookingsRefreshPatchIdle());
      dispatch(notificationsShow('success', 'Successfully refreshed booking'));
    } catch (err) {
      dispatch(bookingsRefreshPatchFail());
      dispatch(notificationsShow('error', 'Failed to refresh booking'));
    }
  };

  bookingsRefreshPatchRequest();
};

export const bookingsPutDeactivateStart = () => ({
  type: BOOKINGS_PUT_DEACTIVATE_REQUEST_START,
  requestState: apiRequestState.LOADING,
});

export const bookingsPutDeactivateSuccess = (bookings) => ({
  type: BOOKINGS_PUT_DEACTIVATE_REQUEST_SUCCESS,
  requestState: apiRequestState.SUCCESS,
  bookings,
});

export const bookingsPutDeactivateFail = () => ({
  type: BOOKINGS_PUT_DEACTIVATE_REQUEST_FAIL,
  requestState: apiRequestState.FAIL,
});

export const bookingsPutDeactivateIdle = () => ({
  type: BOOKINGS_PUT_DEACTIVATE_REQUEST_IDLE,
  requestState: apiRequestState.IDLE,
});

export const bookingsPutDeactivate = (bookings) => (dispatch) => {
  dispatch(bookingsPutDeactivateStart());
  const bookingsPutDeactivateRequest = async () => {
    try {
      const response = await axios.put(`${API_BASE_URL}/bookings/deactivate`, bookings);
      await dispatch(bookingsPutDeactivateSuccess(response.data));
      await dispatch(bookingsPutDeactivateIdle());
      dispatch(notificationsShow('success', 'Successfully deactivated booking'));
    } catch (err) {
      dispatch(bookingsPutDeactivateFail());
      dispatch(notificationsShow('error', 'Failed to deactivate booking'));
    }
  };

  bookingsPutDeactivateRequest();
};

export const bookingsDeleteStart = () => ({
  type: BOOKINGS_DELETE_REQUEST_START,
  requestState: apiRequestState.LOADING,
});

export const bookingsDeleteSuccess = (bookings) => ({
  type: BOOKINGS_DELETE_REQUEST_SUCCESS,
  requestState: apiRequestState.SUCCESS,
  bookings,
});

export const bookingsDeleteFail = () => ({
  type: BOOKINGS_DELETE_REQUEST_FAIL,
  requestState: apiRequestState.FAIL,
});

export const bookingsDeleteIdle = () => ({
  type: BOOKINGS_DELETE_REQUEST_IDLE,
  requestState: apiRequestState.IDLE,
});

export const bookingsDelete = (bookings) => (dispatch) => {
  dispatch(bookingsDeleteStart());

  const bookingsDeleteRequest = async () => {
    try {
      const response = await axios.delete(`${API_BASE_URL}/bookings`, { data: bookings });

      await dispatch(bookingsDeleteSuccess(response.data));
      await dispatch(bookingsDeleteIdle());
      dispatch(notificationsShow('success', 'Successfully deleted booking'));
    } catch (err) {
      dispatch(bookingsDeleteFail());
      dispatch(notificationsShow('error', 'Failed to delete booking'));
    }
  };

  bookingsDeleteRequest();
};
