import React, {
  useState, useEffect, forwardRef, useImperativeHandle,
} from 'react';
import _ from 'lodash';
import {
  FaCheck, FaPlus, FaTimes, FaTrash,
} from 'react-icons/fa';
import { apiRequestState, get } from '../../../utility/utility';
import MyDropzone from '../../FileUploader';

import './BookingFormAttachDocument.css';
import SectionDivider from '../../SectionDivider';
import CoreFormDualAction from '../../core/form/CoreFormDualAction';
import { renderSelectField, validateForm } from '../../core/input/CoreInputUtils';

const fieldsToValidate = ['fileType'];
const BookingFormAttachDocument = forwardRef(({
  bookings,
  bookingsDocumentPatchRequestState,
  dispatchBookingDocumentPatch,
  dispatchBookingDocumentDelete,
  bookingsDocumentDeleteRequestState,
  dispatchBookingsGetDocument,
}, ref) => {
  const [inputState, setInputState] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);
  const [documentPendingDeletion, setDocumentPendingDeletion] = useState(null);

  const [isOpen, setIsOpen] = useState(false);
  const [currentBooking, setCurrentBooking] = useState({});
  const [documentSettings, setDocumentSettings] = useState([]);
  const [error, setError] = useState({});
  const [showUploader, setShowUploader] = useState(false);

  useImperativeHandle(ref, () => ({
    open: (booking, settings) => {
      setDocumentSettings(settings);
      setCurrentBooking(booking);
      setIsOpen(true);
      setSelectedFile(null);
      setInputState({});
      setError({});
      setShowUploader(false);
      setInputState({});
      setSelectedFile(null);
    },
    close: () => setIsOpen(false),
  }));

  useEffect(() => {
    if (isOpen
      && (bookingsDocumentDeleteRequestState === apiRequestState.SUCCESS
        || bookingsDocumentPatchRequestState === apiRequestState.SUCCESS)) {
      const bookingIndex = bookings.findIndex(
        (booking) => booking._id === currentBooking._id,
      );

      const newCurrentBooking = bookings[bookingIndex];
      setCurrentBooking(newCurrentBooking);
      setShowUploader(false);
      setInputState({});
      setSelectedFile(null);
    }
  }, [bookingsDocumentDeleteRequestState, bookingsDocumentPatchRequestState]);

  const closeModal = () => {
    setIsOpen(false);
  };

  if (!isOpen) return null;

  const onInputChange = (event) => {
    const newState = { ...inputState };
    newState[event.target.id] = event.target.value;
    setInputState(newState);

    validateForm({
      state: newState,
      fieldsToValidate,
      setError,
    });
  };

  const handleFileDrop = (files) => {
    const file = files[0];
    setSelectedFile(file);
  };

  const handleFileReset = () => {
    setSelectedFile(null);
    setShowUploader(false);
  };

  const handleDocumentUploadButtonClicked = () => {
    if (validateForm({
      state: inputState,
      fieldsToValidate,
      setError,
    })) {
      if (selectedFile) {
        dispatchBookingDocumentPatch(
          currentBooking,
          inputState.fileType,
          selectedFile,
        );
      }
    }
  };

  const handleDocumentDeleteClicked = (booking, documentKey) => {
    dispatchBookingDocumentDelete(booking, documentKey);
    setDocumentPendingDeletion(null);
  };

  const renderAddButton = () => (
    <div style={{ paddingTop: '20px' }}>
      <div
        className="inspection-form-document-add-button "
        onClick={() => setShowUploader(true)}
      >
        <FaPlus />
      </div>
    </div>
  );

  return (
    <CoreFormDualAction
      title="Files"
      primaryButtonCaption="Close"
      primaryButtonCallback={closeModal}
      secondaryButtonCallback={closeModal}
    >
      <div style={{
        display: 'flex', flexDirection: 'column', gap: '20px', paddingTop: '20px',
      }}
      >
        {
          Object.keys(currentBooking?.documents)?.map((document) => (
            <div key={document} className="inspection-form-document-existing-documents">
              <div className="document-name-group">
                {' '}
                <b>{_.startCase(document)}</b>
                <i
                  onClick={() => dispatchBookingsGetDocument(currentBooking, document)}

                >
                  <u>
                    {currentBooking.documents[document].replace(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z-/, '')}
                  </u>
                </i>
              </div>
              {documentPendingDeletion === document ? (
                <div className="inspection-form-document-icon-confirm-group">
                  <FaCheck onClick={() => handleDocumentDeleteClicked(currentBooking, document)} />
                  <FaTimes onClick={() => setDocumentPendingDeletion(null)} />
                </div>
              ) : (
                <FaTrash onClick={() => setDocumentPendingDeletion(document)} />
              )}
            </div>
          ))
        }

        {showUploader
          ? (
            <div>
              <SectionDivider style={{ position: 'relative', paddingTop: '20px' }} label="Add document" />
              {selectedFile && inputState.fileType
                ? (
                  <div>
                    <div className="inspection-form-document-adding-documents">
                      <span style={{ fontWeight: '400', paddingRight: '20px' }}>
                        <p>
                          <b>{_.startCase(inputState.fileType)}</b>
                          {' - '}
                          <i>{selectedFile.name.replace(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z-/, '')}</i>
                        </p>
                      </span>
                      <div className="inspection-form-document-icon-confirm-group">
                        <FaCheck onClick={() => handleDocumentUploadButtonClicked()} />
                        <FaTimes onClick={() => handleFileReset()} />
                      </div>
                    </div>
                  </div>
                )
                : (
                  <div>
                    {renderSelectField({
                      id: 'fileType',
                      inputState,
                      onInputChange,
                      placeholder: 'Pick a file type to upload',
                      options: Object.keys(documentSettings).map((element) => ({ label: _.startCase(element), value: element })),
                      error,
                    })}
                    {get(inputState, 'fileType', false) && (
                      <div style={{ paddingTop: '20px' }}>
                        <MyDropzone onFileDrop={handleFileDrop} id="document" type="file" name="filePicker" />
                      </div>
                    )}
                  </div>
                )}
            </div>
          ) : renderAddButton()}
      </div>
    </CoreFormDualAction>
  );
});

export default BookingFormAttachDocument;
