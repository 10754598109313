import React, { useEffect, useState } from 'react';
import './DateTimePicker.css';
import { FaClock, FaTimes } from 'react-icons/fa';

function DateTimePicker({
  id,
  label,
  value,
  onChange,
}) {
  const [inputState, setInputState] = useState(value || { date: '', time: '' });

  const inputStyle = {
    height: '35px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none',
    borderRadius: '0',
    backgroundColor: 'white',
    flex: '1 1 auto',
  };

  const iconContainerStyle = (backgroundColor) => ({
    ...inputStyle,
    backgroundColor,
    padding: '0 10px',
    cursor: 'pointer',
  });

  useEffect(() => {
    setInputState(value || { date: '', time: '' });
  }, [value]);

  const onChangeHandler = (event) => {
    const newState = { ...inputState, [event.target.id]: event.target.value };
    setInputState(newState);
    onChange({ target: { id, value: newState } });
  };

  const onSetNow = () => {
    const dateTimeNow = new Date();
    const formattedDate = dateTimeNow.toISOString().split('T')[0];
    const formattedTime = dateTimeNow.toTimeString().split(' ')[0];
    const newState = { date: formattedDate, time: formattedTime };
    setInputState(newState);
    onChange({ target: { id, value: newState } });
  };

  const onClear = () => {
    const newState = { date: '', time: '' };
    setInputState(newState);
    onChange({ target: { id, value: newState } });
  };

  return (
    <>
      {label && <p className="date-time-picker-label">{label}</p>}
      <div className="date-time-picker-container">
        <input
          id="date"
          className="date-picker"
          value={inputState.date}
          type="date"
          name="date"
          onChange={onChangeHandler}
          style={{ ...inputStyle, borderRight: '1px solid #ccc' }}
        />
        <input
          id="time"
          className="time-picker"
          value={inputState.time}
          type="time"
          name="time"
          onChange={onChangeHandler}
          style={{ ...inputStyle, borderRight: '1px solid #ccc' }}
        />
        <div style={iconContainerStyle('#eee')}>
          <div
            onClick={onClear}
          >
            <FaTimes />
          </div>
        </div>
        <div
          className="date-time-picker-icon-clock"
          onClick={onSetNow}
        >
          <FaClock />
        </div>
      </div>
    </>
  );
}

export default DateTimePicker;
