import React, { useRef, useEffect, useState } from 'react';
import { FaMapMarkerAlt } from 'react-icons/fa';
import Map, { Marker } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { MAPBOX_TOKEN } from '../config';

const initialViewState = {
  latitude: 37.7751,
  longitude: -122.4193,
  zoom: 0,
  bearing: 0,
  pitch: 0,
};

export default function MyMap({ flyZoom, milestones = [] }) {
  const mapRef = useRef(null);
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [interactive, setInteractive] = useState(false);

  function createArc(start, end, numPoints = 100) {
    const [x1, y1] = start;
    const [x2, y2] = end;

    return Array.from({ length: numPoints }, (_, i) => {
      const t = i / (numPoints - 1);
      const x = x1 + t * (x2 - x1);
      const y = y1 + t * (y2 - y1);
      const elevation = 0.5 - Math.abs(0.5 - t);
      const arcY = y + elevation * 0.1 * (y2 - y1);
      return [x, arcY];
    });
  }

  const milestonesLocationFiltering = (milestoneElements) => {
    const filteredMilestones = [];
    milestoneElements.forEach((milestone) => {
      if (milestone.description.toLowerCase() !== 'carrier release') {
        filteredMilestones.push(milestone);
      }
    });

    return filteredMilestones;
  };

  function generateArcsForMilestones(milestoneElements) {
    const arcs = [];
    const filteredMilestones = milestonesLocationFiltering(milestoneElements);

    filteredMilestones.forEach((milestone, i) => {
      if (i < filteredMilestones.length - 1) {
        const start = [
          milestone.location.geolocation.longitude,
          milestone.location.geolocation.latitude,
        ];
        const end = [
          filteredMilestones[i + 1].location.geolocation.longitude,
          filteredMilestones[i + 1].location.geolocation.latitude,
        ];
        const arcCoordinates = createArc(start, end);
        arcs.push({
          type: 'Feature',
          properties: {
            planned: filteredMilestones[i + 1].planned,
          },
          geometry: {
            type: 'LineString',
            coordinates: arcCoordinates,
          },
        });
      }
    });

    return {
      type: 'FeatureCollection',
      features: arcs,
    };
  }

  useEffect(() => {
    if (isMapLoaded && mapRef.current && milestones?.length) {
      const mapboxInstance = mapRef.current.getMap();
      const arcsGeoJSON = generateArcsForMilestones(milestones);
      let { geolocation } = milestones[milestones.length - 1].location;

      // Find the current milestone location to fly to
      if (milestones.length > 1) {
        milestones.forEach((milestone, index) => {
          if (!milestone.planned) {
            geolocation = milestones[index].location.geolocation;
          }
        });
      }

      mapboxInstance.flyTo({ center: [geolocation.longitude, geolocation.latitude], zoom: flyZoom, duration: 4000 });

      // Add arcs as a source
      if (mapboxInstance.getSource('arcs')) { // Check and remove existing source & layer to prevent errors
        mapboxInstance.removeLayer('arcs');
        mapboxInstance.removeSource('arcs');
      }
      mapboxInstance.addSource('arcs', {
        type: 'geojson',
        data: arcsGeoJSON,
      });

      mapboxInstance.addLayer({
        id: 'arcs',
        source: 'arcs',
        type: 'line',
        paint: {
          'line-width': 2,
          'line-color': [
            'case',
            ['==', ['get', 'planned'], true],
            '#F7F7F7', // Color for non-planned milestones (change to your desired color)
            '#3798EF', // Color for planned milestones
          ],
        },
      });
    }
  }, [isMapLoaded, milestones]); // Milestones dependency added

  return (
    <Map
      ref={mapRef}
      initialViewState={initialViewState}
      mapStyle="mapbox://styles/mapbox/dark-v10"
      mapboxAccessToken={MAPBOX_TOKEN}
      onLoad={() => setIsMapLoaded(true)}
      onClick={() => setInteractive(true)}
      dragRotate={false}
      dragPan={interactive}
      scrollZoom={interactive}
      minZoom={2}
    >
      {milestones.map((milestone, index) => (
        <Marker
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          latitude={milestone.location.geolocation.latitude}
          longitude={milestone.location.geolocation.longitude}
        >
          <FaMapMarkerAlt size={30} color={milestone.planned ? 'white' : 'var(--color-primaryLight)'} />
        </Marker>
      ))}
    </Map>
  );
}
