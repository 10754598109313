import React from 'react';
import _ from 'lodash';
import { get } from '../../../utility/utility';
import CoreCard from '../../core/content/CoreCard';

function InspectionMeasurementCard({ measurement, actions }) {
  return (
    <CoreCard
      isHoverable
      content={(
        <div key={get(measurement, '_id')} style={{ paddingBottom: '5px' }}>
          <div style={{ paddingLeft: '10px' }}>
            <div style={{ color: 'var(--color-grey900)' }}>
              {_.startCase(get(measurement, 'description'))}
            </div>
            <div style={{ color: 'var(--color-grey700)' }}>
              {`${get(measurement, 'value')} ${get(measurement, 'unit')}`}
            </div>
          </div>
        </div>
      )}
      actions={actions}
    />
  );
}

export default InspectionMeasurementCard;
