// InspectionDetailView.js
import React, { useEffect, useState } from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import './InspectionDetailView.css';
import Spinner from '../Spinner';
import Button from '../core/input/Button';
import { get } from '../../utility/utility';

function InspectionDetailView({
  children, onBack, leftToSync, flushImages, dispatchGetImagesLeftToSyncCount, inspectionId, dispatchInspectionsGetSingle, selectedInspection,
}) {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    dispatchInspectionsGetSingle({ inspectionId });
    const timeout = setTimeout(() => setIsVisible(true), 0);
    return () => clearTimeout(timeout);
  }, []);

  const displaySyncStatus = (imageCount) => {
    let status = '';
    if (imageCount === 0) {
      status = '';
    } else if (imageCount > 0) {
      status = `${imageCount} to sync`;
    }
    return status;
  };

  const handleFlushImages = async () => {
    try {
      await flushImages();
      await dispatchGetImagesLeftToSyncCount();
    } catch (error) {
      // eslint-disable-next-line
      console.error(error)
    }
  };

  const renderHeaderInfo = (inspection) => {
    const output = [];

    const applicantName = get(inspection, 'participants.applicant.name', null);
    if (applicantName) output.push(applicantName);

    const purchaseOrder = get(inspection, 'shipment.logistics.purchaseOrder', null);
    if (purchaseOrder) output.push(`#${purchaseOrder}`);

    const commodity = get(inspection, 'shipment.logistics.commodity', null);
    const maxCommodityLength = 40; // Change this value to the desired maximum length
    if (commodity) {
      const truncatedCommodity = commodity.length > maxCommodityLength
        ? `${commodity.substring(0, maxCommodityLength)}...`
        : commodity;
      output.push(truncatedCommodity);
    }

    return output.join(' - ');
  };

  return (
    <div className={`inspection-detail-view-container ${isVisible ? 'slide-in' : ''}`}>
      <div className="inspection-detail-view-header">
        <div className="inspection-detail-view-header-flex-container">
          <div className="inspection-detail-view-back-button">
            <FaArrowLeft onClick={onBack} />
          </div>
          {displaySyncStatus(leftToSync) ? null
            : (
              <div className="inspection-header-info">
                {renderHeaderInfo(selectedInspection)}
              </div>
            )}
          <div className="inspection-detail-view-spinner">
            {displaySyncStatus(leftToSync)
              ? (
                <div className="loading-container">
                  <div className="loading-status">{displaySyncStatus(leftToSync)}</div>
                  <div className="button-container">
                    <Button simple onClick={handleFlushImages}>Flush</Button>
                  </div>
                  <Spinner />
                </div>
              )
              : null}
          </div>
        </div>
      </div>
      <div className="inspection-detail-view">
        {children}
      </div>
    </div>
  );
}

export default InspectionDetailView;
