// BookingDetailView.js
import React, { useEffect, useState } from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import './BookingDetailView.css';

function BookingDetailView({ children, onBack, actions = [] }) {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setIsVisible(true), 0);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <div className={`booking-detail-view-container ${isVisible ? 'slide-in' : ''}`}>
      <div className="booking-detail-view-header">
        <div className="booking-detail-view-header-flex-container">
          <div className="booking-detail-view-back-button">
            <FaArrowLeft onClick={onBack} />
          </div>
          <div className="booking-detail-view-icons-container">
            {actions.map((action) => (
              <div
                className="booking-detail-view-icon"
                key={action.id}
                id={action.id}
                onClick={action.callback}
              >
                {action.icon}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="booking-detail-view">
        {children}
      </div>
    </div>
  );
}

export default BookingDetailView;
