import './App.css';
import { useMediaQuery } from 'react-responsive';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { connect } from 'react-redux';
import { React, useEffect, useState } from 'react';
import {
  getImagesLeftToSyncCount,
  organizationGet,
  signOut, storeToken, userGetMe,
} from './actions';
import Backdrop from './components/Backdrop';
import BackgroundVideo from './components/BackgroudVideo';
import BookingPage from './containers/BookingPage';
import Footer from './components/Footer';
import Header from './components/Header';
import localStorageIsAvailable, { apiRequestState, get } from './utility/utility';
import Navigation from './components/Navigation';
import OrganizationPage from './containers/OrganizationPage';
import PasswordForgot from './containers/PasswordForgot';
import PasswordReset from './containers/PasswordReset';
import ReportingPage from './containers/ReportingPage';
import SignIn from './containers/SignIn';
import StatusBanner from './components/StatusBanner';
import SignupInvite from './containers/SignupInvite';
import InspectionPage from './containers/InspectionPage';
import { MEDIA_MOBILE_MAX_WIDTH } from './utility/AppParameters';
import { API_BASE_URL, IMAGE_SYNC_BATCH_SIZE } from './config';
import {
  serviceWorkerCheckSupport,
  initServiceWorker,
  setDispatchFunction,
} from './utility/serviceWorkerUtils';

const branding = {
  name: 'vessel',
};

// Define the colors object
const colors = {
  primaryLight: '#3798EF',
  primaryMedium: '#3A81C1',
  primaryDark: '#215380',

  secondaryLight: '#00ff00',
  secondaryMedium: '#00ff00',
  secondaryDark: '#00ff00',

  tertiaryLight: '#8AD1A9',
  tertiaryMedium: '#8AD1A9',
  tertiaryDark: '#8AD1A9',

  grey000: '#F7F7F7',
  grey050: '#EDEDED',
  grey100: '#acacac',
  grey300: '#E6E6E6',
  grey500: '#C5C5C6',
  grey700: '#A1A1A1',
  grey900: '#464953',
  grey1100: '#1F2021',

  mapDarkBackround: '#191A1A',

  warning: 'orange',
  critical: 'red',
};

// Apply colors to CSS
Object.entries(colors).forEach(([key, value]) => {
  document.documentElement.style.setProperty(`--color-${key}`, value);
});

const navigationBasicOptions = [];

function checkIndexedDBSupport() {
  if (('indexedDB' in window)) {
    return true;
  }
  return false;
}

// Check for browser capabilities
const indexedDbFeatureIsAvailable = checkIndexedDBSupport();
const serviceWorkerFeatureIsAvailable = serviceWorkerCheckSupport();

function App({
  me,
  token,
  dispatchStoreToken,
  dispatchUserGetMe,
  dispatchSignOut,
  dispatchOrganizationsGet,
  dispatchGetImagesLeftToSyncCount,
  organization,
  organizationGetRequestState,
  notificationSeverity,
  notificationMessage,
  notificationIsShown,
}) {
  const isDesktopOrLaptop = useMediaQuery({ minDeviceWidth: MEDIA_MOBILE_MAX_WIDTH });
  const [menuState, setMenuState] = useState(false);

  useEffect(() => {
    if (localStorageIsAvailable()) {
      const storedToken = localStorage.getItem('token');

      if (!token) {
        dispatchStoreToken(storedToken);
      }
    }
  }, []);

  const serviceWorkerConfig = {
    api: {
      baseUrl: API_BASE_URL,
      jwtToken: token,
      batchSize: IMAGE_SYNC_BATCH_SIZE,
    },
    sync: {
      filePath: '/',
      fileName: 'syncSw.js',
      syncEventTag: 'syncInspectionImages',
    },
    callbacks: {
      dispatchGetImagesLeftToSyncCount,
    },
  };

  setDispatchFunction(dispatchGetImagesLeftToSyncCount);
  useEffect(() => {
    if (token && !me) {
      dispatchUserGetMe();
      if (organizationGetRequestState === apiRequestState.IDLE) {
        dispatchOrganizationsGet();
        dispatchGetImagesLeftToSyncCount([]);
      }
    }
  }, [token]);

  useEffect(() => {
    if (get(organization, 'inspectionSettings', false)
      && serviceWorkerFeatureIsAvailable
      && indexedDbFeatureIsAvailable
      && get(serviceWorkerConfig, 'api', false)
      && get(serviceWorkerConfig, 'sync', false)) {
      initServiceWorker(serviceWorkerConfig);
    }
  }, [organization]);

  const handleMenuClick = () => {
    if (menuState) {
      setMenuState(false);
    } else {
      setMenuState(true);
    }
  };

  const navigationOptions = [...navigationBasicOptions];

  // Adding navigation menu options depending on the permissions.
  if (me?.permissions?.bookingsView) navigationOptions.push('Bookings');
  if (me?.permissions?.bookingsView) navigationOptions.push('Reporting');
  if (me?.permissions?.bookingsView) navigationOptions.push('Organization');
  if (me?.permissions?.inspections?.view) navigationOptions.push('Inspections');

  const displayDefaultPage = (permissions) => {
    if (get(permissions, 'inspections.view', false)) {
      return <Route path="/*" element={<InspectionPage />} />;
    }

    return <Route path="/*" element={<BookingPage />} />;
  };

  return (
    <>
      <Header
        brandName={branding.name.toUpperCase()}
        showMenuButton={token}
        onMenuClick={handleMenuClick}
      />
      {
        notificationIsShown
          ? <StatusBanner statusSeverity={notificationSeverity} statusMessage={notificationMessage} />
          : <div />
      }
      <Footer />

      {
        (token)
          ? (
            <div className="dashboardView">
              <Router>
                <Navigation options={navigationOptions} isShown={menuState} signOut={dispatchSignOut} onClick={handleMenuClick} />
                <Backdrop onClick={handleMenuClick} isShown={menuState} />
                <Routes>
                  <Route exact path="/bookings" element={<BookingPage />} />
                  <Route exact path="/reporting" element={<ReportingPage />} />
                  <Route exact path="/organization" element={<OrganizationPage />} />
                  {me?.permissions?.inspections?.view && <Route exact path="/inspections" element={<InspectionPage />} />}
                  {displayDefaultPage(get(me, 'permissions', {}))}
                </Routes>
              </Router>
            </div>
          )
          : (
            <>
              <Router>
                <Routes>
                  <Route exact path="/signin" element={<SignIn />} />
                  <Route exact path="/forgot-password" element={<PasswordForgot />} />
                  <Route path="/reset-password" element={<PasswordReset />} />
                  <Route path="/signup-invite" element={<SignupInvite />} />
                  <Route path="/*" element={<SignIn />} />
                </Routes>
              </Router>
              {isDesktopOrLaptop ? <BackgroundVideo videoSrc="ship.mp4" /> : null}
            </>
          )
      }
    </>
  );
}

const mapStateToProps = (state) => ({
  token: state.auth.token,
  me: state.users.me,
  carriers: state.carriers.carriers,
  notificationIsShown: state.notifications.isShown,
  notificationSeverity: state.notifications.severity,
  notificationMessage: state.notifications.message,
  signInRequestState: state.auth.signInRequestState,
  organizationGetRequestState: state.organizations.organizationGetRequestState,
  organization: state.organizations.organization,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchStoreToken: (token) => dispatch(storeToken(token)),
  dispatchUserGetMe: () => dispatch(userGetMe()),
  dispatchOrganizationsGet: () => dispatch(organizationGet()),
  dispatchSignOut: () => dispatch(signOut()),
  dispatchGetImagesLeftToSyncCount: (uploadedImages) => dispatch(getImagesLeftToSyncCount(uploadedImages)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
