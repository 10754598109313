import {
  React, useEffect, useRef, useState,
} from 'react';
import { useMediaQuery } from 'react-responsive';
import './BookingsShared.css';
import _ from 'lodash';
import Table from '../Table';
import TableHeader from '../TableHeader';
import TableBody from '../TableBody';
import {
  apiRequestState, getDateString, get, handleSort,
} from '../../utility/utility';
import TableData from '../TableData';
import Spinner from '../Spinner';
import { SORT_OPTIONS, getStatusColor, sortKeyMap } from '../../utility/vesselParams';
import TableCollapsibleRow from '../TableCollapsibleRow';
import BookingSection from './sections/BookingSection';
import DocumentsSection from './sections/DocumentSection';
import DetailsSection from './sections/DetailsSection';
import ContainerTrackingSection from './sections/ContainerTrackingSection';
import { getBookingsCount } from '../../utility/BookingsUtility';
import Map from '../Map';
import SharedContainersCardList from '../cardLists/SharedContainersCardList';
import { MEDIA_MOBILE_MAX_WIDTH } from '../../utility/AppParameters';

function BookingsShared({
  bookingsGetRequestState,
  bookingsGetSharedRequestState,
  bookingsPostRequestState,
  dispatchBookingsGetDocument,
  headerTitles,
  organization,
  searchedBookings,
  searchInput,
  sharedBookings,
  title,
  user,
}) {
  const isDesktopOrLaptop = useMediaQuery({ minDeviceWidth: MEDIA_MOBILE_MAX_WIDTH });
  const [previousBookings, setPreviousBookings] = useState(searchedBookings);
  const [sortedBookingsState, setSortedBookingsState] = useState(previousBookings);
  const prevSearchedBookingsRef = useRef();

  useEffect(() => {
    prevSearchedBookingsRef.current = searchedBookings;
  }, [searchedBookings]);

  useEffect(() => {
    if (!_.isEqual(previousBookings, searchedBookings)) {
      setPreviousBookings(searchedBookings);
    }
    handleSort({
      target: {
        value: SORT_OPTIONS.ETA,
      },
    }, searchedBookings, sortKeyMap, setSortedBookingsState);
  }, [searchedBookings, previousBookings]);

  const handleTableHeaderClick = (header) => {
    handleSort({
      target: {
        value: header.title,
      },
    }, searchedBookings, sortKeyMap, setSortedBookingsState);
  };

  const sharedContainersCount = getBookingsCount(
    sharedBookings.length,
    searchedBookings.length,
    searchInput,
  );

  const displayBookings = (displayedBookings) => {
    if (!organization?.bookingSettings) return null;
    return (
      <>
        {displayedBookings.map((booking) => (
          <TableCollapsibleRow
            className="table-collaspsible-row"
            key={booking._id}
            id={booking._id}
            title={getStatusColor(booking).status}
            expandedContent={(
              <div className="bookings-shared-table-collaspsible-row-content">
                <div className="bookings-shared-map">
                  <Map flyZoom={5} milestones={booking?.tracking?.container?.status?.milestones} />
                </div>
                <BookingSection booking={booking.booking} />
                <DetailsSection detailsFields={organization.bookingSettings.details} details={booking.details} />
                <DocumentsSection booking={booking} dispatchBookingsGetDocument={dispatchBookingsGetDocument} />
                <ContainerTrackingSection container={booking.tracking.container} />
              </div>
            )}
          >
            <TableData color={getStatusColor(booking).color} />
            <TableData>{getDateString(get(booking, 'tracking.container.status.eta'))}</TableData>
            <TableData>{get(booking, 'details.pickupDate')}</TableData>
            <TableData>{get(booking, 'tracking.container.carrierName')}</TableData>
            <TableData>{get(booking, 'tracking.container.status.vessel')}</TableData>
            <TableData>{get(booking, 'tracking.container.containerId')}</TableData>
            <TableData>{get(booking, 'booking.purchaseOrder')}</TableData>
            <TableData>{get(booking, 'organizationName')}</TableData>
            {' '}
            <TableData>{get(booking, 'booking.shipper')}</TableData>
            <TableData>{get(booking, 'booking.productDescription')}</TableData>
            <TableData>
              {get(booking, 'tracking.container.status.origin.country')}
              ,
              {' '}
              {get(booking, 'tracking.container.status.origin.city')}
            </TableData>
            <TableData>
              {get(booking, 'tracking.container.status.destination.country')}
              ,
              {' '}
              {get(booking, 'tracking.container.status.destination.city')}
            </TableData>
            <TableData />
          </TableCollapsibleRow>
        ))}
      </>
    );
  };

  function renderSharedContainersTable() {
    if (sharedContainersCount.total === 0) return null;

    return (
      <div className="booking-view-containers-table">
        <div className="bookings-shared-title-block">
          <div className="bookings-shared-title">
            {`${title} ${sharedContainersCount.text}`}
          </div>
        </div>
        {sharedContainersCount.found > 0
          && (
            <div>
              {(bookingsGetSharedRequestState === apiRequestState.LOADING)
                ? (
                  <div style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  >
                    <Spinner />
                  </div>
                )
                : (
                  <Table>
                    <TableHeader onClick={handleTableHeaderClick} titles={headerTitles} />
                    <TableBody me={user}>
                      {displayBookings(sortedBookingsState)}
                    </TableBody>
                  </Table>
                )}
            </div>

          )}
      </div>
    );
  }

  function renderSharedContainersCardList() {
    if (sharedContainersCount.found === 0 || sharedContainersCount.total === 0) return null;

    return (
      <SharedContainersCardList
        title={`${title} ${sharedContainersCount.text}`}
        sortOptions={headerTitles.filter((option) => typeof option === 'string' && option.length > 0)}
        handleTableHeaderClick={handleTableHeaderClick}
        organization={organization}
        bookings={sortedBookingsState}
        dispatchBookingsGetDocument={dispatchBookingsGetDocument}
        bookingsPostRequestState={bookingsPostRequestState}
        bookingsGetRequestState={bookingsGetRequestState}
      />
    );
  }

  return (
    <div>
      {isDesktopOrLaptop ? renderSharedContainersTable() : renderSharedContainersCardList()}
    </div>
  );
}

export default BookingsShared;
