// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdown-with-input {
    position: relative;
    width: 100%; /* Ensure the parent takes full width */
    padding-top: 20px;
  }
  
  .dropdown-input {
    width: 100%; /* Ensure the input takes full width of the parent */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
    padding: 8px;
    font-size: 14px;
  }
  
  .react-select__menu {
    position: absolute;
    width: 100%; /* Ensure the dropdown takes full width of the parent */
    z-index: 1;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/core/input/DropdownWithInput.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW,EAAE,uCAAuC;IACpD,iBAAiB;EACnB;;EAEA;IACE,WAAW,EAAE,oDAAoD;IACjE,sBAAsB,EAAE,uEAAuE;IAC/F,YAAY;IACZ,eAAe;EACjB;;EAEA;IACE,kBAAkB;IAClB,WAAW,EAAE,uDAAuD;IACpE,UAAU;EACZ","sourcesContent":[".dropdown-with-input {\n    position: relative;\n    width: 100%; /* Ensure the parent takes full width */\n    padding-top: 20px;\n  }\n  \n  .dropdown-input {\n    width: 100%; /* Ensure the input takes full width of the parent */\n    box-sizing: border-box; /* Include padding and border in the element's total width and height */\n    padding: 8px;\n    font-size: 14px;\n  }\n  \n  .react-select__menu {\n    position: absolute;\n    width: 100%; /* Ensure the dropdown takes full width of the parent */\n    z-index: 1;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
