import React, { useState } from 'react';
import './SharedContainersCardList.css';

import BookingDetailView from './BookingDetailView';
import BookingSection from '../tables/sections/BookingSection';
import DetailsSection from '../tables/sections/DetailsSection';
import DocumentsSection from '../tables/sections/DocumentSection';
import { ChecklistSection } from '../tables/sections/ChecklistSection';
import ContainerTrackingSection from '../tables/sections/ContainerTrackingSection';
import BookingSharingSection from '../tables/sections/BookingSharingSection';
import Map from '../Map';
import Section from '../tables/sections/Section';
import TitleCard from './TitleCard';
import SharedContainerCard from './SharedContainerCard';

function SharedContainersCardList({
  title,
  handleTableHeaderClick,
  sortOptions,
  organization,
  bookings,
  dispatchBookingsGetDocument,
  sharingSectionEnabled = false,
}) {
  const [selectedBooking, setSelectedBooking] = useState(null);

  return (
    <div>
      {selectedBooking
        ? (
          <BookingDetailView
            onBack={() => setSelectedBooking(null)}
            actions={[]}
          >
            <BookingSection booking={selectedBooking.booking} />
            <DetailsSection presetDetailsFields={organization.bookingSettings.details} details={selectedBooking.details} />
            <DocumentsSection booking={selectedBooking} dispatchBookingsGetDocument={dispatchBookingsGetDocument} />
            <ChecklistSection booking={selectedBooking} presetChecklistFields={organization.bookingSettings.checklist} />
            {sharingSectionEnabled ? <BookingSharingSection booking={selectedBooking} /> : null}
            <ContainerTrackingSection container={selectedBooking.tracking.container} />
            <Section title="Location">
              <div className="shared-containers-card-list-location-map">
                <Map flyZoom={2} milestones={selectedBooking.tracking.container.status.milestones} />
              </div>
            </Section>
          </BookingDetailView>
        )
        : null}
      <div className="shared-containers-cardlist-frame">
        <TitleCard
          title={title}
          sortOptions={sortOptions}
          handleTableHeaderClick={handleTableHeaderClick}
        />
        {bookings.map((booking) => (
          <SharedContainerCard organization={organization} booking={booking} onSelect={setSelectedBooking} />
        ))}
      </div>
    </div>
  );
}

export default SharedContainersCardList;
