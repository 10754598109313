// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.backdrop {
    position: fixed;
    display: none;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 40;
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
}

.open {
    display: block;
}
`, "",{"version":3,"sources":["webpack://./src/components/Backdrop.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,aAAa;IACb,MAAM;IACN,QAAQ;IACR,WAAW;IACX,YAAY;IACZ,8BAA8B;IAC9B,WAAW;IACX,mCAA2B;YAA3B,2BAA2B;AAC/B;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".backdrop {\n    position: fixed;\n    display: none;\n    top: 0;\n    right: 0;\n    width: 100%;\n    height: 100%;\n    background: rgba(0, 0, 0, 0.5);\n    z-index: 40;\n    backdrop-filter: blur(10px);\n}\n\n.open {\n    display: block;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
