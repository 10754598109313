import React, { useState, useEffect } from 'react';
import './StatusBanner.css'; // Import the CSS file for styling
import { FaTimes } from 'react-icons/fa';
import { connect } from 'react-redux';
import { notificationsClear } from '../actions';
import { getNotificationBannerTimeoutMs } from '../utility/vesselParams';

function StatusBanner({ statusSeverity, statusMessage, dispatchNotificationsClear }) {
  const [status, setStatus] = useState({ severity: statusSeverity, message: statusMessage });

  useEffect(() => {
    if (status) {
      const timeout = setTimeout(() => {
        setStatus(null);
        dispatchNotificationsClear();
      }, getNotificationBannerTimeoutMs()); // Adjust the duration as needed

      return () => {
        clearTimeout(timeout);
      };
    }
    return () => { }; // No-op function
  }, [status, dispatchNotificationsClear]);

  const handleClose = () => {
    setStatus(null);
    dispatchNotificationsClear();
  };

  return (
    <div className="status-banner-container">
      {status && (
        <div className={`status-banner ${status.severity}`}>
          {status.message}
          <button className="close-button" type="button" onClick={handleClose}><FaTimes /></button>
        </div>
      )}
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  dispatchNotificationsClear: () => dispatch(notificationsClear()),
});

export default connect(null, mapDispatchToProps)(StatusBanner);
