import { React } from 'react';
import './TableHeader.css';
import { FaArrowDown } from 'react-icons/fa';

function TableHeader({ titles, onClick }) {
  const handleClick = onClick || (() => { });

  return (
    <thead className="th">
      <tr>
        {titles.map((title, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <th key={index} onClick={() => handleClick({ title, index })}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              {title}
              {typeof title === 'string' && title // Checks if title is a string and not empty
                ? <FaArrowDown className="arrow-icon" />
                : null}
            </div>
          </th>
        ))}
      </tr>
    </thead>
  );
}

export default TableHeader;
