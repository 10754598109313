// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inspections-buttons .action-icon:hover {
    color: black;
}

.inspections-buttons .action-icon {
    cursor: pointer;
    color: var(--color-primaryMedium);
}

.inspections-onsite-image-gallery-actions-container {
    display: flex;
    align-items: center;
    justify-content: flex-end; /* Align items to the right */
    padding-right: 20px; /* Adjust padding if necessary */
    gap: 20px; /* Space between items */
    color: var(--color-primaryMedium);
}


.inspections-onsite-image-gallery-action {
    cursor: pointer;
    color: var(--color-primaryMedium);
}

.inspections-onsite-image-gallery-action:hover {
    color: black;
}
`, "",{"version":3,"sources":["webpack://./src/components/inspection/tabs/InspectionTabOnsite.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;IACI,eAAe;IACf,iCAAiC;AACrC;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,yBAAyB,EAAE,6BAA6B;IACxD,mBAAmB,EAAE,gCAAgC;IACrD,SAAS,EAAE,wBAAwB;IACnC,iCAAiC;AACrC;;;AAGA;IACI,eAAe;IACf,iCAAiC;AACrC;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".inspections-buttons .action-icon:hover {\n    color: black;\n}\n\n.inspections-buttons .action-icon {\n    cursor: pointer;\n    color: var(--color-primaryMedium);\n}\n\n.inspections-onsite-image-gallery-actions-container {\n    display: flex;\n    align-items: center;\n    justify-content: flex-end; /* Align items to the right */\n    padding-right: 20px; /* Adjust padding if necessary */\n    gap: 20px; /* Space between items */\n    color: var(--color-primaryMedium);\n}\n\n\n.inspections-onsite-image-gallery-action {\n    cursor: pointer;\n    color: var(--color-primaryMedium);\n}\n\n.inspections-onsite-image-gallery-action:hover {\n    color: black;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
