import axios from 'axios';
import {
  AUTH_SIGN_IN_REQUEST_START,
  AUTH_SIGN_IN_REQUEST_FAIL,
  AUTH_SIGN_IN_REQUEST_SUCCESS,
  AUTH_SIGN_IN_REQUEST_IDLE,
  AUTH_FORGOT_PASSWORD_REQUEST_START,
  AUTH_FORGOT_PASSWORD_REQUEST_FAIL,
  AUTH_FORGOT_PASSWORD_REQUEST_SUCCESS,
  AUTH_FORGOT_PASSWORD_REQUEST_IDLE,
  AUTH_RESET_PASSWORD_REQUEST_START,
  AUTH_RESET_PASSWORD_REQUEST_FAIL,
  AUTH_RESET_PASSWORD_REQUEST_SUCCESS,
  AUTH_RESET_PASSWORD_REQUEST_IDLE,
  AUTH_SIGNUP_INVITE_REQUEST_START,
  AUTH_SIGNUP_INVITE_REQUEST_FAIL,
  AUTH_SIGNUP_INVITE_REQUEST_SUCCESS,
  AUTH_SIGNUP_INVITE_REQUEST_IDLE,
} from '../actions';
import { apiRequestState, updateObject } from '../utility/utility';

const initialState = {
  forgotPasswordRequestState: apiRequestState.IDLE,
  resetPasswordRequestState: apiRequestState.IDLE,
  signInRequestState: apiRequestState.IDLE,
  signUpInviteRequestState: apiRequestState.IDLE,
};

const signInStart = (state, action) => updateObject(state, {
  signInRequestState: action.requestState,
});

const signInFail = (state, action) => updateObject(state, {
  signInRequestState: action.requestState,
});

const signInSuccess = (state, action) => {
  axios.defaults.headers.common['x-auth-token'] = `${action.token}`;

  return updateObject(state, {
    token: action.token,
    signInRequestState: action.requestState,
  });
};

const signInIdle = (state, action) => updateObject(state, {
  signInRequestState: action.requestState,
});

const forgotPasswordStart = (state, action) => updateObject(state, {
  forgotPasswordRequestState: action.requestState,
});

const forgotPasswordFail = (state, action) => updateObject(state, {
  forgotPasswordRequestState: action.requestState,
});

const forgotPasswordSuccess = (state, action) => updateObject(state, {
  forgotPasswordRequestState: action.requestState,
});

const forgotPasswordIdle = (state, action) => updateObject(state, {
  forgotPasswordRequestState: action.requestState,
});

const resetPasswordStart = (state, action) => updateObject(state, {
  resetPasswordRequestState: action.requestState,
});

const resetPasswordFail = (state, action) => updateObject(state, {
  resetPasswordRequestState: action.requestState,
});

const resetPasswordSuccess = (state, action) => updateObject(state, {
  token: action.token,
  resetPasswordRequestState: action.requestState,
});

const resetPasswordIdle = (state, action) => updateObject(state, {
  resetPasswordRequestState: action.requestState,
});

const signUpInviteStart = (state, action) => updateObject(state, {
  signUpInviteRequestState: action.requestState,
});

const signUpInviteFail = (state, action) => updateObject(state, {
  signUpInviteRequestState: action.requestState,
});

const signUpInviteSuccess = (state, action) => updateObject(state, {
  token: action.token,
  signUpInviteRequestState: action.requestState,
});

const signUpInviteIdle = (state, action) => updateObject(state, {
  signUpInviteRequestState: action.requestState,
});

// eslint-disable-next-line default-param-last
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_SIGN_IN_REQUEST_START:
      return signInStart(state, action);
    case AUTH_SIGN_IN_REQUEST_FAIL:
      return signInFail(state, action);
    case AUTH_SIGN_IN_REQUEST_SUCCESS:
      return signInSuccess(state, action);
    case AUTH_SIGN_IN_REQUEST_IDLE:
      return signInIdle(state, action);
    case AUTH_FORGOT_PASSWORD_REQUEST_START:
      return forgotPasswordStart(state, action);
    case AUTH_FORGOT_PASSWORD_REQUEST_FAIL:
      return forgotPasswordFail(state, action);
    case AUTH_FORGOT_PASSWORD_REQUEST_SUCCESS:
      return forgotPasswordSuccess(state, action);
    case AUTH_FORGOT_PASSWORD_REQUEST_IDLE:
      return forgotPasswordIdle(state, action);
    case AUTH_RESET_PASSWORD_REQUEST_START:
      return resetPasswordStart(state, action);
    case AUTH_RESET_PASSWORD_REQUEST_FAIL:
      return resetPasswordFail(state, action);
    case AUTH_RESET_PASSWORD_REQUEST_SUCCESS:
      return resetPasswordSuccess(state, action);
    case AUTH_RESET_PASSWORD_REQUEST_IDLE:
      return resetPasswordIdle(state, action);
    case AUTH_SIGNUP_INVITE_REQUEST_START:
      return signUpInviteStart(state, action);
    case AUTH_SIGNUP_INVITE_REQUEST_FAIL:
      return signUpInviteFail(state, action);
    case AUTH_SIGNUP_INVITE_REQUEST_SUCCESS:
      return signUpInviteSuccess(state, action);
    case AUTH_SIGNUP_INVITE_REQUEST_IDLE:
      return signUpInviteIdle(state, action);
    default:
      return state;
  }
};

export default reducer;
