import React, {
  useState, useEffect, forwardRef, useImperativeHandle,
} from 'react';
import { apiRequestState, get, set } from '../../../utility/utility';
import CoreFormDualAction from '../../core/form/CoreFormDualAction';
import { INSPECTION_SAMPLE_FORM_MODES, populateProductsSelection } from '../../../utility/InspectionsUtils';
import {
  renderCheckbox, renderSelectField, validateForm,
} from '../../core/input/CoreInputUtils';

const fieldsToValidate = ['productId'];
const InspectionFormSample = forwardRef(({
  dispatchInspectionsPut,
  inspectionsPutRequestState,
}, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentInspection, setCurrentInspection] = useState(null);
  const [currentSample, setCurrentSample] = useState(null);
  const [inputState, setInputState] = useState({});
  const [error, setError] = useState({});
  const [modeState, setModeState] = useState(null);

  useImperativeHandle(ref, () => ({
    open: (inspection, mode, sample) => {
      setCurrentInspection(inspection);
      setCurrentSample(sample);
      setIsOpen(true);
      setModeState(mode);
      setError({});

      switch (mode) {
        case INSPECTION_SAMPLE_FORM_MODES.new:
          setInputState({});
          break;
        case INSPECTION_SAMPLE_FORM_MODES.edit:
          setInputState({ ...sample });
          break;
        case INSPECTION_SAMPLE_FORM_MODES.duplicate:
          setInputState({ ...sample });
          break;
        default:
          break;
      }
    },
    close: () => setIsOpen(false),
  }));

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (inspectionsPutRequestState === apiRequestState.SUCCESS) {
      closeModal();
    }
  }, [inspectionsPutRequestState]);

  if (!isOpen) return null;

  const onInputChange = (event) => {
    const newState = { ...inputState };
    newState[event.target.id] = event.target.value;
    setInputState(newState);
    validateForm({
      state: newState,
      fieldsToValidate,
      setError,
    });
  };

  const handleSave = (inspection) => {
    const validation = validateForm({
      state: inputState,
      fieldsToValidate,
      setError,
    });

    if (validation) {
      const update = {};
      const samplesToAdd = [];
      const samplesToUpdate = [];

      switch (modeState) {
        case INSPECTION_SAMPLE_FORM_MODES.new:
          samplesToAdd.push(inputState);
          set(update, 'analysis.samplesToAdd', samplesToAdd);
          dispatchInspectionsPut(inspection, update);
          break;
        case INSPECTION_SAMPLE_FORM_MODES.edit:
          samplesToUpdate.push(inputState);
          set(update, 'analysis.samplesToUpdate', samplesToUpdate);
          dispatchInspectionsPut(inspection, update);
          break;
        case INSPECTION_SAMPLE_FORM_MODES.duplicate:
          delete inputState._id;
          samplesToAdd.push(inputState);
          set(update, 'analysis.samplesToAdd', samplesToAdd);
          dispatchInspectionsPut(inspection, update);
          break;
        default:
          break;
      }
    }
  };

  const renderTitle = (mode) => {
    switch (mode) {
      case INSPECTION_SAMPLE_FORM_MODES.new:
        return 'New Sample';
      case INSPECTION_SAMPLE_FORM_MODES.edit:
        return 'Edit Sample';
      case INSPECTION_SAMPLE_FORM_MODES.duplicate:
        return 'Duplicate Sample';
      default:
        return 'Sample';
    }
  };

  const renderPrimaryAction = (mode) => {
    switch (mode) {
      case INSPECTION_SAMPLE_FORM_MODES.new:
        return 'Create';
      case INSPECTION_SAMPLE_FORM_MODES.edit:
        return 'Save';
      case INSPECTION_SAMPLE_FORM_MODES.duplicate:
        return 'Duplicate';
      default:
        return 'Save';
    }
  };

  return (
    <CoreFormDualAction
      title={renderTitle(modeState)}
      primaryButtonCaption={renderPrimaryAction(modeState)}
      primaryButtonCallback={() => handleSave(currentInspection, currentSample)}
      primaryButtonCallbackStatus={inspectionsPutRequestState === apiRequestState.LOADING}
      primaryButtonDisabled={Object.keys(error).length > 0 || inspectionsPutRequestState === apiRequestState.LOADING}
      secondaryButtonCaption="Cancel"
      secondaryButtonCallback={closeModal}
    >
      {renderSelectField({
        id: 'productId',
        label: 'Product to Sample',
        placeholder: 'Pick a product to sample',
        inputState,
        onInputChange,
        options: populateProductsSelection(get(currentInspection, 'shipment.products', [])),
        error,
      })}
      {get(inputState, 'productId', false) && (
        <div>
          {renderCheckbox({
            id: 'defectChecksumIsShown', label: 'Show Checksum', inputState, onInputChange,
          })}
        </div>
      )}
    </CoreFormDualAction>
  );
});

export default InspectionFormSample;
