import { React, useEffect, useState } from 'react';
import './ReportingPage.css';
import { connect } from 'react-redux';
import { FaSearch } from 'react-icons/fa';
import { bookingsGetDocument, reportsContainersGet } from '../actions';
import {
  apiRequestState,
  handleSort, searchByNestedFields,
} from '../utility/utility';
import Select from '../components/Select';
import BookingsShippedOnContainers from '../components/tables/BookingsShippedOnContainers';
import { SORT_OPTIONS, searchableFieldsBookings, sortKeyMap } from '../utility/vesselParams';
import BookingsNone from '../components/BookingsNone';
import BookingsLoading from '../components/tables/BookingsLoading';
import InputField from '../components/core/input/InputField';

function ReportingPage({
  organization,
  bookings,
  dispatchReportsContainersGet,
  dispatchBookingsGetDocument,
  reportsContainersGetRequestState,
}) {
  const TIMELINE_OPTIONS = {
    LAST_30_DAYS: 'Last 30 days',
    LAST_90_DAYS: 'Last 90 days',
    EVER: 'Ever',
  };

  const timelineOptions = [
    TIMELINE_OPTIONS.LAST_30_DAYS,
    TIMELINE_OPTIONS.LAST_90_DAYS,
    TIMELINE_OPTIONS.EVER,
  ];

  const [searchedContainersState, setSearchedContainersState] = useState(bookings);
  const [sortedBookingsState, setSortedContainersState] = useState(searchedContainersState);
  const [timelineState, setTimelineState] = useState(TIMELINE_OPTIONS.LAST_30_DAYS);
  const [searchInputState, setSearchInputState] = useState('');

  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  useEffect(() => {
    // Today's date
    const today = new Date();

    // Add a day TODO: not sure why this is needed but it won't work if archived same day. maybe timezone.
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 2);

    const formattedToday = formatDate(tomorrow);

    // Date 30 days ago
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
    const formattedThirtyDaysAgo = formatDate(thirtyDaysAgo);
    dispatchReportsContainersGet(formattedThirtyDaysAgo, formattedToday);
  }, []);

  const handleSearch = (event) => {
    setSearchInputState(event.target.value);
    const newState = searchByNestedFields(
      bookings,
      searchableFieldsBookings,
      event.target.value,
    );
    setSearchedContainersState(newState);
    handleSort({
      target: {
        value: SORT_OPTIONS.ETA,
      },
    }, newState, sortKeyMap, setSortedContainersState);
  };

  useEffect(() => {
    handleSearch({
      target: {
        value: '',
      },
    });
  }, [bookings]);

  const handleTimelineChange = (event) => {
    let newState = { ...timelineState };

    if (event.target.id === 'timeline') {
      newState = event.target.value;
      // Add a day TODO: not sure why this is needed but it won't work if archived same day. maybe timezone.
      const today = new Date();
      const tomorrow = new Date(today);
      tomorrow.setDate(today.getDate() + 1);
      const formattedToday = formatDate(tomorrow);

      let daysAgo = 30;

      if (event.target.value === TIMELINE_OPTIONS.LAST_90_DAYS) {
        daysAgo = 90;
      } else if (event.target.value === TIMELINE_OPTIONS.EVER) {
        daysAgo = 50000;
      }
      // Date 30 days ago
      const timeAgo = new Date();
      timeAgo.setDate(timeAgo.getDate() - daysAgo);
      const formattedDaysAgo = formatDate(timeAgo);
      dispatchReportsContainersGet(formattedDaysAgo, formattedToday);
    }
    setTimelineState(newState);
  };

  const renderControls = () => (
    <div>
      <div className="reporting-title">Reporting</div>
      <div className="reporting-timeline-select">
        <Select
          onChange={handleTimelineChange}
          id="timeline"
          options={timelineOptions}
          value={timelineState}
        />
      </div>
      <div className="reporting-search-bar">
        <InputField
          placeholder="by PO, container ID, Product, Carrier, Vessel"
          onChange={(event) => handleSearch(event)}
          icon={<FaSearch />}
        >
          Search By
        </InputField>
      </div>
    </div>
  );

  const renderReportedContainers = () => {
    if (!bookings.length) {
      if (reportsContainersGetRequestState === apiRequestState.IDLE) {
        return (
          <BookingsNone
            title="No containers"
            subtitle="No containers to report for the selected time period"
          />
        );
      }
      return <BookingsLoading />;
    }
    return (
      <div className="reporting-table">
        <BookingsShippedOnContainers
          title="Archived Containers"
          searchInput={searchInputState}
          organization={organization}
          rowSelectionIsEnabled={false}
          headerTitles={['', 'ETA', 'Pickup Date', 'Carrier', 'Vessel', 'Container Id', 'Purchase Order', 'Shipper', 'Product', 'Origin', 'Destination', '', '', '']}
          bookings={bookings}
          searchedBookings={sortedBookingsState}
          dispatchBookingsGetDocument={dispatchBookingsGetDocument}
          presetDetailsFields={organization?.bookingSettings?.details}
          presetChecklistFields={organization?.bookingSettings?.checklist}
        />
      </div>
    );
  };

  return (
    <div>
      {renderControls()}
      {renderReportedContainers()}
      <div className="report-view-page-last-element" />
    </div>
  );
}

const mapStateToProps = (state) => ({
  organization: state.organizations.organization,
  bookings: state.reports.bookings,
  reportsContainersGetRequestState: state.reports.reportsContainersGetRequestState,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchReportsContainersGet: (startDate, endDate) => dispatch(reportsContainersGet(startDate, endDate)),
  dispatchBookingsGetDocument: (container, documentType) => dispatch(bookingsGetDocument(container, documentType)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportingPage);
