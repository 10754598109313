// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.containers-cardlist-frame {
    width: 100%;
    padding: 0px;
    margin: 0px;
    background-color: var(--color-grey000);
}

.containers-card-list-location-map {
    display: block;
    position: relative;
    height: 400px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/cardLists/ContainersCardList.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,WAAW;IACX,sCAAsC;AAC1C;;AAEA;IACI,cAAc;IACd,kBAAkB;IAClB,aAAa;IACb,MAAM;IACN,OAAO;IACP,QAAQ;IACR,SAAS;AACb","sourcesContent":[".containers-cardlist-frame {\n    width: 100%;\n    padding: 0px;\n    margin: 0px;\n    background-color: var(--color-grey000);\n}\n\n.containers-card-list-location-map {\n    display: block;\n    position: relative;\n    height: 400px;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
