import React from 'react';
import { useSwipeable } from 'react-swipeable';
import './ImageGallery.css';
import './ImageViewer.css';

function ImageViewer({
  image,
  onClose,
  onNavigateLeft,
  onNavigateRight,
  isSelectable,
  isSelected,
  handleCheckboxChange,
}) {
  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => {
      if (onNavigateRight) {
        onNavigateRight();
      }
    },
    onSwipedRight: () => {
      if (onNavigateLeft) {
        onNavigateLeft();
      }
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  return (
    <div {...swipeHandlers} className="image-viewer-container" onClick={onClose}>
      <button
        type="button"
        className="close-button"
        onClick={(event) => {
          event.stopPropagation();
          onClose();
        }}
      >
        X
      </button>
      <div
        className="nav-arrow left-arrow"
        onClick={(event) => {
          event.stopPropagation();
          onNavigateLeft();
        }}
      >
        &lt;
      </div>
      <div
        className="nav-arrow right-arrow"
        onClick={(event) => {
          event.stopPropagation();
          onNavigateRight();
        }}
      >
        &gt;
      </div>
      <img
        src={image.key}
        alt="Full screen"
        onClick={(event) => event.stopPropagation()}
      />
      {isSelectable && (
        <div
          className={`image-viewer-checkbox-container ${isSelected ? 'selected' : ''}`}
          onClick={(event) => {
            event.stopPropagation();
            handleCheckboxChange(event, image._id);
          }}
        >
          <div className="custom-checkbox" />
          <input
            type="checkbox"
            checked={isSelected}
            onChange={() => handleCheckboxChange(image._id)}
            onClick={(event) => event.stopPropagation()}
          />
        </div>
      )}
      <div className="image-count">
        {image.index + 1}
        {' '}
        /
        {image.total}
      </div>
    </div>
  );
}

export default ImageViewer;
