import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-select';
import './DropdownWithInput.css'; // Import custom CSS
import { get } from '../../../utility/utility';

function DropdownWithInput({
  id, inputState, onChange, path = id, options, label, isRequired = false,
}) {
  const [inputValue, setInputValue] = useState('');
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    const initialValue = get(inputState, path, '');
    setInputValue(initialValue);
  }, [inputState, path]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setMenuIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleInputChange = (newValue, { action }) => {
    if (action === 'input-change') {
      setInputValue(newValue);
      setMenuIsOpen(newValue.length > 0 && options.some((option) => option.label.toLowerCase().includes(newValue.toLowerCase())));
      onChange({ target: { id, value: newValue } }, path);
    }
  };

  const handleChange = (option) => {
    setInputValue(option.label);
    setMenuIsOpen(false);
    onChange({ target: { id, value: option.label } }, path);
  };

  return (
    <div className="dropdown-with-input" ref={inputRef}>
      <label className="dropdown-label" htmlFor={id}>
        {label}
        {isRequired && '*'}
      </label>
      <input
        id={id}
        type="text"
        value={inputValue}
        onChange={(e) => {
          setInputValue(e.target.value);
          setMenuIsOpen(e.target.value.length > 0 && options.some((option) => option.label.toLowerCase().includes(e.target.value.toLowerCase())));
          onChange(e, path);
        }}
        onFocus={() => setMenuIsOpen(inputValue.length > 0 && options.some((option) => option.label.toLowerCase().includes(inputValue.toLowerCase())))}
        placeholder="Type to search or select..."
        className="dropdown-input"
        autoComplete="off" // Disable browser autocomplete
      />
      <Select
        options={options}
        onChange={handleChange}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        menuIsOpen={menuIsOpen}
        onMenuClose={() => setMenuIsOpen(false)}
        onBlur={() => setMenuIsOpen(false)}
        placeholder="Select an option"
        components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
        styles={{
          control: (provided) => ({
            ...provided,
            display: 'none', // Hide the default input field from react-select
          }),
          menu: (provided) => ({
            ...provided,
            marginTop: 0,
            position: 'absolute',
            top: '0px', // Adjust based on the height of your input field
            width: '100%', // Take the full width of the parent component
            zIndex: 1,
          }),
          option: (provided) => ({
            ...provided,
            color: 'black', // Set the option text color to black
          }),
        }}
      />
    </div>
  );
}

export default DropdownWithInput;
