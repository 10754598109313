import React from 'react';
import './ContainerCard.css';
import {
  DATE_OPTIONS, getDateString, getLocationString, get,
} from '../../utility/utility';
import { getBookingIdentifier } from '../../utility/BookingsUtility';
import { processChecklistAlarms } from '../tables/sections/ChecklistSection';
import { getStatusColor } from '../../utility/vesselParams';

function ContainerCard({ organization, booking, onSelect }) {
  const alarm = processChecklistAlarms(booking, organization?.bookingSettings?.checklist);
  return (
    <div className="container-card" onClick={() => onSelect(booking)}>
      <div className="container-card-status" style={{ backgroundColor: getStatusColor(booking).color }} />
      <div className="container-card-body">
        <div className="container-card-line1">
          <div className="container-card-line1-left">
            {getDateString(get(booking, 'tracking.container.status.eta'), DATE_OPTIONS.INCLUDE_WEEK)}
          </div>
          <div className="container-card-line1-right">
            {get(booking, 'booking.shipper')}
            {' '}
            #
            {getBookingIdentifier(booking).value}
          </div>
        </div>
        <div className="container-card-line-carrier">
          <div className="container-card-line-carrier-left-group">
            <span className="container-card-carrier-name">
              {get(booking, 'tracking.container.carrierName')}
            </span>
            <span className="container-card-vessel-name">
              {get(booking, 'tracking.container.status.vessel')}
            </span>
          </div>
          <span className="container-card-container">
            {get(booking, 'tracking.container.containerId')}
          </span>
        </div>

        <div className="container-card-product">
          {get(booking, 'booking.productDescription')}
        </div>
        <div className="container-card-line-journey">
          <div className="container-card-journey">
            <span>
              {getLocationString(
                get(booking, 'tracking.container.status.origin.country'),
                get(booking, 'tracking.container.status.origin.city'),
              )}
              {' '}

            </span>
            <span> &rarr; </span>
            {' '}
            {/* HTML entity for right arrow */}
            <span>
              {getLocationString(
                get(booking, 'tracking.container.status.destination.country'),
                get(booking, 'tracking.container.status.destination.city'),
              )}
            </span>
          </div>
        </div>
        <div className="container-card-line-status">
          {getStatusColor(booking).status}
        </div>
      </div>
      <div className="container-card-alarm" style={{ backgroundColor: alarm.associatedColor }} />
    </div>
  );
}

export default ContainerCard;
