import React, { useEffect, useRef } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import './ImageTile.css';
import { FaHandPaper } from 'react-icons/fa';
import { IMAGE_GALLERY_MODES } from './ImageGallery';
import { MEDIA_MOBILE_MAX_WIDTH } from '../utility/AppParameters';

function ImageTile({
  id, index, image, handleImageClick, handleCheckboxChange, isSelected, isSelectable, mode,
}) {
  const {
    attributes, listeners, setNodeRef, transform, transition, isDragging,
  } = useSortable({ id });
  const isDesktopOrLaptop = useMediaQuery({ minDeviceWidth: MEDIA_MOBILE_MAX_WIDTH });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const isRearrangeMode = mode === IMAGE_GALLERY_MODES.rearrange;

  const tileRef = useRef();
  const handleRef = useRef();

  useEffect(() => {
    if (!isDesktopOrLaptop && isRearrangeMode) {
      const node = handleRef.current;

      const handleTouchStart = (event) => {
        event.stopPropagation();
        event.preventDefault();
      };

      const handleTouchMove = (event) => {
        event.stopPropagation();
        event.preventDefault();
      };

      const handleTouchEnd = (event) => {
        event.stopPropagation();
      };

      node.addEventListener('touchstart', handleTouchStart, { passive: false });
      node.addEventListener('touchmove', handleTouchMove, { passive: false });
      node.addEventListener('touchend', handleTouchEnd);

      return () => {
        if (node) {
          node.removeEventListener('touchstart', handleTouchStart, { passive: false });
          node.removeEventListener('touchmove', handleTouchMove, { passive: false });
          node.removeEventListener('touchend', handleTouchEnd);
        }
      };
    }
    return undefined;
  }, [isRearrangeMode, isDragging, isDesktopOrLaptop]);

  return (
    <div
      ref={(node) => {
        setNodeRef(node);
        tileRef.current = node;
      }}
      style={style}
      {...attributes}
      className={`image-tile ${isRearrangeMode && !isDragging ? 'wobble' : ''} ${isDragging ? 'is-dragging' : ''}`}
      onClick={(event) => {
        if (!isDragging && mode !== IMAGE_GALLERY_MODES.rearrange) {
          event.stopPropagation();
          handleImageClick(index);
        }
      }}
      {...(isRearrangeMode && isDesktopOrLaptop ? listeners : {})} // Apply listeners for desktop/laptop
    >
      <img src={image.thumbnailKey} alt="inspectedImage" />
      {isRearrangeMode && !isDesktopOrLaptop && (
        <div ref={handleRef} className="image-tile-drag-handle" {...listeners}>
          <i className="icon-drag-handle"><FaHandPaper /></i>
        </div>
      )}
      {mode === IMAGE_GALLERY_MODES.select && isSelectable && (
        <div
          className={`image-tile-checkbox-container ${isSelected ? 'selected' : ''}`}
          onClick={(event) => {
            event.stopPropagation();
            handleCheckboxChange(event, id);
          }}
        >
          <div className="checkbox">
            <input
              type="checkbox"
              checked={isSelected}
              onChange={(event) => handleCheckboxChange(event, id)}
              onClick={(event) => event.stopPropagation()}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default ImageTile;
