import React from 'react';
import _ from 'lodash';
import { FaEdit, FaTrash } from 'react-icons/fa';
import BubbleCard from '../../BubbleCard';
import { getDateString, get, uuid } from '../../../utility/utility';
import Button from '../../core/input/Button';
import CoreCard from '../../core/content/CoreCard';

function OrganizationBubbleTeammates({
  organization,
  handleButtonOrganizationFormTeammateOpen,
  dispatchUserDelete,
}) {
  function getAllKeys(obj) {
    let keys = [];

    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        if (typeof obj[key] === 'object' && obj[key] !== null) {
          // For nested objects, prepend the parent key to the nested keys (e.g., "parent.child")
          const nestedKeys = getAllKeys(obj[key]).map((nestedKey) => `${key}.${nestedKey}`);
          keys = keys.concat(nestedKeys);
        } else if (typeof obj[key] === 'boolean' && obj[key] === true) {
          // Only push the key if it's a boolean and true
          keys.push(key);
        }
      }
    }

    return keys;
  }

  const userDelete = (user) => {
    dispatchUserDelete(user);
  };

  const renderName = (user) => {
    const result = [];

    const firstName = get(user, 'firstName');
    const lastName = get(user, 'lastName');

    if (firstName) result.push(_.startCase(firstName));
    if (lastName) result.push(_.startCase(lastName));

    return result.join(' ');
  };

  return (
    <BubbleCard title="Teammates">
      {get(organization, 'users', []).map((user) => (
        <CoreCard
          key={get(user, '_id')}
          style={{ paddingTop: '5px', paddingBottom: '5px' }}
          isHoverable
          content={(
            <div>
              <div style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                {renderName(user)}
              </div>
              <div style={{
                paddingLeft: '10px', paddingRight: '10px', fontSize: '12px', fontWeight: '300',
              }}
              >
                {(get(user, 'email'))}
              </div>
              <div style={{
                paddingLeft: '10px', paddingRight: '10px', fontSize: '12px', fontStyle: 'italic', color: 'gray',
              }}
              >
                {`Member since ${getDateString(get(user, 'createdAt'))}`}
              </div>
              <div style={{
                paddingLeft: '10px', paddingRight: '10px', fontSize: '12px', fontStyle: 'italic', color: 'gray',
              }}
              >
                {`Permissions: [${getAllKeys(user.permissions).join(', ')}]`}
              </div>
            </div>
          )}
          actions={[
            {
              _id: uuid(),
              text: 'Edit',
              icon: <FaEdit title="Edit" />,
              onClick: () => handleButtonOrganizationFormTeammateOpen(organization, user),
            },
            {
              _id: uuid(),
              text: 'Delete',
              icon: <FaTrash title="Delete" />,
              onClick: () => userDelete(user),
            },
          ]}
        />
      ))}
      <div style={{ display: 'flex', justifyContent: 'flex-start', paddingLeft: '10px' }}>
        <Button
          style={{ width: 'auto', paddingLeft: '10px', paddingRight: '10px' }}
          primary
          simple
          onClick={() => handleButtonOrganizationFormTeammateOpen(organization, null)}
        >
          Invite Teammate
        </Button>
      </div>
    </BubbleCard>
  );
}

export default OrganizationBubbleTeammates;
