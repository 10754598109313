import React, {
  useState, useEffect, forwardRef, useImperativeHandle,
} from 'react';
import Mustache from 'mustache';
import _ from 'lodash';

import { apiRequestState, capitalizeFirstLetter } from '../../../utility/utility';
import Select from '../../Select';
import CoreFormDualAction from '../../core/form/CoreFormDualAction';
import TextArea from '../../core/input/TextArea';
import InputField from '../../core/input/InputField';

const BookingFormSingleEmail = forwardRef(({
  dispatchEmailsPost,
  user,
  organization,
  emailsPostRequestState,
}, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [inputState, setInputState] = useState({
    template: '',
    booking: {},
    user,
    tags: [],
  });
  const [generatedEmailState, setGeneratedEmailState] = useState({
    subject: '',
    message: '',
    to: '',
    cc: '',
    attachments: [],
  });
  const [error, setError] = useState(null);

  useImperativeHandle(ref, () => ({
    open: (booking) => {
      setIsOpen(true);
      setInputState({
        template: '',
        booking: _.cloneDeep(booking),
        user,
        tags: [],
      });
      setError(null);
    },
    close: () => setIsOpen(false),
  }));

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (emailsPostRequestState === apiRequestState.SUCCESS) {
      closeModal();
    }
  }, [emailsPostRequestState]);

  if (!isOpen) return null;

  const validateForm = (state) => {
    if (!state.template) {
      setError({ template: 'Email template is required.' });
      return false;
    }
    setError(null);
    return true;
  };

  const handleGeneratedEmailChange = (event) => {
    const newGeneratedEmailState = { ...generatedEmailState };
    switch (event.target.id) {
      case 'to':
        newGeneratedEmailState.to = event.target.value;
        break;

      case 'cc':
        newGeneratedEmailState.cc = event.target.value;
        break;

      case 'subject':
        newGeneratedEmailState.subject = event.target.value;
        break;

      case 'message':
        newGeneratedEmailState.message = event.target.value;
        break;

      default:
        break;
    }
    setGeneratedEmailState(newGeneratedEmailState);
  };

  const handleChange = (event) => {
    const newState = { ...inputState };

    switch (event.target.id) {
      case 'template': {
        newState.template = event.target.value;
        const templateIndex = organization.emailTemplates.findIndex((obj) => obj.name === event.target.value);
        const newGeneratedEmailState = {};

        if (templateIndex >= 0) {
          const templateSubjectString = organization.emailTemplates[templateIndex]?.subjectHandlebar;
          const templateMessageString = organization.emailTemplates[templateIndex]?.messageHandlebar;
          const attachments = organization.emailTemplates[templateIndex]?.attachments;
          const tags = organization.emailTemplates[templateIndex]?.tags;

          // Convert array of parameters to object of key/values
          const data = {};
          const newtags = _.cloneDeep(tags);
          // eslint-disable-next-line no-param-reassign
          newtags.forEach((tag) => {
            const nestedValue = _.get(inputState, tag.value);
            if (nestedValue) {
              // eslint-disable-next-line no-param-reassign
              tag.value = nestedValue;
            } else {
              // eslint-disable-next-line no-param-reassign
              tag.value = '';
            }
            data[tag.id] = tag.value;
          });

          const newAttachments = _.cloneDeep(attachments);
          // Check if the attachments are available and fill in values
          newAttachments.forEach((attachment) => {
            const nestedValue = _.get(inputState, attachment.value);
            if (nestedValue) {
              // eslint-disable-next-line no-param-reassign
              attachment.value = nestedValue;
            } else {
              // eslint-disable-next-line no-param-reassign
              attachment.value = '';
            }
            data[attachment.id] = attachment.value;
          });

          newGeneratedEmailState.to = organization.emailTemplates[templateIndex]?.to;
          newGeneratedEmailState.cc = `${user.email}`;
          newGeneratedEmailState.subject = Mustache.render(templateSubjectString, data);
          newGeneratedEmailState.message = Mustache.render(templateMessageString, data);
          newGeneratedEmailState.attachments = newAttachments;
        } else {
          newGeneratedEmailState.to = '';
          newGeneratedEmailState.cc = '';
          newGeneratedEmailState.subject = '';
          newGeneratedEmailState.message = '';
        }
        setGeneratedEmailState(newGeneratedEmailState);
        break;
      }
      default:
        break;
    }
    setInputState(newState);
    validateForm(newState);
  };

  const handleSendEmail = (state) => {
    if (validateForm(inputState)) {
      dispatchEmailsPost(state);
    }
  };

  const templateOptions = [].concat(organization.emailTemplates.map((emailTemplate) => emailTemplate.name));

  return (
    <CoreFormDualAction
      title="Send Email"
      primaryButtonCaption="Send"
      primaryButtonCallback={() => handleSendEmail(generatedEmailState)}
      primaryButtonCallbackStatus={emailsPostRequestState === apiRequestState.LOADING}
      primaryButtonDisabled={error || emailsPostRequestState === apiRequestState.LOADING}
      secondaryButtonCaption="Cancel"
      secondaryButtonCallback={closeModal}
    >
      <Select id="template" value={inputState.template ?? ''} onChange={handleChange} options={[''].concat(templateOptions)}>Template</Select>
      {error?.template && <div style={{ color: 'var(--color-warning)' }}>{error.template}</div>}
      {inputState.template.length
        ? (
          <>
            <InputField id="to" onChange={handleGeneratedEmailChange} label="To" value={generatedEmailState.to} />
            <InputField id="cc" onChange={handleGeneratedEmailChange} label="cc" value={generatedEmailState.cc} />
            <InputField id="subject" onChange={handleGeneratedEmailChange} label="Subject" value={generatedEmailState.subject} />
            <TextArea id="message" style={{ minHeight: '250px' }} onChange={handleGeneratedEmailChange} label="Message" value={generatedEmailState.message} />
            <label>Attachments</label>
            {generatedEmailState.attachments.map((attachment) => (
              <div style={{ paddingLeft: '25px' }}>
                <label>
                  {`- ${capitalizeFirstLetter(_.startCase(attachment.id))}`}
                  <span style={{ color: 'red', fontStyle: 'italic', paddingLeft: '10px' }}>{attachment.value.length !== 0 ? '' : 'Missing'}</span>
                </label>
              </div>
            ))}
          </>
        )
        : <div />}
    </CoreFormDualAction>
  );
});

export default BookingFormSingleEmail;
