import React from 'react';
import './DatePicker.css';

function DatePicker(props) {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
      <p className="date-picker-label">{props.children}</p>
      <div className="date-picker-container">
        <input
          className="date-picker"
          value={props.value}
          type="date"
          name={props.name}
          id={props.id}
          onChange={props.onChange}
        />
      </div>
    </div>
  );
}

export default DatePicker;
