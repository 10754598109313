import React from 'react';
import _ from 'lodash';
import InputField from './InputField';
import Select from '../../Select';
import { get } from '../../../utility/utility';
import DateTimePicker from './DateTimePicker';
import TextArea from './TextArea';
import CoreInputCheckbox from './CoreInputCheckbox';
import DropdownWithInput from './DropdownWithInput';

export const renderInputField = ({
  id,
  path = id,
  label,
  placeholder,
  inputState,
  onInputChange,
  error,
}) => (
  <>
    <InputField
      id={id}
      label={label ?? _.startCase(id)}
      placeholder={placeholder ?? label ?? _.startCase(id)}
      value={get(inputState, path)}
      onChange={(event) => onInputChange(event, path)}
    />
    {get(error, id) && (
      <div style={{ color: 'var(--color-warning)' }}>
        {get(error, id)}
      </div>
    )}
  </>
);

export const renderTextArea = ({
  id,
  path = id,
  label,
  placeholder,
  inputState,
  onInputChange,
  error,
}) => (
  <>
    <TextArea
      id={id}
      label={label ?? _.startCase(id)}
      placeholder={placeholder ?? label ?? _.startCase(id)}
      value={get(inputState, path)}
      onChange={(event) => onInputChange(event, path)}
    />
    {get(error, id) && (
      <div style={{ color: 'var(--color-warning)' }}>
        {get(error, id)}
      </div>
    )}
  </>
);

export const renderSelectField = ({
  id,
  path = id,
  label,
  placeholder,
  inputState,
  onInputChange,
  options,
  error,
}) => (
  <>
    <Select
      id={id}
      value={get(inputState, path)}
      onChange={(event) => onInputChange(event, path)}
      options={[''].concat(options)}
      placeholder={placeholder ?? label ?? _.startCase(id)}
    >
      {label ?? _.startCase(id)}
    </Select>
    {get(error, id) && (
      <div style={{ color: 'var(--color-warning)' }}>
        {get(error, id)}
      </div>
    )}
  </>
);

export const renderInputSelectField = ({
  id,
  path = id,
  label,
  placeholder,
  inputState,
  onInputChange,
  error,
  options,
  isRequired,
}) => (
  <>
    <DropdownWithInput
      id={id}
      options={options}
      label={label}
      isRequired={isRequired}
      inputState={inputState}
      placeholder={placeholder ?? label ?? _.startCase(id)}
      value={get(inputState, path)}
      onChange={(event) => onInputChange(event, path)}
    />
    {get(error, id) && (
      <div style={{ color: 'var(--color-warning)' }}>
        {get(error, id)}
      </div>
    )}
  </>
);

export const renderDateTimePicker = ({
  id,
  path = id,
  label,
  inputState,
  onInputChange,
}) => (
  <DateTimePicker
    id={id}
    label={label ?? _.startCase(id)}
    value={get(inputState, path)}
    onChange={(event) => onInputChange(event, path)}
  />
);

export const renderCheckbox = ({
  id,
  path = id,
  label,
  placeholder,
  inputState,
  onInputChange,
  error,
}) => {
  const onCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    // Instead of modifying the event, directly call onInputChange with the necessary info.
    onInputChange({
      target: {
        id: event.target.id,
        value: isChecked, // Pass the checked state as the value directly.
      },
    });
  };
  return (
    <>
      <CoreInputCheckbox
        id={id}
        label={label ?? _.startCase(id)}
        placeholder={placeholder ?? label ?? _.startCase(id)}
        checked={get(inputState, path)}
        onChange={(event) => onCheckboxChange(event)}
      >
        {label}
      </CoreInputCheckbox>
      {
        get(error, id) && (
          <div style={{ color: 'var(--color-warning)' }}>
            {get(error, id)}
          </div>
        )
      }
    </>
  );
};

export const validateForm = ({
  fieldsToValidate,
  state,
  setError,
}) => {
  // Initialize an object to hold error messages for each field
  const errors = {};

  // Iterate over the fields and validate
  fieldsToValidate.forEach((fieldId) => {
    if (!get(state, fieldId)) {
      errors[fieldId] = `${_.startCase(fieldId)} is required`;
    }
  });

  // Set the error state based on the validation results
  setError(errors);
  return Object.keys(errors).length === 0;
};
