import React, {
  useState, useEffect, forwardRef, useImperativeHandle,
} from 'react';
import { apiRequestState, get } from '../../../utility/utility';
import CoreFormDualAction from '../../core/form/CoreFormDualAction';
import InspectionImageGallery from '../../InspectionImageGallery';
import { INSPECTION_IMAGE_TYPES, retrieveProductImagesAvailableForAssociation, retrieveProductImagesCurrentlyUsedForAssociation } from '../../../utility/InspectionsUtils';

const InspectionFormImagesSelector = forwardRef(({
  dispatchInspectionImagesAssociate,
  inspectionsImagesAssociateRequestState,
}, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentInspection, setCurrentInspection] = useState({});
  const [productState, setProductState] = useState(null);
  const [sampleState, setSampleState] = useState(null);
  const [defectState, setDefectState] = useState(null);
  const [selectedImagesIdsState, setSelectedImagesIdsState] = useState([]);
  const [initiallySelectedImagesIdsState, setInitiallySelectedImagesIdsState] = useState([]);

  useImperativeHandle(ref, () => ({
    open: (inspection, product, sample, defect) => {
      setCurrentInspection(inspection);
      setProductState(product);
      setSampleState(sample);
      setDefectState(defect);
      setIsOpen(true);

      const images = get(inspection, `onsite.${INSPECTION_IMAGE_TYPES.productImages}`, []);
      const currentlyUsedDefectImages = retrieveProductImagesCurrentlyUsedForAssociation(images, sample._id, defect._id);
      setSelectedImagesIdsState(currentlyUsedDefectImages.map((image) => image._id));
      setInitiallySelectedImagesIdsState(currentlyUsedDefectImages.map((image) => image._id));
    },
    close: () => setIsOpen(false),
  }));

  useEffect(() => {
    if (inspectionsImagesAssociateRequestState === apiRequestState.SUCCESS) {
      setIsOpen(false);
    }
  }, [inspectionsImagesAssociateRequestState]);

  if (!isOpen) return null;

  const closeModal = () => {
    setIsOpen(false);
  };

  const productImages = get(currentInspection, `onsite.${INSPECTION_IMAGE_TYPES.productImages}`, []);

  const handleActionButton = () => {
    const defectImagesToDeassociate = initiallySelectedImagesIdsState.filter((id) => !selectedImagesIdsState.includes(id));
    const defectImagesToAssociate = selectedImagesIdsState.filter((id) => !initiallySelectedImagesIdsState.includes(id));

    const request = {
      defectImagesToAssociate: {
        imagesIds: defectImagesToAssociate,
        defectId: get(defectState, '_id', null),
        sampleId: get(sampleState, '_id', null),
      },
      defectImagesToDeassociate: {
        imagesIds: defectImagesToDeassociate,
      },
    };
    dispatchInspectionImagesAssociate(currentInspection, request);
  };

  return (
    <CoreFormDualAction
      title="Defect Image Selector"
      primaryButtonCaption="Ok"
      primaryButtonCallback={handleActionButton}
      primaryButtonCallbackStatus={inspectionsImagesAssociateRequestState === apiRequestState.LOADING}
      secondaryButtonCaption="Cancel"
      secondaryButtonCallback={closeModal}
    >
      <div>
        <p>
          Pick images to associate with this defect:
        </p>
        <InspectionImageGallery
          images={[
            ...retrieveProductImagesAvailableForAssociation(productImages),
            ...retrieveProductImagesCurrentlyUsedForAssociation(productImages, sampleState._id, defectState._id),
          ].sort((a, b) => new Date(a.metadata.capturedAt) - new Date(b.metadata.capturedAt))}
          productId={productState._id}
          selectedImagesIdsState={selectedImagesIdsState}
          setSelectedImagesIdsState={setSelectedImagesIdsState}
          isSelectable
        />

      </div>
    </CoreFormDualAction>
  );
});

export default InspectionFormImagesSelector;
