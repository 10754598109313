import React from 'react';
import './BubbleCard.css';

function BubbleCard({ title, children, style = { margin: '25px' } }) {
  return (
    <div className="bubble-card-container" style={style}>
      {!!title && (
      <div className="bubble-card-title-block">
        <div className="bubble-card-title">
          {title}
        </div>
      </div>
      )}
      {children}
    </div>
  );
}

export default BubbleCard;
