// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
    position: fixed;
    background-color: var(--color-grey1100);
    min-height: 40px;
    width: 100%;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
    z-index: 150;
    display: inline-block;
}

.logo {
    display: inline-block;
    margin-top: 0;
    padding-top: 10px;
    padding-left: 20px;
    font-size: 1.2em;
    color: var(--color-primaryLight);
    text-align: left;
    width: calc(100% - 75px);
    vertical-align: middle;
}

.menu {
    display: inline-block;
    vertical-align: middle;
    stroke: var(--color-primaryLight);
}`, "",{"version":3,"sources":["webpack://./src/components/Header.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,uCAAuC;IACvC,gBAAgB;IAChB,WAAW;IACX,yCAAyC;IACzC,YAAY;IACZ,qBAAqB;AACzB;;AAEA;IACI,qBAAqB;IACrB,aAAa;IACb,iBAAiB;IACjB,kBAAkB;IAClB,gBAAgB;IAChB,gCAAgC;IAChC,gBAAgB;IAChB,wBAAwB;IACxB,sBAAsB;AAC1B;;AAEA;IACI,qBAAqB;IACrB,sBAAsB;IACtB,iCAAiC;AACrC","sourcesContent":[".header {\n    position: fixed;\n    background-color: var(--color-grey1100);\n    min-height: 40px;\n    width: 100%;\n    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);\n    z-index: 150;\n    display: inline-block;\n}\n\n.logo {\n    display: inline-block;\n    margin-top: 0;\n    padding-top: 10px;\n    padding-left: 20px;\n    font-size: 1.2em;\n    color: var(--color-primaryLight);\n    text-align: left;\n    width: calc(100% - 75px);\n    vertical-align: middle;\n}\n\n.menu {\n    display: inline-block;\n    vertical-align: middle;\n    stroke: var(--color-primaryLight);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
