import React from 'react';
import './InputField.css';

function InputField(props) {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
      {props.label
        ? (
          <p className="input-field-label">
            {props.label}
            {(props.isRequired) ? '*' : ''}
          </p>
        )
        : null}

      <div className="input-icon-container">
        <input
          className="input-field"
          id={props.id}
          type={props.type}
          name={props.name}
          placeholder={props.placeholder}
          onChange={props.onChange}
          onBlur={props.onBlur}
          value={props.value}
          disabled={props.disabled}
        />
        {props.icon
          ? (
            <div className="icon-container">
              <div className="input-icon">
                {props.icon}
              </div>
            </div>
          )
          : null}
      </div>
    </div>
  );
}

export default InputField;
