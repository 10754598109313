import React, { useEffect, useState } from 'react';
import {
  FaEdit,
} from 'react-icons/fa';
import {
  get, set, uuid,
} from '../../../utility/utility';
import './InspectionTabOnsite.css';
import InspectionBubbleAssessment from '../bubbles/InspectionBubbleAssessment';
import InspectionBubbleMeasurements from '../bubbles/inspectionBubbleMeasurements';
import InspectionBubbleProductsImages from '../bubbles/InspectionBubbleProductImages';

function InspectionTabOnsite({
  inspectionState,
  dispatchInspectionsPut,
  dispatchInspectionImagePatch,
  handleButtonInspectionImagesDeleteModalOpen,
  handleButtonInspectionImagesAssociateModalOpen,
  handleButtonInspectionAssessmentFormOpen,
  handleButtonInspectionMeasurementsFormOpen,
}) {
  const [selectedProductImagesIdsState, setSelectedProductImagesIdsState] = useState([]);

  // Toggle select/deselect all
  const toggleSelectAll = (images) => {
    const allSelected = images.every((image) => selectedProductImagesIdsState.includes(image._id));
    let newSelectedProductImagesIdsState;

    if (allSelected) {
      // Remove the IDs of the images that are all currently selected
      newSelectedProductImagesIdsState = selectedProductImagesIdsState.filter((id) => !images.some((image) => image._id === id));
    } else {
      // Add the IDs of the images that are not currently selected
      newSelectedProductImagesIdsState = [
        ...new Set([...selectedProductImagesIdsState, ...images.map((image) => image._id)]),
      ];
    }

    setSelectedProductImagesIdsState(newSelectedProductImagesIdsState);
  };

  const toggleSelectImage = (imageId) => {
    const isSelected = selectedProductImagesIdsState.includes(imageId);
    setSelectedProductImagesIdsState(
      isSelected
        ? selectedProductImagesIdsState.filter((id) => id !== imageId)
        : [...selectedProductImagesIdsState, imageId],
    );
  };

  useEffect(() => {
    setSelectedProductImagesIdsState([]);
  }, [inspectionState]);

  const handleFileDrop = async (files, updateInspection, imageType, assignmentId = null) => {
    try {
      // Directly dispatch action with the original files
      dispatchInspectionImagePatch(updateInspection, files, imageType, assignmentId);
    } catch (error) {
      // Log any errors encountered during the dispatch
      // eslint-disable-next-line
      console.error('Error dispatching files:', error);
    }
  };

  const handleImagesDelete = (imagesType, imagesIds) => {
    handleButtonInspectionImagesDeleteModalOpen(inspectionState, imagesType, imagesIds);
  };

  const handleImagesAssociate = (imagesType, imagesIds) => {
    handleButtonInspectionImagesAssociateModalOpen(inspectionState, imagesType, imagesIds);
  };

  const onDelete = (measurement) => {
    const measurementsToDelete = [measurement];
    const update = {};
    set(update, 'onsite.measurements.temperaturesToDelete', measurementsToDelete);
    dispatchInspectionsPut(inspectionState, update);
  };

  return (
    <div style={{ paddingBottom: '100px' }}>
      <InspectionBubbleProductsImages
        inspection={inspectionState}
        images={get(inspectionState, 'onsite.productImages', [])}
        handleFileDrop={handleFileDrop}
        handleImagesDelete={handleImagesDelete}
        handleImagesAssociate={handleImagesAssociate}
        selectedImagesIdsState={selectedProductImagesIdsState}
        setSelectedImagesIdsState={setSelectedProductImagesIdsState}
        toggleSelectImage={toggleSelectImage}
        toggleSelectAll={toggleSelectAll}
        dispatchInspectionsPut={dispatchInspectionsPut}
      />
      <InspectionBubbleMeasurements
        inspection={inspectionState}
        measurements={get(inspectionState, 'onsite.measurements', {})}
        onFormOpen={handleButtonInspectionMeasurementsFormOpen}
        onDelete={onDelete}
      />
      <InspectionBubbleAssessment
        assessment={get(inspectionState, 'onsite.assessment', {})}
        actions={[
          {
            _id: uuid(),
            text: 'Edit',
            icon: <FaEdit title="Edit" />,
            onClick: () => handleButtonInspectionAssessmentFormOpen(inspectionState),
          },
        ]}
      />
    </div>
  );
}

export default InspectionTabOnsite;
