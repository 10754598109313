import React from 'react';
import _ from 'lodash';
import { getAddressString, get } from '../../../utility/utility';
import CoreCard from '../../core/content/CoreCard';
import BubbleCard from '../../BubbleCard';

function InspectionBubbleParticipant({
  participant,
  partipantType,
  actions,
}) {
  return (
    <BubbleCard
      title={_.startCase(partipantType)}
      style={{ marginBottom: '10px' }}
    >
      <CoreCard
        content={(
          <div>
            <div style={{ paddingLeft: '10px', color: 'var(--color-grey900)' }}>
              {`${get(participant, 'name')} ${get(participant, 'referenceNumber')}`}
            </div>
            <div style={{ paddingLeft: '10px', color: 'var(--color-grey700)' }}>
              <div>{getAddressString(get(participant, 'address')).lines}</div>
              <div>{getAddressString(get(participant, 'address')).cityState}</div>
              <div>{getAddressString(get(participant, 'address')).countryPostalCode}</div>
            </div>
          </div>
        )}
        actions={actions}
      />
    </BubbleCard>
  );
}

export default InspectionBubbleParticipant;
