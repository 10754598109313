import React, { useState } from 'react';
import './ContainersCardList.css';
import {
  FaTrash, FaEdit, FaPaperclip, FaEnvelope, FaShare, FaArchive,
} from 'react-icons/fa';

import ContainerCard from './ContainerCard';
import BookingDetailView from './BookingDetailView';
import BookingSection from '../tables/sections/BookingSection';
import DetailsSection from '../tables/sections/DetailsSection';
import DocumentsSection from '../tables/sections/DocumentSection';
import { ChecklistSection } from '../tables/sections/ChecklistSection';
import ContainerTrackingSection from '../tables/sections/ContainerTrackingSection';
import BookingSharingSection from '../tables/sections/BookingSharingSection';
import Map from '../Map';
import Section from '../tables/sections/Section';
import TitleCard from './TitleCard';

function ContainersCardList({
  title,
  handleTableHeaderClick,
  sortOptions,
  organization,
  bookings,
  handleButtonBookingDeleteModalOpen,
  handleButtonBookingSaveModalOpen,
  handleButtonBookingAttachDocumentModalOpen,
  dispatchBookingsGetDocument,
  handleButtonBookingArchiveModalOpen,
  handleButtonBookingShareModalOpen,
  handleButtonBookingSingleEmailModalOpen,
  sharingSectionEnabled = false,
}) {
  const [selectedBooking, setSelectedBooking] = useState(null);

  return (
    <div>
      {selectedBooking
        ? (
          <BookingDetailView
            onBack={() => setSelectedBooking(null)}
            actions={[
              {
                id: 'edit',
                icon: <FaEdit />,
                callback: () => handleButtonBookingSaveModalOpen(selectedBooking),
              },
              {
                id: 'attach',
                icon: <FaPaperclip />,
                callback: () => handleButtonBookingAttachDocumentModalOpen(selectedBooking, organization.bookingSettings.documents),
              },
              {
                id: 'email',
                icon: <FaEnvelope />,
                callback: () => handleButtonBookingSingleEmailModalOpen(selectedBooking),
              },
              {
                id: 'archive',
                icon: <FaArchive />,
                callback: () => handleButtonBookingArchiveModalOpen([selectedBooking]),
              },
              {
                id: 'share',
                icon: <FaShare />,
                callback: () => handleButtonBookingShareModalOpen([selectedBooking], organization.bookingSettings),
              },
              {
                id: 'delete',
                icon: <FaTrash />,
                callback: () => handleButtonBookingDeleteModalOpen([selectedBooking]),
              },
            ]}
          >
            <BookingSection booking={selectedBooking.booking} />
            <DetailsSection presetDetailsFields={organization.bookingSettings.details} details={selectedBooking.details} />
            <DocumentsSection booking={selectedBooking} dispatchBookingsGetDocument={dispatchBookingsGetDocument} />
            <ChecklistSection booking={selectedBooking} presetChecklistFields={organization.bookingSettings.checklist} />
            {sharingSectionEnabled ? <BookingSharingSection booking={selectedBooking} /> : null}
            <ContainerTrackingSection container={selectedBooking.tracking.container} />
            <Section title="Location">
              <div className="containers-card-list-location-map">
                <Map flyZoom={2} milestones={selectedBooking.tracking.container.status.milestones} />
              </div>
            </Section>
          </BookingDetailView>
        )
        : null}
      <div className="bookings-cardlist-frame">
        <TitleCard
          title={title}
          sortOptions={sortOptions}
          handleTableHeaderClick={handleTableHeaderClick}
        />
        {bookings.map((booking) => (
          <ContainerCard organization={organization} booking={booking} onSelect={setSelectedBooking} />
        ))}
      </div>
    </div>
  );
}

export default ContainersCardList;
