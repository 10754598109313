// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
    position: fixed;
    /* background-color: #2a2b2b; */
    background-color: var(--color-grey1100);
    padding-top: 0px;
    margin-top: 0px;
    bottom: 0;
    min-height: 50px;
    width: 100%;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
    z-index: 120;
}

@media (max-width: 1200px) {
    .footer {
        min-height: 25px; 
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Footer.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,+BAA+B;IAC/B,uCAAuC;IACvC,gBAAgB;IAChB,eAAe;IACf,SAAS;IACT,gBAAgB;IAChB,WAAW;IACX,yCAAyC;IACzC,YAAY;AAChB;;AAEA;IACI;QACI,gBAAgB;IACpB;AACJ","sourcesContent":[".footer {\n    position: fixed;\n    /* background-color: #2a2b2b; */\n    background-color: var(--color-grey1100);\n    padding-top: 0px;\n    margin-top: 0px;\n    bottom: 0;\n    min-height: 50px;\n    width: 100%;\n    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);\n    z-index: 120;\n}\n\n@media (max-width: 1200px) {\n    .footer {\n        min-height: 25px; \n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
