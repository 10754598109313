import { React } from 'react';
import './TextArea.css';

function TextArea({
  value,
  children,
  label,
  isRequired,
  onChange,
  id,
  style,
}) {
  return (
    <>
      <p className="label">
        {label}
        {(isRequired) ? '*' : ''}
      </p>
      <textarea
        className="textarea"
        id={id}
        value={value}
        onChange={onChange}
        style={style}
      >
        {children}
      </textarea>
    </>
  );
}

export default TextArea;
